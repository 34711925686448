const cropImage = async (image, cropX, cropY, cropWidth, cropHeight) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = cropWidth;
  canvas.height = cropHeight;

  ctx.drawImage(
    image,
    cropX,
    cropY,
    cropWidth,
    cropHeight,
    0,
    0,
    cropWidth,
    cropHeight
  );

  const croppedImageUrl = canvas.toDataURL();
  const croppedResponse = await fetch(croppedImageUrl);
  const croppedBlob = await croppedResponse.blob();
  // canvas = null;
  // ctx = null;

  return croppedBlob;
};

export const captureImage = async qrCodeScanner => {
  const canvasElement = qrCodeScanner.current.children[1];

  if (canvasElement) {
    const originalImageUrl = canvasElement.toDataURL();
    const response = await fetch(originalImageUrl);
    const blob = await response.blob();
    const originalImage = await createImageBitmap(blob);

    const cropX = originalImage.width * 0.2;
    const cropY = originalImage.height * 0.2;
    const cropWidth = originalImage.width * 0.6;
    const cropHeight = originalImage.height * 0.6;

    return cropImage(originalImage, cropX, cropY, cropWidth, cropHeight);
  } else return null;
};

export const testSave = (blob, fileName) => {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();
};
