import { FC } from 'react';

import { Svg } from './CenterPart.styles';

export const CenterPart: FC = () => {
  return (
    <Svg
      viewBox="0 0 217 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <g clipPath="url(#clip0_5927_203113)">
        <g clipPath="url(#clip1_5927_203113)">
          <path
            d="M-1231 2H14.3469C17.2999 2 19.6938 4.3939 19.6938 7.34694V52.6531C19.6938 55.6061 17.2999 58 14.3469 58H-1231V2Z"
            fill="#3F536B"
            stroke="black"
            strokeWidth="4"
          />
          <path
            d="M15 54C14.2556 54.6219 13.272 55 12.1907 55L-1229 54.9999"
            stroke="#39609C"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M-1229 4.99998L11.835 5.00003C14.1344 5.00003 16 6.85935 16 9.13905L16 50.9388C16 52.1515 15.474 53.2401 14.6388 54"
            stroke="#A8C2C7"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M3.1632 0.000264143L3.1632 12.539L9.28565 18.6615L9.28565 42.0982L3.1632 48.2207L3.1632 59.835"
            stroke="#000100"
            strokeWidth="3"
            strokeMiterlimit="10"
          />
          <path
            d="M12.1019 10.9054C12.1019 10.0432 11.403 9.34423 10.5407 9.34423C9.67848 9.34423 8.97949 10.0432 8.97949 10.9054C8.97949 11.7677 9.67848 12.4667 10.5407 12.4667C11.403 12.4667 12.1019 11.7677 12.1019 10.9054Z"
            fill="#39609C"
            stroke="#000100"
            strokeWidth="1.22449"
            strokeMiterlimit="10"
          />
          <path
            d="M12.1019 49.3671C12.1019 48.5049 11.403 47.8059 10.5407 47.8059C9.67848 47.8059 8.97949 48.5049 8.97949 49.3671C8.97949 50.2294 9.67848 50.9283 10.5407 50.9283C11.403 50.9283 12.1019 50.2294 12.1019 49.3671Z"
            fill="#39609C"
            stroke="#000100"
            strokeWidth="1.22449"
            strokeMiterlimit="10"
          />
          <path
            d="M1460.94 2H25.5916C22.6385 2 20.2446 4.39391 20.2446 7.34694V52.6531C20.2446 55.6061 22.6385 58 25.5916 58H1460.94V2Z"
            fill="#3F536B"
            stroke="black"
            strokeWidth="4"
          />
          <path
            d="M25 6C25.7466 5.37814 26.733 5.00005 27.8175 5.00005L1459 5.00006"
            stroke="#A8C2C7"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M1459 55L28.1771 55C25.871 55 24 53.1406 24 50.861L24 9.06115C24 7.84855 24.5276 6.7599 25.3652 6"
            stroke="#39609C"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M186.612 0.000264143L186.612 12.539L180.489 18.6615L180.489 42.0982L186.612 48.2207L186.612 59.835"
            stroke="#000100"
            strokeWidth="3"
            strokeMiterlimit="10"
          />
          <path
            d="M27.8365 10.9054C27.8365 10.0432 28.5355 9.34423 29.3978 9.34423C30.26 9.34423 30.959 10.0432 30.959 10.9054C30.959 11.7677 30.26 12.4667 29.3978 12.4667C28.5355 12.4667 27.8365 11.7677 27.8365 10.9054Z"
            fill="#39609C"
            stroke="#000100"
            strokeWidth="1.22449"
            strokeMiterlimit="10"
          />
          <path
            d="M27.8365 48.3671C27.8365 47.5049 28.5355 46.8059 29.3978 46.8059C30.26 46.8059 30.959 47.5049 30.959 48.3671C30.959 49.2294 30.26 49.9283 29.3978 49.9283C28.5355 49.9283 27.8365 49.2294 27.8365 48.3671Z"
            fill="#39609C"
            stroke="#000100"
            strokeWidth="1.22449"
            strokeMiterlimit="10"
          />
          <path
            d="M175.898 10.9054C175.898 10.0432 176.597 9.34423 177.459 9.34423C178.321 9.34423 179.02 10.0432 179.02 10.9054C179.02 11.7677 178.321 12.4667 177.459 12.4667C176.597 12.4667 175.898 11.7677 175.898 10.9054Z"
            fill="#39609C"
            stroke="#000100"
            strokeWidth="1.22449"
            strokeMiterlimit="10"
          />
          <path
            d="M175.898 48.3671C175.898 47.5049 176.597 46.8059 177.459 46.8059C178.321 46.8059 179.02 47.5049 179.02 48.3671C179.02 49.2294 178.321 49.9283 177.459 49.9283C176.597 49.9283 175.898 49.2294 175.898 48.3671Z"
            fill="#39609C"
            stroke="#000100"
            strokeWidth="1.22449"
            strokeMiterlimit="10"
          />
          <path
            d="M76.7381 24.0067C75.8456 24.0067 75.1222 23.2833 75.1222 22.3908C75.1222 21.4984 75.8456 20.7749 76.7381 20.7749C77.6305 20.7749 78.354 21.4984 78.354 22.3908C78.354 23.2833 77.6305 24.0067 76.7381 24.0067Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M76.7381 31.2747C75.8456 31.2747 75.1222 30.5512 75.1222 29.6588C75.1222 28.7663 75.8456 28.0428 76.7381 28.0428C77.6305 28.0428 78.354 28.7663 78.354 29.6588C78.354 30.5512 77.6305 31.2747 76.7381 31.2747Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M76.7381 38.5428C75.8456 38.5428 75.1222 37.8193 75.1222 36.9268C75.1222 36.0344 75.8456 35.3109 76.7381 35.3109C77.6305 35.3109 78.354 36.0344 78.354 36.9268C78.354 37.8193 77.6305 38.5428 76.7381 38.5428Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M92.6194 24.0067C91.727 24.0067 91.0035 23.2833 91.0035 22.3908C91.0035 21.4984 91.727 20.7749 92.6194 20.7749C93.5119 20.7749 94.2354 21.4984 94.2354 22.3908C94.2354 23.2833 93.5119 24.0067 92.6194 24.0067Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M92.6194 31.2747C91.727 31.2747 91.0035 30.5512 91.0035 29.6588C91.0035 28.7663 91.727 28.0428 92.6194 28.0428C93.5119 28.0428 94.2354 28.7663 94.2354 29.6588C94.2354 30.5512 93.5119 31.2747 92.6194 31.2747Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M92.6194 38.5428C91.727 38.5428 91.0035 37.8193 91.0035 36.9268C91.0035 36.0344 91.727 35.3109 92.6194 35.3109C93.5119 35.3109 94.2354 36.0344 94.2354 36.9268C94.2354 37.8193 93.5119 38.5428 92.6194 38.5428Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M108.502 24.0067C107.609 24.0067 106.886 23.2833 106.886 22.3908C106.886 21.4984 107.609 20.7749 108.502 20.7749C109.394 20.7749 110.118 21.4984 110.118 22.3908C110.118 23.2833 109.394 24.0067 108.502 24.0067Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M108.502 31.2747C107.609 31.2747 106.886 30.5512 106.886 29.6588C106.886 28.7663 107.609 28.0428 108.502 28.0428C109.394 28.0428 110.118 28.7663 110.118 29.6588C110.118 30.5512 109.394 31.2747 108.502 31.2747Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M108.502 38.5428C107.609 38.5428 106.886 37.8193 106.886 36.9268C106.886 36.0344 107.609 35.3109 108.502 35.3109C109.394 35.3109 110.118 36.0344 110.118 36.9268C110.118 37.8193 109.394 38.5428 108.502 38.5428Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M124.384 24.0067C123.492 24.0067 122.768 23.2833 122.768 22.3908C122.768 21.4984 123.492 20.7749 124.384 20.7749C125.277 20.7749 126 21.4984 126 22.3908C126 23.2833 125.277 24.0067 124.384 24.0067Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M124.384 31.2747C123.492 31.2747 122.768 30.5512 122.768 29.6588C122.768 28.7663 123.492 28.0428 124.384 28.0428C125.277 28.0428 126 28.7663 126 29.6588C126 30.5512 125.277 31.2747 124.384 31.2747Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M124.384 38.5428C123.492 38.5428 122.768 37.8193 122.768 36.9268C122.768 36.0344 123.492 35.3109 124.384 35.3109C125.277 35.3109 126 36.0344 126 36.9268C126 37.8193 125.277 38.5428 124.384 38.5428Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M116.443 20.443C115.551 20.443 114.827 19.7195 114.827 18.8271C114.827 17.9347 115.551 17.2112 116.443 17.2112C117.336 17.2112 118.059 17.9347 118.059 18.8271C118.059 19.7195 117.336 20.443 116.443 20.443Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M116.443 27.7111C115.551 27.7111 114.827 26.9876 114.827 26.0952C114.827 25.2027 115.551 24.4792 116.443 24.4792C117.336 24.4792 118.059 25.2027 118.059 26.0952C118.059 26.9876 117.336 27.7111 116.443 27.7111Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M116.443 34.9772C115.551 34.9772 114.827 34.2537 114.827 33.3613C114.827 32.4688 115.551 31.7454 116.443 31.7454C117.336 31.7454 118.059 32.4688 118.059 33.3613C118.059 34.2537 117.336 34.9772 116.443 34.9772Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M116.443 42.2434C115.551 42.2434 114.827 41.52 114.827 40.6275C114.827 39.7351 115.551 39.0116 116.443 39.0116C117.336 39.0116 118.059 39.7351 118.059 40.6275C118.059 41.52 117.336 42.2434 116.443 42.2434Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M100.561 20.443C99.6684 20.443 98.9449 19.7195 98.9449 18.8271C98.9449 17.9347 99.6684 17.2112 100.561 17.2112C101.453 17.2112 102.177 17.9347 102.177 18.8271C102.177 19.7195 101.453 20.443 100.561 20.443Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M100.561 27.7111C99.6684 27.7111 98.9449 26.9876 98.9449 26.0952C98.9449 25.2027 99.6684 24.4792 100.561 24.4792C101.453 24.4792 102.177 25.2027 102.177 26.0952C102.177 26.9876 101.453 27.7111 100.561 27.7111Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M100.561 34.9772C99.6684 34.9772 98.9449 34.2537 98.9449 33.3613C98.9449 32.4688 99.6684 31.7454 100.561 31.7454C101.453 31.7454 102.177 32.4688 102.177 33.3613C102.177 34.2537 101.453 34.9772 100.561 34.9772Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M100.561 42.2434C99.6684 42.2434 98.9449 41.52 98.9449 40.6275C98.9449 39.7351 99.6684 39.0116 100.561 39.0116C101.453 39.0116 102.177 39.7351 102.177 40.6275C102.177 41.52 101.453 42.2434 100.561 42.2434Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M84.679 20.443C83.7866 20.443 83.0631 19.7195 83.0631 18.8271C83.0631 17.9347 83.7866 17.2112 84.679 17.2112C85.5715 17.2112 86.2949 17.9347 86.2949 18.8271C86.2949 19.7195 85.5715 20.443 84.679 20.443Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M84.679 27.7111C83.7866 27.7111 83.0631 26.9876 83.0631 26.0952C83.0631 25.2027 83.7866 24.4792 84.679 24.4792C85.5715 24.4792 86.2949 25.2027 86.2949 26.0952C86.2949 26.9876 85.5715 27.7111 84.679 27.7111Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M84.679 34.9772C83.7866 34.9772 83.0631 34.2537 83.0631 33.3613C83.0631 32.4688 83.7866 31.7454 84.679 31.7454C85.5715 31.7454 86.2949 32.4688 86.2949 33.3613C86.2949 34.2537 85.5715 34.9772 84.679 34.9772Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M84.679 42.2434C83.7866 42.2434 83.0631 41.52 83.0631 40.6275C83.0631 39.7351 83.7866 39.0116 84.679 39.0116C85.5715 39.0116 86.2949 39.7351 86.2949 40.6275C86.2949 41.52 85.5715 42.2434 84.679 42.2434Z"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            className="dot"
          />
          <path
            d="M197.026 4.36612L197.026 53.6069"
            stroke="#A8C2C7"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M200.077 58.4437L200.077 1.83557"
            stroke="#000100"
            strokeWidth="3"
            strokeMiterlimit="10"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5927_203113">
          <rect width="217" height="60" fill="white" />
        </clipPath>
        <clipPath id="clip1_5927_203113">
          <rect
            width="2695.94"
            height="60"
            fill="white"
            transform="translate(-1233)"
          />
        </clipPath>
      </defs>
    </Svg>
  );
};
