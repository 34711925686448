import get from 'lodash/get';

const LOCAL_STORAGE_KEY = 'SJ-MEATAVERSE';

const defaultLocalState = {};

export default {
  get: (key: string, session = false) => {
    try {
      const serializedData = session
        ? sessionStorage.getItem(LOCAL_STORAGE_KEY)
        : localStorage.getItem(LOCAL_STORAGE_KEY);
      if (serializedData === null) return get(defaultLocalState, key);
      return get(JSON.parse(serializedData), key);
    } catch (e) {
      // no local storage (ssr or incognito mode)
      return null;
    }
  },
  set: (key: string, val: any, session = false) => {
    try {
      if (val !== undefined) {
        const storage = session ? sessionStorage : localStorage;
        const serializedData = storage.getItem(LOCAL_STORAGE_KEY);
        if (serializedData === null) {
          storage.setItem(
            LOCAL_STORAGE_KEY,
            JSON.stringify({ ...defaultLocalState, [key]: val })
          );
        } else {
          storage.setItem(
            LOCAL_STORAGE_KEY,
            JSON.stringify({ ...JSON.parse(serializedData), [key]: val })
          );
        }
      }
      return true;
    } catch (e) {
      // no local storage (ssr or incognito mode)
      return false;
    }
  },
};
