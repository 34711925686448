import styled, { css } from 'styled-components';

import assets from 'utils/assets';
import { backgroundImage } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.div<{ index: number }>`
  width: 100%;
  height: 30vw;

  ${mediaTablet(
    css`
      width: 60%;
      max-height: 10em;
    `
  )}

  ${({ index }) => {
    if (index === 0) {
      return css`
        ${backgroundImage(
          assets.svgs.lab.season1.scanner.welcomePopupAssets.assets1,
          [190, 150]
        )}
      `;
    }

    if (index === 1) {
      return css`
        ${backgroundImage(
          assets.svgs.lab.season1.scanner.welcomePopupAssets.assets2Mobile,
          [108, 166],
          assets.svgs.lab.season1.scanner.welcomePopupAssets.assets2,
          [374, 150]
        )}
      `;
    }

    if (index === 2) {
      return css`
        ${backgroundImage(
          assets.svgs.lab.season1.scanner.welcomePopupAssets.assets3,
          [190, 130]
        )}
      `;
    }

    if (index === 3) {
      return css`
        ${backgroundImage(
          assets.svgs.lab.season1.scanner.welcomePopupAssets.assets4,
          [220, 138]
        )}
      `;
    }

    if (index === 4) {
      return css`
        ${backgroundImage(
          assets.svgs.lab.season1.scanner.welcomePopupAssets.assets5,
          [190, 130]
        )}
      `;
    }
  }}
`;
