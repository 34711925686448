import { getShareStructurePath } from 'config/config';

export const getIdOfShare = (url: string): string | null => {
  console.log('getIdOfShare', url);
  return (url && url.split('/').slice(-2, -1)[0]) || null;
};

export const generateLinkToShare = (ogImage: string, imageID: string) => {
  if (imageID) return `${getShareStructurePath()}${imageID}/share.jpg`;
  else return ogImage;
};
