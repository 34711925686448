import styled, { css } from 'styled-components';

import Link from 'components/Link/Link';
import { setVh } from 'utils/styles/mixins';
import { layoutMaxWidthDesktop, mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.header<{ $isNavbarCentered: boolean }>`
  height: ${({ theme }) => theme.layout.header.height}rem;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  z-index: ${({ theme }) => theme.layout.zIndex.header};

  background: linear-gradient(
    270deg,
    ${({ theme }) => theme.colors.turbo} 0%,
    ${({ theme }) => theme.colors.crimson} 100%
  );

  ${({ $isNavbarCentered }) =>
    $isNavbarCentered &&
    css`
      padding: 0 calc(50vw - ${layoutMaxWidthDesktop / 2}rem);
    `};
`;

export const LogoWrapper = styled(Link)`
  position: relative;
  height: 100%;
  margin-left: 25rem;

  ${mediaTablet(css`
    margin-left: 80rem;
  `)};
`;

export const LogoMask = styled.img`
  width: 180rem;
  height: 100%;
  object-fit: cover;
  user-select: none;
  pointer-events: none;
`;

export const Logo = styled.img`
  width: 146rem;
  height: 142rem;
  position: absolute;
  left: 12rem;
  top: 25rem;
`;

const listStyle = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

export const ListDesktop = styled.ul`
  display: none;
  ${mediaTablet(listStyle)};
`;

export const ListMobile = styled.ul`
  ${listStyle}
  ${mediaTablet(css`
    display: none;
  `)};
`;

export const MobileMenuWrapper = styled.section`
  height: ${setVh(100)};
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  z-index: ${({ theme }) => theme.layout.zIndex.headerMenu};
`;
