import { FC } from 'react';

import * as Styled from './WelcomePopupAssets.styles';

export interface WelcomePopupAssetsProps {
  slideIndex: number;
}

const WelcomePopupAssets: FC<WelcomePopupAssetsProps> = ({ slideIndex }) => {
  return <Styled.Wrapper index={slideIndex} />;
};

export default WelcomePopupAssets;
