import styled from 'styled-components';

import { aspectRatio } from 'utils/styles/mixins';

export const Canvas = styled.canvas<{
  $size: { width: number; height: number };
}>`
  width: 100%;
  height: 100%;

  ${({ $size }) => $size && aspectRatio($size.width, $size.height)};
`;
