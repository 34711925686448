import { FC } from 'react';

import Button, { GenericButtonProps } from 'components/Button/Button';

import * as Styled from './ErrorContent.styles';

export const validTitleSizes = ['sm', 'lg'] as const;

export interface ErrorContentProps {
  title?: string;
  titleSize?: typeof validTitleSizes[number];
  body?: string;
  footer?: string;
  cta?: GenericButtonProps | Array<GenericButtonProps>;
  autoJustifyContent?: boolean;
}

const ErrorContent: FC<ErrorContentProps> = ({
  title,
  titleSize = 'sm',
  body,
  cta,
  footer,
  autoJustifyContent = false,
}) => {
  return (
    <Styled.Wrapper $autoJustifyContent={autoJustifyContent}>
      <Styled.TitleWrapper>
        <Styled.Title $titleSize={titleSize}>{title}</Styled.Title>
      </Styled.TitleWrapper>
      <Styled.Body>{body}</Styled.Body>
      {footer && <Styled.Footer>{footer}</Styled.Footer>}
      {cta && (
        <Styled.CtaWrapper as={Array.isArray(cta) ? 'ul' : 'div'}>
          {Array.isArray(cta) ? (
            cta.map((buttonProps, i) => (
              <li key={buttonProps.label || i}>
                <Button {...buttonProps} />
              </li>
            ))
          ) : (
            <Button {...cta} />
          )}
        </Styled.CtaWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default ErrorContent;
