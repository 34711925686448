export const polygonNodeOptions = {
  rpcUrl: 'https://rpc-mumbai.matic.today',
  chainId: 80001,
};

export const customNodeOptions = {
  rpcUrl: 'https://rpc-mumbai.maticvigil.com/"', // Polygon RPC URL
  chainId: 80001, // Polygon chain id
};

export const silverGigaJim =
  'https://storage.googleapis.com/u9ltd-project-3229-slimji-3afe-development-be-uploads/pfp/season0/based_gigajim.png';
export const GoldenGigaJim =
  'https://storage.googleapis.com/u9ltd-project-3229-slimji-3afe-development-be-uploads/pfp/season0/based_gold_gigajim.png';

export const getMagicConnectKey = () => {
  const key =
    process.env.ENV !== 'production'
      ? 'pk_live_740E6360B62C5F8B'
      : 'pk_live_F8D4B3A480875E10';
  return key;
};

export const getMagicAuthKey = () => {
  const key =
    process.env.ENV !== 'production'
      ? 'pk_live_72B20FC8C59A1706'
      : 'pk_live_D7EFE3055B796949';

  return key;
};

export const getBlockchainNodeData = () => {
  return process.env.ENV !== 'production'
    ? {
        rpcUrl: 'https://rpc-mumbai.maticvigil.com/',
        chainId: 80001,
      }
    : {
        rpcUrl: 'https://polygon-rpc.com/', // Polygon RPC URL
        chainId: 137, // Polygon chain id
      };
};
