import axios from 'axios';

import { logMe } from 'utils/logger';

import { buildAPIUrl, deleteApi, getApi, postApi, putApi } from './apiUtils';
import { MagicService } from './magicLink';
import { User } from './user';

import { getReCaptchaID } from 'config/config';
import { API_T } from 'constants/enum';

export const executeRecaptcha = async action => {
  try {
    const token = await window['grecaptcha'].execute(getReCaptchaID(), {
      action: action,
    });
    return token;
  } catch (error) {
    console.error(error);
    return ' ';
  }
};

export class API {
  private static instance: API;
  private static _magic: MagicService;
  private static _user: User;

  static get getI(): API {
    if (!this.instance) {
      this._user = new User();
      this._magic = new MagicService();
      this.instance = new this();

      if (process.env.ENV === 'local')
        axios.defaults.headers.common['Debug-Ignore-Recaptcha'] = true;
    }
    return this.instance;
  }

  static get user(): User {
    return this._user;
  }
  static get magic(): MagicService {
    return this._magic;
  }
  init = () => {
    logMe('init');
  };

  // wallet

  connectWallet = () => {
    return API.magic.loginConnect();
  };
  getInfo = () => {
    return API.magic.getInfo();
  };

  setDebugDate = debugDate => {
    // 2023-06-11T00:00:00
    axios.defaults.headers.common['Debug-Date'] = debugDate;
    // axios.defaults.headers.common['Debug-Ignore-Recaptcha'] = true;
  };

  getNonce = async () => {
    return await getApi(buildAPIUrl({ type: API_T.nonce }), API._user.didToken);
  };

  signWallet = (walletAddress, hashDigest) => {
    return API.magic.personalSign(walletAddress, hashDigest);
  };

  // USER
  codeVerification = async code =>
    await postApi(buildAPIUrl({ type: API_T.unlockCode }), { code });

  isUserExist = async (email: string) =>
    await getApi(buildAPIUrl({ type: API_T.exists, value: email }));

  evolvePfp = async (id: number, formula: string) =>
    await putApi(
      buildAPIUrl({ type: API_T.evolve, value: String(id) }),
      { formula },
      API._user.didToken
    );

  changeActivePfp = async (id: number) =>
    await putApi(
      buildAPIUrl({ type: API_T.setActivePfp, value: String(id) }),
      {},
      API._user.didToken
    );

  pfpHistory = async (id: number) =>
    await getApi(
      buildAPIUrl({
        type: API_T.evolveHistory,
        value: String(id),
        query: '?page_size=20',
      }),
      API._user.didToken
    );

  getUser = async () =>
    await getApi(buildAPIUrl({ type: API_T.me }), API._user.didToken);

  generatePFP = async () =>
    await postApi(buildAPIUrl({ type: API_T.pfp }), {}, API._user.didToken);

  generateShare = async (formData: any) => {
    const recaptcha_token = await executeRecaptcha('submit');
    return await postApi(
      buildAPIUrl({ type: API_T.uploadShare }),
      formData,
      '',
      recaptcha_token
    );
  };

  updateTutorialProgress = async tutorialProgress => {
    this.saveUserSession(API.user.didToken, API.user.email, tutorialProgress);

    return await putApi(
      buildAPIUrl({ type: API_T.tutorial }),
      { tutorialProgress },
      API._user.didToken
    );
  };

  saveUserSession = async (didToken, email, tutorialStatus = null) => {
    const userData = await fetch('/api/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        didToken,
        email,
        tutorialStatus,
      }),
    });

    const user = await userData.json();
    logMe('user', user);
  };

  scanBarcode = async form => {
    const data = await postApi(
      buildAPIUrl({ type: API_T.scanBarcode }),
      form,
      API._user.didToken
    );

    return data;
  };

  mixSauce = async sauces =>
    await postApi(
      buildAPIUrl({ type: API_T.mixSauce }),
      { sauces },
      API._user.didToken
    );

  deleteUser = async () => await deleteApi(buildAPIUrl({ type: API_T.delete }));

  updateUserWallet = async (walletAddress: string) =>
    await putApi(
      buildAPIUrl({ type: API_T.wallet }),
      { walletAddress },
      API._user.didToken
    );

  updateWallet = async (wallet, signedMessage) =>
    await putApi(
      buildAPIUrl({ type: API_T.wallet }),
      { walletAddress: wallet, signedMessage: signedMessage },
      API._user.didToken
    );

  resendCode = async (phone_no: string) =>
    await getApi(
      buildAPIUrl({ type: API_T.resend, value: phone_no }),
      API._user.didToken
    );

  registerUser = async phoneNo => {
    const { code, didToken } = API._user;
    API._user.update({ phoneNo });
    return await postApi(
      buildAPIUrl({ type: API_T.register }),
      { phoneNo, code },
      didToken
    );
  };

  registerUserWithWallet = async (email: string, wallet: string) =>
    await getApi(
      buildAPIUrl({ type: API_T.registerWithWallet, value: [email, wallet] })
    );

  achievementsUnwrap = async () => {
    const { didToken } = API._user;
    return await postApi(
      buildAPIUrl({ type: API_T.achievementUnwrap }),
      {},
      didToken
    );
  };

  activateAccount = async code =>
    await putApi(
      buildAPIUrl({ type: API_T.activate }),
      { code },
      API._user.didToken
    );

  logout() {
    API._magic.logout();
    API._user.clearData();
  }

  // gallery
  getGallery = async galleryOptionsStr => {
    //return generateGalleryMock(100);
    return await getApi(
      buildAPIUrl({ type: API_T.gallery, query: galleryOptionsStr })
    );
  };
  // gallery
  getGallerySimilar = async (pfp_id: string) =>
    await getApi(buildAPIUrl({ type: API_T.gallery, value: pfp_id }));

  getGalleryItem = async (pfp_id: string) =>
    await getApi(buildAPIUrl({ type: API_T.galleryItem, value: pfp_id }));

  getPfpRank = async (pfp_id: string) =>
    await getApi(buildAPIUrl({ type: API_T.galleryRank, value: pfp_id }));

  loginML = async () => await API._magic.login(API._user.email);

  getMetaData = async () => {
    const data = await API._magic.getMetaData();
    API.user.update({ ...(data.metaData as any), didToken: data.didToken });
  };
}
