import { FC, Fragment, useMemo } from 'react';

import Footer from 'components/Footer/Footer';
import Navbar from 'components/Navbar/Navbar';
import { useGlobalStore } from 'store';
import { ROUTES } from 'utils/routes';

import LayoutContainer from './LayoutContainer/LayoutContainer';

import * as Styled from './Layout.styles';

export interface LayoutProps extends FC {
  Container: typeof LayoutContainer;
  FluidContainer: typeof Styled.FluidContainer;
  CenteredContainer: typeof Styled.CenteredContainer;
  DefaultWrapper: typeof Styled.Wrapper;
}

const Layout: LayoutProps = ({ children }) => {
  const { isDesktopView, navbarGlowingElement } = useGlobalStore();
  const Wrapper = useMemo(() => {
    const key = children['key'];

    switch (key) {
      case ROUTES.LAB:
      case ROUTES.LAB_MEATERIALIZER:
      case ROUTES.LAB_INVENTORY:
      case ROUTES.LAB_SAUCE_MIXER:
        return Styled.LabWrapper;
      case ROUTES.HOME:
        return Fragment;
      default:
        return Styled.Wrapper;
    }
  }, [children]);

  return (
    <>
      <Navbar glowingElement={navbarGlowingElement} />
      <Wrapper>{children}</Wrapper>
      {isDesktopView && <Footer />}
    </>
  );
};

Layout.Container = LayoutContainer;
Layout.FluidContainer = Styled.FluidContainer;
Layout.CenteredContainer = Styled.CenteredContainer;
Layout.DefaultWrapper = Styled.Wrapper;

export default Layout;
