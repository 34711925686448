import {
  InventoryTutorialStage,
  LabRoomStage,
  MeaterializerTutorialStage,
  Tutorial,
} from 'components/LaboratoryS1/LabEnum';

export const tmpCapsule = {
  id: 54,
  url: '/images/gallery/1.png',
  nftName: 'Gigajim #54',
  rarity: 'COMMON',
  dateOfBirth: '2023-03-26T15:33:41.954Z',
  score: 54,
  type: 'SEASON1',
  feet: {
    filename: '07_0007s_0008s_0000_legendary_sjpack.png',
    name: 'Legendary Slim Jim Box Shoes',
    marketShare: 77,
  },
  capsule: {
    filename: '13_0013s_0001_common_blackplasticc.png',
    name: 'null Black Plastic Capsule',
    marketShare: 75,
  },
  headAccessory: {
    filename: '',
    name: 'null None',
    marketShare: 42,
  },
  leftHandAccessory: {
    filename: '',
    name: 'null None',
    marketShare: 61,
  },
  hands: {
    filename: '',
    name: 'null None',
    marketShare: 61,
  },
  armAccessory: {
    filename: '',
    name: 'null None',
    marketShare: 51,
  },
  rightHandAccessory: {
    filename: '03_0003s_0006s_0000_legendary_meatsaber.png',
    name: 'Legendary Meat Sword',
    marketShare: 38,
  },
  torso: {
    filename: '06_0006s_0010s_0000_legendary_lbgshirt.png',
    name: 'Legendary LBG T-Shirt',
    marketShare: 10,
  },
  legs: {
    filename: '08_0008s_0000s_0001_epic_sjleggings.png',
    name: 'Epic Slim Jim Leggings',
    marketShare: 27,
  },
  head: {
    filename: '10_0010s_0008_common_monstersize.png',
    name: 'null Monster Size',
    marketShare: 30,
  },
  backAccessory: {
    filename: '11_0011s_0001s_0003_common_pogostick.png',
    name: 'Common Pogo Stick',
    marketShare: 70,
  },
};

export const testBaseSauces = [
  {
    name: 'Cheese',
    count: 10,
  },
  {
    name: 'BBQ',
    count: 10,
  },
  {
    name: 'Hot',
    count: 10,
  },
  {
    name: 'Nice',
    count: 10,
  },
  {
    name: 'Secret',
    count: 10,
  },
];

export const testSuperSauces = [
  {
    name: 'Southpaw Sauce',
    count: 5,
  },
  {
    name: 'Swole Sauce',
    count: 5,
  },
  {
    name: 'Sick Drip Sauce',
    count: 1,
  },
  {
    name: 'GANG GANG Sauce',
    count: 1,
  },
  {
    name: 'Level Up Sauce',
    count: 1,
  },
];

export const testTutorial = {
  [Tutorial.Meatalizer]: MeaterializerTutorialStage.INITIAL,
  [Tutorial.Mixer]: LabRoomStage.INITIAL,
  [Tutorial.Inventory]: InventoryTutorialStage.INITIAL,
  [Tutorial.Scanner]: LabRoomStage.INITIAL,
};

export const testSauces = {
  baseSauces: testBaseSauces,
  superSauces: testSuperSauces,
};
export const testUser = {
  walletAddress: '123123123123123',
  hasAccessToSeasonZero: true,
  pfps: [tmpCapsule],
  sauces: { baseSauces: testBaseSauces, superSauces: testSuperSauces },
  userStats: {
    baseSaucesCollected: 0,
    evolutionsMade: 1,
    superSaucesCreated: 0,
  },
};
