import { FC } from 'react';

import LayoutBackgroundSide from './LayoutBackgroundSide/LayoutBackgroundSide';

import * as Styled from './LayoutContainer.styles';

export enum LayoutBackgroundVariant {
  DEFAULT = 'DEFAULT',
  S1_GRADIENT = 'S1_GRADIENT',
  NONE = 'NONE',
}

export interface LayoutContainerProps {
  background?: LayoutBackgroundVariant;
}

const LayoutContainer: FC<LayoutContainerProps> = ({
  children,
  background = LayoutBackgroundVariant.DEFAULT,
}) => {
  return (
    <Styled.Wrapper>
      <Styled.SideBackgroundContainer>
        <Styled.SideBackgroundWrapper $position="left">
          <LayoutBackgroundSide />
        </Styled.SideBackgroundWrapper>
      </Styled.SideBackgroundContainer>
      <Styled.Children $background={background}>{children}</Styled.Children>
      <Styled.SideBackgroundContainer>
        <Styled.SideBackgroundWrapper $position="right">
          <LayoutBackgroundSide />
        </Styled.SideBackgroundWrapper>
      </Styled.SideBackgroundContainer>
    </Styled.Wrapper>
  );
};

export default LayoutContainer;
