import { FC } from 'react';

import { validSizes } from 'components/InventoryItem/InventoryItem';
import assets from 'utils/assets';

import * as Styled from './SuperSauce.styles';

import { SauceFormula } from 'constants/sauces';

export interface SuperSauceProps {
  label?: string;
  formula?: SauceFormula;
  size?: typeof validSizes[number];
  hasGlowEffect?: boolean;
}

const SuperSauce: FC<SuperSauceProps> = ({
  label,
  formula,
  size,
  hasGlowEffect,
}) => {
  // prettier-ignore
  return (
    <Styled.Wrapper aria-label={label ?? 'Super Sauce'} {...(size && { $size: size })} {...(hasGlowEffect && { $hasGlowEffect: hasGlowEffect })}>
      <Styled.Pie {...(formula && { formula })} aria-hidden='true'/>
      {/* bubbles */}
      <Styled.Img src={assets.svgs.superSauce.bubbles} $mixBlendMode={'soft-light'}  />
      {/* overlay */}
      <Styled.Img src={assets.svgs.superSauce.overlay} $mixBlendMode={ 'multiply'}  />
      {/* frame */}
      <Styled.Img src={assets.svgs.superSauce.frame}  />
      {/* shim */}
      <Styled.Img src={assets.svgs.superSauce.shim} $mixBlendMode={ 'screen'}  />
    </Styled.Wrapper>
  );
};

export default SuperSauce;
