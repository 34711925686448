import React, { useState } from 'react';

import { saveUserSession } from 'services/api/calls/userData';
import { useGlobalStore, useUserStore } from 'store';

import * as Styled from './Version.styles';

import { initialTutorial } from 'constants/global';
import { testSauces, testTutorial, testUser } from 'constants/testItem';

const Version = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onToggle = () => setIsOpen(state => !state);
  const {
    isNftLimitReached: nftLimitReached,
    setIsNftLimitReached: setNftLimitReached,
    isUsaOnly,
    setIsUsaOnly,
  } = useGlobalStore();
  const {
    isLoggedIn,
    setIsLoggedIn,
    setUserSauces,
    setUserData,
    setTutorialStatus,
  } = useUserStore();

  return (
    <Styled.Wrapper>
      {isOpen ? (
        <Styled.Info>
          {`v. ${process.env.VERSION} - (${
            process.env.ENV
          }), built on ${new Date(process.env.BUILD_DATE).toUTCString()}`}
          {'|\n'}

          <Styled.SwitchButton
            onClick={() => {
              setIsLoggedIn(!isLoggedIn);
              setUserSauces(isLoggedIn ? null : testSauces);
              setUserData(isLoggedIn ? null : testUser);
              setTutorialStatus(isLoggedIn ? initialTutorial : testTutorial);
              saveUserSession();
            }}
            $active={isLoggedIn}
          >
            {isLoggedIn ? '[ON]' : '[OFF]'}isLoggedIn
          </Styled.SwitchButton>
          {' | '}
          <Styled.SwitchButton
            onClick={() => setNftLimitReached(!nftLimitReached)}
            $active={nftLimitReached}
          >
            {nftLimitReached ? '[ON] nft-limit' : '[OFF] nft-limit'}
          </Styled.SwitchButton>
          {' | '}
          <Styled.SwitchButton
            onClick={() => setIsUsaOnly(!isUsaOnly)}
            $active={isUsaOnly}
          >
            {isUsaOnly ? '[ON] is-USA' : '[OFF] is-USA'}
          </Styled.SwitchButton>
        </Styled.Info>
      ) : null}
      <Styled.Button onClick={onToggle}>[ {isOpen ? '-' : '+'} ]</Styled.Button>
    </Styled.Wrapper>
  );
};

Version.displayName = 'Version';

export default React.memo(Version);
