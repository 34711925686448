import { FC } from 'react';

import * as Styled from './NavbarMobileMenuLinkWrapper.styles';

type NavbarMobileMenuWrapperProps = {
  isLink: boolean;
  href: string;
  onClick?: () => void;
};

const NavbarMobileMenuLinkWrapper: FC<NavbarMobileMenuWrapperProps> = ({
  isLink,
  href,
  children,
  onClick,
}) => {
  if (isLink) {
    return (
      <Styled.WrapperLink href={href} onClick={onClick}>
        {children}
      </Styled.WrapperLink>
    );
  }

  return <Styled.Wrapper onClick={onClick}>{children}</Styled.Wrapper>;
};

export default NavbarMobileMenuLinkWrapper;
