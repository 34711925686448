import { useRouter } from 'next/router';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import Button from 'components/Button/Button';
import { LabRoomStage } from 'components/LaboratoryS1/LabEnum';
import Tooltip from 'components/Tooltip/Tooltip';
import TooltipContent from 'components/Tooltip/TooltipContent/TooltipContent';
import { scanCode } from 'services/api/calls/userData';
import { useCopyStore, useGlobalStore, useUserStore } from 'store';
import { ROUTES } from 'utils/routes';

import BarcodeInput from './components/BarcodeInput/BarcodeInput';
import NewItemPopup from './components/NewItemPopup/NewItemPopup';
import PermissionsPopup from './components/PermissionsPopup/PermissionsPopup';
import WelcomePopup from './components/WelcomePopup/WelcomePopup';
import ScannerCamera from './ScannerCamera';

import * as Styled from './Scanner.styles';

import { ScanningBonus } from 'constants/enum';

export const SCANNER_MIN_LENGTH = 10;
export const SCANNER_MAX_LENGTH = 13;
export const TIMEOUT_NUMBER_OF_SAUCES_TOOLTIP = 3500; //ms

export enum ScannerState {
  WELCOME = 'WELCOME',
  PERMISSIONS = 'PERMISSIONS',
  SCANNER = 'SCANNER',
  INPUT = 'INPUT',
}

export interface ScannerProps {}

const Scanner: FC<ScannerProps> = () => {
  const router = useRouter();
  const copy = useCopyStore(({ copy }) => copy.lab.scanner);
  const scannerStateRef = useRef(ScannerState.WELCOME);
  const [scannerState, setScannerState] = useState<ScannerState>(
    ScannerState.WELCOME
  );
  const [hasPermissions, setPermissions] = useState<boolean>(false);
  const [hasCheckedPermissions, setCheckedPermissions] =
    useState<boolean>(false);
  const [codeScanned, setCodeScanned] = useState(false);
  const [isValid, setValid] = useState<boolean>(true);
  const [input, setInput] = useState<string>('');
  const [barcode, setBarcode] = useState<string>('');
  const [sent, setSent] = useState(false);
  const [items, setItems] = useState<Array<string>>([]);
  const [rewardType, setRewardType] = useState<ScanningBonus>(null);
  const [newItemPopup, setNewItemPopup] = useState<boolean>(false);
  const [showCollectedSaucesTooltip, setShowCollectedSaucesTooltip] =
    useState<boolean>(false);
  const [numberOfScans, setNumberOfScans] = useState(1);
  const [allowedDailyScans, setAllowedDailyScans] = useState(3);
  const [cameraIsRunning, setCameraIsRunning] = useState(false);

  const { userData, tutorialStatus, setTutorialStatus, setUserSauces } =
    useUserStore();
  const { setUnexpectedError } = useGlobalStore();

  const [tutorialStage, setTutorialStage] = useMemo(() => {
    return [
      tutorialStatus.scanner,
      (stage: LabRoomStage) => setTutorialStatus({ scanner: stage }),
    ];
  }, [setTutorialStatus, tutorialStatus.scanner]);

  useEffect(() => {
    if (hasCheckedPermissions && scannerState === ScannerState.WELCOME) return;

    if (!hasCheckedPermissions && !hasPermissions) {
      handleScannerStateUpdate(ScannerState.PERMISSIONS);
      return;
    }

    if (
      hasCheckedPermissions &&
      hasPermissions &&
      scannerState !== ScannerState.INPUT
    ) {
      handleScannerStateUpdate(ScannerState.SCANNER);
      return;
    }

    if (hasCheckedPermissions && !hasPermissions) {
      handleScannerStateUpdate(ScannerState.INPUT);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPermissions, hasCheckedPermissions, scannerState]);

  useEffect(() => {
    if (input.length >= SCANNER_MIN_LENGTH && !codeScanned) {
      handleScanCodeFromInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, codeScanned]);

  useEffect(() => {
    if (userData) {
      if (tutorialStage === LabRoomStage.INITIAL) {
        handleScannerStateUpdate(ScannerState.WELCOME);
      } else if (tutorialStage === LabRoomStage.FINISHED) {
        handleScannerStateUpdate(ScannerState.PERMISSIONS);
      }
    }
  }, [tutorialStage, userData]);

  const handleScannerStateUpdate = state => {
    scannerStateRef.current = state;
    setScannerState(state);
  };

  const handleScanCodeFromInput = () => {
    scanCode({
      code: input,
      scannerStateRef,
      setValid,
      setBarcode,
      setItems,
      setNewItemPopup,
      setUserSauces,
      setUnexpectedError,
      setRewardType,
      codeFromInput: true,
      setNumberOfScans,
      setAllowedDailyScans,
    });
    setCodeScanned(true);
  };

  const getScannerCopy = () => {
    {
      if (isValid && items) return copy.scan.detected;
      else {
        if (barcode === 'SocialCodeExpiredError') return copy.scan.expired;
        else if (barcode === 'DailyScansLimitReachedError')
          return copy.scan.limit;
        else return copy.scan.invalid;
      }
    }
  };
  const handleChange = event => {
    // const value = event.target.value;

    // check if input only contains numbers and "-"
    //const isValidChar = /^[0-9-]*$/.test(value);

    // if (!isValidChar) return;

    setInput(event.target.value);
    setCodeScanned(false);
    setValid(true);
  };

  const validate = () => {
    if (input.length < SCANNER_MIN_LENGTH && !codeScanned) {
      handleScanCodeFromInput();
    }
  };

  const handleContinue = () => {
    setInput('');
    setBarcode('');
    setValid(true);
    setItems(null);
    setSent(false);
    setNewItemPopup(false);
    setShowCollectedSaucesTooltip(true);

    setTimeout(() => {
      setShowCollectedSaucesTooltip(false);
    }, TIMEOUT_NUMBER_OF_SAUCES_TOOLTIP);
  };

  const handleInputButton = () => {
    setInput('');
    setBarcode('');
    handleScannerStateUpdate(ScannerState.INPUT);
    setValid(true);
  };

  let inner = null;

  if (scannerState === ScannerState.PERMISSIONS) {
    inner = <PermissionsPopup />;
  }

  if (scannerState === ScannerState.SCANNER) {
    inner = (
      <Styled.Overlay>
        <Styled.CameraContainer>
          <Styled.BlurOverlayHole />
          <Styled.AnimatedLaser>
            <Styled.Laser />
            <Styled.Laser reverse />
          </Styled.AnimatedLaser>
          <Styled.ButtonWrapper>
            <span aria-hidden="true" tabIndex={-1}>
              <Button
                onClick={handleInputButton}
                icon="keyboard"
                disabled={!cameraIsRunning}
              />
            </span>
          </Styled.ButtonWrapper>
        </Styled.CameraContainer>
        <Styled.Title dangerouslySetInnerHTML={{ __html: copy.title }} />

        {!!barcode && !newItemPopup && (
          <Styled.ReaderTooltip>
            <TooltipContent
              key="reader-tooltip"
              text={getScannerCopy()}
              fontVariant="md"
              color={isValid ? 'turbo' : 'crimson'}
              arrowPosition="center"
              arrowVariant={isValid ? 'check' : 'invalid'}
            />
          </Styled.ReaderTooltip>
        )}
        {showCollectedSaucesTooltip && !barcode && (
          <Styled.ReaderTooltip>
            <TooltipContent
              key="collected-tooltip"
              text="SAUCES COLLECTED TODAY"
              fontVariant="md"
              color="turbo"
              arrowPosition="center"
              arrowVariant="text"
              arrowText={`${numberOfScans} / ${allowedDailyScans}`}
            />
          </Styled.ReaderTooltip>
        )}
        <Styled.Background />
      </Styled.Overlay>
    );
  }

  if (scannerState === ScannerState.INPUT) {
    inner = (
      <Styled.Overlay>
        <Styled.Title>{copy.input.title}</Styled.Title>
        <Styled.BarcodeInputContainer>
          <Styled.InputWrapper>
            <BarcodeInput
              barcode={input}
              onChange={handleChange}
              onBlur={e => {
                if (
                  !(
                    (e as any)?.relatedTarget?.nodeName === 'BUTTON' ||
                    (e as any)?.relatedTarget?.lastChild?.nodeName === 'BUTTON'
                  )
                ) {
                  validate();
                }
              }}
              placeholder={copy.input.placeholder}
              label={copy.input.label}
              hasError={!isValid}
            />
          </Styled.InputWrapper>
          <Styled.ButtonWrapper>
            <span aria-hidden="true" tabIndex={-1}>
              <Button
                onClick={() => {
                  setInput('');
                  setBarcode('');
                  if (hasPermissions) {
                    handleScannerStateUpdate(ScannerState.SCANNER);
                  } else {
                    handleScannerStateUpdate(ScannerState.PERMISSIONS);
                  }
                }}
                disabled={cameraIsRunning}
                icon="camera"
              />
            </span>
          </Styled.ButtonWrapper>
        </Styled.BarcodeInputContainer>
        <Styled.ToolTipAnchor>
          <Tooltip
            text={getScannerCopy()}
            fontVariant="md"
            color="crimson"
            open={!isValid}
            arrowVariant="invalid"
            arrowPosition="center"
            anchor={<Styled.ToolTipAnchor />}
          />
        </Styled.ToolTipAnchor>
        <Styled.Background />
      </Styled.Overlay>
    );
  }

  return (
    <>
      <ScannerCamera
        {...{
          setPermissions,
          setCheckedPermissions,
          setNewItemPopup,
          setItems,
          setBarcode,
          setValid,
          sent,
          setSent,
          scannerStateRef,
          setRewardType,
          setNumberOfScans,
          setAllowedDailyScans,
          setCameraIsRunning,
        }}
      />
      {inner}
      {newItemPopup && (
        <NewItemPopup
          variant={rewardType}
          sauces={items}
          onClose={() => {
            router.push(ROUTES.LAB_SAUCE_MIXER);
          }}
          onContinue={handleContinue}
        />
      )}
      {scannerState === ScannerState.WELCOME && (
        <WelcomePopup
          onContinue={() => setTutorialStage(LabRoomStage.FINISHED)}
        />
      )}
    </>
  );
};

export default Scanner;
