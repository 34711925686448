import React, { useState } from 'react';

import Footer from 'components/Footer/Footer';
import { hrefs } from 'components/Navbar/Navbar';
import useDisableScroll from 'hooks/useDisableScroll';
import { NavbarLink } from 'store/copy.types';

import NavbarMobileMenuLink from './NavbarMobileMenuLink/NavbarMobileMenuLink';

import * as Styled from './NavbarMobileMenu.styles';

export interface NavbarMobileMenuProps {
  links: Array<NavbarLink>;
  onClickLink: () => void;
}

const NavbarMobileMenu: React.FC<NavbarMobileMenuProps> = ({
  links,
  onClickLink,
}) => {
  const [dropdownOpened, setDropdownOpened] = useState<string | null>(null);
  useDisableScroll(true);

  return (
    <Styled.Wrapper>
      <Styled.Spacer />
      <Styled.Nav>
        <Styled.List>
          {links.map(({ key, label, children, ...link }) => (
            <li key={key}>
              <NavbarMobileMenuLink
                onClick={() => handleClick(!!children, label)}
                open={dropdownOpened === label}
                label={label}
                nested={children}
                {...link}
              />
              {children && (
                <Styled.Dropdown opened={dropdownOpened === label}>
                  <Styled.DropdownInner>
                    {children.map(child => {
                      const { key, label } = child;
                      const href = hrefs[key];

                      return (
                        <Styled.DropDownItem href={href} key={label}>
                          {label}
                        </Styled.DropDownItem>
                      );
                    })}
                  </Styled.DropdownInner>
                </Styled.Dropdown>
              )}
            </li>
          ))}
        </Styled.List>
      </Styled.Nav>
      <Styled.FooterWrapper>
        <Footer />
      </Styled.FooterWrapper>
    </Styled.Wrapper>
  );

  function handleClick(dropdown: boolean, label: string) {
    if (dropdown) {
      setDropdownOpened(prev => (prev === label ? null : label));
      return;
    }

    onClickLink();
  }
};

export default NavbarMobileMenu;
