import { FC } from 'react';

import Button, { ButtonVariant } from 'components/Button/Button';
import { useCopyStore, useGlobalStore } from 'store';
import GTM, { GTM_CATEGORY } from 'u9/utils/gtm';
import { ROUTES } from 'utils/routes';

import { CenterPart } from './components/CenterPart/CenterPart';

import * as Styled from './Footer.styles';

export interface FooterProps {}

const Footer: FC<FooterProps> = () => {
  const { isNavbarCentered, isDesktopView, preferencesCopy } = useGlobalStore();
  const { copy, socials } = useCopyStore(({ copy }) => ({
    copy: copy.global.footer,
    socials: copy.global.socials,
  }));
  const socialsCopy = [socials.discord, socials.twitter];
  const copyrightText = (
    <Styled.Text>
      © {new Date().getFullYear()}{' '}
      <Button
        variant={ButtonVariant.LINK_SECONDARY}
        label={copy.slimJim.label}
        uppercase
        href={copy.slimJim.href}
        onClick={() => {
          GTM.trackEvent(GTM_CATEGORY.Footer, 'Click', 'SLIM JIM');
        }}
        small
        paddingSize="xs"
      />
    </Styled.Text>
  );

  return (
    <Styled.Wrapper>
      <Styled.Background>
        <CenterPart />
      </Styled.Background>
      <Styled.Content $isFooterCentered={isNavbarCentered}>
        <Styled.TermsLinksList>
          <li>
            {isDesktopView ? (
              copyrightText
            ) : (
              <Button
                variant={ButtonVariant.LINK}
                label={copy.faq}
                uppercase
                href={ROUTES.FAQ}
                onClick={() => {
                  GTM.trackEvent(GTM_CATEGORY.Footer, 'Click', 'FAQ');
                }}
                small
                paddingSize="sm"
              />
            )}
          </li>
          <li>
            <Button
              variant={ButtonVariant.LINK}
              label={copy.terms}
              uppercase
              href={ROUTES.TERMS_AND_CONDITIONS}
              onClick={() => {
                GTM.trackEvent(GTM_CATEGORY.Footer, 'Click', 'T&C');
              }}
              small
              paddingSize="sm"
            />
          </li>
          <li>
            <Button
              variant={ButtonVariant.LINK}
              label={copy.privacy}
              uppercase
              href={ROUTES.PRIVACY_POLICY}
              onClick={() => {
                GTM.trackEvent(GTM_CATEGORY.Footer, 'Click', 'PRIVACY POLICY');
              }}
              small
              paddingSize="sm"
            />
          </li>
          {isDesktopView && (
            <li>
              <Button
                variant={ButtonVariant.LINK}
                label={'FAQ'}
                uppercase
                href={ROUTES.FAQ}
                onClick={() => {
                  GTM.trackEvent(GTM_CATEGORY.Footer, 'Click', 'FAQ');
                }}
                small
                paddingSize="sm"
              />
            </li>
          )}
          <li>
            <Button
              variant={ButtonVariant.LINK}
              label={preferencesCopy || copy.preferences}
              uppercase
              onClick={() => {
                GTM.trackEvent(
                  GTM_CATEGORY.Footer,
                  'Click',
                  'Cookie Preferences'
                );
                window?.['OneTrust'] &&
                  window?.['OneTrust']?.ToggleInfoDisplay();
              }}
              small
              paddingSize="sm"
            />
          </li>
          {!isDesktopView && copyrightText}
        </Styled.TermsLinksList>
        <Styled.SocialLinksWrapper>
          {isDesktopView && <Styled.Text>{copy.followUs}</Styled.Text>}
          <Styled.SocialLinksList>
            {socialsCopy.map(({ icon, href, title }) => (
              <li key={title}>
                <Button
                  title={title}
                  icon={icon}
                  href={href}
                  small
                  onClick={() => {
                    GTM.trackEvent(GTM_CATEGORY.Footer, 'Click', title);
                  }}
                />
              </li>
            ))}
          </Styled.SocialLinksList>
        </Styled.SocialLinksWrapper>
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default Footer;
