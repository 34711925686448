import {
  InventoryTutorialStage,
  LabRoomStage,
  MeaterializerTutorialStage,
  Tutorial,
} from 'components/LaboratoryS1/LabEnum';

export const AES_TMP = 'ASD$%^$YRTGFDSASXVCXB@#$SDS';

export const initialTutorial = {
  [Tutorial.Meatalizer]: MeaterializerTutorialStage.INITIAL,
  [Tutorial.Mixer]: LabRoomStage.INITIAL,
  [Tutorial.Inventory]: InventoryTutorialStage.INITIAL,
  [Tutorial.Scanner]: LabRoomStage.INITIAL,
};

export const after10Tutorial = {
  [Tutorial.Meatalizer]: MeaterializerTutorialStage.FINISHED,
  [Tutorial.Mixer]: LabRoomStage.FINISHED,
  [Tutorial.Inventory]: InventoryTutorialStage.FINISHED,
  [Tutorial.Scanner]: LabRoomStage.FINISHED,
};
