import styled, { css } from 'styled-components';

import { setVh } from 'utils/styles/mixins';
import { mediaMobile, mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.main<{ $noPaddingBottom?: boolean }>`
  width: 100vw;
  overflow: hidden;
  position: relative;
  padding-top: ${({ theme }) => theme.layout.header.height}rem;
  min-height: ${setVh(100)};

  ${({ $noPaddingBottom }) =>
    !$noPaddingBottom &&
    mediaTablet(css`
      padding-bottom: ${({ theme }) => theme.layout.footer.height.desktop}rem;
    `)};
`;

export const LabWrapper = styled.main`
  height: ${setVh(100)};
  width: 100vw;
  overflow: hidden;
  position: relative;

  ${mediaMobile(css`
    height: auto;
  `)}
`;

export const FluidContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100vw;
`;

export const CenteredContainer = styled.div`
  display: flex;
  height: ${setVh(100)};
  width: 100vw;
`;
