import React from 'react';

import Error from 'components/Error/Error';
import { useCopyStore } from 'store';

interface UnsupportedBrowserProps {
  setAccept(state: boolean): void;
}

const UnsupportedBrowserProps = () => {
  const copies = useCopyStore(state => state.copy);

  if (!copies.global) return null;
  const {
    global: {
      errors: { unsupportedBrowser: copy },
    },
  } = copies;

  return (
    <Error
      displayVariant="container"
      title={copy.title}
      body={copy.body}
      footer={copy.footer}
    />
  );
};

export default UnsupportedBrowserProps;
