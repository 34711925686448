import { FC } from 'react';

import * as Styled from './LayoutBackgroundSide.styles';

export interface LayoutBackgroundSideProps {}

const LayoutBackgroundSide: FC<LayoutBackgroundSideProps> = () => {
  return (
    <Styled.Wrapper>
      <Styled.ElementTop />
      <Styled.ElementBody />
      <Styled.ElementBottom />
    </Styled.Wrapper>
  );
};

export default LayoutBackgroundSide;
