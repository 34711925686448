import { loginSteps } from 'constants/enum';

export const ROUTES = {
  HOME: '/',
  ABOUT: '/about',
  FAQ: '/faq',
  NOT_FOUND: '/404',
  GALLERY: '/gallery',
  GALLERY_ITEM: '/gallery-item',
  DEBUG_PFP: '/debug_pfp',
  DEBUG_API: '/debug_api',
  PRIVACY_POLICY: 'https://www.conagrabrands.com/privacy-policy',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  ROADMAP: '/roadmap',
  // MY_ACCOUNT: '/my-account',
  // ACCOUNT_SETTINGS: '/account-settings',
  // LOGIN: '/login',
  LAB: '/lab',
  LAB_MEATERIALIZER: '/lab/meaterializer',
  QA_SAUCE: '/qa/scan',
  QA_MIXER: '/qa/mix',
  QA_MODIFY: '/qa/modify',
  LAB_SAUCE_MIXER: '/lab/sauce-mixer',
  LAB_BARCODE_SCANNER: '/lab/scanner',
  LAB_INVENTORY: '/lab/inventory',
  LABP: '/labp',
  // TEST: '/test',
} as const;

export enum Pages {
  index = 'index',
  about = 'about',
  gallery = 'gallery',
  galleryItem = 'galleryItem',
  notFound = 'notFound',
  debugPFP = 'debug_pfp',
  debugAPI = 'debug_api',
  terms = 'terms',
  roadmap = 'roadmap',
  accountSettings = 'accountSettings',
  myAccount = 'myAccount',
  login = 'login',
  lab = 'lab',
  labp = 'labp',
}

export const pushLoginRoute = (router: any, nextPage: string, step: any) => {
  router.push(
    {
      pathname: nextPage,
      query: {
        step: loginSteps[step],
      },
    },
    nextPage
  );
};

export const pushGalleryItem = (router: any, id: string) => {
  router.push({
    pathname: ROUTES.GALLERY_ITEM,
    query: {
      id,
    },
  });
};
export const pushRouteAs = (
  router: any,
  nextPage: string,
  asPage: string = ROUTES.HOME
) => {
  router.push(
    {
      pathname: nextPage,
    },
    asPage
  );
};
