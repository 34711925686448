import axios from 'axios';

import { path } from 'config/config';
import { API_T, API_URL } from 'constants/enum';

const getHeaders = (didToken, captcha) => {
  if (didToken !== '')
    return {
      Authorization: `Bearer ${didToken}`,
    };
  if (captcha !== '')
    return {
      'Recaptcha-Token': `${captcha}`,
    };

  return {};
};

export const postApi = async (url, data, didToken = '', captcha = '') => {
  const req = await axios.post(`${path()}${url}`, data, {
    validateStatus: status => {
      return status < 501;
    },
    headers: getHeaders(didToken, captcha),
  });
  return await { data: req.data, status: req.status };
};

export const putApi = async (url, data, didToken = '') => {
  const req = await axios.put(`${path()}${url}`, data, {
    validateStatus: status => {
      return status < 501;
    },
    headers: {
      Authorization: `Bearer ${didToken}`,
    },
  });
  return await { data: req.data, status: req.status };
};

export const getApi = async (url, didToken = '') => {
  const req = await axios.get(`${path()}${url}`, {
    validateStatus: status => {
      return status < 500;
    },
    headers: {
      Authorization: `Bearer ${didToken}`,
    },
  });
  return await { data: req.data, status: req.status };
};
export const deleteApi = async (url, didToken = '') => {
  const req = await axios.delete(`${path()}${url}`, {
    validateStatus: status => {
      return status < 500;
    },
    headers: {
      Authorization: `Bearer ${didToken}`,
    },
  });
  return await { data: req.data, status: req.status };
};

interface buildAPIUrlProps {
  type: API_T;
  value?: string | string[];
  query?: string;
}

export const buildAPIUrl = ({ type, value, query }: buildAPIUrlProps) => {
  let url: string = API_URL[type][0];

  if (API_URL[type].length === 2) {
    url += value + API_URL[type][1];
  } else if (API_URL[type].length === 3) {
    url += value[0] + API_URL[type][1];
    url += value[1] + API_URL[type][2];
  } else if (url.charAt(url.length - 1) === '/') {
    url += value;
  }
  if (query) {
    url += `${query}`;
  }
  return url;
};
