import { FC } from 'react';

import assets from 'utils/assets';

import * as Styled from './BaseSauce.styles';

import { BaseSauceName } from 'constants/sauces';

const validSizes = ['xxs', 'xs', 'sm', 'lg', 'xx', 'responsive'] as const;
export interface BaseSauceProps {
  label?: string;
  name?: BaseSauceName;
  size?: typeof validSizes[number];
  hasGlowEffect?: boolean;
  hasShadowEffect?: boolean;
  active?: boolean;
  isAnimated?: boolean;
}

const BaseSauce: FC<BaseSauceProps> = ({
  label,
  name = 'BBQ',
  size,
  hasGlowEffect = false,
  hasShadowEffect = false,
  active = true,
  isAnimated = true,
}) => {
  // prettier-ignore
  return (
    <Styled.Wrapper
      aria-label={label ?? 'Base Sauce'} {...(size && { $size: size })} $hasGlowEffect={hasGlowEffect} $hasShadowEffect={hasShadowEffect} $active={active}>
      {active && (
        <>
          {/* overlay */}
          <Styled.Img src={assets.images.baseSauce[`overlay${name}`]} $mixBlendMode={ 'hard-light'}  />
          {/* bubbles */}
          <Styled.Img src={assets.images.baseSauce.bubbles} $mixBlendMode={'soft-light'} $isAnimated={isAnimated}  />
          {/* frame */}
          <Styled.Img src={assets.images.baseSauce.frame}  />
          {/* shim */}
          <Styled.Img src={assets.images.baseSauce.shim} $mixBlendMode={ 'screen'}  />
        </>
      )}
    </Styled.Wrapper>
  );
};

export default BaseSauce;
