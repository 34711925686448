import create, { UseBoundStore } from 'zustand';

import { GALLERY_DATA, GALLERY_REQUEST } from 'types/gallery';

import { Gallery_Order, Gallery_Theme, Rarity } from 'constants/enum';

export interface UserStoreType {
  galleryData: GALLERY_DATA;
  setGalleryData: (state: any) => void;
  sortData: GALLERY_REQUEST;
  setSortData: (state: GALLERY_REQUEST) => void;
  galleryTheme: Gallery_Theme;
  setGalleryTheme: (state: Gallery_Theme) => void;
  orderBy: Gallery_Order;
  setGalleryOrderBy: (state: Gallery_Order) => void;
  orderDesc: boolean;
  setOrderDesc: (state: boolean) => void;
  colorRarity: string;
  setcolorRarity: (state: string) => void;
  capsuleRarity: string;
  setCapsuleRarity: (state: string) => void;
  elementsSetRarity: string;
  setElementsSetRarity: (state: string) => void;
  rarity: Rarity;
  setRarity: (state: Rarity) => void;
}

const userGalleryStore: UseBoundStore<UserStoreType> = create(
  (
    set: (partial: (store: UserStoreType) => Partial<UserStoreType>) => void
  ) => {
    return {
      galleryData: {
        all: 0,
        nextPage: null,
        previousPage: null,
        pfps: [],
      },
      setGalleryData: state => {
        set(() => ({
          galleryData: state,
        }));
      },
      sortData: {
        page_size: Gallery_Theme.DESKTOP_3X3,
        page_no: 0,
        filter_by_rarity: [],
        filter_by_color: [],
        filter_by_capsule: [],
        filter_by_set: [],
        filter_by_name: '',
        min_score: 0,
        max_score: 500,
        order_by: Gallery_Order.DateOfBirth,
        order_descending: true,
      },
      setSortData: state => {
        set(() => ({
          sortData: state,
        }));
      },
      galleryTheme: Gallery_Theme.DESKTOP_3X3,
      setGalleryTheme: state => {
        set(() => ({
          galleryTheme: state,
        }));
      },
      orderBy: Gallery_Order.DateOfBirth,
      setGalleryOrderBy: state => {
        set(() => ({
          orderBy: state,
        }));
      },
      orderDesc: false,
      setOrderDesc: state => {
        set(() => ({
          orderDesc: state,
        }));
      },
      colorRarity: Rarity.None,
      setcolorRarity: state => {
        set(() => ({
          colorRarity: state,
        }));
      },
      capsuleRarity: '',
      setCapsuleRarity: state => {
        set(() => ({
          capsuleRarity: state,
        }));
      },
      elementsSetRarity: Rarity.None,
      setElementsSetRarity: state => {
        set(() => ({
          elementsSetRarity: state,
        }));
      },
      rarity: Rarity.None,
      setRarity: state => {
        set(() => ({
          rarity: state,
        }));
      },
    };
  }
);

export default userGalleryStore;
