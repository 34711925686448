import assets, { gridAnimation, NewCircuitVariant } from 'utils/assets';

export type BackgroundType = {
  src: string;
  aspectRatio?: [number, number];
  mask?: string;
};

export type BackgroundResponsiveType = {
  mobile: BackgroundType;
  desktop: BackgroundType;
};

export type AnimationType = {
  mobile?: Partial<BackgroundType>;
  desktop?: Partial<BackgroundType>;
  infinite?: boolean;
};

type NewCircuitType = {
  stretchable?: boolean;
  top?: BackgroundResponsiveType;
  body: {
    mobile: Partial<BackgroundType>;
    desktop: Partial<BackgroundType>;
  };
  bottom?: BackgroundResponsiveType;
  decorations?: {
    top?: Partial<BackgroundResponsiveType>;
    left?: Partial<BackgroundResponsiveType>;
    right?: Partial<BackgroundResponsiveType>;
    bottom?: Partial<BackgroundResponsiveType>;
  };
  animation?: AnimationType;
  animationExtra?: AnimationType;
};

const ncAssets = assets.svgs.newCircuit;

export const newCircuitVariants: Record<NewCircuitVariant, NewCircuitType> = {
  [NewCircuitVariant.ROADMAP_PRIMARY]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.ROADMAP_PRIMARY].topMobile,
        aspectRatio: [352, 21],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.ROADMAP_PRIMARY].top,
        aspectRatio: [945, 49],
      },
    },
    body: {
      mobile: { src: ncAssets[NewCircuitVariant.ROADMAP_PRIMARY].bodyMobile },
      desktop: { src: ncAssets[NewCircuitVariant.ROADMAP_PRIMARY].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.ROADMAP_PRIMARY].bottomMobile,
        aspectRatio: [352, 71],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.ROADMAP_PRIMARY].bottom,
        aspectRatio: [945, 170],
      },
    },
    decorations: {
      left: {
        mobile: {
          src: ncAssets[NewCircuitVariant.ROADMAP_PRIMARY]
            .decorationsLeftMobile,
          aspectRatio: [203, 60],
        },
      },
      right: {
        desktop: {
          src: ncAssets[NewCircuitVariant.ROADMAP_PRIMARY].decorationsRight,
          aspectRatio: [501, 140],
        },
      },
    },
  },
  [NewCircuitVariant.ROADMAP_SECONDARY]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.ROADMAP_SECONDARY].topMobile,
        aspectRatio: [352, 37],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.ROADMAP_SECONDARY].top,
        aspectRatio: [944, 100],
      },
    },
    body: {
      mobile: { src: ncAssets[NewCircuitVariant.ROADMAP_SECONDARY].bodyMobile },
      desktop: { src: ncAssets[NewCircuitVariant.ROADMAP_SECONDARY].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.ROADMAP_SECONDARY].bottomMobile,
        aspectRatio: [352, 36],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.ROADMAP_SECONDARY].bottom,
        aspectRatio: [944, 70],
      },
    },
    decorations: {
      left: {
        mobile: {
          src: ncAssets[NewCircuitVariant.ROADMAP_SECONDARY]
            .decorationsLeftMobile,
          aspectRatio: [25, 104],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.ROADMAP_SECONDARY].decorationsLeft,
          aspectRatio: [111, 504],
        },
      },
      right: {
        mobile: {
          src: ncAssets[NewCircuitVariant.ROADMAP_SECONDARY]
            .decorationsRightMobile,
          aspectRatio: [70, 310],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.ROADMAP_SECONDARY].decorationsRight,
          aspectRatio: [44, 190],
        },
      },
    },
  },
  [NewCircuitVariant.ROADMAP_FOOTER]: {
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.ROADMAP_FOOTER].bodyMobile,
        aspectRatio: [366, 210],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.ROADMAP_FOOTER].body,
        aspectRatio: [1044, 474],
      },
    },
  },
  [NewCircuitVariant.ROADMAP_CONTENT]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.ROADMAP_CONTENT].topMobile,
        aspectRatio: [288, 37],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.ROADMAP_CONTENT].top,
        aspectRatio: [464, 60],
      },
    },
    body: {
      mobile: { src: ncAssets[NewCircuitVariant.ROADMAP_CONTENT].bodyMobile },
      desktop: { src: ncAssets[NewCircuitVariant.ROADMAP_CONTENT].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.ROADMAP_CONTENT].bottomMobile,
        aspectRatio: [288, 47],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.ROADMAP_CONTENT].bottom,
        aspectRatio: [464, 75],
      },
    },
  },
  [NewCircuitVariant.MY_ACCOUNT_HEADER]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_HEADER].topMobile,
        aspectRatio: [353, 34],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_HEADER].top,
        aspectRatio: [944, 87],
      },
    },
    body: {
      mobile: { src: ncAssets[NewCircuitVariant.MY_ACCOUNT_HEADER].bodyMobile },
      desktop: { src: ncAssets[NewCircuitVariant.MY_ACCOUNT_HEADER].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_HEADER].bottomMobile,
        aspectRatio: [353, 31],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_HEADER].bottom,
        aspectRatio: [944, 57],
      },
    },
    decorations: {
      left: {
        mobile: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_HEADER]
            .decorationsLeftMobile,
          aspectRatio: [82, 434],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_HEADER].decorationsLeft,
          aspectRatio: [110, 764],
        },
      },
      right: {
        mobile: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_HEADER]
            .decorationsRightMobile,
          aspectRatio: [50, 251],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_HEADER].decorationsRight,
          aspectRatio: [66, 494],
        },
      },
    },
  },
  [NewCircuitVariant.MY_ACCOUNT_WALLET]: {
    body: {
      mobile: { src: ncAssets[NewCircuitVariant.MY_ACCOUNT_WALLET].bodyMobile },
      desktop: { src: ncAssets[NewCircuitVariant.MY_ACCOUNT_WALLET].body },
    },
    decorations: {
      left: {
        mobile: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_WALLET]
            .decorationsLeftMobile,
          aspectRatio: [180, 182],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_WALLET].decorationsLeft,
          aspectRatio: [482, 450],
        },
      },
    },
  },
  [NewCircuitVariant.MY_ACCOUNT_SETTINGS]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_SETTINGS].topMobile,
        aspectRatio: [352, 36],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_SETTINGS].top,
        aspectRatio: [945, 93],
      },
    },
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_SETTINGS].bodyMobile,
      },
      desktop: { src: ncAssets[NewCircuitVariant.MY_ACCOUNT_SETTINGS].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_SETTINGS].bottomMobile,
        aspectRatio: [352, 31],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_SETTINGS].bottom,
        aspectRatio: [945, 62],
      },
    },
    decorations: {
      right: {
        mobile: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_SETTINGS]
            .decorationsRightMobile,
          aspectRatio: [191, 149],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_SETTINGS].decorationsRight,
          aspectRatio: [510, 379],
        },
      },
    },
    animation: {
      desktop: {
        src: assets.images.animations.steam03,
        aspectRatio: [32, 50],
      },
      infinite: false,
    },
  },
  [NewCircuitVariant.MY_ACCOUNT_ADDITIONAL]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ADDITIONAL].topMobile,
        aspectRatio: [352, 18],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ADDITIONAL].top,
        aspectRatio: [945, 41],
      },
    },
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ADDITIONAL].bodyMobile,
      },
      desktop: { src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ADDITIONAL].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ADDITIONAL].bottomMobile,
        aspectRatio: [352, 50],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ADDITIONAL].bottom,
        aspectRatio: [945, 109],
      },
    },
    decorations: {
      left: {
        mobile: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ADDITIONAL]
            .decorationsLeftMobile,
          aspectRatio: [182, 160],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ADDITIONAL]
            .decorationsLeft,
          aspectRatio: [482, 405],
        },
      },
      right: {
        mobile: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ADDITIONAL]
            .decorationsRightMobile,
          aspectRatio: [32, 166],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ADDITIONAL]
            .decorationsRight,
          aspectRatio: [44, 336],
        },
      },
    },
  },
  [NewCircuitVariant.MY_ACCOUNT_POPUP]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_POPUP].topMobile,
        aspectRatio: [352, 218],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_POPUP].top,
        aspectRatio: [945, 225],
      },
    },
    body: {
      mobile: { src: ncAssets[NewCircuitVariant.MY_ACCOUNT_POPUP].bodyMobile },
      desktop: { src: ncAssets[NewCircuitVariant.MY_ACCOUNT_POPUP].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_POPUP].bottomMobile,
        aspectRatio: [352, 231],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_POPUP].bottom,
        aspectRatio: [945, 229],
      },
    },
    decorations: {
      bottom: {
        mobile: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_POPUP]
            .decorationsBottomMobile,
          aspectRatio: [271, 384],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_POPUP].decorationsBottom,
          aspectRatio: [379, 511],
        },
      },
    },
  },
  [NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS].topMobile,
        aspectRatio: [352, 218],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS].top,
        aspectRatio: [945, 225],
      },
    },
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS].bodyMobile,
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS].body,
      },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS].bottomMobile,
        aspectRatio: [352, 231],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS].bottom,
        aspectRatio: [945, 229],
      },
    },
  },
  [NewCircuitVariant.MY_ACCOUNT_STATISTICS]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_STATISTICS].topMobile,
        aspectRatio: [352, 36],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_STATISTICS].top,
        aspectRatio: [945, 93],
      },
    },
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_STATISTICS].bodyMobile,
      },
      desktop: { src: ncAssets[NewCircuitVariant.MY_ACCOUNT_STATISTICS].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_STATISTICS].bottomMobile,
        aspectRatio: [352, 31],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_STATISTICS].bottom,
        aspectRatio: [945, 62],
      },
    },
  },
  [NewCircuitVariant.MY_ACCOUNT_NFTS]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_NFTS].topMobile,
        aspectRatio: [352, 37],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_NFTS].top,
        aspectRatio: [944, 100],
      },
    },
    body: {
      mobile: { src: ncAssets[NewCircuitVariant.MY_ACCOUNT_NFTS].bodyMobile },
      desktop: { src: ncAssets[NewCircuitVariant.MY_ACCOUNT_NFTS].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_NFTS].bottomMobile,
        aspectRatio: [352, 36],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_NFTS].bottom,
        aspectRatio: [944, 70],
      },
    },
    decorations: {
      right: {
        desktop: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_NFTS].decorationsRight,
          aspectRatio: [288, 405],
        },
      },
    },
  },
  [NewCircuitVariant.ABOUT_LCD_LEFT]: {
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.ABOUT_LCD_LEFT].bodyMobile,
        aspectRatio: [304, 68],
        mask: ncAssets[NewCircuitVariant.ABOUT_LCD_LEFT].maskMobile,
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.ABOUT_LCD_LEFT].body,
        aspectRatio: [202, 427],
        mask: ncAssets[NewCircuitVariant.ABOUT_LCD_LEFT].mask,
      },
    },
  },
  [NewCircuitVariant.ABOUT_LCD_RIGHT]: {
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.ABOUT_LCD_RIGHT].bodyMobile,
        aspectRatio: [304, 317],
        mask: ncAssets[NewCircuitVariant.ABOUT_LCD_RIGHT].maskMobile,
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.ABOUT_LCD_RIGHT].body,
        aspectRatio: [548, 428],
        mask: ncAssets[NewCircuitVariant.ABOUT_LCD_RIGHT].mask,
      },
    },
  },
  [NewCircuitVariant.LOG_IN_POPUP]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.LOG_IN_POPUP].topMobile,
        aspectRatio: [351, 217],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.LOG_IN_POPUP].top,
        aspectRatio: [945, 225],
      },
    },
    body: {
      mobile: { src: ncAssets[NewCircuitVariant.LOG_IN_POPUP].bodyMobile },
      desktop: { src: ncAssets[NewCircuitVariant.LOG_IN_POPUP].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.LOG_IN_POPUP].bottomMobile,
        aspectRatio: [351, 230],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.LOG_IN_POPUP].bottom,
        aspectRatio: [945, 229],
      },
    },
    decorations: {
      left: {
        mobile: {
          src: ncAssets[NewCircuitVariant.LOG_IN_POPUP].decorationsLeftMobile,
          aspectRatio: [205, 232],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.LOG_IN_POPUP].decorationsLeft,
          aspectRatio: [371, 466],
        },
      },
      right: {
        mobile: {
          src: ncAssets[NewCircuitVariant.LOG_IN_POPUP].decorationsRightMobile,
          aspectRatio: [26, 186],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.LOG_IN_POPUP].decorationsRight,
          aspectRatio: [44, 335],
        },
      },
    },
  },
  [NewCircuitVariant.FREEZE_POPUP]: {
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.FREEZE_POPUP].bodyMobile,
        aspectRatio: [387, 707],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.FREEZE_POPUP].body,
        aspectRatio: [990, 606],
      },
    },
    decorations: {
      left: {
        desktop: {
          src: ncAssets[NewCircuitVariant.FREEZE_POPUP].decorationsLeft,
          aspectRatio: [286, 303],
        },
      },
      right: {
        mobile: {
          src: ncAssets[NewCircuitVariant.FREEZE_POPUP].decorationsRightMobile,
          aspectRatio: [175, 223],
        },
      },
      bottom: {
        desktop: {
          src: gridAnimation,
          aspectRatio: [1512, 550],
        },
        mobile: {
          src: gridAnimation,
          aspectRatio: [1512, 550],
        },
      },
    },
  },
  [NewCircuitVariant.FREEZE_LCD]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.FREEZE_LCD].topMobile,
        aspectRatio: [308, 48],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.FREEZE_LCD].top,
        aspectRatio: [499, 56],
      },
    },
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.FREEZE_LCD].bodyMobile,
        aspectRatio: [308, 10],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.FREEZE_LCD].body,
        aspectRatio: [499, 10],
      },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.FREEZE_LCD].bottomMobile,
        aspectRatio: [308, 48],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.FREEZE_LCD].bottom,
        aspectRatio: [499, 57],
      },
    },
  },
  [NewCircuitVariant.CAPSULES]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.CAPSULES].topMobile,
        aspectRatio: [353, 40],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.CAPSULES].top,
        aspectRatio: [944, 100],
      },
    },
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.CAPSULES].bodyMobile,
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.CAPSULES].body,
      },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.CAPSULES].bottomMobile,
        aspectRatio: [353, 40],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.CAPSULES].bottom,
        aspectRatio: [944, 100],
      },
    },
    decorations: {
      left: {
        mobile: {
          src: ncAssets[NewCircuitVariant.CAPSULES].decorationsLeftMobile,
          aspectRatio: [47, 115],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.CAPSULES].decorationsLeft,
          aspectRatio: [616, 276],
        },
      },
      right: {
        mobile: {
          src: ncAssets[NewCircuitVariant.CAPSULES].decorationsRightMobile,
          aspectRatio: [50, 140],
        },
        desktop: {
          src: '',
          aspectRatio: [555, 1457],
        },
      },
    },
    animation: {
      desktop: {
        src: assets.images.animations.steam01,
        aspectRatio: [25, 30],
      },
    },
  },
  [NewCircuitVariant.CAPSULES_BG]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.CAPSULES_BG].top,
        aspectRatio: [884, 40],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.CAPSULES_BG].top,
        aspectRatio: [884, 40],
      },
    },
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.CAPSULES_BG].body,
        aspectRatio: [884, 30],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.CAPSULES_BG].body,
        aspectRatio: [884, 30],
      },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.CAPSULES_BG].bottom,
        aspectRatio: [884, 45],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.CAPSULES_BG].bottom,
        aspectRatio: [884, 45],
      },
    },
  },
  [NewCircuitVariant.LANDING_PRIMARY]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.LANDING_PRIMARY].topMobile,
        aspectRatio: [352, 35],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.LANDING_PRIMARY].top,
        aspectRatio: [945, 88],
      },
    },
    body: {
      mobile: { src: ncAssets[NewCircuitVariant.LANDING_PRIMARY].bodyMobile },
      desktop: { src: ncAssets[NewCircuitVariant.LANDING_PRIMARY].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.LANDING_PRIMARY].bottomMobile,
        aspectRatio: [352, 40],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.LANDING_PRIMARY].bottom,
        aspectRatio: [945, 80],
      },
    },
    decorations: {
      left: {
        mobile: {
          src: ncAssets[NewCircuitVariant.LANDING_PRIMARY]
            .decorationsLeftMobile,
          aspectRatio: [194, 191],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.LANDING_PRIMARY].decorationsLeft,
          aspectRatio: [481, 469],
        },
      },
      right: {
        mobile: {
          src: ncAssets[NewCircuitVariant.LANDING_PRIMARY]
            .decorationsRightMobile,
          aspectRatio: [27, 187],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.LANDING_PRIMARY].decorationsRight,
          aspectRatio: [44, 317],
        },
      },
    },
  },
  [NewCircuitVariant.STATIC_LANDING_SECONDARY]: {
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.STATIC_LANDING_SECONDARY].bodyMobile,
        aspectRatio: [352, 504],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.STATIC_LANDING_SECONDARY].body,
        aspectRatio: [944, 684],
      },
    },
    decorations: {
      left: {
        mobile: {
          src: ncAssets[NewCircuitVariant.STATIC_LANDING_SECONDARY]
            .decorationsLeftMobile,
          aspectRatio: [41, 114],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.STATIC_LANDING_SECONDARY]
            .decorationsLeft,
          aspectRatio: [84, 240],
        },
      },
      right: {
        mobile: {
          src: ncAssets[NewCircuitVariant.STATIC_LANDING_SECONDARY]
            .decorationsRightMobile,
          aspectRatio: [180, 160],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.STATIC_LANDING_SECONDARY]
            .decorationsRight,
          aspectRatio: [292, 648],
        },
      },
    },
    animation: {
      desktop: {
        src: assets.images.animations.alarmLeft,
        aspectRatio: [1, 1],
      },
      infinite: true,
    },
  },
  [NewCircuitVariant.LANDING_FOOTER]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.LANDING_FOOTER].topMobile,
        aspectRatio: [352, 40],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.LANDING_FOOTER].top,
        aspectRatio: [947, 95],
      },
    },
    body: {
      mobile: { src: ncAssets[NewCircuitVariant.LANDING_FOOTER].bodyMobile },
      desktop: { src: ncAssets[NewCircuitVariant.LANDING_FOOTER].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.LANDING_FOOTER].bottomMobile,
        aspectRatio: [352, 40],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.LANDING_FOOTER].bottom,
        aspectRatio: [947, 65],
      },
    },
    decorations: {
      left: {
        mobile: {
          src: ncAssets[NewCircuitVariant.LANDING_FOOTER].decorationsLeftMobile,
          aspectRatio: [201, 205],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.LANDING_FOOTER].decorationsLeft,
          aspectRatio: [292, 1222],
        },
      },
      right: {
        mobile: {
          src: ncAssets[NewCircuitVariant.LANDING_FOOTER]
            .decorationsRightMobile,
          aspectRatio: [278, 80],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.LANDING_FOOTER].decorationsRight,
          aspectRatio: [500, 132],
        },
      },
    },
  },
  [NewCircuitVariant.LAB_POPUP]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.LAB_POPUP].topMobile,
        aspectRatio: [352, 202],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.LAB_POPUP].top,
        aspectRatio: [745, 230],
      },
    },
    body: {
      mobile: { src: ncAssets[NewCircuitVariant.LAB_POPUP].bodyMobile },
      desktop: { src: ncAssets[NewCircuitVariant.LAB_POPUP].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.LAB_POPUP].bottomMobile,
        aspectRatio: [352, 211],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.LAB_POPUP].bottom,
        aspectRatio: [745, 250],
      },
    },
    decorations: {
      bottom: {
        mobile: {
          src: ncAssets[NewCircuitVariant.LAB_POPUP].decorationsBottomMobile,
          aspectRatio: [285, 384],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.LAB_POPUP].decorationsBottom,
          aspectRatio: [379, 511],
        },
      },
    },
    animation: {
      desktop: {
        src: assets.images.animations.steam06,
        aspectRatio: [20, 15],
      },
    },
  },
  [NewCircuitVariant.LAB_INVENTORY_MEATA_POPUP]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.LAB_INVENTORY_POPUP].topMobile,
        aspectRatio: [352, 45],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.LAB_INVENTORY_POPUP].top,
        aspectRatio: [343, 45],
      },
    },
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.LAB_INVENTORY_POPUP].bodyMobile,
      },
      desktop: { src: ncAssets[NewCircuitVariant.LAB_INVENTORY_POPUP].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.LAB_INVENTORY_POPUP].bottomMobile,
        aspectRatio: [352, 55],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.LAB_INVENTORY_POPUP].bottom,
        aspectRatio: [343, 60],
      },
    },
    decorations: {
      right: {
        mobile: {
          src: ncAssets[NewCircuitVariant.LAB_INVENTORY_POPUP]
            .decorationsRightMobile,
          aspectRatio: [238, 230],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.LAB_INVENTORY_POPUP].decorationsRight,
          aspectRatio: [359, 348],
        },
      },
    },
  },
  [NewCircuitVariant.LAB_INVENTORY_POPUP]: {
    body: {
      mobile: {
        src: null,
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.LAB_INVENTORY_SAUCES].body,
        aspectRatio: [702, 613],
      },
    },
    decorations: {
      right: {
        mobile: {
          src: null,
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.LAB_INVENTORY_POPUP].decorationsRight,
          aspectRatio: [359, 348],
        },
      },
      bottom: {
        desktop: {
          src: gridAnimation,
          aspectRatio: [1512, 550],
        },
      },
    },
  },
  [NewCircuitVariant.LAB_INVENTORY_SAUCES]: {
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.LAB_INVENTORY_SAUCES].bodyMobile,
        aspectRatio: [348, 536],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.LAB_INVENTORY_SAUCES].body,
        aspectRatio: [702, 613],
      },
    },
    decorations: {
      left: {
        desktop: {
          src: ncAssets[NewCircuitVariant.LAB_INVENTORY_SAUCES].decorationsLeft,
          aspectRatio: [422, 441],
        },
      },
      bottom: {
        desktop: {
          src: gridAnimation,
          aspectRatio: [1512, 550],
        },
        mobile: {
          src: gridAnimation,
          aspectRatio: [1512, 550],
        },
      },
    },
  },
  [NewCircuitVariant.LAB_WIDE_POPUP]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.LAB_WIDE_POPUP].topMobile,
        aspectRatio: [352, 20],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.LAB_WIDE_POPUP].top,
        aspectRatio: [1190, 50],
      },
    },
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.LAB_WIDE_POPUP].bodyMobile,
      },
      desktop: { src: ncAssets[NewCircuitVariant.LAB_WIDE_POPUP].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.LAB_WIDE_POPUP].bottomMobile,
        aspectRatio: [352, 20],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.LAB_WIDE_POPUP].bottom,
        aspectRatio: [1190, 50],
      },
    },
    decorations: {
      top: {
        desktop: {
          src: ncAssets[NewCircuitVariant.LAB_WIDE_POPUP].decorationsTop,
          aspectRatio: [652, 602],
        },
      },
    },
  },
  [NewCircuitVariant.FAQ_FOOTER]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.FAQ_FOOTER].topMobile,
        aspectRatio: [352, 35],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.FAQ_FOOTER].top,
        aspectRatio: [945, 88],
      },
    },
    body: {
      mobile: { src: ncAssets[NewCircuitVariant.FAQ_FOOTER].bodyMobile },
      desktop: { src: ncAssets[NewCircuitVariant.FAQ_FOOTER].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.FAQ_FOOTER].bottomMobile,
        aspectRatio: [352, 40],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.FAQ_FOOTER].bottom,
        aspectRatio: [945, 80],
      },
    },
    decorations: {
      left: {
        mobile: {
          src: ncAssets[NewCircuitVariant.FAQ_FOOTER].decorationsLeftMobile,
          aspectRatio: [190, 143],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.FAQ_FOOTER].decorationsLeft,
          aspectRatio: [511, 379],
        },
      },
      right: {
        mobile: {
          src: ncAssets[NewCircuitVariant.FAQ_FOOTER].decorationsRightMobile,
          aspectRatio: [41, 114],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.FAQ_FOOTER].decorationsRight,
          aspectRatio: [104, 294],
        },
      },
    },
    animation: {
      desktop: {
        src: assets.images.animations.alarmRight,
        aspectRatio: [1, 1],
      },
      infinite: true,
    },
    animationExtra: {
      desktop: {
        src: assets.images.animations.steam04,
        aspectRatio: [30, 35],
      },
      infinite: false,
    },
  },
  [NewCircuitVariant.STATIC_GALLERY]: {
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.STATIC_GALLERY].bodyMobile,
        aspectRatio: [353, 2317],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.STATIC_GALLERY].body,
        aspectRatio: [1512, 818],
      },
    },
    animation: {
      desktop: {
        src: assets.images.animations.sparks06,
        aspectRatio: [10, 25],
      },
      infinite: false,
    },
    decorations: {
      bottom: {
        desktop: {
          src: gridAnimation,
          aspectRatio: [1512, 550],
        },
      },
    },
  },
  [NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS].topMobile,
        aspectRatio: [352, 18],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS].top,
        aspectRatio: [945, 41],
      },
    },
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS].bodyMobile,
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS].body,
      },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS].bottomMobile,
        aspectRatio: [352, 50],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS].bottom,
        aspectRatio: [945, 109],
      },
    },
    decorations: {
      left: {
        mobile: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS]
            .decorationsLeftMobile,
          aspectRatio: [179, 88],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS]
            .decorationsLeft,
          aspectRatio: [482, 231],
        },
      },
      right: {
        mobile: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS]
            .decorationsRightMobile,
          aspectRatio: [33, 166],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS]
            .decorationsRight,
          aspectRatio: [500, 139],
        },
      },
      bottom: {
        mobile: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS]
            .decorationsBottomMobile,
          aspectRatio: [136, 206],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS]
            .decorationsBottom,
          aspectRatio: [379, 511],
        },
      },
    },
    animation: {
      desktop: {
        src: assets.images.animations.steam02,
        aspectRatio: [263, 200],
      },
      infinite: false,
    },
    animationExtra: {
      desktop: {
        src: assets.images.animations.sparks05,
        aspectRatio: [30, 27],
      },
      infinite: false,
    },
  },
  [NewCircuitVariant.ABOUT_QUESTIONS]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.ABOUT_QUESTIONS].topMobile,
        aspectRatio: [352, 35],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.ABOUT_QUESTIONS].top,
        aspectRatio: [945, 88],
      },
    },
    body: {
      mobile: { src: ncAssets[NewCircuitVariant.ABOUT_QUESTIONS].bodyMobile },
      desktop: { src: ncAssets[NewCircuitVariant.ABOUT_QUESTIONS].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.ABOUT_QUESTIONS].bottomMobile,
        aspectRatio: [352, 40],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.ABOUT_QUESTIONS].bottom,
        aspectRatio: [945, 80],
      },
    },
    decorations: {
      bottom: {
        mobile: {
          src: ncAssets[NewCircuitVariant.ABOUT_QUESTIONS]
            .decorationsBottomMobile,
          aspectRatio: [32, 273],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.ABOUT_QUESTIONS].decorationsBottom,
          aspectRatio: [42, 363],
        },
      },
      left: {
        desktop: {
          src: ncAssets[NewCircuitVariant.ABOUT_QUESTIONS].decorationsLeft,
          aspectRatio: [292, 530],
        },
      },
      right: {
        mobile: {
          src: ncAssets[NewCircuitVariant.ABOUT_QUESTIONS]
            .decorationsRightMobile,
          aspectRatio: [191, 149],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.ABOUT_QUESTIONS].decorationsRight,
          aspectRatio: [510, 379],
        },
      },
    },
    animation: {
      desktop: {
        src: assets.images.animations.alarmLeft,
        aspectRatio: [1, 1],
      },
      infinite: true,
    },
  },
  [NewCircuitVariant.ABOUT_CONTENT]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.ABOUT_CONTENT].topMobile,
        aspectRatio: [352, 38],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.ABOUT_CONTENT].top,
        aspectRatio: [945, 92],
      },
    },
    body: {
      mobile: { src: ncAssets[NewCircuitVariant.ABOUT_CONTENT].bodyMobile },
      desktop: { src: ncAssets[NewCircuitVariant.ABOUT_CONTENT].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.ABOUT_CONTENT].bottomMobile,
        aspectRatio: [352, 20],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.ABOUT_CONTENT].bottom,
        aspectRatio: [945, 70],
      },
    },
    decorations: {
      bottom: {
        desktop: {
          src: ncAssets[NewCircuitVariant.ABOUT_CONTENT].decorationsBottom,
          aspectRatio: [1099, 230],
        },
      },
      left: {
        desktop: {
          src: ncAssets[NewCircuitVariant.ABOUT_CONTENT].decorationsLeft,
          aspectRatio: [111, 504],
        },
      },
      right: {
        mobile: {
          src: ncAssets[NewCircuitVariant.ABOUT_CONTENT].decorationsRightMobile,
          aspectRatio: [70, 311],
        },
      },
    },
  },
  [NewCircuitVariant.FAQ_HEADER]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.FAQ_HEADER].topMobile,
        aspectRatio: [352, 40],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.FAQ_HEADER].top,
        aspectRatio: [945, 93],
      },
    },
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.FAQ_HEADER].bodyMobile,
      },
      desktop: { src: ncAssets[NewCircuitVariant.FAQ_HEADER].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.FAQ_HEADER].bottomMobile,
        aspectRatio: [352, 25],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.FAQ_HEADER].bottom,
        aspectRatio: [945, 62],
      },
    },
    decorations: {
      right: {
        desktop: {
          src: ncAssets[NewCircuitVariant.FAQ_HEADER].decorationsRight,
          aspectRatio: [482, 449],
        },
      },
    },
  },
  [NewCircuitVariant.MY_ACCOUNT_HEADER2]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_HEADER2].topMobile,
        aspectRatio: [353, 34],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_HEADER2].top,
        aspectRatio: [944, 87],
      },
    },
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_HEADER2].bodyMobile,
      },
      desktop: { src: ncAssets[NewCircuitVariant.MY_ACCOUNT_HEADER2].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_HEADER2].bottomMobile,
        aspectRatio: [353, 31],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.MY_ACCOUNT_HEADER2].bottom,
        aspectRatio: [944, 57],
      },
    },
    decorations: {
      left: {
        mobile: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_HEADER2]
            .decorationsLeftMobile,
          aspectRatio: [18, 77],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_HEADER2].decorationsLeft,
          aspectRatio: [34, 147],
        },
      },
      right: {
        mobile: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_HEADER2]
            .decorationsRightMobile,
          aspectRatio: [50, 251],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.MY_ACCOUNT_HEADER2].decorationsRight,
          aspectRatio: [111, 494],
        },
      },
    },
  },
  [NewCircuitVariant.STATIC_MY_ACCOUNT_BODY]: {
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.STATIC_MY_ACCOUNT_BODY].bodyMobile,
        aspectRatio: [352, 709],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.STATIC_MY_ACCOUNT_BODY].body,
        aspectRatio: [945, 616],
      },
    },
    decorations: {
      left: {
        mobile: {
          src: ncAssets[NewCircuitVariant.STATIC_MY_ACCOUNT_BODY]
            .decorationsLeftMobile,
          aspectRatio: [179, 182],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.STATIC_MY_ACCOUNT_BODY]
            .decorationsLeft,
          aspectRatio: [482, 467],
        },
      },
      right: {
        desktop: {
          src: ncAssets[NewCircuitVariant.STATIC_MY_ACCOUNT_BODY]
            .decorationsRight,
          aspectRatio: [66, 494],
        },
      },
    },
  },
  [NewCircuitVariant.GALLERY_FILTERS]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.GALLERY_FILTERS].topMobile,
        aspectRatio: [299, 20],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.GALLERY_FILTERS].top,
        aspectRatio: [299, 20],
      },
    },
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.GALLERY_FILTERS].bodyMobile,
      },
      desktop: { src: ncAssets[NewCircuitVariant.GALLERY_FILTERS].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.GALLERY_FILTERS].bottomMobile,
        aspectRatio: [299, 35],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.GALLERY_FILTERS].bottom,
        aspectRatio: [299, 35],
      },
    },
  },
  [NewCircuitVariant.CAPSULE_STATS_FOOTER]: {
    top: {
      mobile: {
        src: ncAssets[NewCircuitVariant.CAPSULE_STATS_FOOTER].topMobile,
        aspectRatio: [298, 25],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.CAPSULE_STATS_FOOTER].top,
        aspectRatio: [620, 25],
      },
    },
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.CAPSULE_STATS_FOOTER].bodyMobile,
      },
      desktop: { src: ncAssets[NewCircuitVariant.CAPSULE_STATS_FOOTER].body },
    },
    bottom: {
      mobile: {
        src: ncAssets[NewCircuitVariant.CAPSULE_STATS_FOOTER].bottomMobile,
        aspectRatio: [298, 25],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.CAPSULE_STATS_FOOTER].bottom,
        aspectRatio: [620, 26],
      },
    },
  },
  [NewCircuitVariant.STATIC_ERROR_ACCOUNT]: {
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.STATIC_ERROR_ACCOUNT].bodyMobile,
        aspectRatio: [352, 582],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.STATIC_ERROR_ACCOUNT].body,
        aspectRatio: [945, 542],
      },
    },
  },
  [NewCircuitVariant.STATIC_ERROR_DATABASE]: {
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.STATIC_ERROR_DATABASE].bodyMobile,
        aspectRatio: [352, 582],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.STATIC_ERROR_DATABASE].body,
        aspectRatio: [945, 542],
      },
    },
    decorations: {
      left: {
        mobile: {
          src: ncAssets[NewCircuitVariant.STATIC_ERROR_DATABASE]
            .decorationsLeftMobile,
          aspectRatio: [228, 274],
        },
      },
      right: {
        mobile: {
          src: ncAssets[NewCircuitVariant.STATIC_ERROR_DATABASE]
            .decorationsRightMobile,
          aspectRatio: [58, 171],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.STATIC_ERROR_DATABASE]
            .decorationsRight,
          aspectRatio: [77, 248],
        },
      },
      bottom: {
        mobile: {
          src: ncAssets[NewCircuitVariant.STATIC_ERROR_DATABASE]
            .decorationsBottomMobile,
          aspectRatio: [136, 66],
        },
        desktop: {
          src: ncAssets[NewCircuitVariant.STATIC_ERROR_DATABASE]
            .decorationsBottom,
          aspectRatio: [181, 87],
        },
      },
    },
  },
  [NewCircuitVariant.STATIC_ERROR_TRANSACTION]: {
    body: {
      mobile: {
        src: ncAssets[NewCircuitVariant.STATIC_ERROR_TRANSACTION].bodyMobile,
        aspectRatio: [352, 610],
      },
      desktop: {
        src: ncAssets[NewCircuitVariant.STATIC_ERROR_TRANSACTION].body,
        aspectRatio: [944, 581],
      },
    },
    decorations: {
      right: {
        desktop: {
          src: ncAssets[NewCircuitVariant.STATIC_ERROR_TRANSACTION]
            .decorationsRight,
          aspectRatio: [72, 424],
        },
      },
    },
  },
};
