import { FC, Fragment, ReactNode } from 'react';

import { ReactComponent as SvgArrow } from 'svgs/tooltip/arrow.svg';
import { ReactComponent as SvgCheck } from 'svgs/tooltip/check.svg';
import { ReactComponent as SvgInvalid } from 'svgs/tooltip/invalid.svg';

import * as Styled from './TooltipContent.styles';

export const validPositions = ['bottom', 'top', 'left', 'right'] as const;
export const validArrowPositions = [
  'very-start',
  'start',
  'center',
  'end',
  'very-end',
] as const;
export const validColors = ['rumSwizzle', 'turbo', 'crimson'] as const;
export const validArrowVariants = [
  'default',
  'check',
  'invalid',
  'none',
  'text',
] as const;
export const validFontVariants = ['sm', 'md', 'lg'] as const;

const tooltipIcons: Record<typeof validArrowVariants[number], FC> = {
  default: SvgArrow,
  check: SvgCheck,
  invalid: SvgInvalid,
  none: undefined,
  text: undefined,
};

export interface TooltipContentProps {
  text: string | ReactNode;
  position?: typeof validPositions[number];
  color?: typeof validColors[number];
  fontVariant?: typeof validFontVariants[number];
  arrowPosition?: typeof validArrowPositions[number];
  arrowVariant?: typeof validArrowVariants[number];
  arrowText?: string;
  smallWidth?: boolean;
}

const TooltipContent: FC<TooltipContentProps> = ({
  text,
  position = 'bottom',
  color = 'rumSwizzle',
  fontVariant = 'sm',
  arrowPosition = 'center',
  arrowVariant = 'default',
  smallWidth,
  arrowText,
}) => {
  const TooltipIcon = tooltipIcons[arrowVariant] || null;

  const textParts = typeof text === 'string' ? text.split('\n') : [];

  return (
    <Styled.Container $arrowPosition={arrowPosition}>
      <Styled.Wrapper
        $position={position}
        $color={color}
        $smallWidth={smallWidth}
        $arrowVariant={arrowVariant}
      >
        <Styled.Content $fontVariant={fontVariant}>
          {typeof text === 'string' ? (
            <span>
              {textParts.map((part, index) => (
                <Fragment key={index}>
                  {part}
                  {index < textParts.length - 1 && <br />}
                </Fragment>
              ))}
            </span>
          ) : (
            text
          )}
        </Styled.Content>
      </Styled.Wrapper>
      {arrowVariant !== 'none' && (
        <Styled.ArrowWrapper
          $position={position}
          $arrowVariant={arrowVariant}
          $color={color}
        >
          <Styled.ArrowAnimationWrapper>
            {arrowVariant === 'text' ? (
              <Styled.Text>{arrowText}</Styled.Text>
            ) : (
              <TooltipIcon />
            )}
          </Styled.ArrowAnimationWrapper>
        </Styled.ArrowWrapper>
      )}
    </Styled.Container>
  );
};

export default TooltipContent;
