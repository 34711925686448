import { motion } from 'framer-motion';
import styled from 'styled-components';

import Link from 'components/Link/Link';
import {
  setDefaultBackground,
  setTypography,
  setVh,
} from 'utils/styles/mixins';

export const Wrapper = styled(motion.div)`
  ${({ theme }) => setDefaultBackground(theme.layout.header.height, 0)};
  width: 100%;
  min-height: ${setVh(100)};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
`;

Wrapper.defaultProps = {
  exit: {
    opacity: 0,
    transition: {
      delay: 0.2,
      duration: 0,
    },
  },
};

export const Spacer = styled.div`
  height: ${({ theme }) => theme.layout.header.height}rem;
  flex-shrink: 0;
`;

export const Nav = styled.nav`
  padding: 90rem 0 20rem;
  width: 100%;
  flex: 1;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20rem;

  li {
    margin-top: -10rem;
  }
`;

export const FooterWrapper = styled.div`
  flex-shrink: 0;
  width: 100%;
`;

export const Dropdown = styled.div<{ opened: boolean }>`
  height: 100%;
  width: 100%;
  margin-top: -2em;
  margin-bottom: 1em;
  background-color: ${({ theme }) => theme.colors.fiord};
  border: 5px solid ${({ theme }) => theme.colors.black};
  display: ${({ opened }) => (opened ? 'flex' : 'none')};
  box-shadow: 0px 16rem 0px 0px rgba(0, 0, 0, 0.3);
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const DropdownInner = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 1em;
  padding-left: 6em;
  padding-right: 1em;
  border: 5px solid ${({ theme }) => theme.colors.teal};
  border-right: 5px solid ${({ theme }) => theme.colors.opal};
`;

export const DropDownItem = styled(Link)`
  width: 100%;
  padding: 1em 0;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.rumSwizzle};
  text-shadow: 0 4rem 0 ${({ theme }) => theme.colors.black};

  ${setTypography('heading2Menu')}

  &:not(:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.colors.osloGray};
  }
`;
