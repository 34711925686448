import { rgba } from 'polished';
import ReactMarkdown from 'react-markdown';
import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

import { ErrorContentProps } from './ErrorContent';

export const TitleWrapper = styled.div``;

export const Title = styled.h1<{
  $titleSize: ErrorContentProps['titleSize'];
}>`
  ${({ theme: { locale } }) => setTypography('heading1', locale)};
  color: ${({ theme }) => theme.colors.turbo};
  text-shadow: 0 8rem 0 ${({ theme }) => rgba(theme.colors.black, 0.8)};
  text-transform: uppercase;
  text-align: center;
  max-width: 1350rem;

  ${mediaTablet(css`
    text-shadow: 0 12rem 0 ${({ theme }) => rgba(theme.colors.black, 0.8)};
  `)}

  ${({ $titleSize }) =>
    $titleSize === 'lg' &&
    css`
      ${({ theme: { locale } }) => setTypography('heading1Large', locale)};

      ${mediaTablet(css`
        padding-bottom: 40rem;
      `)}
    `};
`;

export const Body = styled(ReactMarkdown)`
  ${({ theme: { locale } }) => setTypography('heading2', locale)};
  text-transform: uppercase;
  white-space: pre-wrap;
  text-shadow: 0 4rem 0 ${({ theme }) => theme.colors.black};
  text-align: center;
  max-width: 1200rem;
`;

export const CtaWrapper = styled.div`
  display: grid;
  min-width: 100%;
  flex-direction: column;

  li {
    margin: 16rem 0;

    button,
    a {
      width: 100%;
    }
  }

  ${mediaTablet(css`
    min-width: unset;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;

    li {
      margin: 0 20rem;
    }
  `)};
`;

export const Footer = styled(ReactMarkdown)`
  ${({ theme: { locale } }) => setTypography('body1', locale)};
  text-shadow: 0 4rem 0 ${({ theme }) => theme.colors.black};
  white-space: pre-wrap;
  text-align: center;
  text-transform: uppercase;
`;

export const Wrapper = styled.div<{
  $autoJustifyContent: ErrorContentProps['autoJustifyContent'];
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  padding: 70rem;

  ${mediaTablet(css`
    padding: 0;
  `)}

  ${({ $autoJustifyContent }) =>
    !$autoJustifyContent &&
    css`
      justify-content: center;

      ${TitleWrapper} {
        padding-top: 70rem;
        flex: 3;
        display: flex;
        align-items: flex-end;
      }

      ${Title} {
        margin-bottom: 80rem;
      }

      ${CtaWrapper} {
        flex: 2;
        margin-top: 140rem;
        display: flex;
        justify-content: flex-end;
      }

      ${Footer} {
        flex: 2;
        margin-top: 140rem;
      }

      ${mediaTablet(css`
        ${Title} {
          margin-bottom: 100rem;
        }

        ${TitleWrapper} {
          padding-top: 0;
          flex: unset;
        }

        ${CtaWrapper} {
          flex: unset;
        }

        ${Footer} {
          flex: unset;
        }
      `)}
    `};
`;
