import React, { FC } from 'react';

import Error from 'components/Error/Error';
import { SharedCopy } from 'store/copy.types';

export interface JavaScriptDisabledProps {
  sharedCopy: SharedCopy;
}

const JavaScriptDisabled: FC<JavaScriptDisabledProps> = ({ sharedCopy }) => {
  if (!sharedCopy?.global) return null;

  const {
    global: {
      errors: { javascriptDisabled: copy },
    },
  } = sharedCopy;

  return (
    <Error
      displayVariant="container"
      title={copy.title}
      body={copy.body}
      footer={copy.footer}
    />
  );
};

export default JavaScriptDisabled;
