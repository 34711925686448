import styled, { css } from 'styled-components';

import svgErrorBody from 'svgs/error-body.svg';
import svgErrorBodyMobile from 'svgs/error-body-mobile.svg';
import svgErrorBottom from 'svgs/error-bottom.svg';
import svgErrorBottomMobile from 'svgs/error-bottom-mobile.svg';
import svgErrorLinesLeft from 'svgs/error-lines-left.svg';
import svgErrorLinesLeftMobile from 'svgs/error-lines-left-mobile.svg';
import svgErrorLinesRight from 'svgs/error-lines-right.svg';
import svgErrorLinesRightMobile from 'svgs/error-lines-right-mobile.svg';
import svgErrorTop from 'svgs/error-top.svg';
import svgErrorTopMobile from 'svgs/error-top-mobile.svg';
import { aspectRatio, setDefaultBackground } from 'utils/styles/mixins';
import { setVh } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

export const OverflowWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: ${setVh(100)};
  overflow-y: auto;
  overflow-x: hidden;
  z-index: ${({ theme }) => theme.layout.zIndex.nonFunctionals};
`;

export const Container = styled.div`
  ${({ theme }) => setDefaultBackground(theme.layout.header.height, 0)};
  position: relative;
  height: 100%;
  min-height: 1200rem;

  ${mediaTablet(css`
    min-height: 1250rem;
  `)};
`;

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: calc(${({ theme }) => theme.layout.header.height}rem + 42rem);
  left: 30rem;
  width: calc(100% - 60rem);
  height: calc(
    100% - calc(${({ theme }) => theme.layout.header.height}rem + 42rem) - 20rem
  );

  svg {
    width: 100%;
  }

  ${mediaTablet(css`
    top: calc(${({ theme }) => theme.layout.header.height}rem + 72rem);
    left: 30rem;
    width: calc(100% - 60rem);
    height: calc(
      100% - calc(${({ theme }) => theme.layout.header.height}rem + 72rem) -
        30rem
    );
  `)};
`;

export const TopPart = styled.div`
  background-image: url(${svgErrorTopMobile});
  background-repeat: no-repeat;
  background-size: 100%;
  ${aspectRatio(348, 90)};

  svg {
    height: 100%;
  }

  ${mediaTablet(css`
    ${aspectRatio(1432, 120)};
    background-image: url(${svgErrorTop});
  `)};
`;

export const BodyPart = styled.div`
  flex: 1;
  background-image: url(${svgErrorBodyMobile});
  background-repeat: repeat-y;
  background-size: 100%;
  margin: -2rem 0;

  ${mediaTablet(css`
    background-image: url(${svgErrorBody});
  `)};
`;

export const BottomPart = styled.div`
  background-image: url(${svgErrorBottomMobile});
  background-repeat: no-repeat;
  background-size: 100%;
  ${aspectRatio(348, 45)};

  ${mediaTablet(css`
    ${aspectRatio(1432, 120)};
    background-image: url(${svgErrorBottom});
  `)};
`;

export const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 200rem 0;

  ${mediaTablet(css`
    padding: 0;
  `)};
`;

const DecorationLine = styled.div`
  background-repeat: no-repeat;
  position: absolute;
  width: 35rem;
  height: 371rem;
  top: 0;
  bottom: 0;
  margin: auto;

  ${mediaTablet(css`
    width: 500rem;
    height: 646rem;
  `)}
`;

export const DecorationLineLeft = styled(DecorationLine)`
  background-image: url(${svgErrorLinesLeftMobile});
  left: 0;

  ${mediaTablet(css`
    background-image: url(${svgErrorLinesLeft});
  `)}
`;

export const DecorationLineRight = styled(DecorationLine)`
  background-image: url(${svgErrorLinesRightMobile});
  right: 0;

  ${mediaTablet(css`
    background-image: url(${svgErrorLinesRight});
  `)}
`;
