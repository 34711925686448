export const stringToBoolean = (val: string): boolean => {
  if (!val) return false;
  switch (val.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return false;
  }
};

export const copyToClipboard = text => {
  if (text) {
    navigator.clipboard.writeText(text);
    // .then(() => alert('Text copied to clipboard!'))
    // .catch(err => console.error('Could not copy text: ', err));
  }
};

import { fromPairs } from 'lodash';

export const parseUrlQuery = () => {
  if (typeof window === 'undefined') return {};
  return fromPairs(
    window.location.search
      .replace('?', '')
      .split('&')
      .filter(Boolean)
      .map(parameter => parameter.split('='))
  );
};

export const checkQuery = (query: string) => {
  const url = window.location.href;
  if (url.indexOf('?' + query + '=') !== -1) return true;
  else if (url.indexOf('&' + query + '=') !== -1) return true;
  return false;
};

export const openInNewWindow = link => {
  const a = document.createElement('a');
  a.href = link;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const entityMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '/': '&#x2F;',
  '`': '&#x60;',
  '=': '&#x3D;',
};

export const escapeHtml = string => {
  return String(string).replace(/[&<>"'`=\\/]/g, s => {
    return entityMap[s];
  });
};
