import React from 'react';

import Error from 'components/Error/Error';
import { useCopyStore } from 'store';

export interface WindowTooSmallProps {}

const WindowTooSmall = () => {
  const copies = useCopyStore(state => state.copy);

  if (!copies.global) return null;
  const {
    global: {
      errors: { windowTooSmall: copy },
    },
  } = copies;

  return (
    <Error
      displayVariant="container"
      title={copy.title}
      body={copy.body}
      footer={copy.footer}
    />
  );
};

export default WindowTooSmall;
