import styled, { css } from 'styled-components';

import assets from 'utils/assets';
import {
  backgroundImage,
  glowAnimation,
  setTextStyles,
  setTypography,
} from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

import { InventoryItemKey, InventoryItemProps } from './InventoryItem';

export const Wrapper = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:disabled {
    cursor: default;
  }
`;

export const Item = styled.div<{
  $size: InventoryItemProps['size'];
  $isEmpty: InventoryItemProps['isEmpty'];
  $count: InventoryItemProps['amount'];
}>`
  ${backgroundImage(
    assets.svgs.lab.season1.meaterializer.inventory.background.mobile
  )};
  width: 260rem;
  height: 260rem;
  margin-bottom: 36rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${({ $size }) =>
    $size === 'xs' &&
    css`
      width: 100rem;
      height: 100rem;
      margin-bottom: 12rem;

      ${mediaTablet(css`
        ${backgroundImage(
          assets.svgs.lab.season1.meaterializer.inventory.background.desktop
        )};
        width: 100rem;
        height: 100rem;
      `)};
    `};

  ${({ $size }) =>
    $size === 'lg' &&
    css`
      ${mediaTablet(css`
        ${backgroundImage(
          assets.svgs.lab.season1.meaterializer.inventory.background.desktop
        )};
        width: 376rem;
        height: 376rem;
        margin-bottom: 40rem;
      `)};
    `};

  ${({ $isEmpty, $size }) =>
    $isEmpty &&
    css`
      ${backgroundImage(
        assets.svgs.lab.season1.meaterializer.inventory.backgroundEmpty.mobile
      )};

      ${$size === 'lg' &&
      mediaTablet(
        backgroundImage(
          assets.svgs.lab.season1.meaterializer.inventory.backgroundEmpty
            .desktop
        )
      )};
    `};

  ${({ $count, $size }) =>
    $count !== undefined &&
    css`
      &::after {
        ${({ theme: { locale } }) => setTypography('badgeLarge', locale)};
        content: '${$count}';
        position: absolute;
        top: 0;
        right: 0;
        background: black;
        height: 72rem;
        width: 72rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        ${$size === 'lg' &&
        css`
          top: 10rem;
          right: 10rem;
          transform: scale(1.3);
        `}
      }
    `};
`;

export const Label = styled.label<{ $size: InventoryItemProps['size'] }>`
  ${({ theme: { locale } }) => setTypography('body4', locale)};
  ${setTextStyles('rumSwizzle', 'md')};
  text-align: center;
  max-width: 260rem;

  ${({ $size }) =>
    $size === 'lg' &&
    css`
      ${({ theme: { locale } }) => setTypography('body1', locale)};

      ${mediaTablet(css`
        max-width: 250rem;
      `)};
    `};
`;

export const SuperSauceWrapper = styled.div`
  width: 76%;
  height: 76%;
`;

export const Icon = styled.img<{
  $itemKey: InventoryItemProps['itemKey'];
  $size: InventoryItemProps['size'];
  $hasGlowEffect: InventoryItemProps['hasGlowEffect'];
}>`
  width: 202rem;
  height: 202rem;
  user-select: none;

  ${({ $size }) =>
    $size === 'lg' &&
    css`
      ${mediaTablet(css`
        width: 292rem;
        height: 292rem;
      `)};
    `};

  ${({ $itemKey: $icon, $size }) =>
    $icon === InventoryItemKey.SLIM_JIM &&
    css`
      width: 176rem;
      height: 260rem;
      margin: 0 0 60rem 26rem;

      ${mediaTablet(css`
        margin: 0 0 60rem 26rem;
      `)};

      ${$size === 'lg' &&
      mediaTablet(css`
        width: 252rem;
        height: 376rem;
        margin: 0 0 84rem 35rem;
      `)};
    `};

  ${({ $hasGlowEffect }) => $hasGlowEffect && glowAnimation(true)};
`;

export const EmptyIcon = styled.img<{
  $size: InventoryItemProps['size'];
}>`
  width: 217rem;
  height: 217rem;
  user-select: none;

  ${({ $size }) =>
    $size === 'lg' &&
    css`
      ${mediaTablet(css`
        width: 310rem;
        height: 310rem;
      `)};
    `};
`;
