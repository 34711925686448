import { Tooltip, TooltipAnchor } from 'ariakit';
import styled, { css, keyframes } from 'styled-components';

import { TooltipProps } from 'components/Tooltip/Tooltip';

import { ArrowAnimationWrapper } from './TooltipContent/TooltipContent.styles';

type TooltipContainerProps = {
  $zIndex: TooltipProps['zIndex'];
  $position: TooltipProps['position'];
  $arrowPosition: TooltipProps['arrowPosition'];
  $animatedArrow: TooltipProps['animatedArrow'];
  $nonePointerEvents: TooltipProps['nonePointerEvents'];
};

const DEFAULT_ANIMATION_SHIFT = '75%';

const arrowAnimation = () => keyframes`
  0%   { transform: translateY(0); }
  20%  { transform: translateY(0); }
  50%  { transform: translateY(-100%); }
  70%  { transform: translateY(0); }
  100% { transform: translateY(0); }
`;

export const TooltipContainer = styled(Tooltip)<TooltipContainerProps>`
  position: relative;
  z-index: ${({ $zIndex }) => ($zIndex !== undefined ? $zIndex : 10)};
  opacity: 0;
  user-select: none;

  ${({ $position }) =>
    $position === 'top' &&
    css<TooltipContainerProps>`
      transform: translate3d(0, -${DEFAULT_ANIMATION_SHIFT}, 0);
      margin: 0 0 40rem;

      ${({ $arrowPosition }) =>
        $arrowPosition === 'start' &&
        css`
          margin-left: -66rem;
        `};

      ${({ $arrowPosition }) =>
        $arrowPosition === 'end' &&
        css`
          margin-right: -66rem;
        `};
    `};

  ${({ $position }) =>
    $position === 'bottom' &&
    css<TooltipContainerProps>`
      transform: translate3d(0, ${DEFAULT_ANIMATION_SHIFT}, 0);
      margin: 40rem 0 0;

      ${({ $arrowPosition }) =>
        $arrowPosition === 'start' &&
        css`
          margin-left: -66rem;
        `};

      ${({ $arrowPosition }) =>
        $arrowPosition === 'end' &&
        css`
          margin-right: -66rem;
        `};

      ${({ $arrowPosition }) =>
        $arrowPosition === 'very-end' &&
        css`
          margin-right: -46rem;
        `};
    `};

  ${({ $position }) =>
    $position === 'left' &&
    css<TooltipContainerProps>`
      transform: translate3d(-${DEFAULT_ANIMATION_SHIFT}, 0, 0);
      margin: 0 40rem 0 0;

      ${({ $arrowPosition }) =>
        $arrowPosition === 'start' &&
        css`
          margin-top: -70rem;
        `};

      ${({ $arrowPosition }) =>
        $arrowPosition === 'end' &&
        css`
          margin-bottom: -70rem;
        `};
    `};

  ${({ $position }) =>
    $position === 'right' &&
    css<TooltipContainerProps>`
      transform: translate3d(${DEFAULT_ANIMATION_SHIFT}, 0, 0);
      margin: 0 0 0 40rem;

      ${({ $arrowPosition }) =>
        $arrowPosition === 'start' &&
        css`
          margin-top: -70rem;
        `};

      ${({ $arrowPosition }) =>
        $arrowPosition === 'end' &&
        css`
          margin-bottom: -70rem;
        `};
    `};

  transition: opacity 0.5s cubic-bezier(0.28, 0.84, 0.42, 1),
    transform 0.5s cubic-bezier(0.28, 0.84, 0.42, 1);

  &[data-enter] {
    opacity: 1;
    transform: translate3d(0, 0, 0);

    ${({ $animatedArrow }) =>
      $animatedArrow &&
      css`
        ${ArrowAnimationWrapper} {
          animation: ${arrowAnimation()} 1s;
          animation-delay: 0.5s;
          animation-iteration-count: infinite;
          animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
        }
      `};
  }

  ${({ $nonePointerEvents }) =>
    $nonePointerEvents &&
    css`
      pointer-events: none;
    `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px -2rem 10rem ${({ theme }) => theme.colors.cyanAqua});
`;

export const Anchor = styled(TooltipAnchor)<{ $inheritedStyle: boolean }>`
  ${({ $inheritedStyle }) =>
    $inheritedStyle &&
    css`
      width: inherit;
      height: inherit;
    `};
`;

export const OffsetWrapper = styled.div<{ $offset: TooltipProps['offset'] }>`
  ${({ $offset }) =>
    $offset &&
    $offset.bottom &&
    css`
      margin-bottom: ${$offset.bottom}rem;
    `};

  ${({ $offset }) =>
    $offset &&
    $offset.top &&
    css`
      margin-top: ${$offset.top}rem;
    `};

  ${({ $offset }) =>
    $offset &&
    $offset.left &&
    css`
      margin-left: ${$offset.left}rem;
    `};

  ${({ $offset }) =>
    $offset &&
    $offset.right &&
    css`
      margin-right: ${$offset.right}rem;
    `};
`;
