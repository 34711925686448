import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { FC } from 'react';

import { isRemotePath } from 'u9/utils/path';

export type LinkProps = {
  title?: string;
  className?: string;
  onClick?: () => void;
  openInNewTab?: boolean;
};

const Link: FC<LinkProps & NextLinkProps> = ({
  href,
  children,
  scroll,
  openInNewTab,
  ...props
}) => {
  return (
    <NextLink href={href} passHref scroll={scroll}>
      <a
        target={
          openInNewTab ||
          isRemotePath(
            (typeof href === 'string' ? href : href.pathname) as string
          )
            ? '_blank'
            : undefined
        }
        rel="noopener noreferrer"
        title={props.title}
        className={props.className}
        {...props}
      >
        {children}
      </a>
    </NextLink>
  );
};

export default Link;
