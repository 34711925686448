import { USER_DATA } from 'types/user';
import localStorage from 'utils/localStorage';
import { logMe } from 'utils/logger';

import { USER_TYPE } from 'constants/enum';

export class User {
  private _issuer = '';
  private _demo = false;
  private _userType: USER_TYPE;
  private _publicAddress = '';
  private _email = '';
  private _exists = false;
  private _active = false;
  private isMfa = false;
  private _phoneNo = '';
  private _code = '';
  private _didToken = '123';

  // public get id(): string {
  //   return this._id;
  // }
  public get email(): string {
    return this._email;
  }
  public get demo(): boolean {
    return this._demo;
  }
  public get userType(): USER_TYPE {
    return this._userType;
  }
  public get didToken(): string {
    return this._didToken;
  }
  public get phoneNo(): string {
    return this._phoneNo;
  }
  public get code(): string {
    return this._code;
  }
  public get exists(): boolean {
    return this._exists;
  }
  public get active(): boolean {
    return this._active;
  }
  public get walletAddress(): string {
    return this._publicAddress;
  }

  // setId = (uid: string) => (this._id = uid);

  updateLoginStatus = data => {
    this._exists = data.exists;
    this._active = data.isActive;
  };
  updateEmail = email => {
    this._email = email;
  };

  updateCode = code => {
    this._code = code;
  };

  update(data: USER_DATA) {
    this._phoneNo = data.phoneNo ?? this._phoneNo;
    // there is one place when we're reciving it as phoneNumber
    this._phoneNo = data.phoneNumber ?? this._phoneNo;
    this._code = data.code ?? this._code;
    this._issuer = data.issuer ?? this._issuer;
    this._email = data.email ?? this._email;
    this._publicAddress = data.publicAddress ?? this._publicAddress;
    this._exists = data.exists ?? this._exists;
    this._active = data.isActive ?? this._active;
    this._didToken = data.didToken ?? this._didToken;
    this._demo = data.demo ?? this._demo;
    this._userType = data.demoType ?? this._userType;
  }

  displayLogs = () => {
    logMe('_issuer', this._issuer);
    logMe('_publicAddress', this._publicAddress);
    logMe('_email', this._email);
    logMe('_didToken', this._didToken);
    logMe('_code', this._code);
  };

  updateDidToken = (didToken: any) => {
    this._didToken = didToken;
  };
  saveSeassion = code => {
    localStorage.set('codeSJ', code, true);
    localStorage.set('emailSJ', this._email, true);
  };

  getSeassion = () => {
    const codeSJ = localStorage.get('codeSJ', true);
    const emailSJ = localStorage.get('emailSJ', true);
    if (this._email === emailSJ) {
      this._code = codeSJ;
    }
  };

  clearData = () => {
    this._issuer = '';
    this._publicAddress = '';
    this._email = '';
    this._exists = false;
    this._active = false;
    this.isMfa = false;
    this._phoneNo = '';
    this._code = '';
    this._didToken = '';
  };
}
