import { isSafari } from 'u9/utils/platform';

export enum NewCircuitVariant {
  ROADMAP_PRIMARY = 'ROADMAP_PRIMARY',
  ROADMAP_SECONDARY = 'ROADMAP_SECONDARY',
  ROADMAP_FOOTER = 'ROADMAP_FOOTER',
  ROADMAP_CONTENT = 'ROADMAP_CONTENT',
  MY_ACCOUNT_HEADER = 'MY_ACCOUNT_HEADER',
  MY_ACCOUNT_WALLET = 'MY_ACCOUNT_WALLET',
  MY_ACCOUNT_SETTINGS = 'MY_ACCOUNT_SETTINGS',
  MY_ACCOUNT_ADDITIONAL = 'MY_ACCOUNT_ADDITIONAL',
  MY_ACCOUNT_POPUP = 'MY_ACCOUNT_POPUP',
  MY_ACCOUNT_STATISTICS = 'MY_ACCOUNT_STATISTICS',
  MY_ACCOUNT_ACHIEVEMENTS = 'MY_ACCOUNT_ACHIEVEMENTS',
  MY_ACCOUNT_NFTS = 'MY_ACCOUNT_NFTS',
  ABOUT_LCD_LEFT = 'ABOUT_LCD_LEFT',
  ABOUT_LCD_RIGHT = 'ABOUT_LCD_RIGHT',
  LOG_IN_POPUP = 'LOG_IN_POPUP',
  FREEZE_POPUP = 'FREEZE_POPUP',
  FREEZE_LCD = 'FREEZE_LCD',
  CAPSULES = 'CAPSULES',
  CAPSULES_BG = 'CAPSULES_BG',
  LANDING_PRIMARY = 'LANDING_PRIMARY',
  STATIC_LANDING_SECONDARY = 'STATIC_LANDING_SECONDARY',
  LANDING_FOOTER = 'LANDING_FOOTER',
  LAB_POPUP = 'LAB_POPUP',
  LAB_INVENTORY_MEATA_POPUP = 'LAB_INVENTORY_MEATA_POPUP',
  LAB_INVENTORY_POPUP = 'LAB_INVENTORY_POPUP',
  LAB_INVENTORY_SAUCES = 'LAB_INVENTORY_SAUCES',
  LAB_WIDE_POPUP = 'LAB_WIDE_POPUP',
  FAQ_FOOTER = 'FAQ_FOOTER',
  STATIC_GALLERY = 'STATIC_GALLERY',
  ABOUT_QUESTIONS = 'ABOUT_QUESTIONS',
  ABOUT_CONTENT = 'ABOUT_CONTENT',
  FAQ_HEADER = 'FAQ_HEADER',
  MY_ACCOUNT_HEADER2 = 'MY_ACCOUNT_HEADER2',
  STATIC_MY_ACCOUNT_BODY = 'STATIC_MY_ACCOUNT_BODY',
  GALLERY_FILTERS = 'GALLERY_FILTERS',
  CAPSULE_STATS_FOOTER = 'CAPSULE_STATS_FOOTER',
  STATIC_ERROR_ACCOUNT = 'STATIC_ERROR_ACCOUNT',
  STATIC_ERROR_DATABASE = 'STATIC_ERROR_DATABASE',
  STATIC_ERROR_TRANSACTION = 'STATIC_ERROR_TRANSACTION',
}

const assets = {
  svgs: {
    navbar: {
      icons: {
        about: '/svgs/navbar/icon-about.svg',
        account: '/svgs/navbar/icon-account.svg',
        barcode: '/svgs/navbar/icon-barcode.svg',
        close: '/svgs/navbar/icon-close.svg',
        discord: '/svgs/navbar/icon-discord.svg',
        gallery: '/svgs/navbar/icon-gallery.svg',
        lab: '/svgs/navbar/icon-lab.svg',
        meataverse: '/svgs/navbar/icon-meataverse.svg',
        menu: '/svgs/navbar/icon-menu.svg',
        roadmap: '/svgs/navbar/icon-roadmap.svg',
        signUp: '/svgs/navbar/icon-sign-up.svg',
      },
      chevronDown: '/svgs/navbar/chevron-down.svg',
      chevronUp: '/svgs/navbar/chevron-up.svg',
      separator: '/svgs/navbar/separator.svg',
    },
    circuit: {
      circuit12: '/svgs/circuit-12.svg',
      circuit4: '/svgs/circuit4.svg',
      circuit4Mobile: '/svgs/circuit4-mobile.svg',
      circuit5: '/svgs/circuit5.svg',
      circuit5Mobile: '/svgs/circuit5-mobile.svg',
      circuit6: '/svgs/circuit6.svg',
      circuit6Mobile: '/svgs/circuit6-mobile.svg',
      circuit7: '/svgs/circuit7.svg',
      circuit7Mobile: '/svgs/circuit7-mobile.svg',
      circuit8: '/svgs/circuit8.svg',
      circuit8Background: '/svgs/circuit8-background.svg',
      circuit8BackgroundMobile: '/svgs/circuit8-background-mobile.svg',
      circuit8Mobile: '/svgs/circuit8-mobile.svg',
      circuit12Mobile: '/svgs/circuit12-mobile.svg',
      circuit13: '/svgs/circuit13.svg',
      circuit13Mobile: '/svgs/circuit13-mobile.svg',
      circuit1: '/svgs/landing-circuit1.svg',
      circuit1Mobile: '/svgs/landing-circuit1-mobile.svg',
      circuit1Pipes: '/svgs/landing-circuit1-pipes.svg',
      circuit1PipesMobile: '/svgs/landing-circuit1-pipes-mobile.svg',
      circuit2: '/svgs/landing-circuit2.svg',
      circuit2Mobile: '/svgs/landing-circuit2-mobile.svg',
      circuit2Pipes: '/svgs/landing-circuit2-pipes.svg',
      circuit2PipesMobile: '/svgs/landing-circuit2-pipes-mobile.svg',
      circuit3: '/svgs/landing-circuit3.svg',
      circuit3Mobile: '/svgs/landing-circuit3-mobile.svg',
    },
    newCircuit: {
      [NewCircuitVariant.ROADMAP_PRIMARY]: {
        body: '/svgs/circuits/1/body.svg',
        bodyMobile: '/svgs/circuits/1/body-mobile.svg',
        bottom: '/svgs/circuits/1/bottom.svg',
        bottomMobile: '/svgs/circuits/1/bottom-mobile.svg',
        decorationsLeftMobile: '/svgs/circuits/1/decorations-left-mobile.svg',
        decorationsRight: '/svgs/circuits/1/decorations-right.svg',
        top: '/svgs/circuits/1/top.svg',
        topMobile: '/svgs/circuits/1/top-mobile.svg',
      },
      [NewCircuitVariant.ROADMAP_SECONDARY]: {
        body: '/svgs/circuits/2/body.svg',
        bodyMobile: '/svgs/circuits/2/body-mobile.svg',
        bottom: '/svgs/circuits/2/bottom.svg',
        bottomMobile: '/svgs/circuits/2/bottom-mobile.svg',
        decorationsLeft: '/svgs/circuits/2/decorations-left.svg',
        decorationsLeftMobile: '/svgs/circuits/2/decorations-left-mobile.svg',
        decorationsRight: '/svgs/circuits/2/decorations-right.svg',
        decorationsRightMobile: '/svgs/circuits/2/decorations-right-mobile.svg',
        top: '/svgs/circuits/2/top.svg',
        topMobile: '/svgs/circuits/2/top-mobile.svg',
      },
      [NewCircuitVariant.ROADMAP_FOOTER]: {
        body: '/svgs/circuits/3/circuit.svg',
        bodyMobile: '/svgs/circuits/3/circuit-mobile.svg',
      },
      [NewCircuitVariant.ROADMAP_CONTENT]: {
        body: '/svgs/circuits/4/body.svg',
        bodyMobile: '/svgs/circuits/4/body-mobile.svg',
        bottom: '/svgs/circuits/4/bottom.svg',
        bottomMobile: '/svgs/circuits/4/bottom-mobile.svg',
        top: '/svgs/circuits/4/top.svg',
        topMobile: '/svgs/circuits/4/top-mobile.svg',
      },
      [NewCircuitVariant.MY_ACCOUNT_HEADER]: {
        body: '/svgs/circuits/5/body.svg',
        bodyMobile: '/svgs/circuits/5/body-mobile.svg',
        bottom: '/svgs/circuits/5/bottom.svg',
        bottomMobile: '/svgs/circuits/5/bottom-mobile.svg',
        decorationsLeft: '/svgs/circuits/5/decorations-left.svg',
        decorationsLeftMobile: '/svgs/circuits/5/decorations-left-mobile.svg',
        decorationsRight: '/svgs/circuits/5/decorations-right.svg',
        decorationsRightMobile: '/svgs/circuits/5/decorations-right-mobile.svg',
        top: '/svgs/circuits/5/top.svg',
        topMobile: '/svgs/circuits/5/top-mobile.svg',
      },
      [NewCircuitVariant.MY_ACCOUNT_WALLET]: {
        body: '/svgs/circuits/6-my-account-wallet/body.svg',
        bodyMobile: '/svgs/circuits/6-my-account-wallet/body-mobile.svg',
        decorationsLeft:
          '/svgs/circuits/6-my-account-wallet/decorations-left.svg',
        decorationsLeftMobile:
          '/svgs/circuits/6-my-account-wallet/decorations-left-mobile.svg',
      },
      [NewCircuitVariant.MY_ACCOUNT_SETTINGS]: {
        body: '/svgs/circuits/7/body.svg',
        bodyMobile: '/svgs/circuits/7/body-mobile.svg',
        bottom: '/svgs/circuits/7/bottom.svg',
        bottomMobile: '/svgs/circuits/7/bottom-mobile.svg',
        decorationsRight: '/svgs/circuits/7/decorations-right.svg',
        decorationsRightMobile: '/svgs/circuits/7/decorations-right-mobile.svg',
        top: '/svgs/circuits/7/top.svg',
        topMobile: '/svgs/circuits/7/top-mobile.svg',
      },
      [NewCircuitVariant.MY_ACCOUNT_ADDITIONAL]: {
        body: '/svgs/circuits/8/body.svg',
        bodyMobile: '/svgs/circuits/8/body-mobile.svg',
        bottom: '/svgs/circuits/8/bottom.svg',
        bottomMobile: '/svgs/circuits/8/bottom-mobile.svg',
        decorationsLeft: '/svgs/circuits/8/decorations-left.svg',
        decorationsLeftMobile: '/svgs/circuits/8/decorations-left-mobile.svg',
        decorationsRight: '/svgs/circuits/8/decorations-right.svg',
        decorationsRightMobile: '/svgs/circuits/8/decorations-right-mobile.svg',
        top: '/svgs/circuits/8/top.svg',
        topMobile: '/svgs/circuits/8/top-mobile.svg',
      },
      [NewCircuitVariant.MY_ACCOUNT_POPUP]: {
        body: '/svgs/circuits/9/body.svg',
        bodyMobile: '/svgs/circuits/9/body-mobile.svg',
        bottom: '/svgs/circuits/9/bottom.svg',
        bottomMobile: '/svgs/circuits/9/bottom-mobile.svg',
        decorationsBottom: '/svgs/circuits/9/decorations-bottom.svg',
        decorationsBottomMobile:
          '/svgs/circuits/9/decorations-bottom-mobile.svg',
        top: '/svgs/circuits/9/top.svg',
        topMobile: '/svgs/circuits/9/top-mobile.svg',
      },
      [NewCircuitVariant.MY_ACCOUNT_NFTS]: {
        body: '/svgs/circuits/27-my-account-nfts/body.svg',
        bodyMobile: '/svgs/circuits/27-my-account-nfts/body-mobile.svg',
        bottom: '/svgs/circuits/27-my-account-nfts/bottom.svg',
        bottomMobile: '/svgs/circuits/27-my-account-nfts/bottom-mobile.svg',
        top: '/svgs/circuits/27-my-account-nfts/top.svg',
        topMobile: '/svgs/circuits/27-my-account-nfts/top-mobile.svg',
        decorationsRight:
          '/svgs/circuits/27-my-account-nfts/decorations-right.svg',
      },
      [NewCircuitVariant.ABOUT_LCD_LEFT]: {
        body: '/svgs/circuits/10-about-lcd-left/body.svg',
        bodyMobile: '/svgs/circuits/10-about-lcd-left/body-mobile.svg',
        mask: '/svgs/circuits/10-about-lcd-left/mask.svg',
        maskMobile: '/svgs/circuits/10-about-lcd-left/mask-mobile.svg',
      },
      [NewCircuitVariant.ABOUT_LCD_RIGHT]: {
        body: '/svgs/circuits/11-about-lcd-right/body.svg',
        bodyMobile: '/svgs/circuits/11-about-lcd-right/body-mobile.svg',
        mask: '/svgs/circuits/11-about-lcd-right/mask.svg',
        maskMobile: '/svgs/circuits/11-about-lcd-right/mask-mobile.svg',
      },
      [NewCircuitVariant.LOG_IN_POPUP]: {
        body: '/svgs/circuits/12-log-in/body.svg',
        bodyMobile: '/svgs/circuits/12-log-in/body-mobile.svg',
        bottom: '/svgs/circuits/12-log-in/bottom.svg',
        bottomMobile: '/svgs/circuits/12-log-in/bottom-mobile.svg',
        decorationsLeft: '/svgs/circuits/12-log-in/decorations-left.svg',
        decorationsLeftMobile:
          '/svgs/circuits/12-log-in/decorations-left-mobile.svg',
        decorationsRight: '/svgs/circuits/12-log-in/decorations-right.svg',
        decorationsRightMobile:
          '/svgs/circuits/12-log-in/decorations-right-mobile.svg',
        top: '/svgs/circuits/12-log-in/top.svg',
        topMobile: '/svgs/circuits/12-log-in/top-mobile.svg',
      },
      [NewCircuitVariant.FREEZE_POPUP]: {
        body: '/svgs/circuits/13-freeze-popup/body.svg',
        bodyMobile: '/svgs/circuits/13-freeze-popup/body-mobile.svg',
        decorationsBottomMobile:
          '/svgs/circuits/13-freeze-popup/decorations-bottom-mobile.svg',
        decorationsLeft: '/svgs/circuits/13-freeze-popup/decorations-left.svg',
        decorationsRight:
          '/svgs/circuits/13-freeze-popup/decorations-right.svg',
        decorationsRightMobile:
          '/svgs/circuits/13-freeze-popup/decorations-right-mobile.svg',
      },
      [NewCircuitVariant.FREEZE_LCD]: {
        body: '/svgs/circuits/14-freeze-lcd/body.svg',
        bodyMobile: '/svgs/circuits/14-freeze-lcd/body-mobile.svg',
        bottom: '/svgs/circuits/14-freeze-lcd/bottom.svg',
        bottomMobile: '/svgs/circuits/14-freeze-lcd/bottom-mobile.svg',
        top: '/svgs/circuits/14-freeze-lcd/top.svg',
        topMobile: '/svgs/circuits/14-freeze-lcd/top-mobile.svg',
      },
      [NewCircuitVariant.CAPSULES]: {
        body: '/svgs/circuits/15-capsules/body.svg',
        bodyMobile: '/svgs/circuits/15-capsules/body-mobile.svg',
        bottom: '/svgs/circuits/15-capsules/bottom.svg',
        bottomMobile: '/svgs/circuits/15-capsules/bottom-mobile.svg',
        top: '/svgs/circuits/15-capsules/top.svg',
        topMobile: '/svgs/circuits/15-capsules/top-mobile.svg',
        decorationsLeft: '/svgs/circuits/15-capsules/decorations-left.svg',
        decorationsLeftMobile:
          '/svgs/circuits/15-capsules/decorations-left-mobile.svg',
        // decorationsRight: '/svgs/circuits/15-capsules/decorations-right.svg',
        decorationsRightMobile:
          '/svgs/circuits/15-capsules/decorations-right-mobile.svg',
      },
      [NewCircuitVariant.CAPSULES_BG]: {
        body: '/svgs/circuits/16-capsules-bg/body.svg',
        bodyMobile: '/svgs/circuits/16-capsules-bg/body.svg',
        bottom: '/svgs/circuits/16-capsules-bg/bottom.svg',
        bottomMobile: '/svgs/circuits/16-capsules-bg/bottom.svg',
        top: '/svgs/circuits/16-capsules-bg/top.svg',
        topMobile: '/svgs/circuits/16-capsules-bg/top.svg',
      },
      [NewCircuitVariant.LANDING_PRIMARY]: {
        body: '/svgs/circuits/17-landing-primary/body.svg',
        bodyMobile: '/svgs/circuits/17-landing-primary/body-mobile.svg',
        bottom: '/svgs/circuits/17-landing-primary/bottom.svg',
        bottomMobile: '/svgs/circuits/17-landing-primary/bottom-mobile.svg',
        decorationsLeft:
          '/svgs/circuits/17-landing-primary/decorations-left.svg',
        decorationsLeftMobile:
          '/svgs/circuits/17-landing-primary/decorations-left-mobile.svg',
        decorationsRight:
          '/svgs/circuits/17-landing-primary/decorations-right.svg',
        decorationsRightMobile:
          '/svgs/circuits/17-landing-primary/decorations-right-mobile.svg',
        top: '/svgs/circuits/17-landing-primary/top.svg',
        topMobile: '/svgs/circuits/17-landing-primary/top-mobile.svg',
      },
      [NewCircuitVariant.STATIC_LANDING_SECONDARY]: {
        body: '/svgs/circuits/18-static-landing-secondary/body.svg',
        bodyMobile:
          '/svgs/circuits/18-static-landing-secondary/body-mobile.svg',
        decorationsLeft:
          '/svgs/circuits/18-static-landing-secondary/decorations-left.svg',
        decorationsLeftMobile:
          '/svgs/circuits/18-static-landing-secondary/decorations-left-mobile.svg',
        decorationsRight:
          '/svgs/circuits/18-static-landing-secondary/decorations-right.svg',
        decorationsRightMobile:
          '/svgs/circuits/18-static-landing-secondary/decorations-right-mobile.svg',
      },
      [NewCircuitVariant.LANDING_FOOTER]: {
        body: '/svgs/circuits/19-landing-footer/body.svg',
        bodyMobile: '/svgs/circuits/19-landing-footer/body-mobile.svg',
        bottom: '/svgs/circuits/19-landing-footer/bottom.svg',
        bottomMobile: '/svgs/circuits/19-landing-footer/bottom-mobile.svg',
        decorationsLeft:
          '/svgs/circuits/19-landing-footer/decorations-left.svg',
        decorationsLeftMobile:
          '/svgs/circuits/19-landing-footer/decorations-left-mobile.svg',
        decorationsRight:
          '/svgs/circuits/19-landing-footer/decorations-right.svg',
        decorationsRightMobile:
          '/svgs/circuits/19-landing-footer/decorations-right-mobile.svg',
        top: '/svgs/circuits/19-landing-footer/top.svg',
        topMobile: '/svgs/circuits/19-landing-footer/top-mobile.svg',
      },
      [NewCircuitVariant.LAB_POPUP]: {
        body: '/svgs/circuits/20-lab-popup/body.svg',
        bodyMobile: '/svgs/circuits/20-lab-popup/body-mobile.svg',
        bottom: '/svgs/circuits/20-lab-popup/bottom.svg',
        bottomMobile: '/svgs/circuits/20-lab-popup/bottom-mobile.svg',
        decorationsBottom: '/svgs/circuits/20-lab-popup/decorations-bottom.svg',
        decorationsBottomMobile:
          '/svgs/circuits/20-lab-popup/decorations-bottom-mobile.svg',
        top: '/svgs/circuits/20-lab-popup/top.svg',
        topMobile: '/svgs/circuits/20-lab-popup/top-mobile.svg',
      },
      [NewCircuitVariant.LAB_INVENTORY_POPUP]: {
        body: '/svgs/circuits/21-lab-inventory-popup/body.svg',
        bodyMobile: '/svgs/circuits/21-lab-inventory-popup/body-mobile.svg',
        bottom: '/svgs/circuits/21-lab-inventory-popup/bottom.svg',
        bottomMobile: '/svgs/circuits/21-lab-inventory-popup/bottom-mobile.svg',
        decorationsRight:
          '/svgs/circuits/21-lab-inventory-popup/decorations-right.svg',
        decorationsRightMobile:
          '/svgs/circuits/21-lab-inventory-popup/decorations-right-mobile.svg',
        top: '/svgs/circuits/21-lab-inventory-popup/top.svg',
        topMobile: '/svgs/circuits/21-lab-inventory-popup/top-mobile.svg',
        close: '/svgs/circuits/21-lab-inventory-popup/close.svg',
      },
      [NewCircuitVariant.LAB_INVENTORY_SAUCES]: {
        body: '/svgs/circuits/22-lab-inventory-square/body.svg',
        bodyMobile: '/svgs/circuits/22-lab-inventory-square/mobile-body.svg',
        close: '/svgs/circuits/21-lab-inventory-popup/close.svg',
        decorationsLeft:
          '/svgs/circuits/22-lab-inventory-square/decorations-left.svg',
      },
      [NewCircuitVariant.LAB_WIDE_POPUP]: {
        body: '/svgs/circuits/23-lab-wide-popup/body.svg',
        bodyMobile: '/svgs/circuits/23-lab-wide-popup/body-mobile.svg',
        bottom: '/svgs/circuits/23-lab-wide-popup/bottom.svg',
        bottomMobile: '/svgs/circuits/23-lab-wide-popup/bottom-mobile.svg',
        decorationsTop: '/svgs/circuits/23-lab-wide-popup/decorations-top.svg',
        top: '/svgs/circuits/23-lab-wide-popup/top.svg',
        topMobile: '/svgs/circuits/23-lab-wide-popup/top-mobile.svg',
        close: '/svgs/circuits/23-lab-wide-popup/close.svg',
      },
      [NewCircuitVariant.MY_ACCOUNT_STATISTICS]: {
        body: '/svgs/circuits/24-statistics/body.svg',
        bodyMobile: '/svgs/circuits/24-statistics/body-mobile.svg',
        bottom: '/svgs/circuits/24-statistics/bottom.svg',
        bottomMobile: '/svgs/circuits/24-statistics/bottom-mobile.svg',
        top: '/svgs/circuits/24-statistics/top.svg',
        topMobile: '/svgs/circuits/24-statistics/top-mobile.svg',
      },
      [NewCircuitVariant.FAQ_FOOTER]: {
        body: '/svgs/circuits/24-faq-footer/body.svg',
        bodyMobile: '/svgs/circuits/24-faq-footer/body-mobile.svg',
        bottom: '/svgs/circuits/24-faq-footer/bottom.svg',
        bottomMobile: '/svgs/circuits/24-faq-footer/bottom-mobile.svg',
        top: '/svgs/circuits/24-faq-footer/top.svg',
        topMobile: '/svgs/circuits/24-faq-footer/top-mobile.svg',
        decorationsLeft: '/svgs/circuits/24-faq-footer/decorations-left.svg',
        decorationsLeftMobile:
          '/svgs/circuits/24-faq-footer/decorations-left-mobile.svg',
        decorationsRight: '/svgs/circuits/24-faq-footer/decorations-right.svg',
        decorationsRightMobile:
          '/svgs/circuits/24-faq-footer/decorations-right-mobile.svg',
      },
      [NewCircuitVariant.STATIC_GALLERY]: {
        body: '/svgs/circuits/25-static-gallery/body.svg',
        bodyMobile: '/svgs/circuits/25-static-gallery/body-mobile.svg',
      },
      [NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS]: {
        body: '/svgs/circuits/26-my-account-achievements/body.svg',
        bodyMobile: '/svgs/circuits/26-my-account-achievements/body-mobile.svg',
        bottom: '/svgs/circuits/26-my-account-achievements/bottom.svg',
        bottomMobile:
          '/svgs/circuits/26-my-account-achievements/bottom-mobile.svg',
        decorationsLeft:
          '/svgs/circuits/26-my-account-achievements/decorations-left.svg',
        decorationsLeftMobile:
          '/svgs/circuits/26-my-account-achievements/decorations-left-mobile.svg',
        decorationsRight:
          '/svgs/circuits/26-my-account-achievements/decorations-right.svg',
        decorationsRightMobile:
          '/svgs/circuits/26-my-account-achievements/decorations-right-mobile.svg',
        decorationsBottom:
          '/svgs/circuits/26-my-account-achievements/decorations-bottom.svg',
        decorationsBottomMobile:
          '/svgs/circuits/26-my-account-achievements/decorations-bottom-mobile.svg',
        top: '/svgs/circuits/26-my-account-achievements/top.svg',
        topMobile: '/svgs/circuits/26-my-account-achievements/top-mobile.svg',
      },
      [NewCircuitVariant.ABOUT_QUESTIONS]: {
        body: '/svgs/circuits/28-about-questions/body.svg',
        bodyMobile: '/svgs/circuits/28-about-questions/body-mobile.svg',
        bottom: '/svgs/circuits/28-about-questions/bottom.svg',
        bottomMobile: '/svgs/circuits/28-about-questions/bottom-mobile.svg',
        decorationsLeft:
          '/svgs/circuits/28-about-questions/decorations-left.svg',
        decorationsRight:
          '/svgs/circuits/28-about-questions/decorations-right.svg',
        decorationsRightMobile:
          '/svgs/circuits/28-about-questions/decorations-right-mobile.svg',
        decorationsBottom:
          '/svgs/circuits/28-about-questions/decorations-bottom.svg',
        decorationsBottomMobile:
          '/svgs/circuits/28-about-questions/decorations-bottom-mobile.svg',
        top: '/svgs/circuits/28-about-questions/top.svg',
        topMobile: '/svgs/circuits/28-about-questions/top-mobile.svg',
      },
      [NewCircuitVariant.ABOUT_CONTENT]: {
        body: '/svgs/circuits/29-about-content/body.svg',
        bodyMobile: '/svgs/circuits/29-about-content/body-mobile.svg',
        bottom: '/svgs/circuits/29-about-content/bottom.svg',
        bottomMobile: '/svgs/circuits/29-about-content/bottom-mobile.svg',
        decorationsLeft: '/svgs/circuits/29-about-content/decorations-left.svg',
        decorationsRightMobile:
          '/svgs/circuits/29-about-content/decorations-right-mobile.svg',
        decorationsBottom:
          '/svgs/circuits/29-about-content/decorations-bottom.svg',

        top: '/svgs/circuits/29-about-content/top.svg',
        topMobile: '/svgs/circuits/29-about-content/top-mobile.svg',
      },
      [NewCircuitVariant.FAQ_HEADER]: {
        body: '/svgs/circuits/30-faq-header/body.svg',
        bodyMobile: '/svgs/circuits/30-faq-header/body-mobile.svg',
        bottom: '/svgs/circuits/30-faq-header/bottom.svg',
        bottomMobile: '/svgs/circuits/30-faq-header/bottom-mobile.svg',
        top: '/svgs/circuits/30-faq-header/top.svg',
        topMobile: '/svgs/circuits/30-faq-header/top-mobile.svg',
        decorationsRight: '/svgs/circuits/30-faq-header/decorations-right.svg',
      },
      [NewCircuitVariant.MY_ACCOUNT_HEADER2]: {
        body: '/svgs/circuits/31-my-account-header2/body.svg',
        bodyMobile: '/svgs/circuits/31-my-account-header2/body-mobile.svg',
        bottom: '/svgs/circuits/31-my-account-header2/bottom.svg',
        bottomMobile: '/svgs/circuits/31-my-account-header2/bottom-mobile.svg',
        decorationsLeft:
          '/svgs/circuits/31-my-account-header2/decorations-left.svg',
        decorationsLeftMobile:
          '/svgs/circuits/31-my-account-header2/decorations-left-mobile.svg',
        decorationsRight:
          '/svgs/circuits/31-my-account-header2/decorations-right.svg',
        decorationsRightMobile:
          '/svgs/circuits/31-my-account-header2/decorations-right-mobile.svg',
        top: '/svgs/circuits/31-my-account-header2/top.svg',
        topMobile: '/svgs/circuits/31-my-account-header2/top-mobile.svg',
      },
      [NewCircuitVariant.STATIC_MY_ACCOUNT_BODY]: {
        body: '/svgs/circuits/32-my-account-about/body.svg',
        bodyMobile: '/svgs/circuits/32-my-account-about/body-mobile.svg',
        decorationsLeft:
          '/svgs/circuits/32-my-account-about/decorations-left.svg',
        decorationsLeftMobile:
          '/svgs/circuits/32-my-account-about/decorations-left-mobile.svg',
        decorationsRight:
          '/svgs/circuits/32-my-account-about/decorations-right.svg',
      },
      [NewCircuitVariant.GALLERY_FILTERS]: {
        body: '/svgs/circuits/33-gallery-filters/body.svg',
        bodyMobile: '/svgs/circuits/33-gallery-filters/body.svg',
        bottom: '/svgs/circuits/33-gallery-filters/bottom.svg',
        bottomMobile: '/svgs/circuits/33-gallery-filters/bottom.svg',
        top: '/svgs/circuits/33-gallery-filters/top.svg',
        topMobile: '/svgs/circuits/33-gallery-filters/top.svg',
      },
      [NewCircuitVariant.CAPSULE_STATS_FOOTER]: {
        body: '/svgs/circuits/34-gallery-footer/body.svg',
        bodyMobile: '/svgs/circuits/34-gallery-footer/body-mobile.svg',
        bottom: '/svgs/circuits/34-gallery-footer/bottom.svg',
        bottomMobile: '/svgs/circuits/34-gallery-footer/bottom-mobile.svg',
        top: '/svgs/circuits/34-gallery-footer/top.svg',
        topMobile: '/svgs/circuits/34-gallery-footer/top-mobile.svg',
      },
      [NewCircuitVariant.STATIC_ERROR_ACCOUNT]: {
        body: '/svgs/circuits/35-static-error-account/body.svg',
        bodyMobile: '/svgs/circuits/35-static-error-account/body-mobile.svg',
      },
      [NewCircuitVariant.STATIC_ERROR_DATABASE]: {
        body: '/svgs/circuits/36-static-error-database/body.svg',
        bodyMobile: '/svgs/circuits/36-static-error-database/body-mobile.svg',
        decorationsBottom:
          '/svgs/circuits/36-static-error-database/decorations-bottom.svg',
        decorationsBottomMobile:
          '/svgs/circuits/36-static-error-database/decorations-bottom-mobile.svg',
        decorationsRight:
          '/svgs/circuits/36-static-error-database/decorations-right.svg',
        decorationsLeftMobile:
          '/svgs/circuits/36-static-error-database/decorations-left-mobile.svg',
        decorationsRightMobile:
          '/svgs/circuits/36-static-error-database/decorations-right-mobile.svg',
      },
      [NewCircuitVariant.STATIC_ERROR_TRANSACTION]: {
        body: '/svgs/circuits/37-static-error-transaction/body.svg',
        bodyMobile:
          '/svgs/circuits/37-static-error-transaction/body-mobile.svg',
        decorationsRight:
          '/svgs/circuits/37-static-error-transaction/decorations-right.svg',
      },
    },

    gallery: {
      tiles: {
        tiles_top: '/svgs/gallery/tiles_top.svg',
        tiles_top_mobile: '/svgs/gallery/top_tile_mobile.svg',
        tile: '/svgs/gallery/tile.svg',
        tileMobile: '/svgs/gallery/tile-mobile.svg',
        left_tile: '/svgs/gallery/left_tile.svg',
        mobile_tile_side: '/svgs/gallery/mobile_tile_side.svg',
        pagination_tiles: '/svgs/gallery/pagination_bg_desktop.svg',
        pagination_tiles_mobile: '/svgs/gallery/top_tile_mobile.svg',
        pagination_tile_horizontal:
          '/svgs/gallery/pagination_horizontal_tile.svg',
      },
      filters: {
        background: '/svgs/gallery/filters_bg.svg',
        expandIcon: '/svgs/gallery/expand.png',
        angleUp: '/svgs/gallery/angle_up.svg',
        sliderThumb: '/svgs/gallery/slider_thumb.png',
      },
      navbar: {
        mobilePopup: '/svgs/gallery/mobile_popup.svg',
        sortIcon: '/svgs/gallery/sort_icon.svg',
        filterIcon: '/svgs/gallery/filters_icon.svg',
        desktop: '/svgs/gallery/navbar_desktop.svg',
        mobile: '/svgs/gallery/navbar_mobile.svg',
        sortByExpand: '/svgs/gallery/sorting.svg',
        sortByExpandMobile: '/svgs/gallery/mobile_sort_dropdown.svg',
        check: '/svgs/gallery/check.svg',
        checkFalse: '/svgs/gallery/check-false.svg',
        arrowUp: '/svgs/gallery/arrow-up.svg',
        grid3active: '/svgs/gallery/grid_3_active.svg',
        grid3disabled: '/svgs/gallery/grid_3_disabled.svg',
        grid4active: '/svgs/gallery/grid_4_active.svg',
        grid4disabled: '/svgs/gallery/grid_4_disabled.svg',
      },
      page: {
        mobileCircuit: '/svgs/circuit-gallery-mobile-1.svg',
      },
      saveImage: {
        desktop: '/svgs/gallery/save-image.svg',
        mobile: '/svgs/gallery/save-image-mobile.svg',
      },
      button: '/svgs/gallery/button.svg',
    },
    landingPage: {
      capsules: {
        capsule1: '/svgs/landing/capsule1.svg',
        capsule2: '/svgs/landing/capsule2.svg',
        capsule3: '/svgs/landing/capsule3.svg',
      },
      capsulesWires: '/svgs/landing/capsules-wires.svg',
      elevatorsGround: '/svgs/landing/elevators-ground.svg',
      popupBackground: '/svgs/landing/popup-background.svg',
    },
    about: {
      buttonBg: '/svgs/about/button-bg.svg',
      buttonBgMobile: '/svgs/about/button-bg-mobile.svg',
    },
    lab: {
      season1: {
        inventory: {
          backgroundMobile: '/svgs/lab/s1/inventory/retro-mobile-bg.svg',
          newSauce: '/svgs/lab/s1/inventory/new-sauce.svg',
        },
        meaterializer: {
          background: '/svgs/lab/s1/meaterializer/meaterializer.svg',
          codeCapsule: '/svgs/lab/s1/meaterializer/capsule1.svg',
          warning: '/svgs/lab/s1/meaterializer/warning.svg',
          capsule: {
            cablesTop: '/svgs/lab/s1/meaterializer/capsule-cables-top.svg',
            cablesBottom:
              '/svgs/lab/s1/meaterializer/capsule-cables-bottom.svg',
          },
          scrollArrows: '/svgs/lab/s1/meaterializer/scroll-arrows.svg',
          inventory: {
            background: {
              desktop: '/svgs/lab/s1/meaterializer/inventory/background.svg',
              mobile:
                '/svgs/lab/s1/meaterializer/inventory/background-mobile.svg',
            },
            backgroundEmpty: {
              desktop:
                '/svgs/lab/s1/meaterializer/inventory/background-empty.svg',
              mobile:
                '/svgs/lab/s1/meaterializer/inventory/background-empty-mobile.svg',
            },
            items: {
              sauce: '/svgs/lab/s1/meaterializer/inventory/item-sauce.svg',
              slimJim: '/images/lab/s1/meaterializer/item-slim-jim.png',
            },
            emptyIcon: '/svgs/lab/s1/meaterializer/inventory/item-is-empty.svg',
            button: '/svgs/lab/s1/meaterializer/inventory-btn.svg',
            tabViewActiveLeft:
              '/svgs/lab/s1/meaterializer/inventory/tabview-active-left.svg',
            tabViewActiveRight:
              '/svgs/lab/s1/meaterializer/inventory/tabview-active-right.svg',
            tabViewActiveMobileLeft:
              '/svgs/lab/s1/meaterializer/inventory/tabview-active-left-mobile.svg',
            tabViewActiveMobileRight:
              '/svgs/lab/s1/meaterializer/inventory/tabview-active-right-mobile.svg',
            sauceBg: '/svgs/lab/s1/meaterializer/inventory/sauce-bg.svg',
          },
          lcd: {
            stick: {
              mobile: {
                item: '/images/lab/s1/meaterializer/stick-item-mobile.png',
                slot: '/svgs/lab/s1/meaterializer/lcd/stick-slot-mobile.svg',
                slotOver:
                  '/svgs/lab/s1/meaterializer/lcd/stick-slot-over-mobile.svg',
              },
              desktop: {
                item: '/images/lab/s1/meaterializer/stick-item.png',
                slot: '/svgs/lab/s1/meaterializer/lcd/stick-slot.svg',
                slotOver: '/svgs/lab/s1/meaterializer/lcd/stick-slot-over.svg',
              },
            },
            sauce: {
              mobile: {
                item: '/svgs/lab/s1/meaterializer/lcd/sauce-item.svg',
                slot: '/svgs/lab/s1/meaterializer/lcd/sauce-slot-mobile.svg',
              },
              desktop: {
                item: '/svgs/lab/s1/meaterializer/lcd/sauce-item.svg',
                slot: '/svgs/lab/s1/meaterializer/lcd/sauce-slot.svg',
              },
            },
            docks: {
              stickDock: '/svgs/lab/s1/meaterializer/lcd/stick-dock.svg',
              stickDock2: '/svgs/lab/s1/meaterializer/lcd/stick-dock2.svg',
              sauceDock: '/svgs/lab/s1/meaterializer/lcd/sauce-dock.svg',
              arrow: '/svgs/lab/s1/meaterializer/lcd/arrow.svg',
            },
            button: {
              base: '/svgs/lab/s1/meaterializer/lcd/button-base.svg',
              clickable: '/svgs/lab/s1/meaterializer/lcd/button.svg',
            },
            endIcon: '/svgs/lab/s1/meaterializer/lcd/end-icon.svg',
            parts: {
              hands: '/svgs/lab/s1/meaterializer/lcd/parts/hands.svg',
            },
          },
          evolutionButton: '/svgs/lab/s1/meaterializer/evolution-button.svg',
          evolutionButtonMobile:
            '/svgs/lab/s1/meaterializer/evolution-button-mobile.svg',
          evolutionIcon: '/svgs/lab/s1/meaterializer/evolution-icon.svg',
          recipeIcon: '/svgs/lab/s1/meaterializer/recipe-icon.svg',
          effects: {
            background: '/svgs/lab/s1/meaterializer/effects/background.svg',
            rarity: {
              rare: '/svgs/lab/s1/meaterializer/effects/rarity-rare.svg',
              common: '/svgs/lab/s1/meaterializer/effects/rarity-common.svg',
              epic: '/svgs/lab/s1/meaterializer/effects/rarity-epic.svg',
              legendary:
                '/svgs/lab/s1/meaterializer/effects/rarity-legendary.svg',
            },
            elements: {
              arms: '/svgs/lab/s1/meaterializer/effects/elements/arms.svg',
              backpack:
                '/svgs/lab/s1/meaterializer/effects/elements/backpack.svg',
              body: '/svgs/lab/s1/meaterializer/effects/elements/body.svg',
              foots: '/svgs/lab/s1/meaterializer/effects/elements/foots.svg',
              handAccessory:
                '/svgs/lab/s1/meaterializer/effects/elements/hand-accessory.svg',
              hands: '/svgs/lab/s1/meaterializer/effects/elements/hands.svg',
              headMid:
                '/svgs/lab/s1/meaterializer/effects/elements/head-mid.svg',
              head: '/svgs/lab/s1/meaterializer/effects/elements/head.svg',
              pants: '/svgs/lab/s1/meaterializer/effects/elements/pants.svg',
            },
            extra: {
              capsules: '/svgs/lab/s1/meaterializer/effects/extra/capsules.svg',
              double: '/svgs/lab/s1/meaterializer/effects/extra/double.svg',
              randomSpot:
                '/svgs/lab/s1/meaterializer/effects/extra/random-spot.svg',
              undo: '/svgs/lab/s1/meaterializer/effects/extra/undo.svg',
              commonToRare:
                '/svgs/lab/s1/meaterializer/effects/extra/common-to-rare.svg',
              colorRare:
                '/svgs/lab/s1/meaterializer/effects/extra/color-rare.svg',
              colorEpic:
                '/svgs/lab/s1/meaterializer/effects/extra/color-epic.svg',
              colorLegendary:
                '/svgs/lab/s1/meaterializer/effects/extra/color-legendary.svg',
              randomToEpic:
                '/svgs/lab/s1/meaterializer/effects/extra/random-to-epic.svg',
              epicToLegendary:
                '/svgs/lab/s1/meaterializer/effects/extra/epic-to-legendary.svg',
              commonToCommon:
                '/svgs/lab/s1/meaterializer/effects/extra/common-to-common.svg',
              anyTraitToCommon:
                '/svgs/lab/s1/meaterializer/effects/extra/any-trait-to-common.svg',
            },
            stripeCapsule:
              '/svgs/lab/s1/meaterializer/effects/stripe-capsule.svg',
          },
        },
        sauceMixer: {
          background: '/svgs/lab/s1/sauce-mixer/background.svg',
          backgroundMobile: '/svgs/lab/s1/sauce-mixer/background-mobile.svg',
          mixButton: '/svgs/lab/s1/sauce-mixer/button.png',
          mixButtonActive: '/svgs/lab/s1/sauce-mixer/button-active.png',
          mixButtonPressed: '/svgs/lab/s1/sauce-mixer/button-pressed.png',
          mixTube: '/svgs/lab/s1/sauce-mixer/mix-tube.svg',
          mixTubeActive: '/svgs/lab/s1/sauce-mixer/mix-tube-active.svg',
          clipper: '/svgs/lab/s1/sauce-mixer/sauce-clipper.svg',
          arrowIndicator: '/svgs/lab/s1/sauce-mixer/arrow-indicator.svg',
          cursor: '/svgs/lab/s1/sauce-mixer/cursor.svg',
          cursorDrag: '/svgs/lab/s1/sauce-mixer/cursor-drag.svg',
          welcomePopup: {
            assets1: '/svgs/lab/s1/sauce-mixer/welcome-popup/assets-1.svg',
            assets1Mobile:
              '/svgs/lab/s1/sauce-mixer/welcome-popup/assets-1-mobile.svg',
            assets2: '/svgs/lab/s1/sauce-mixer/welcome-popup/assets-2.svg',
            assets2Mobile:
              '/svgs/lab/s1/sauce-mixer/welcome-popup/assets-2-mobile.svg',
            assets3: '/svgs/lab/s1/sauce-mixer/welcome-popup/assets-3.svg',
            assets4: '/svgs/lab/s1/sauce-mixer/welcome-popup/assets-4.svg',
            assets4Mobile:
              '/svgs/lab/s1/sauce-mixer/welcome-popup/assets-4-mobile.svg',
          },
        },
        scanner: {
          welcomePopupAssets: {
            assets1:
              '/svgs/lab/s1/barcode-scanner/welcome-popup-assets/assets1.svg',
            assets2:
              '/svgs/lab/s1/barcode-scanner/welcome-popup-assets/assets2.svg',
            assets2Mobile:
              '/svgs/lab/s1/barcode-scanner/welcome-popup-assets/assets2mobile.svg',
            assets3:
              '/svgs/lab/s1/barcode-scanner/welcome-popup-assets/assets3.svg',
            assets4:
              '/svgs/lab/s1/barcode-scanner/welcome-popup-assets/assets4.svg',
            assets5:
              '/svgs/lab/s1/barcode-scanner/welcome-popup-assets/assets5.svg',
          },
        },
        navigation: {
          separator: '/svgs/lab/s1/navigation-separator.svg',
          mobile: '/svgs/lab/s1/navigation-mobile.svg',
          desktop: '/svgs/lab/s1/navigation.svg',
        },
      },
    },
    baseSauce: {
      overlayCheese: '/svgs/base-sauce/overlay-cheese.svg',
      overlayBBQ: '/svgs/base-sauce/overlay-bbq.svg',
      overlayHot: '/svgs/base-sauce/overlay-hot.svg',
      overlayNice: '/svgs/base-sauce/overlay-nice.svg',
      overlaySecret: '/svgs/base-sauce/overlay-secret.svg',
      bubbles: '/svgs/base-sauce/bubbles.svg',
      frame: '/svgs/base-sauce/frame.svg',
      shim: '/svgs/base-sauce/shim.svg',
    },
    superSauce: {
      overlay: '/svgs/super-sauce/overlay.svg',
      bubbles: '/svgs/super-sauce/bubbles.svg',
      frame: '/svgs/super-sauce/frame.svg',
      shim: '/svgs/super-sauce/shim.svg',
    },
    icons: {
      download: '/svgs/download2.svg',
      usaFlag: '/svgs/usa.svg',
    },
    roadmap: {
      bgPipes: '/svgs/roadmap-pipes.svg',
    },
  },
  images: {
    landingPage: {
      circuitBackground: {
        mobile: '/images/landing/circuit-background-mobile.png',
        desktop: '/images/landing/circuit-background.png',
      },
      elevatorsBackground: {
        mobile: '/images/landing/elevators-background-mobile.png',
        desktop: '/images/landing/elevators-background.png',
      },
      lab: {
        mobile: '/images/landing/lab-mobile.png',
        desktop: '/images/landing/lab.png',
      },
      labZoom: {
        mobile: '/images/landing/lab-mobile.png',
        desktop: '/images/landing/lab-zoom.png',
      },
      hero: {
        lightsOn: '/images/landing/hero-lights-on.jpg',
        lightsOff: '/images/landing/hero-lights-off.jpg',
        mobile: '/images/landing/hero-mobile.jpg',
      },
      comet: '/images/landing/comet.png',
    },
    lab: {
      season1: {
        meaterializer: {
          background: {
            mobile: '/images/lab/s1/meaterializer/background-mobile.png',
            desktop: '/images/lab/s1/meaterializer/background.png',
          },
          stickDockGlowEffect:
            '/images/lab/s1/meaterializer/stick-dock-glow-effect.png',
          inventory: {
            stars: {
              variant1: '/images/lab/s1/meaterializer/inventory/stars1.png',
              variant2: '/images/lab/s1/meaterializer/inventory/stars2.png',
            },
          },
          evolutionIcon: '/images/lab/s1/meaterializer/evolution-icon.png',
          recipeIcon: '/images/lab/s1/meaterializer/recipe-icon.png',
          errorIcon: '/images/lab/s1/meaterializer/error-icon.png',
        },
        sauceMixer: {
          background: '/images/lab/s1/sauce-mixer/background.png',
          iconAlert: '/images/lab/s1/icons/icon-alert.svg',
        },
        inventory: {
          background: '/images/lab/s1/inventory/background.png',
          glass: '/images/lab/s1/inventory/glass.png',
          capsuleStand: '/images/lab/s1/inventory/capsule-stand.png',
          sauce: '/images/lab/s1/inventory/sauce.png',
          slotLeft: '/images/lab/s1/inventory/slot-left.png',
          slotRight: '/images/lab/s1/inventory/slot-right.png',
        },
      },
      scanner: {
        iconInfo: '/images/lab/s1/icons/icon-info.svg',
        lensFrame: {
          frameGrouped: '/images/lab/s1/lens-frame/frame-grouped-camera.svg',
          frameGroupedMobile:
            '/images/lab/s1/lens-frame/frame-grouped-camera-mobile.svg',
          laser: '/images/lab/s1/lens-frame/laser.png',
          frameGroupedInputMiddle:
            '/images/lab/s1/lens-frame/frame-grouped-input.svg',
          frameGroupedInputLeft:
            '/images/lab/s1/lens-frame/frame-grouped-left.svg',
          frameGroupedInputRight:
            '/images/lab/s1/lens-frame/frame-grouped-right.svg',
          frameGroupedInputMobile:
            '/images/lab/s1/lens-frame/frame-grouped-input-mobile.svg',
        },
      },
    },
    gallery: {
      backgroundMobileTop: '/images/gallery/stats-bg-mobile-top.png',
      backgroundMobileMid: '/images/gallery/stats-bg-mobile-mid.png',
      backgroundMobileBottom: '/images/gallery/stats-bg-mobile-bottom.png',
      statsBox: '/images/gallery/stats-box.png',
      statsBoxMobile: '/images/gallery/stats-box-mobile.png',
      sliderThumb: '/images/gallery/slider-thumb.png',
    },
    baseSauce: {
      overlayCheese: '/images/base-sauce/overlay-cheese.png',
      overlayBBQ: '/images/base-sauce/overlay-bbq.png',
      overlayHot: '/images/base-sauce/overlay-hot.png',
      overlayNice: '/images/base-sauce/overlay-nice.png',
      overlaySecret: '/images/base-sauce/overlay-secret.png',
      bubbles: '/images/base-sauce/bubbles.png',
      frame: '/images/base-sauce/frame.png',
      shim: '/images/base-sauce/shim.png',
    },
    animations: {
      alarmLeft: '/images/animations/alarm-left.png',
      alarmRight: '/images/animations/alarm-right.png',
      steam01: '/images/animations/steam01.png',
      steam02: '/images/animations/steam02.png',
      steam03: '/images/animations/steam03.png',
      steam04: '/images/animations/steam04.png',
      steam05: '/images/animations/steam05.png',
      steam06: '/images/animations/steam06.png',
      sparks03: '/images/animations/sparks03.png',
      sparks05: '/images/animations/sparks05.png',
      sparks06: '/images/animations/sparks06.png',
      smallButtonsLeft: '/images/animations/smallButtonsLeft.png',
      smallButtonsRight: '/images/animations/smallButtonsRight.png',
      lamps: '/images/animations/lamps.png',
      webp: {
        lcd: '/images/animations/webp/lcd.webp',
      },
      gif: {
        lcd: '/images/animations/gif/lcd.gif',
      },
    },
  },
  gifs: {
    capsule: {
      loader: '/gifs/capsule-loader.gif',
    },
  },
};

export default assets;

export const gridAnimation = isSafari()
  ? assets.images.animations.gif.lcd
  : assets.images.animations.webp.lcd;
