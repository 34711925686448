import { FC, useState } from 'react';

import Popup from 'components/Popup/Popup';
import { useCopyStore } from 'store';
import { NewCircuitVariant } from 'utils/assets';

import WelcomePopupAssets from './components/WelcomePopupAssets/WelcomePopupAssets';

import * as Styled from './WelcomePopup.styles';

export interface WelcomePopupProps {
  onContinue?: () => void;
}

const WelcomeSaucePopup: FC<WelcomePopupProps> = ({ onContinue }) => {
  const copy = useCopyStore(
    ({ copy }) => copy.lab.season1.barcodeScanner.welcomePopup
  );
  const slidesCount = copy.slides.length;
  const defaultIndex = 0;
  const [index, setIndex] = useState(defaultIndex);

  return (
    <Popup
      variant={NewCircuitVariant.LAB_POPUP}
      hasCloseButton={false}
      minHeight={[1400, 1600]}
      key="welcome"
      alignment="center"
    >
      <Styled.Wrapper>
        <Styled.Title>{copy.slides[index].title}</Styled.Title>
        <Styled.Subtitle>{copy.slides[index].subtitle}</Styled.Subtitle>
        <WelcomePopupAssets slideIndex={index} />
        <Styled.Label $size={'lg'} $marginTop={62}>
          {copy.slides[index].description}
        </Styled.Label>
        <Styled.Label $size={'sm'} $marginTop={20}>
          {copy.slides[index].info}
        </Styled.Label>
        <Styled.Dots $index={index}>
          {copy.slides.map((slide, i) => (
            <div key={i} />
          ))}
        </Styled.Dots>
        <Styled.Actions>
          {index > 0 && (
            <Styled.Cta
              label={index === slidesCount - 1 ? copy.ctaNo : copy.ctaBack}
              onClick={() => setIndex(index - 1)}
            />
          )}
          <Styled.Cta
            label={index === slidesCount - 1 ? copy.ctaYes : copy.ctaNext}
            onClick={() => {
              if (index === slidesCount - 1) {
                onContinue && onContinue();
                return;
              }
              setIndex(index + 1);
            }}
          />
        </Styled.Actions>
      </Styled.Wrapper>
    </Popup>
  );
};

export default WelcomeSaucePopup;
