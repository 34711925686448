import { FC } from 'react';

import ApngAnimation from 'components/ApngAnimation/ApngAnimation';
import { NewCircuitVariant } from 'utils/assets';

import {
  BackgroundResponsiveType,
  newCircuitVariants,
} from './NewCircuit.data';

import * as Styled from './NewCircuit.styles';

export interface NewCircuitProps {
  variant: NewCircuitVariant;
  halfWidth?: boolean;
  showAnimations?: boolean;
  smallerHeight?: boolean;
}

const NewCircuit: FC<NewCircuitProps> = ({
  children,
  halfWidth,
  variant,
  smallerHeight,
  showAnimations = true,
}) => {
  const { top, bottom, decorations, animation, animationExtra } =
    newCircuitVariants[variant];

  return (
    <Styled.Wrapper
      $variant={variant}
      $smallerHeight={smallerHeight}
      $halfWidth={halfWidth}
    >
      {decorations?.left && <Styled.LeftDecorations $variant={variant} />}
      {decorations?.right && <Styled.RightDecorations $variant={variant} />}
      {decorations?.top && <Styled.TopDecorations $variant={variant} />}
      {decorations?.bottom && <Styled.BottomDecorations $variant={variant} />}
      {top && <Styled.TopPart $variant={variant} />}
      <Styled.BodyPart $variant={variant}>
        <Styled.Content $variant={variant}>{children}</Styled.Content>
      </Styled.BodyPart>
      {bottom && <Styled.BottomPart $variant={variant} />}
      {showAnimations && animation && (
        <Styled.Animation $variant={variant} key={variant}>
          <ApngAnimation
            src={animation as BackgroundResponsiveType}
            infinite={animation.infinite}
          />
        </Styled.Animation>
      )}
      {showAnimations && animationExtra && (
        <Styled.AnimationExtra $variant={variant} key={`${variant}extra`}>
          <ApngAnimation
            src={animationExtra as BackgroundResponsiveType}
            infinite={animationExtra.infinite}
          />
        </Styled.AnimationExtra>
      )}
    </Styled.Wrapper>
  );
};

export default NewCircuit;
