import styled, { css, keyframes } from 'styled-components';

import assets from 'utils/assets';
import {
  backgroundImage,
  setTextStyles,
  setTypography,
} from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

export const ReaderWrapper = styled.div<{ $shown: boolean }>`
  grid-area: 1 / 1 / 2 / 2;
  place-self: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  ${mediaTablet(css`
    height: 100%;
  `)}

  & div {
    height: 100%;
    display: flex;
  }

  overflow: hidden;

  ${({ $shown }) => ($shown ? 'display: block;' : 'display: none;')};
`;

export const ReaderCamera = styled.div`
  position: absolute;
  display: block;

  > video {
    height: 100% !important;
    object-fit: cover;
    ${mediaTablet(css`
      transform: scaleX(-100%);
    `)}
  }

  > div {
    display: none !important;
  }
`;

export const Overlay = styled.div`
  grid-area: 1 / 1 / 2 / 2;
`;

export const Title = styled.h1`
  ${({ theme: { locale } }) => setTypography('heading2', locale)};
  ${setTextStyles('rumSwizzle', 'xl', 0.8)};
  text-align: center;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 80%;
  z-index: 3;

  ${mediaTablet(css`
    ${setTextStyles('rumSwizzle', 'xl', 0.8)};
    max-width: 50%;
  `)}
`;

export const ReaderTooltip = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 200rem;
  z-index: 5;
  width: 100%;

  ${mediaTablet(css`
    bottom: 10%;
  `)};
`;

export const Background = styled.div`
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);
  inset: 0;
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  z-index: 4;
  position: absolute;
  right: 2.4em;
  top: 8.5em;

  ${mediaTablet(css`
    transform: scale(1.41);
    right: 5.26em;
    top: 9.2em;
  `)};
`;

export const backgroundStyles = css`
  z-index: 3;
  position: absolute;
  left: 55%;
  top: 45%;
  height: 25em;
  width: 25em;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;

  ${mediaTablet(css`
    height: 35em;
    width: 45em;
  `)};

  &:before {
    z-index: 1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 7%;
    left: -98.5%;

    ${mediaTablet(css`
      top: 14%;
      left: -98.5%;
    `)};

    ${backgroundImage(
      assets.images.lab.scanner.lensFrame.frameGroupedInputLeft
    )};
  }

  &:after {
    z-index: 1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 7%;
    right: -85%;

    ${mediaTablet(css`
      top: 14%;
      right: -78%;
    `)};

    ${backgroundImage(
      assets.images.lab.scanner.lensFrame.frameGroupedInputRight
    )};
  }
`;

export const BlurOverlay = styled.div`
  position: absolute;
  background: #999;
  width: 100%;
  display: none;
  height: auto;
  opacity: 0.5;
`;

export const BlurOverlayHole = styled.div`
  position: fixed;
  top: 58%;
  left: 43.5%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 5000px #999;
  background: transparent;
  opacity: 0.5;
  height: 38%;
  width: 82%;
  border-radius: 25px;

  ${mediaTablet(css`
    top: 63%;
    left: 40%;
    height: 44%;
    width: 74%;
  `)};
`;

export const CameraContainer = styled.div`
  ${backgroundImage(
    assets.images.lab.scanner.lensFrame.frameGroupedMobile,
    [403, 844],
    assets.images.lab.scanner.lensFrame.frameGrouped,
    [630, 378]
  )};
  ${backgroundStyles}
`;

export const BarcodeInputContainer = styled.div`
  ${backgroundImage(
    assets.images.lab.scanner.lensFrame.frameGroupedInputMobile,
    [403, 844],
    assets.images.lab.scanner.lensFrame.frameGroupedInputMiddle,
    [630, 378]
  )};
  ${backgroundStyles}
`;

export const InputWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 7.5em;
  padding-right: 3.5em;

  ${mediaTablet(css`
    bottom: 28%;
    padding-right: 7em;
  `)};
`;

export const ToolTipAnchor = styled.div`
  position: absolute;
  left: 50%;
  bottom: 20%;
  transform: translateX(-50%);
  z-index: 2;

  ${mediaTablet(css`
    bottom: 26%;
  `)};
`;

export const AnimatedLaser = styled.div`
  position: absolute;
  overflow: hidden;
  height: 8.75em;
  width: 90%;
  left: 2em;
  bottom: 6.25em;

  ${mediaTablet(css`
    height: 15em;
    width: 33em;
    bottom: 5.5em;
    left: 1em;
  `)};
`;

const laserAnimation = keyframes`
  0% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(315%);
  }
`;

const laserAnimationMobile = keyframes`
  0% {
    transform: translateX(-30%);
  }
  100% {
    transform: translateX(150%);
  }
`;

export const Laser = styled.div<{ reverse?: boolean }>`
  ${backgroundImage(assets.images.lab.scanner.lensFrame.laser, [300, 100])};
  position: absolute;
  height: 150%;
  width: 8em;
  top: -25%;
  animation: ${laserAnimationMobile} 1.5s ease-in-out infinite;
  ${({ reverse }) =>
    css`
      animation-direction: ${reverse ? 'reverse' : 'normal'};
    `}

  ${({ reverse }) =>
    mediaTablet(css`
      height: 150%;
      width: 8em;
      top: -20%;
      animation: ${laserAnimation} 2s ease-in-out infinite;
      animation-direction: ${reverse ? 'reverse' : 'normal'};
    `)}
`;
