import { rgba } from 'polished';
import styled, { css, keyframes } from 'styled-components';

import { glowAnimation } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

import { BaseSauceProps } from './BaseSauce';

export const Wrapper = styled.div<{
  $size?: BaseSauceProps['size'];
  $hasGlowEffect?: boolean;
  $hasShadowEffect?: boolean;
  $active?: boolean;
}>`
  position: relative;
  aspect-ratio: 1 / 1;
  background: ${({ $active }) => `${$active ? colors.black : colors.nevada}`};
  width: 140rem;
  height: 140rem;
  pointer-events: none;
  user-select: none;

  ${({ $size }) =>
    $size === 'xxs' &&
    css`
      width: 80rem;
      height: 80rem;

      ${mediaTablet(css`
        width: 80rem;
        height: 80rem;
      `)};
    `};

  ${({ $size }) =>
    $size === 'sm' &&
    css`
      ${mediaTablet(css`
        width: 202rem;
        height: 202rem;
      `)};
    `};

  ${({ $size }) =>
    $size === 'xx' &&
    css`
      width: 100%;
      height: 100%;
    `};

  ${({ $size }) => {
    return $size === 'lg'
      ? mediaTablet(css`
          width: 292rem;
          height: 292rem;
        `)
      : $size === 'sm' &&
          css`
            width: 120rem;
            height: 120rem;
          `;
  }}
  border-radius: 50%;
  ${({ $hasGlowEffect }) => $hasGlowEffect && glowAnimation(true)};
  ${({ $hasShadowEffect }) =>
    $hasShadowEffect &&
    css`
      box-shadow: 0 6rem 0 ${rgba(colors.white, 0.3)};
    `};

  ${({ $size }) =>
    $size === 'responsive' &&
    css`
      width: 100%;
      height: 100%;

      ${mediaTablet(css`
        width: 100%;
        height: 100%;
      `)};
    `};
`;

const floatAnim = keyframes`
        0% {
          transform: translate(0%, 0%);
        }
        50% {
          transform: translate(0%, 5%);
        }
        100% {
          transform: translate(0%, 0%);
        }
`;

export const Img = styled.img<{
  $mixBlendMode?: string;
  $isAnimated?: boolean;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  mix-blend-mode: ${({ $mixBlendMode = 'normal' }) => `${$mixBlendMode}`};
  user-select: none;

  ${({ $isAnimated }) =>
    $isAnimated &&
    css`
      animation: ${floatAnim} 5s ease-in-out infinite;
      animation-delay: calc(var(--delay) * 1s);
    `};
`;
