import { FC } from 'react';

import { SCANNER_MAX_LENGTH } from 'components/Scanner/Scanner';

import * as Styled from './BarcodeInput.styles';

export interface BarcodeInputProps {
  barcode: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  label: string;
  hasError?: boolean;
}

const BarcodeInput: FC<BarcodeInputProps> = ({
  barcode,
  onChange,
  onBlur,
  placeholder,
  label,
  hasError,
}) => {
  return (
    <Styled.Input>
      <Styled.Label>{label}</Styled.Label>
      <Styled.InputField
        maxLength={SCANNER_MAX_LENGTH}
        value={barcode}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        $hasError={hasError}
      />
    </Styled.Input>
  );
};

export default BarcodeInput;
