import styled, { css } from 'styled-components';

import { setDefaultBackground } from 'utils/styles/mixins';
import {
  layoutMaxWidthDesktop,
  mediaDesktopWide,
} from 'utils/styles/responsive';

import {
  LayoutBackgroundVariant,
  LayoutContainerProps,
} from './LayoutContainer';

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Children = styled.div<{
  $background: LayoutContainerProps['background'];
}>`
  position: relative;
  z-index: 1;
  overflow: hidden;
  flex-basis: 100vw;

  ${({ $background }) =>
    $background === LayoutBackgroundVariant.NONE &&
    css`
      background-color: transparent;
    `};

  ${({ $background }) =>
    $background === LayoutBackgroundVariant.DEFAULT && setDefaultBackground()};

  ${({ $background }) =>
    $background === LayoutBackgroundVariant.S1_GRADIENT &&
    css`
      background: linear-gradient(
        90deg,
        ${({ theme }) => theme.colors.stratos} 0%,
        ${({ theme }) => theme.colors.downriver} 50%,
        ${({ theme }) => theme.colors.stratos} 100%
      );
    `};

  ${mediaDesktopWide(css`
    flex: 1 0 ${layoutMaxWidthDesktop}rem;
  `)};
`;

export const SideBackgroundContainer = styled.div`
  display: none;
  position: relative;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.black};

  ${mediaDesktopWide(css`
    display: block;
    width: 100%;
  `)};
`;

export const SideBackgroundWrapper = styled.div<{
  $position: 'left' | 'right';
}>`
  position: absolute;
  top: 0;
  right: 10rem;
  height: 100%;
  width: 100%;

  ${({ $position }) =>
    $position === 'right' &&
    css`
      right: unset;
      left: 10rem;
      transform: scaleX(-1);
    `};
`;
