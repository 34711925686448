import create, { UseBoundStore } from 'zustand';

import { ButtonProps } from 'components/Button/Button';
import { ErrorPopupVariant } from 'components/Error/ErrorPopup/ErrorPopup';
import { LaboratoryVariant } from 'components/LaboratoryS1/LabEnum';
import { NavbarProps } from 'components/Navbar/Navbar';

export enum Season {
  ZERO,
  ONE,
}

export const NFT_HOTSPOTS_ENABLED = 'NFT_HOTSPOTS_ENABLED';
export const SCROLL_OVERLAY_SETTINGS = 'SCROLL_OVERLAY_SETTINGS';

type ErrorPopup = {
  variant: ErrorPopupVariant;
  title: string;
  body: string;
  cta: Array<ButtonProps>;
};

export interface GlobalStoreType {
  isWindowTooSmall: boolean;
  setWindowTooSmall: (state: boolean) => void;
  isUnexpectedError: boolean;
  setUnexpectedError: (state: boolean) => void;
  isDesktopView: boolean;
  setIsDesktopView: (state: boolean) => void;
  isNavbarCentered: boolean;
  setIsNavbarCentered: (state: boolean) => void;
  season: Season;
  setSeason: (season: Season) => void;
  isNftLimitReached: boolean;
  setIsNftLimitReached: (state: boolean) => void;
  navbarGlowingElement: NavbarProps['glowingElement'];
  setNavbarGlowingElement: (state: NavbarProps['glowingElement']) => void;
  isUsaOnly: boolean;
  setIsUsaOnly: (state: boolean) => void;
  isNftHotspotsEnabled: boolean;
  setIsNftHotspotsEnabled: (state: boolean) => void;
  scrollOverlaySettings: Record<LaboratoryVariant, boolean>;
  setScrollOverlaySettings: (
    state: Partial<Record<LaboratoryVariant, boolean>>
  ) => void;
  preferencesCopy: string;
  setPreferencesCopy: (state: string) => void;
  errorPopup: ErrorPopup;
  showErrorPopup: (errorPopup: ErrorPopup) => void;
  onSkipCallback: () => void;
  setOnSkipCallback: (callback: () => void) => void;
}

const useGlobalStore: UseBoundStore<GlobalStoreType> = create(
  (
    set: (partial: (store: GlobalStoreType) => Partial<GlobalStoreType>) => void
  ) => {
    return {
      isWindowTooSmall: false,
      setWindowTooSmall: state => {
        set(() => ({
          isWindowTooSmall: state,
        }));
      },
      isUnexpectedError: false,
      setUnexpectedError: state => {
        set(() => ({
          isUnexpectedError: state,
        }));
      },
      isDesktopView: false,
      setIsDesktopView: state => {
        set(() => ({
          isDesktopView: state,
        }));
      },
      isNavbarCentered: false,
      setIsNavbarCentered: state => {
        set(() => ({
          isNavbarCentered: state,
        }));
      },
      season: Season.ONE,
      setSeason: state => {
        set(() => ({
          season: state,
        }));
      },
      isNftLimitReached: false,
      setIsNftLimitReached: state => {
        set(() => ({
          isNftLimitReached: state,
        }));
      },
      navbarGlowingElement: null,
      setNavbarGlowingElement: state => {
        set(() => ({
          navbarGlowingElement: state,
        }));
      },
      isUsaOnly: !process.env.IS_DEBUG as unknown as boolean,
      setIsUsaOnly: state => {
        set(() => ({
          isUsaOnly: state,
        }));
      },
      isNftHotspotsEnabled: false,
      setIsNftHotspotsEnabled: state => {
        set(() => {
          localStorage?.setItem(NFT_HOTSPOTS_ENABLED, state ? 'true' : 'false');

          return {
            isNftHotspotsEnabled: state,
          };
        });
      },
      scrollOverlaySettings: {
        [LaboratoryVariant.MEATERIALIZER]: false,
        [LaboratoryVariant.SAUCE_MIXER]: false,
        [LaboratoryVariant.INVENTORY]: false,
      },
      setScrollOverlaySettings: state => {
        set(draft => {
          const output = { ...draft.scrollOverlaySettings, ...state };
          localStorage.setItem(SCROLL_OVERLAY_SETTINGS, JSON.stringify(output));

          return {
            scrollOverlaySettings: output,
          };
        });
      },
      preferencesCopy: '',
      setPreferencesCopy: state => {
        set(() => ({
          preferencesCopy: state,
        }));
      },
      errorPopup: null,
      showErrorPopup: state => {
        set(() => ({
          errorPopup: state,
        }));
      },
      onSkipCallback: null,
      setOnSkipCallback: callback => {
        set(() => ({
          onSkipCallback: callback,
        }));
      },
    };
  }
);

export default useGlobalStore;
