export const defaultFontSize = 24; // px

export const breakpointTablet = 700; // px
export const breakpointDesktop = 700; // px
export const breakpointDesktopLarge = 1441; // px

export const desktopMinHeight = 400; // px
export const breakpointMobileSmallHeight = 400; // px

export const mobileBaseWidth = 870; // px
export const desktopBaseWidth = 1220; // px

export const mobileMinFontSize = 11; // px
export const desktopMinFontSize = 12; // px

export const mobileScalableFontSize = 110 / mobileBaseWidth; // vw
export const mobileSmallHeightScalableFontSize = mobileScalableFontSize; // vw
export const tabletScalableFontSize = 0.033; // vw
export const desktopScalableFontSize = 40 / desktopBaseWidth; // vw
export const desktopWideScalableFontSize = 0.033; // vw

export const desktopWideAspectRatio = '20 / 11';
