import styled, { css } from 'styled-components';

import svgBody from 'svgs/footer/body.svg';
import svgBodyMobile from 'svgs/footer/body-mobile.svg';
import {
  backgroundImage,
  setTextStyles,
  setTypography,
} from 'utils/styles/mixins';
import { layoutMaxWidthDesktop, mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.footer`
  position: relative;
  width: 100vw;
  height: ${({ theme }) => theme.layout.footer.height.mobile}rem;
  overflow: hidden;
  z-index: ${({ theme }) => theme.layout.zIndex.footer};

  ${mediaTablet(css`
    display: flex;
    height: ${({ theme }) => theme.layout.footer.height.desktop}rem;
    margin-top: ${({ theme }) => -theme.layout.footer.height.desktop}rem;
    justify-content: center;
  `)};
`;

export const Content = styled.div<{ $isFooterCentered: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 24rem;

  ${mediaTablet(css`
    flex-direction: row;
    padding: 0 32rem;
    justify-content: space-between;
  `)};

  ${({ $isFooterCentered }) =>
    $isFooterCentered &&
    css`
      width: min(100vw, ${layoutMaxWidthDesktop}rem);

      ${mediaTablet(css`
        padding: 0;
      `)};
    `};
`;

export const SocialLinksList = styled.ul`
  display: flex;
  padding-top: 10rem;
  transform: scale(0.77);
  margin-right: -12rem;

  li:not(:last-child) {
    margin-right: 20rem;
  }

  ${mediaTablet(css`
    li:not(:last-child) {
      margin-right: 20rem;
    }
    transform: scale(0.82);
    padding-top: 14rem;
    margin-left: -6rem;
    margin-right: -6rem;
  `)};
`;

export const TermsLinksList = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${mediaTablet(css`
    padding: 6rem 0 0;
  `)};
`;

export const Background = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  ${backgroundImage(svgBodyMobile)};

  ${mediaTablet(css`
    height: auto;
    background: unset;

    &::before {
      display: block;
      content: '';
      ${backgroundImage(svgBody)};
      height: 100%;
      width: 100%;
    }
    &::after {
      display: block;
      content: '';
      ${backgroundImage(svgBody)};
      height: 100%;
      width: 100%;
    }
  `)}
`;

export const Text = styled.span`
  display: block;
  ${({ theme: { locale } }) => setTypography('caption2', locale)};
  ${setTextStyles('rumSwizzle', 'sm')};
  padding: 14rem 20rem 12rem;
`;

export const SocialLinksWrapper = styled.div`
  display: flex;
  align-items: center;
`;
