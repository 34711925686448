import { FC, useRef } from 'react';

import { validIcons } from 'components/Button/Button';
import NewCircuit from 'components/NewCircuit/NewCircuit';
import Portal from 'components/Portal/Portal';
import useDisableScroll from 'hooks/useDisableScroll';
import { NewCircuitVariant } from 'utils/assets';

import { getPopupAnimation, usePopupBehavior } from './Popup.utils';

import * as Styled from './Popup.styles';

export const validAlignments = [
  'center',
  'top-right',
  'right',
  'left',
] as const;

export const validAnimationDirections = [
  'left',
  'right',
  'top',
  'bottom',
] as const;

export interface PopupProps {
  variant: NewCircuitVariant;
  hasCloseButton?: boolean;
  closeButtonIcon?: typeof validIcons[number];
  zIndex?: number;
  onClose?: () => void;
  name?: string;
  minHeight?: [number, number]; // mobile, desktop - unit rems
  alignment?: typeof validAlignments[number];
  hasTransparentBackground?: boolean;
  halfWidth?: boolean;
  portal?: boolean;
  animationDirection?: typeof validAnimationDirections[number];
  onAnimationFinished?: (state: boolean) => void;
}

const Popup: FC<PopupProps> = ({
  variant,
  children,
  hasCloseButton = true,
  closeButtonIcon = 'close2',
  zIndex = 5,
  onClose,
  name = 'popup',
  minHeight = [1600, 1500],
  alignment = 'center',
  hasTransparentBackground = false,
  halfWidth = true,
  portal = true,
  animationDirection,
  onAnimationFinished,
}) => {
  const ref = useRef(null);

  const WrapperComponent = portal ? Portal : Styled.WrapperComponent;

  usePopupBehavior(ref, onClose);
  useDisableScroll(true, name);

  return (
    <WrapperComponent>
      <Styled.Container zIndex={zIndex} halfWidth={halfWidth}>
        <Styled.Scroll $hasTransparentBackground={hasTransparentBackground}>
          <Styled.Wrapper
            ref={ref}
            $minHeight={portal ? minHeight : null}
            $hasCloseButton={hasCloseButton}
            $alignment={alignment}
            $hasTransparentBackground={hasTransparentBackground}
          >
            <Styled.Spacer $top />
            <Styled.ContentWrapper
              {...(animationDirection && getPopupAnimation(animationDirection))}
              onAnimationStart={
                onAnimationFinished
                  ? () => {
                      onAnimationFinished(false);
                    }
                  : undefined
              }
              onAnimationComplete={
                onAnimationFinished
                  ? () => {
                      onAnimationFinished(true);
                    }
                  : undefined
              }
            >
              <NewCircuit variant={variant} halfWidth={halfWidth}>
                {children}
              </NewCircuit>
              {hasCloseButton && (
                <Styled.CloseButtonWrapper $variant={variant}>
                  <Styled.CloseButton
                    icon={closeButtonIcon}
                    onClick={onClose}
                  />
                </Styled.CloseButtonWrapper>
              )}
            </Styled.ContentWrapper>
            <Styled.Spacer />
          </Styled.Wrapper>
        </Styled.Scroll>
      </Styled.Container>
    </WrapperComponent>
  );
};

export default Popup;
