export enum LabRoomStage {
  DISABLED = 'disabled',
  INITIAL = 'initial',
  TUTORIAL = 'tutorial',
  DEFAULT = 'default',
  FINISHED = 'finished',
}

export enum Tutorial {
  Meatalizer = 'meatalizer',
  Mixer = 'mixer',
  Inventory = 'inventory',
  Scanner = 'scanner',
}

export enum ZoomedArea {
  NONE,
  CODE_CAPSULE,
  LCD,
}

export enum LaboratoryVariant {
  MEATERIALIZER = 'MEATERIALIZER',
  INVENTORY = 'INVENTORY',
  SAUCE_MIXER = 'SAUCE_MIXER',
}

export enum MeaterializerTutorialStage {
  DISABLED = 'disabled',
  INITIAL = 'initial',
  CODE_CAPSULE = 'code_capsule',
  NEW_ITEMS = 'new_items',
  INVENTORY_SLIM_JIM_TOOLTIP = 'inventory_slim_jim_tooltip',
  DRAG_SLIM_JIM = 'drag_slim_jim',
  INVENTORY_SAUCE_TOOLTIP = 'inventory_sauce_tooltip',
  DRAG_SAUCE = 'drag_sauce',
  CLICK_TO_MIX = 'click_to_mix',
  NEW_GIGA_JIM = 'new_giga_jim',
  FUSING = 'fusing',
  FINISHED = 'finished',
}

export enum InventoryTutorialStage {
  INITIAL = 'initial',
  CAPSULE = 'capsule',
  CAPSULE_IN = 'capsule_in',
  SAUCE = 'sauce',
  SAUCE_IN = 'sauce_in',
  MIXER = 'mixer',
  DISABLED = 'disabled',
  FINISHED = 'finished',
}

export enum SauceMixerStage {
  DISABLED,
  NO_BASE_SAUCE_POPUP,
  INITIAL,
  MIXED_SAUCE_POPUP,
  MIXING_PREPARE,
  MIXING_SELECT,
  MIXING_SELECT_TUTORIAL,
  MIXING_MOVE_TO_READY,
  MIXING_PRESS_READY,
  MIXING_START,
  MIXING_END,
  RECIPE_TOOLTIP,
}
