import { FC } from 'react';

import Layout from 'components/Layout/Layout';
import Navbar from 'components/Navbar/Navbar';

import * as Styled from './ErrorContainer.styles';

export interface ErrorContainerProps {}

const ErrorContainer: FC<ErrorContainerProps> = ({ children }) => {
  return (
    <Styled.OverflowWrapper>
      <Styled.Container>
        <Navbar onlyBar />
        <Layout.Container>
          <Styled.Wrapper>
            <Styled.TopPart />
            <Styled.BodyPart />
            <Styled.BottomPart />
            <Styled.DecorationLineLeft />
            <Styled.DecorationLineRight />
            <Styled.Content>{children}</Styled.Content>
          </Styled.Wrapper>
        </Layout.Container>
      </Styled.Container>
    </Styled.OverflowWrapper>
  );
};

export default ErrorContainer;
