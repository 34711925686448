import { kebabCase } from 'lodash';

import { Pages } from 'utils/routes';

import { CopyStoreType } from './copy.types';
import aboutPageEN from './translations/en/aboutPage.json';
import galleryEN from './translations/en/galleryPage.json';
import globalEN from './translations/en/global.json';
import headEN from './translations/en/head.json';
import labEN from './translations/en/labPage.json';
import landingPageEN from './translations/en/landingPage.json';
import loginEN from './translations/en/login.json';
import myAccountEN from './translations/en/myAccount.json';
import roadmapEN from './translations/en/roadmap.json';
import termsAndConditionsEN from './translations/en/termsAndConditions.json';

const TRANSLATIONS_PATH = './translations';

const tryRequire = (path: string) => {
  try {
    return require(`${path}`);
  } catch (error) {
    if (process.env.IS_DEBUG)
      console.log('copy.data -- tryRequire -- error:', error);

    return null;
  }
};

export const getCopy = (page: Pages, locale = process.env.DEFAULT_LOCALE) => {
  const path = `${TRANSLATIONS_PATH}/${kebabCase(locale)}`;
  const head: Record<typeof page, CopyStoreType['copy']['head']> =
    tryRequire(`${path}/head.json`) ||
    (headEN as Record<
      typeof page,
      Pick<CopyStoreType['copy']['head'], 'title' | 'description' | 'ogImage'>
    >);

  return {
    head: {
      ...head[page],
      ogType: 'website',
    },
    global:
      tryRequire(`${path}/global.json`) ||
      (globalEN as CopyStoreType['copy']['global']),
    index:
      tryRequire(`${path}/landingPage.json`) ||
      (landingPageEN as CopyStoreType['copy']['index']),
    about:
      tryRequire(`${path}/aboutPage.json`) ||
      (aboutPageEN as CopyStoreType['copy']['about']),
    terms:
      tryRequire(`${path}/termsAndConditions.json`) ||
      (termsAndConditionsEN as CopyStoreType['copy']['terms']),
    roadmap:
      tryRequire(`${path}/roadmap.json`) ||
      (roadmapEN as CopyStoreType['copy']['roadmap']),
    gallery:
      tryRequire(`${path}/galleryPage.json`) ||
      (galleryEN as CopyStoreType['copy']['gallery']),
    myAccount:
      tryRequire(`${path}/myAccount.json`) ||
      (myAccountEN as CopyStoreType['copy']['myAccount']),
    login:
      tryRequire(`${path}/login.json`) ||
      (loginEN as CopyStoreType['copy']['login']),
    lab:
      tryRequire(`${path}/labPage.json`) ||
      (labEN as CopyStoreType['copy']['lab']),
  } as CopyStoreType['copy'];
};
