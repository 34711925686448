/* eslint-disable prettier/prettier */
import { keyframes } from 'styled-components';

export const spin = () => keyframes`
  0% { transform: rotate(0); }
  100% { transform: rotate(1turn); }
`;

export const pulse = (transform?: string) => keyframes`
  0% { opacity: 0; transform: ${transform ? `${transform} ` : ''}scale(0.5); }
  40%, 45% { opacity: 1; transform: ${
    transform ? `${transform} ` : ''
  }scale(1); }
  60%, 100% { opacity: 0; transform: ${
    transform ? `${transform} ` : ''
  }scale(0.95); }
`;

export const fadeMotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2 },
};

export const pageMotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2 },
};

export const getBannerMotionProps = (from: 'top' | 'bottom') => ({
  initial: { opacity: 0, y: `${(from === 'top' ? -1 : 1) * 50}%` },
  animate: { opacity: 1, y: '0%' },
  exit: { opacity: 0, y: `${(from === 'top' ? -1 : 1) * 50}%` },
  transition: { duration: 0.6 },
});

export const glowJ = keyframes`
  0% {
    filter: null;
    box-shadow: none;
  }
  25% {
    box-shadow: 0 0 8rem #ffffff, 0 0 16rem #fff2a8, 0 0 16rem #f8e600, 0 0 40rem #ef8533, 0 0 60rem #ef0000;
  }
  50% {
    box-shadow: none;
  }
  75% {
    box-shadow: 0 0 8rem #ffffff, 0 0 16rem #fff2a8, 0 0 16rem #f8e600, 0 0 40rem #ef8533, 0 0 60rem #ef0000;
  }
  100% {
    box-shadow: none;
  }
`;

export const glow = keyframes`
  0% {
    filter: null;
  }
  25% {
    filter: drop-shadow(0 0 2rem #ffffff) drop-shadow(0 0 4rem #fff2a8)
    drop-shadow(0 0 4rem #f8e600)
    drop-shadow(0 0 10rem #ef8533) drop-shadow(0 0 20rem #ef0000);
  }
  50% {
    filter: null;
  }
  75% {
    filter: drop-shadow(0 0 2rem #ffffff) drop-shadow(0 0 4rem #fff2a8)
    drop-shadow(0 0 4rem #f8e600)
    drop-shadow(0 0 10rem #ef8533) drop-shadow(0 0 20rem #ef0000);
  }
  100% {
    filter: null;
  }
`;


export const blinkingLight = keyframes`
  0% {
    fill: #000;
  }
  10% {
    fill: #ef0000;
  }
  45% {
    fill: #ef0000;
  }
  48% {
    fill: #000;
  }
  50% {
    fill: #ef0000;
  }
  60% {
    fill: #ef0000;
  }
  75% {
    fill: #000;
  }
  78% {
    fill: #ef0000;
  }
  90% {
    fill: #ef0000;
  }
  100% {
    fill: #000;
  }
`


export const opacityAnimation = (delay?: number, exitDelay?: number) => ({
  initial: {
    opacity: 0,
  },
  animate: { opacity: 1, transition: { delay } },
  exit: { opacity: 0, transition: { delay: delay || exitDelay } },
  transition: {
    duration: 0.2,
  },
});
