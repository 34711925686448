import { useEffect, useMemo } from 'react';

let locks: string[] = [];

const useDisableScroll = (shouldLock: boolean, lockName?: string) => {
  const name = useMemo(() => lockName || 'popup', [lockName]);

  useEffect(() => {
    if (shouldLock && !locks.includes(name)) {
      locks.push(name);

      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.width = '100%';
      document.body.style.position = 'fixed';
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (shouldLock) {
        locks = locks.filter(n => n !== name);

        if (locks.length === 0) {
          const scrollY = document.body.style.top;
          document.body.style.width = '';
          document.body.style.position = '';
          document.body.style.top = '';
          document.body.style.overflow = 'unset';
          window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
        }
      }
    };
  }, [name, shouldLock]);
};

export default useDisableScroll;
