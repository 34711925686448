export const getMagicLinkPrivateKeysWebsite = () =>
  process.env.ENV !== 'production'
    ? 'https://reveal.magic.link/meataverse_dev'
    : 'https://reveal.magic.link/meataverse';

export const getReCaptchaID = () => '6LdSTRknAAAAANk1UlK2LYYsswBAV0g0pecc30HJ';

export const path = () => {
  if (process.env.ENV === 'production')
    return 'https://be-meataverse.slimjim.com/';
  else if (process.env.ENV === 'test')
    return 'https://u9ltd-project-3229-slimji-3afe-be-test-af5mxtlhrq-uc.a.run.app/';
  else if (process.env.ENV === 'staging')
    return 'https://u9ltd-project-3229-slimji-3afe-be-staging-af5mxtlhrq-uc.a.run.app/';
  else
    return 'https://u9ltd-project-3229-slimji-3afe-be-development-af5mxtlhrq-uc.a.run.app/';
};

export const getShareStructurePath = () => {
  if (process.env.ENV === 'production')
    return 'https://cdn-meataverse.slimjim.com/user_shares/';
  else if (process.env.ENV === 'test')
    return 'https://storage.googleapis.com/u9ltd-project-3229-slimji-3afe-test-be-uploads/user_shares/';
  else if (process.env.ENV === 'staging')
    return 'https://storage.googleapis.com/u9ltd-project-3229-slimji-3afe-staging-be-uploads/user_shares/';
  else
    return 'https://storage.googleapis.com/u9ltd-project-3229-slimji-3afe-development-be-uploads/user_shares/';
};

export const assetsPath = () => {
  return 'https://cdn-meataverse.slimjim.com/pfp/parts/mid/';

  // if (process.env.ENV === 'production')
  //   return 'https://cdn-meataverse.slimjim.com/pfp/parts/mid/';
  // else
  //   return 'https://storage.googleapis.com/u9ltd-project-3229-slimji-3afe-development-be-uploads/pfp/parts/mid/';
};
