import create, { UseBoundStore } from 'zustand';

import { Gallery_Order, Gallery_Theme, Rarity } from 'constants/enum';

export interface UserStoreType {
  galleryData: Array<any>;
  setGalleryData: (state: any) => void;
  galleryTheme: Gallery_Theme;
  setGalleryTheme: (state: Gallery_Theme) => void;
  orderBy: Gallery_Order;
  setGalleryOrderBy: (state: Gallery_Order) => void;
  orderDesc: boolean;
  setOrderDesc: (state: boolean) => void;
  colorRarity: Rarity;
  setcolorRarity: (state: Rarity) => void;
  rarity: Rarity;
  setRarity: (state: Rarity) => void;
}

const userGalleryStore: UseBoundStore<UserStoreType> = create(
  (
    set: (partial: (store: UserStoreType) => Partial<UserStoreType>) => void
  ) => {
    return {
      galleryData: [],
      setGalleryData: state => {
        set(() => ({
          galleryData: state,
        }));
      },
      galleryTheme: Gallery_Theme.DESKTOP_3X3,
      setGalleryTheme: state => {
        set(() => ({
          galleryTheme: state,
        }));
      },
      orderBy: Gallery_Order.None,
      setGalleryOrderBy: state => {
        set(() => ({
          orderBy: state,
        }));
      },
      orderDesc: false,
      setOrderDesc: state => {
        set(() => ({
          orderDesc: state,
        }));
      },
      colorRarity: Rarity.None,
      setcolorRarity: state => {
        set(() => ({
          colorRarity: state,
        }));
      },
      rarity: Rarity.None,
      setRarity: state => {
        set(() => ({
          rarity: state,
        }));
      },
    };
  }
);

export default userGalleryStore;
