import React, { useMemo, useState } from 'react';

import Tooltip from 'components/Tooltip/Tooltip';
import { NavbarLink as NavbarLinkType } from 'store/copy.types';
import { useSvgIcon } from 'u9/hooks';
import GTM from 'u9/utils/gtm';
import assets from 'utils/assets';

import { hrefs } from '../Navbar';

import * as Styled from './NavbarLink.styles';

export const validIcons = [
  'about',
  'account',
  'discord',
  'meataverse',
  'roadmap',
  '_menu',
  '_close',
  'logout',
] as const;

export interface NavbarLinkProps {
  id?: string;
  label?: string;
  icon: typeof validIcons[number];
  iconAsSvg?: boolean;
  href?: string;
  nestedLinks?: NavbarLinkType['children'];
  onClick?: () => void;
  hasGlowEffect?: boolean;
  tooltip?: string;
  iconOnRightSide?: boolean;
}

const NavbarLink: React.FC<NavbarLinkProps> = ({
  id,
  icon,
  label,
  href,
  iconAsSvg,
  nestedLinks,
  onClick,
  hasGlowEffect,
  tooltip,
  iconOnRightSide = false,
}) => {
  const [dropdownOpened, setDropdownOpened] = useState<string | null>(null);

  const Icon = useSvgIcon(iconAsSvg ? icon : undefined);

  const content = useMemo(
    () => (
      <Styled.ContentWrapper $hasGlowEffect={hasGlowEffect}>
        <Styled.IconWrapper $iconOnRightSide={iconOnRightSide}>
          {iconAsSvg ? (
            <Icon.SvgIcon />
          ) : (
            <Styled.Icon
              src={assets.svgs.navbar.icons[icon]}
              alt={`navbar icon ${icon}`}
            />
          )}
        </Styled.IconWrapper>
        {label && <Styled.Label>{label}</Styled.Label>}
        {nestedLinks && <Styled.Chevron />}
        {nestedLinks && (
          <Styled.Dropdown>
            {nestedLinks.map(child => {
              const { key, label } = child;
              const href = hrefs[key];

              return (
                <Styled.DropdownItem
                  key={label}
                  href={href}
                  onClick={() => {
                    GTM.trackEvent('Header ', 'Click', label);
                  }}
                >
                  {label}
                </Styled.DropdownItem>
              );
            })}
          </Styled.Dropdown>
        )}
      </Styled.ContentWrapper>
    ),
    [Icon, hasGlowEffect, icon, iconAsSvg, iconOnRightSide, label, nestedLinks]
  );

  const contentWithTooltip = useMemo(() => {
    return (
      <Tooltip
        text={tooltip}
        open={hasGlowEffect && !!tooltip}
        key={label}
        arrowPosition={icon === '_menu' ? 'very-end' : 'center'}
        position="bottom"
        color="turbo"
        smallWidth
        offset={{ top: 70 }}
        fontVariant="lg"
        anchor={content}
        zIndex={21}
      />
    );
  }, [content, hasGlowEffect, icon, label, tooltip]);

  return (
    <Styled.Item
      onClick={() => {
        if (nestedLinks) setDropdownOpened(prev => (prev === id ? null : id));
      }}
      onMouseOver={() => {
        if (nestedLinks) setDropdownOpened(id);
      }}
      onMouseLeave={() => {
        if (nestedLinks) setDropdownOpened(null);
      }}
    >
      {href && !nestedLinks ? (
        <Styled.WrapperLink
          href={href}
          onClick={onClick}
          $active={dropdownOpened === id}
        >
          {tooltip ? contentWithTooltip : content}
        </Styled.WrapperLink>
      ) : (
        <Styled.WrapperButton onClick={onClick}>
          {tooltip ? contentWithTooltip : content}
        </Styled.WrapperButton>
      )}
    </Styled.Item>
  );
};

export default NavbarLink;
