import { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  className?: string;
  children: React.ReactNode;
}

const Portal: FC<PortalProps> = ({ children, className = 'portal' }) => {
  const [isBrowser, setIsBrowser] = useState(false);
  const [container, setContainer] = useState(null);

  useEffect(() => {
    setIsBrowser(true);
    setContainer(document?.createElement('div'));
  }, []);

  useEffect(() => {
    if (container) {
      container.classList.add(className);
      document.body.appendChild(container);

      return () => {
        document.body.removeChild(container);
      };
    }
  }, [className, container]);

  return isBrowser ? ReactDOM.createPortal(children, container) : null;
};

export default Portal;
