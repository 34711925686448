import styled, { css, keyframes } from 'styled-components';

import { mediaTablet } from 'utils/styles/responsive';

const flickerAnimation = keyframes`
    0% {
      fill: #39609C;
    }
    25% {
      fill: #39609C;
    }
    30% {
      fill: #FF0000;
    }
    35% {
      fill: #39609C;
    }
    40% {
      fill: #FF0000;
    }
    45% {
      fill: #39609C;
    }
    50% {
      fill: #FF0000;
    }
    55% {
      fill: #39609C;
    }
    70% {
      fill: #39609C;
    }
    75% {
      fill: #FF0000;
    }
    80% {
      fill: #39609C;
    }
    85% {
      fill: #FF0000;
    }
    90% {
      fill: #39609C;
    }
    100% {
      fill: #39609C;
    }
`;

export const Svg = styled.svg`
  display: none;

  ${mediaTablet(css`
    height: 100%;
    display: block;
    flex-shrink: 0;
    margin: 0 -4rem;

    .dot {
      fill: #39609c;
      stroke: #000100;
      animation: ${flickerAnimation} 2s infinite;
    }
  `)}
`;
