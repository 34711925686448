import styled, { css } from 'styled-components';

import Button from 'components/Button/Button';
import assets from 'utils/assets';
import {
  backgroundImage,
  setTextStyles,
  setTypography,
} from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const Alert = styled.div`
  ${backgroundImage(assets.images.lab.season1.sauceMixer.iconAlert, [64, 64])};
  width: 89.88rem;
`;

export const Title = styled.h1`
  ${({ theme: { locale } }) => setTypography('heading1', locale)};
  ${setTextStyles('turbo', 'xl', 0.8)};
  text-align: center;
  white-space: pre-wrap;
  max-width: 75%;
  margin-bottom: 10rem;
`;

export const Subtitle = styled.h1`
  ${({ theme: { locale } }) => setTypography('heading2', locale)};
  ${setTextStyles('turbo', 'sm', 0.8)};
  text-align: center;
  white-space: pre-wrap;
  max-width: 75%;
  margin-bottom: 73.24rem;
`;

export const Cta = styled(Button)`
  width: 100%;
  ${mediaTablet(css`
    width: auto;
  `)}
`;

export const Actions = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  gap: 30rem;
  margin-top: 60rem;

  ${mediaTablet(css`
    gap: 60rem;
  `)}
`;

export const validSizes = ['sm', 'lg', 'xl'] as const;

export const Label = styled.label<{
  $size: typeof validSizes[number];
  $marginBottom?: number;
  $marginTop?: number;
}>`
  ${({ theme: { locale } }) => setTypography('body1', locale)};
  ${setTextStyles('rumSwizzle', 'sm', 0.8)};
  text-align: center;
  ${({ $size }) =>
    ($size === 'lg' || $size === 'xl') &&
    css`
      ${({ theme: { locale } }) => setTypography('heading5', locale)};
    `};
  ${({ $size }) =>
    $size === 'xl' &&
    css`
      font-style: italic;
    `};
  ${({ $marginBottom }) =>
    $marginBottom &&
    css`
      margin-bottom: ${$marginBottom}rem;
    `};
  ${({ $marginTop }) =>
    $marginTop &&
    css`
      margin-top: ${$marginTop}rem;
    `};
  max-width: 65%;
`;

export const Dots = styled.div<{
  $index: number;
}>`
  display: flex;
  gap: 8rem;
  margin-top: 24rem;
  margin-bottom: 36rem;
  > div {
    width: 16rem;
    height: 30rem;
    background-color: ${({ theme }) => theme.colors.rumSwizzle};
    border-radius: 6rem;
    border: 2px solid ${({ theme }) => theme.colors.black};
  }
  > div:nth-child(${({ $index }) => $index + 1}) {
    background-color: ${({ theme }) => theme.colors.turbo};
  }
`;
