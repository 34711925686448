import { forwardRef } from 'react';

import Button, {
  ButtonVariant,
  GenericButtonProps,
} from 'components/Button/Button';

import * as Styled from './TooltipButton.styles';

export type TooltipButtonProps = GenericButtonProps;

const TooltipButton = forwardRef<HTMLDivElement, TooltipButtonProps>(
  (props, ref) => {
    const { variant = ButtonVariant.TOOLTIP, ...rest } = props;

    return (
      <Styled.Wrapper ref={ref}>
        <Button variant={variant} renderAsDiv {...rest} />
      </Styled.Wrapper>
    );
  }
);

TooltipButton.displayName = 'TooltipButton';

export default TooltipButton;
