import styled, { css } from 'styled-components';

import { NewCircuitVariant } from 'utils/assets';
import {
  aspectRatio,
  backgroundDecoration,
  backgroundImage,
  mask,
} from 'utils/styles/mixins';
import { layoutMaxWidthDesktop, mediaTablet } from 'utils/styles/responsive';

import { NewCircuitProps } from './NewCircuit';
import { newCircuitVariants } from './NewCircuit.data';

type VariantType = {
  $variant: NewCircuitProps['variant'];
  $halfWidth?: boolean;
  $smallerHeight?: boolean;
};

export const LeftDecorations = styled.div<VariantType>`
  position: absolute;
  pointer-events: none;
  z-index: -1;
  right: 100%;
  margin-right: -8rem;
  top: 60rem;

  ${mediaTablet(css`
    margin-right: -10rem;
  `)};

  ${({ $variant }) => {
    if ($variant && newCircuitVariants[$variant]?.decorations?.left) {
      const { mobile, desktop } = newCircuitVariants[$variant].decorations.left;

      return css`
        ${mobile
          ? backgroundDecoration(mobile.src, mobile.aspectRatio)
          : css`
              display: none;
            `}

        ${mediaTablet(
          desktop
            ? backgroundDecoration(desktop.src, desktop.aspectRatio)
            : css`
                display: none;
              `
        )}
      `;
    }
  }}
`;

export const RightDecorations = styled.div<VariantType>`
  position: absolute;
  pointer-events: none;
  z-index: -1;
  left: 100%;
  margin-left: -8rem;

  ${mediaTablet(css`
    margin-left: -10rem;
  `)};

  ${({ $variant }) => {
    if ($variant && newCircuitVariants[$variant]?.decorations?.right) {
      const { mobile, desktop } =
        newCircuitVariants[$variant].decorations.right;

      return css`
        ${mobile
          ? backgroundDecoration(mobile.src, mobile.aspectRatio)
          : css`
              display: none;
            `}

        ${mediaTablet(
          desktop
            ? backgroundDecoration(desktop.src, desktop.aspectRatio)
            : css`
                display: none;
              `
        )}
      `;
    }
  }};
`;

export const BottomDecorations = styled.div<VariantType>`
  position: absolute;
  pointer-events: none;
  z-index: -1;
  top: 100%;
  margin-top: -8rem;

  ${mediaTablet(css`
    margin-top: -10rem;
  `)};

  ${({ $variant }) => {
    if ($variant && newCircuitVariants[$variant]?.decorations?.bottom) {
      const { mobile, desktop } =
        newCircuitVariants[$variant].decorations.bottom;

      return css`
        ${mobile
          ? backgroundDecoration(mobile.src, mobile.aspectRatio)
          : css`
              display: none;
            `}

        ${mediaTablet(
          desktop
            ? backgroundDecoration(desktop.src, desktop.aspectRatio)
            : css`
                display: none;
              `
        )}
      `;
    }
  }};
`;

export const TopDecorations = styled.div<VariantType>`
  position: absolute;
  pointer-events: none;
  z-index: -1;
  bottom: 100%;
  margin-bottom: -8rem;

  ${mediaTablet(css`
    margin-bottom: -10rem;
  `)};

  ${({ $variant }) => {
    if ($variant && newCircuitVariants[$variant]?.decorations?.top) {
      const { mobile, desktop } = newCircuitVariants[$variant].decorations.top;

      return css`
        ${mobile
          ? backgroundDecoration(mobile.src, mobile.aspectRatio)
          : css`
              display: none;
            `}

        ${mediaTablet(
          desktop
            ? backgroundDecoration(desktop.src, desktop.aspectRatio)
            : css`
                display: none;
              `
        )}
      `;
    }
  }};
`;

export const Content = styled.div<VariantType>`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const TopPart = styled.div<VariantType>`
  z-index: -1;

  ${({ $variant }) => {
    if ($variant && newCircuitVariants[$variant].top) {
      const { mobile, desktop } = newCircuitVariants[$variant].top;

      return backgroundImage(
        mobile.src,
        mobile.aspectRatio,
        desktop.src,
        desktop.aspectRatio
      );
    }
  }};
`;

export const BodyPart = styled.div<VariantType>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: -2rem 0;

  ${({ $variant }) => {
    if ($variant) {
      const {
        top,
        body: { mobile, desktop },
        bottom,
      } = newCircuitVariants[$variant];

      if (top || bottom) {
        return css<VariantType>`
          ${backgroundImage(mobile.src)};
          min-height: 150rem;

          ${mediaTablet(css`
            min-height: 100rem;
            ${backgroundImage(desktop.src)};
          `)};
        `;
      } else {
        return css`
          ${backgroundImage(
            mobile.src,
            mobile.aspectRatio,
            desktop.src,
            desktop.aspectRatio
          )};

          ${Content} {
            ${mask(mobile.mask, desktop.mask)}
          }
        `;
      }
    }
  }}
`;

export const BottomPart = styled.div<VariantType>`
  z-index: -1;

  ${({ $variant }) => {
    if ($variant && newCircuitVariants[$variant].bottom) {
      const { mobile, desktop } = newCircuitVariants[$variant].bottom;

      return backgroundImage(
        mobile.src,
        mobile.aspectRatio,
        desktop.src,
        desktop.aspectRatio
      );
    }
  }}
`;

export const Animation = styled.div<VariantType>`
  position: absolute;
  pointer-events: none;
  z-index: -1;

  ${({ $variant }) => {
    if ($variant && newCircuitVariants[$variant]?.animation) {
      const { mobile, desktop } = newCircuitVariants[$variant].animation;

      return css`
        ${mobile
          ? aspectRatio(mobile.aspectRatio[0], mobile.aspectRatio[1])
          : css`
              display: none;
            `}

        ${mediaTablet(
          desktop
            ? css`
                display: block;
                ${aspectRatio(desktop.aspectRatio[0], desktop.aspectRatio[1])}
              `
            : css`
                display: none;
              `
        )}
      `;
    }
  }};
`;

export const AnimationExtra = styled(Animation)``;

export const Wrapper = styled.section<VariantType>`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 720rem;

  ${mediaTablet(css`
    width: 1900rem;
  `)};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.ROADMAP_PRIMARY &&
    css`
      ${RightDecorations} {
        ${mediaTablet(css`
          top: 0;
          bottom: 0;
          margin: auto 0 auto -10rem;
        `)};
      }

      ${Content} {
        margin: 0rem 0 -75rem;

        ${mediaTablet(css`
          margin: 20rem 0 -190rem;
        `)};
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.ROADMAP_SECONDARY &&
    css`
      ${LeftDecorations} {
        top: 110rem;
        margin-right: -8rem;

        ${mediaTablet(css`
          top: -540rem;
          margin-right: -11rem;
        `)};
      }

      ${RightDecorations} {
        top: -280rem;
        margin-left: -9rem;

        ${mediaTablet(css`
          top: 220rem;
          margin-left: -8rem;
        `)};
      }

      ${Content} {
        margin: 5rem 0 10rem;

        ${mediaTablet(css`
          margin: -60rem 0 -40rem;
        `)};
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.ROADMAP_FOOTER &&
    css`
      width: 740rem;

      ${mediaTablet(css`
        width: 2085rem;
      `)};
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.ROADMAP_CONTENT &&
    css`
      width: 600rem;

      ${mediaTablet(css`
        width: 930rem;
      `)};
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.MY_ACCOUNT_HEADER &&
    css`
      ${LeftDecorations} {
        top: 70rem;

        ${mediaTablet(css`
          top: 185rem;
        `)};
      }

      ${RightDecorations} {
        top: 80rem;

        ${mediaTablet(css`
          top: 235rem;
        `)};
      }

      ${Content} {
        margin: 0 0 15rem;

        ${mediaTablet(css`
          margin: -80rem 0 -40rem;
        `)};
      }

      ${BodyPart} {
        ${mediaTablet(css`
          min-height: 200rem;
        `)};
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.MY_ACCOUNT_WALLET &&
    css`
      width: 722rem;
      height: 1200rem;

      ${mediaTablet(css`
        height: 1650rem;

        ${LeftDecorations} {
          top: 390rem;
        }
      `)};
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.MY_ACCOUNT_SETTINGS &&
    css`
      ${RightDecorations} {
        top: 70rem;

        ${mediaTablet(css`
          top: 195rem;
        `)};
      }

      ${Content} {
        margin: -10rem 0 10rem;

        ${mediaTablet(css`
          margin: -90rem 0 0;
        `)};
      }

      ${Animation} {
        z-index: 1;
        left: calc(100% + 55rem);
        width: 550rem;
        top: -100rem;
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.MY_ACCOUNT_ADDITIONAL &&
    css`
      ${LeftDecorations} {
        top: 0;
        bottom: 0;
        margin: 20rem -10rem 0 0;

        ${mediaTablet(css`
          width: 900rem;
        `)};
      }

      ${RightDecorations} {
        top: 0;
        bottom: 0;
        margin: auto 0 auto -8rem;

        ${mediaTablet(css`
          top: 80rem;
          margin: 0 0 0 -10rem;
        `)};
      }

      ${Content} {
        padding: 15rem 0 0;
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.MY_ACCOUNT_POPUP &&
    css`
      ${BottomDecorations} {
        margin-top: -35rem;
        left: 192rem;

        ${mediaTablet(css`
          margin-top: -50rem;
          left: 700rem;
        `)}
      }

      ${Content} {
        margin: -350rem 0;

        ${mediaTablet(css`
          margin: -300rem 0;
        `)};
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.MY_ACCOUNT_NFTS &&
    css`
      ${RightDecorations} {
        left: auto;
        top: 5em;
        left: calc(100% - 6rem);
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.ABOUT_LCD_LEFT &&
    css`
      width: 625rem;

      ${mediaTablet(css`
        width: 399rem;
      `)};
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.ABOUT_LCD_RIGHT &&
    css`
      width: 625rem;

      ${mediaTablet(css`
        width: 1080rem;
      `)};
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.LOG_IN_POPUP &&
    css`
      ${LeftDecorations} {
        top: 140rem;

        ${mediaTablet(css`
          top: 305rem;
        `)};
      }

      ${RightDecorations} {
        top: 0;
        bottom: 0;
        margin: auto 0;
        margin-left: -10rem;
      }

      ${Content} {
        margin: -400rem 0;

        ${mediaTablet(css`
          margin: -405rem 0 -385rem;
        `)};
      }
    `};

  ${({ $smallerHeight, $variant }) =>
    $smallerHeight &&
    $variant === NewCircuitVariant.LOG_IN_POPUP &&
    css`
      ${TopPart} {
        margin: -80rem 0;
      }

      ${Content} {
        margin: -600rem 0;
      }

      ${BottomPart} {
        margin: -80rem 0;
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.FREEZE_POPUP &&
    css`
      width: 764rem;

      ${mediaTablet(css`
        width: 2080rem;
      `)};

      ${LeftDecorations} {
        right: calc(100% - 545rem);
        z-index: 1;
        top: 722rem;
        width: 608rem;
      }

      ${RightDecorations} {
        left: calc(100% - 345rem);
        z-index: 2;
        top: 142.7rem;
      }

      ${BottomDecorations} {
        top: 610rem;
        left: 72rem;
        height: 752rem;
        width: 619rem;
        background-size: cover;
        background-position: center;
        border-radius: 20rem;
        overflow: hidden;
        z-index: 0;
        clip-path: polygon(
          0 0,
          100% 0,
          100% calc(100% - 82rem),
          calc(100% - 184rem) calc(100% - 82rem),
          calc(100% - 250rem) 100%,
          0 100%
        );

        ${mediaTablet(css`
          top: 115rem;
          left: 805rem;
          width: 1095rem;
          height: 912rem;
          border-radius: 40rem;
          clip-path: polygon(
            0 0,
            100% 0,
            100% calc(100% - 115rem),
            calc(100% - 30rem) calc(100% - 85rem),
            calc(100% - 358rem) calc(100% - 85rem),
            calc(100% - 445rem) 100%,
            0 100%
          );
        `)}
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.FREEZE_LCD &&
    css`
      width: 614rem;

      ${mediaTablet(css`
        width: 1028rem;
      `)};

      ${Content} {
        margin: -80rem 0;
      }

      ${BodyPart} {
        min-height: 100rem;
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.CAPSULES &&
    css`
      ${Content} {
        margin: 20rem 0 40rem;

        ${mediaTablet(css`
          margin: -40rem 0;
        `)}
      }

      & > ${BodyPart} {
        min-height: 1000rem;

        ${mediaTablet(css`
          min-height: 1400rem;
        `)}
      }

      ${LeftDecorations} {
        top: 280rem;

        ${mediaTablet(css`
          top: 320rem;
        `)}
      }

      ${RightDecorations} {
        top: 580rem;

        ${mediaTablet(css`
          top: -505rem;
          left: calc(100% - 106rem);
        `)}
      }

      & > ${Animation} {
        top: 710rem;
        left: calc(100% + 50rem);
        width: 370rem;
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.CAPSULES_BG &&
    css`
      ${mediaTablet(css`
        width: 1800rem;

        & > ${BodyPart} {
          min-height: 533rem;
        }

        ${Content} {
          margin: -140rem 0;
        }
      `)};
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.LANDING_PRIMARY &&
    css`
      ${Content} {
        margin: 45rem 0;

        ${mediaTablet(css`
          margin: 0;
        `)}
      }

      ${LeftDecorations} {
        top: 100rem;

        ${mediaTablet(css`
          top: 186rem;
        `)}
      }

      ${RightDecorations} {
        top: 220rem;

        ${mediaTablet(css`
          top: 0;
          bottom: 0;
          margin: auto 0;
          left: calc(100% - 8rem);
        `)}
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.STATIC_LANDING_SECONDARY &&
    css`
      ${LeftDecorations} {
        top: 110rem;
      }

      ${RightDecorations} {
        top: 0;
        bottom: 0;
        margin: auto 0;
        left: calc(100% - 12rem);

        ${mediaTablet(css`
          top: auto;
          bottom: 350rem;
          left: calc(100% - 8rem);
        `)}
      }

      ${Animation} {
        top: 130rem;
        width: 195rem;
        right: calc(100% + 15rem);
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.LANDING_FOOTER &&
    css`
      ${Content} {
        margin: 20rem 0 45rem;

        ${mediaTablet(css`
          margin: 0;
        `)}
      }

      ${LeftDecorations} {
        top: auto;
        bottom: 100rem;

        ${mediaTablet(css`
          bottom: 160rem;
        `)}
      }

      ${RightDecorations} {
        top: 110rem;

        ${mediaTablet(css`
          top: 220rem;
        `)}
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.LAB_POPUP &&
    css`
      ${mediaTablet(css`
        width: 1500rem;
      `)};

      ${Content} {
        margin: -350rem 0;
        padding: 0 36rem;
      }

      ${BottomDecorations} {
        margin-top: -35rem;
        left: 182rem;

        ${mediaTablet(css`
          margin-top: -50rem;
          left: 510rem;
        `)}
      }

      ${Animation} {
        z-index: 1;
        top: calc(100% + 17rem);
        width: 520rem;
        left: 420rem;
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.LAB_INVENTORY_MEATA_POPUP &&
    css`
      ${mediaTablet(css`
        width: 686rem;
      `)};

      ${RightDecorations} {
        top: 60rem;

        ${mediaTablet(css`
          top: 50rem;
        `)};
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.LAB_INVENTORY_POPUP &&
    css`
      ${mediaTablet(css`
        width: 42.5vw;
        max-width: 70vh;
        right: 4vw;
      `)};

      ${RightDecorations} {
        left: auto;
        right: -30%;
        top: 3%;

        ${mediaTablet(css`
          top: 8%;
          right: -20%;
        `)};
      }

      ${BottomDecorations} {
        top: 7%;
        left: 4.8%;
        height: 86%;
        width: 90.1%;
        background-size: cover;
        background-position: center;
        border-radius: 30rem;
        border-top-right-radius: 100rem;
        overflow: hidden;
        z-index: 0;
      }
    `};

  ${({ $variant, $halfWidth }) =>
    $variant === NewCircuitVariant.LAB_INVENTORY_SAUCES &&
    css`
      height: 100%;
      width: 100%;
      ${mediaTablet(css`
        width: ${$halfWidth ? '43.5vw' : '50em'};
        max-width: 70vh;
        left: 3vw;
      `)}

      ${LeftDecorations} {
        top: 12%;
        right: 72%;
      }

      ${BottomDecorations} {
        top: 3.8%;
        left: 5.5%;
        height: 93.8%;
        width: 89%;
        background-size: cover;
        background-position: center;
        border-radius: 7rem;
        overflow: hidden;
        z-index: 0;

        ${mediaTablet(css`
          top: 6.2%;
          left: 5%;
          height: 86.4%;
          width: 90%;
          border-radius: 30rem;
          border-top-right-radius: 158rem;
        `)}
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.LAB_WIDE_POPUP &&
    css`
      ${mediaTablet(css`
        width: 2380rem;
      `)};

      ${TopDecorations} {
        margin: 0 auto -8rem;
        left: 0;
        right: 0;
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.FAQ_FOOTER &&
    css`
      ${LeftDecorations} {
        top: 190rem;
      }

      ${RightDecorations} {
        top: 130rem;
      }

      ${Animation} {
        top: 153rem;
        width: 245rem;
        left: calc(100% + 18rem);
      }

      ${AnimationExtra} {
        left: unset;
        top: 120rem;
        width: 580rem;
        right: calc(100% + 55rem);
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.STATIC_GALLERY &&
    css`
      ${Animation} {
        z-index: 1;
        top: 680rem;
        width: 60rem;
        left: calc(100% - 242rem);
      }

      ${BottomDecorations} {
        top: unset;
        left: unset;
        bottom: 270rem;
        right: 410rem;
        height: 1150rem;
        width: 1310rem;
        background-size: cover;
        background-position: center;
        border-radius: 20rem;
        overflow: hidden;
        z-index: 0;
      }

      ${mediaTablet(css`
        width: ${layoutMaxWidthDesktop}rem;
      `)}
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.MY_ACCOUNT_ACHIEVEMENTS &&
    css`
      ${LeftDecorations} {
        ${mediaTablet(css`
          top: unset;
          bottom: 20%;
        `)};
      }

      ${RightDecorations} {
        top: 0;
        bottom: 0;
        margin: auto 0 auto -8rem;

        ${mediaTablet(css`
          top: 0;
          bottom: 0;
          margin: auto 0;
          left: calc(100% - 25rem);
        `)};
      }

      ${BottomDecorations} {
        margin-top: -35rem;
        right: 92rem;

        ${mediaTablet(css`
          margin-top: -110rem;
          left: 10rem;
        `)}
      }

      ${Animation} {
        z-index: 1;
        top: calc(100% - 45rem);
        width: 520rem;
        left: 420rem;
      }

      ${AnimationExtra} {
        top: 0;
        bottom: 0;
        z-index: 1;
        left: 100%;
        margin: auto 0;
        width: 500rem;
        transform: translateY(100rem);
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.ABOUT_QUESTIONS &&
    css`
      ${RightDecorations} {
        top: 70rem;

        ${mediaTablet(css`
          top: 255rem;
        `)};
      }

      ${LeftDecorations} {
        top: -250rem;
      }

      ${BottomDecorations} {
        left: 0;
        right: 0;
        margin: 0 auto;
        margin-top: -44rem;
      }

      ${Animation} {
        top: 236rem;
        width: 245rem;
        right: calc(100% + 19rem);
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.ABOUT_CONTENT &&
    css`
      ${RightDecorations} {
        top: unset;
        bottom: calc(100% - 350rem);
      }

      ${LeftDecorations} {
        top: unset;
        bottom: calc(100% - 450rem);
      }

      ${BottomDecorations} {
        left: calc(100% - 650rem);
        margin-top: -44rem;
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.FAQ_HEADER &&
    css`
      ${RightDecorations} {
        top: 180rem;
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.MY_ACCOUNT_HEADER2 &&
    css`
      ${LeftDecorations} {
        top: 70rem;
        right: calc(100% - 3rem);

        ${mediaTablet(css`
          top: 145rem;
        `)};
      }

      ${RightDecorations} {
        top: 80rem;

        ${mediaTablet(css`
          top: 235rem;
        `)};
      }

      ${Content} {
        margin: 0 0 15rem;

        ${mediaTablet(css`
          margin: -80rem 0 -40rem;
        `)};
      }

      ${BodyPart} {
        ${mediaTablet(css`
          min-height: 200rem;
        `)};
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.STATIC_MY_ACCOUNT_BODY &&
    css`
      ${LeftDecorations} {
        top: 170rem;

        ${mediaTablet(css`
          top: 320rem;
        `)};
      }

      ${RightDecorations} {
        ${mediaTablet(css`
          top: 140rem;
        `)};
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.GALLERY_FILTERS &&
    css`
      width: 620rem;

      ${mediaTablet(css`
        width: 620rem;
      `)};
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.CAPSULE_STATS_FOOTER &&
    css`
      width: 100%;

      ${mediaTablet(css`
        width: 100%;
      `)};
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.STATIC_ERROR_DATABASE &&
    css`
      ${LeftDecorations} {
        top: -103rem;
        right: calc(100% - 140rem);
      }

      ${RightDecorations} {
        top: 50%;

        ${mediaTablet(css`
          top: 45%;
        `)}
      }

      ${BottomDecorations} {
        top: calc(100% - 30rem);
        left: 150rem;

        ${mediaTablet(css`
          top: calc(100% - 35rem);
        `)}
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.STATIC_ERROR_TRANSACTION &&
    css`
      ${RightDecorations} {
        top: 14%;
      }
    `};

  ${({ $variant }) =>
    [
      NewCircuitVariant.STATIC_ERROR_TRANSACTION,
      NewCircuitVariant.STATIC_ERROR_ACCOUNT,
      NewCircuitVariant.STATIC_ERROR_DATABASE,
    ].includes($variant) &&
    css`
      ${Content} {
        padding: 100rem 0;

        ${mediaTablet(css`
          padding: 110rem 0;
        `)}
      }
    `};
`;
