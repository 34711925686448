/* eslint-disable simple-import-sort/imports */
import isValidProp from '@emotion/is-prop-valid';
import * as Sentry from '@sentry/react';
import { MotionConfig } from 'framer-motion';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin';
import Error from 'next/error';
import Script from 'next/script';
import React, { useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import Layout from 'components/Layout/Layout';
import { API } from 'services/api/api';
import { tutorialUpdate } from 'services/api/calls/userData';
import {
  setupStoreDevTools,
  useCopyStore,
  useGlobalStore,
  useUserStore,
} from 'store';
import { NFT_HOTSPOTS_ENABLED, SCROLL_OVERLAY_SETTINGS } from 'store/global';
import ErrorBoundary from 'u9/components/ErrorBoundary/ErrorBoundary';
import Head from 'u9/components/Head/Head';
import Version from 'u9/components/versioning/Version/Version';
import VersionScreen from 'u9/components/versioning/VersionScreen/VersionScreen';
import NonFunctionals from 'u9/containers/NonFunctionals/NonFunctionals';
import GTM from 'u9/utils/gtm';
import { isMobile } from 'u9/utils/platform';
import customFonts from 'utils/styles/fonts';
import GlobalStyles from 'utils/styles/globalStyles';
import theme from 'utils/styles/theme';

import 'intersection-observer';
import { checkQuery, parseUrlQuery } from 'u9/utils/url';
import { loadCaptcha } from 'services/captcha';

if (process.env.ENV !== 'local') {
  Sentry.init({
    enabled: process.env.NODE_ENV !== 'development',
    dsn: process.env.SENTRY_DSN,
    environment: process.env.ENV,
  });
}

const IS_DEBUG = process.env.IS_DEBUG && true;

// GSAP config
gsap.defaults({ duration: 1, ease: 'none' });
gsap.registerPlugin(ScrollToPlugin);

const App = ({ Component, pageProps, router, props }: any) => {
  const [isMounted, setMounted] = useState(false);
  const { copy, setCopy } = useCopyStore();
  const {
    setIsDesktopView,
    setIsNftHotspotsEnabled,
    setScrollOverlaySettings,
  } = useGlobalStore();
  const { setIsWhitelisted, tutorialStatus, isLoggedIn } = useUserStore();

  const ErrorBoundaryComponent = useMemo(
    () => (process.env.ENV === 'local' ? ErrorBoundary : Sentry.ErrorBoundary),
    []
  );

  // useEffect(() => {
  //   const observer = new MutationObserver(mutationsList => {
  //     for (const mutation of mutationsList) {
  //       if (mutation.addedNodes.length) {
  //         mutation.addedNodes.forEach(node => {
  //           if (
  //             node instanceof HTMLIFrameElement &&
  //             node.classList.contains('magic-iframe')
  //           ) {
  //             console.log('Found the magic iframe');

  //             setTimeout(() => {
  //               console.error('node', node);
  //               if (node instanceof HTMLElement) {
  //                 const magicModal = node.querySelector('#magic-modal');

  //                 if (magicModal) {
  //                   console.log('Found the magic modal');
  //                   console.log('magicModal');
  //                   // Do something with the magicModal here
  //                 }
  //               }
  //             }, 4000);

  //             // .find(button => button.textContent.trim() === 'Connect Wallet');

  //             // if (button) {
  //             //   button.click();
  //             // }
  //           }
  //         });
  //       }
  //     }
  //   });

  //   // Start observing the document with the configured parameters
  //   observer.observe(document.body, { childList: true, subtree: true });

  //   // Clean up after the component is unmounted
  //   return () => {
  //     observer.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    API.getI.init();

    loadCaptcha();
    setupStoreDevTools();
    setMounted(true);
    setIsDesktopView(!isMobile());

    window.addEventListener('OneTrustGroupsUpdated', event => {
      if (event?.['detail']?.includes('C0002')) {
        GTM.initialize(true);
      }
    });

    if (IS_DEBUG && checkQuery('debugDate')) {
      const debugDate = parseUrlQuery().debugDate;
      API.getI.setDebugDate(debugDate);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pageProps.initialCopy) {
      setCopy(pageProps.initialCopy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageProps.initialCopy]);

  const isWhitelisted = router.query['whitelisted'];

  useEffect(() => {
    setIsWhitelisted(
      (!!isWhitelisted || (isWhitelisted as string) === '') &&
        (isWhitelisted as string) !== 'false'
    );
  }, [isWhitelisted, setIsWhitelisted]);

  useEffect(() => {
    if (isLoggedIn) {
      tutorialUpdate(tutorialStatus); //TODO: we should handle new tutorial status in api
    }
  }, [isLoggedIn, tutorialStatus]);

  // get local storage items
  useEffect(() => {
    setIsNftHotspotsEnabled(
      localStorage?.getItem(NFT_HOTSPOTS_ENABLED) === 'true' || false
    );

    const scrollOverflowSettings = localStorage?.getItem(
      SCROLL_OVERLAY_SETTINGS
    );
    const parsedSettings = scrollOverflowSettings
      ? JSON.parse(scrollOverflowSettings)
      : undefined;

    if (parsedSettings) {
      setScrollOverlaySettings(parsedSettings);
    }
  }, [setIsNftHotspotsEnabled, setScrollOverlaySettings]);

  // Example of use Error Popup
  // useEffect(() => {
  //   showErrorPopup({
  //     variant: ErrorPopupVariant.ACCOUNT,
  //     title: 'Account log in failed',
  //     body: "We couldn't find an account with the selected log in method. Choose the same method that you first used to sign up.",
  //     cta: [
  //       {
  //         label: 'Click me',
  //       },
  //       {
  //         label: 'Go to home page',
  //         variant: ButtonVariant.SECONDARY,
  //       },
  //     ],
  //   });
  // }, [showErrorPopup]);

  return (
    <React.StrictMode>
      <Head {...(pageProps.initialCopy || copy).head} shareId={props.share} />
      {isMounted && (
        <ThemeProvider theme={{ ...theme, locale: router.locale }}>
          <MotionConfig isValidProp={isValidProp}>
            <GlobalStyles />
            <style dangerouslySetInnerHTML={{ __html: customFonts }} />

            <ErrorBoundaryComponent
              {...(process.env.ENV !== 'local'
                ? { fallback: <Error statusCode={isMounted ? 400 : 500} /> }
                : {})}
            >
              <NonFunctionals
                // If the non-functionals are the page component, pass the initial copy
                // fetched from the server instead of the stored copy
                initialCopy={copy}
                router={router}
              >
                <Layout>
                  <Component
                    key={router.route}
                    router={router}
                    {...pageProps}
                  />
                </Layout>
              </NonFunctionals>
            </ErrorBoundaryComponent>
            {isMounted ? process.env.ENV !== 'production' && <Version /> : null}
            {isMounted
              ? process.env.IS_DEBUG &&
                window.location.hash === '#version' && <VersionScreen />
              : null}
          </MotionConfig>
        </ThemeProvider>
      )}
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-document-language="true"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script={process.env.ONE_TRUST_DOMAIN_KEY}
      />
    </React.StrictMode>
  );
};

App.getInitialProps = context => {
  const { query } = context.ctx;

  return {
    props: {
      share: query.share,
    },
  };
};

export default App;
