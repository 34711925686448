import { FC } from 'react';

import ErrorContainer from './ErrorContainer/ErrorContainer';
import ErrorContent, { ErrorContentProps } from './ErrorContent/ErrorContent';
import ErrorPopup, { ErrorPopupProps } from './ErrorPopup/ErrorPopup';

// import * as Styled from './Error.styles';

export const validDisplayVariants = ['container', 'popup'] as const;

export type ErrorContainerType = ErrorContentProps & {
  displayVariant: 'container';
  variant?: never;
  onClose?: never;
};

export type ErrorPopupType = ErrorContentProps & {
  displayVariant: 'popup';
  variant: ErrorPopupProps['variant'];
  onClose: () => void;
};

export type ErrorProps = ErrorContainerType | ErrorPopupType;

const Error: FC<ErrorProps> = ({
  autoJustifyContent,
  displayVariant,
  title,
  titleSize,
  body,
  footer,
  cta,
  variant,
  onClose,
}) => {
  const content = (
    <ErrorContent
      title={title}
      titleSize={titleSize}
      body={body}
      footer={footer}
      cta={cta}
      autoJustifyContent={autoJustifyContent}
    />
  );
  return displayVariant === 'container' ? (
    <ErrorContainer>{content}</ErrorContainer>
  ) : (
    <ErrorPopup variant={variant} onClose={onClose}>
      {content}
    </ErrorPopup>
  );
};

export default Error;
