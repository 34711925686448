import { FC } from 'react';

import assets from 'utils/assets';
import { getSauceDataWhereName } from 'utils/sauces';

import BaseSauce from './components/BaseSauce/BaseSauce';
import SuperSauce from './components/SuperSauce/SuperSauce';

import * as Styled from './InventoryItem.styles';

import { BaseSauce as baseSauces } from 'constants/sauces';

export enum InventoryItemKey {
  SLIM_JIM = 'SLIM_JIM',
  SAUCE = 'SAUCE',
}

const icons = assets.svgs.lab.season1.meaterializer.inventory.items;

export const iconAssets = {
  [InventoryItemKey.SLIM_JIM]: icons.slimJim,
  [InventoryItemKey.SAUCE]: icons.sauce,
};

export const validSizes = ['xs', 'sm', 'lg', 'responsive'] as const;

export interface InventoryItemProps {
  amount?: number;
  sauceName?: string;
  label?: string;
  itemKey: InventoryItemKey;
  size?: typeof validSizes[number];
  hasGlowEffect?: boolean;
  isEmpty?: boolean;
  isDisabled?: boolean;
  onClick?: (isEmpty?: boolean) => void;
}

const InventoryItem: FC<InventoryItemProps> = ({
  amount,
  sauceName,
  label,
  itemKey,
  size = 'sm',
  hasGlowEffect = false,
  isEmpty = false,
  isDisabled = false,
  onClick,
}) => {
  const isBaseSauce = baseSauces.map(({ name }) => name).includes(sauceName);
  // const isSuperSauce = itemKey === InventoryItemKey.SAUCE && !isBaseSauce;

  const content = (
    <Styled.Wrapper
      onClick={onClick ? () => onClick(isEmpty) : undefined}
      as={onClick ? 'button' : 'div'}
      disabled={isDisabled}
    >
      <Styled.Item $size={size} $isEmpty={isEmpty} $count={amount}>
        {isEmpty ? (
          <Styled.EmptyIcon
            $size={size}
            src={assets.svgs.lab.season1.meaterializer.inventory.emptyIcon}
            alt="Slot is empty"
          />
        ) : (
          <>
            {itemKey === InventoryItemKey.SAUCE ? (
              <Styled.SuperSauceWrapper>
                {isBaseSauce ? (
                  <BaseSauce
                    size="responsive"
                    hasGlowEffect={hasGlowEffect}
                    label={`Icon of ${itemKey}`}
                    name={sauceName}
                  />
                ) : (
                  <SuperSauce
                    size="responsive"
                    hasGlowEffect={hasGlowEffect}
                    label={`Icon of ${itemKey}`}
                    formula={
                      sauceName
                        ? getSauceDataWhereName(sauceName)?.formula || null
                        : null
                    }
                  />
                )}
              </Styled.SuperSauceWrapper>
            ) : (
              <Styled.Icon
                src={iconAssets[itemKey]}
                alt={`Icon of ${itemKey}`}
                $itemKey={itemKey}
                $size={size}
                $hasGlowEffect={hasGlowEffect}
              />
            )}
          </>
        )}
      </Styled.Item>
      {label && <Styled.Label $size={size}>{label}</Styled.Label>}
    </Styled.Wrapper>
  );

  // return isSuperSauce && sauceName ? (
  //   <Tooltip
  //     position="top"
  //     arrowPosition="center"
  //     arrowVariant="none"
  //     offset={{ bottom: -100 }}
  //     text={getSauceDataWhereName(sauceName)?.effectDescription}
  //     smallWidth
  //     slide={true}
  //     anchor={content}
  //     zIndex={12}
  //   />
  // ) : (
  //   content
  // );

  return content;
};

export default InventoryItem;
