import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import Link from 'components/Link/Link';
import assets from 'utils/assets';
import {
  backgroundImage,
  glowAnimation,
  hover,
  setTypography,
} from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

export const Chevron = styled.div`
  width: 0.8em;
  height: 0.8em;
  margin-left: 24rem;
  order: 3;

  ${backgroundImage(assets.svgs.navbar.chevronDown)};
`;

const wrapperStyle = css`
  ${({ theme: { locale } }) => setTypography('caption', locale)};
  color: ${({ theme }) => theme.colors.black};
  position: relative;
  text-shadow: -2rem 0 0 ${({ theme }) => rgba(theme.colors.turbo, 0.7)};
  display: flex;
  align-items: center;
  padding: 0 26rem;
  height: 100%;

  &::before {
    position: absolute;
    left: -2.5rem;
    top: 0;
    content: '';
    width: 5rem;
    height: 100%;
    background: url(${assets.svgs.navbar.separator});
  }

  ${hover(css`
    background: ${({ theme }) => theme.colors.mirage};
    color: ${({ theme }) => theme.colors.rumSwizzle};
    text-shadow: -2rem 0 0 ${({ theme }) => theme.colors.black};
  `)}

  ${mediaTablet(css`
    padding: 0 46rem;
  `)}

  &:hover ${Chevron} {
    ${backgroundImage(assets.svgs.navbar.chevronUp)};
  }
`;

export const WrapperLink = styled(Link)<{
  $active?: boolean;
}>`
  ${wrapperStyle};

  ${({ $active }) =>
    $active &&
    css`
      background: ${({ theme }) => theme.colors.mirage};
      color: ${({ theme }) => theme.colors.rumSwizzle};
      text-shadow: -2rem 0 0 ${({ theme }) => theme.colors.black};
      ${Chevron} {
        ${backgroundImage(assets.svgs.navbar.chevronUp)};
      }
    `}
`;

export const SubMenu = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 99rem;
  left: 0;
  width: 100%;
`;

export const WrapperButton = styled.button`
  ${wrapperStyle};
`;

export const IconWrapper = styled.div<{ $iconOnRightSide: boolean }>`
  width: 48rem;
  height: 40rem;
  order: 0;
  margin-right: 12rem;

  svg {
    height: 100%;
    width: 100%;
  }

  ${mediaTablet(css`
    width: 80rem;
    height: 80rem;
  `)};

  ${({ $iconOnRightSide }) =>
    $iconOnRightSide &&
    css`
      order: 2;
      margin-right: 0;
      margin-left: 12rem;
    `};
`;

export const Icon = styled.img`
  width: 100%;
  height: 100%;
`;

export const Label = styled.span`
  order: 1;
`;

export const Dropdown = styled.div`
  display: none;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  border-radius: 0.5rem;
  z-index: 1;
  background: linear-gradient(
      270deg,
      ${({ theme }) => theme.colors.turbo} -272.22%,
      ${({ theme }) => theme.colors.crimson} 490.66%
    ),
    ${({ theme }) => theme.colors.mirage};
  order: 4;
`;

export const DropdownItem = styled(Link)`
  padding: 28rem 1.5em;
  width: fit-content;
  display: flex;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
  border-top: 1px solid rgba(201, 17, 46, 0.4);
  ${({ theme: { locale } }) => setTypography('caption', locale)};
  text-shadow: -2rem 0 0 ${({ theme }) => rgba(theme.colors.turbo, 0.7)};

  &:hover {
    background: ${({ theme }) => theme.colors.mirage};
    color: ${({ theme }) => theme.colors.rumSwizzle};
    text-shadow: -2rem 0 0 ${({ theme }) => theme.colors.black};
  }
`;

export const Item = styled.li`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  &:hover ${Dropdown} {
    display: flex;
  }
`;

export const ContentWrapper = styled.div<{ $hasGlowEffect?: boolean }>`
  display: flex;
  align-items: center;

  ${({ $hasGlowEffect }) =>
    $hasGlowEffect &&
    css`
      ${glowAnimation()}
    `};
`;
