import { forwardRef } from 'react';

import { ButtonVariant } from 'components/Button/Button';

import TooltipButton, { TooltipButtonProps } from './TooltipButton';

const TooltipButtonSecondary = forwardRef<HTMLDivElement, TooltipButtonProps>(
  (props, ref) => (
    <TooltipButton
      ref={ref}
      variant={ButtonVariant.TOOLTIP_SECONDARY}
      {...props}
    />
  )
);

TooltipButtonSecondary.displayName = 'TooltipButtonSecondary';

export default TooltipButtonSecondary;
