import styled, { css } from 'styled-components';

import assets from 'utils/assets';
import {
  backgroundImage,
  setTextStyles,
  setTypography,
} from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const Info = styled.div`
  ${backgroundImage(assets.images.lab.scanner.iconInfo, [26, 26])};
  width: 52rem;
  margin-bottom: 20rem;
`;

export const Title = styled.h1`
  ${({ theme: { locale } }) => setTypography('heading1', locale)};
  ${setTextStyles('turbo', 'xl', 0.8)};
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: 88rem;
  max-width: 75%;
  ${mediaTablet(css`
    max-width: unset;
  `)}
`;

export const Subtitle = styled.div`
  ${({ theme: { locale } }) => setTypography('heading2', locale)};
  text-transform: uppercase;
  white-space: pre-wrap;
  text-shadow: 0 4rem 0 ${({ theme }) => theme.colors.black};
  text-align: center;
  max-width: 75%;
  margin-bottom: 100rem;
`;

export const Body = styled.div`
  ${({ theme: { locale } }) => setTypography('body1', locale)};
  text-transform: uppercase;
  white-space: pre-wrap;
  text-shadow: 0 4rem 0 ${({ theme }) => theme.colors.black};
  text-align: center;
  max-width: 75%;
`;
