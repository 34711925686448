import { LinkProps } from 'next/link';
import React, { FC, useCallback } from 'react';

import { useSvgIcon } from 'u9/hooks';

import Link from '../Link/Link';

import * as Styled from './Button.styles';

export const validIcons = [
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'angle-left',
  'bin',
  'close',
  'close2',
  'discord',
  'logout',
  'swap',
  'save',
  'twitter',
  'x',
  'linkedin',
  'inf',
  'camera',
  'keyboard',
  'capsule-arrow-left',
  'capsule-arrow-right',
] as const;

export const validIconPositions = ['left', 'right'] as const;

export const validTextAligns = ['center', 'space-between'] as const;

export const validPaddingSizes = ['xs', 'sm', 'lg'] as const;

export const validSmallValues = [
  true,
  false,
  'on-mobile',
  'on-desktop',
] as const;

export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LINK = 'link',
  LINK_SECONDARY = 'link-secondary',
  TOOLTIP = 'tooltip',
  TOOLTIP_SECONDARY = 'tooltip-secondary',
}

export type GenericButtonType = {
  label?: string;
  loading?: boolean;
  wide?: boolean;
  variant?: ButtonVariant;
  className?: string;
  icon?: typeof validIcons[number];
  iconPosition?: typeof validIconPositions[number];
  small?: typeof validSmallValues[number];
  textAlign?: typeof validTextAligns[number];
  uppercase?: boolean;
  paddingSize?: typeof validPaddingSizes[number];
  renderAsDiv?: boolean;
  autoFocus?: boolean;
};

export type ButtonProps = GenericButtonType &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    href?: never;
  };

export type AnchorProps = GenericButtonType &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & {
    href: LinkProps['href'];
    scroll?: boolean;
    openInNewTab?: boolean;
  };

export type GenericButtonProps = { as?: React.ElementType<any> } & (
  | ButtonProps
  | AnchorProps
);

const GenericButton: FC<GenericButtonProps> = ({
  as,
  label,
  loading,
  wide = false,
  variant = ButtonVariant.PRIMARY,
  className,
  onClick,
  small = 'on-mobile',
  icon,
  iconPosition = 'left',
  textAlign = 'center',
  uppercase = true,
  paddingSize,
  ...props
}) => {
  const defaultIcon =
    variant === ButtonVariant.TOOLTIP ||
    variant === ButtonVariant.TOOLTIP_SECONDARY
      ? 'info'
      : undefined;
  const Icon = useSvgIcon(defaultIcon || icon);
  const onClickCallback = useCallback(
    e => {
      e.stopPropagation();
      onClick && onClick(e);
    },
    [onClick]
  );

  return (
    <Styled.Wrapper
      as={as}
      $variant={variant}
      $wide={wide}
      disabled={loading}
      className={className}
      onClick={onClickCallback}
      $small={small}
      $iconPosition={icon && iconPosition}
      $hasLabel={label}
      $textAlign={textAlign}
      $uppercase={uppercase}
      $paddingSize={paddingSize}
      {...props}
    >
      {label && <Styled.Label>{label}</Styled.Label>}
      {(defaultIcon || icon) && (
        <Styled.IconWrapper>
          <Icon.SvgIcon />
        </Styled.IconWrapper>
      )}
    </Styled.Wrapper>
  );
};

const Button = (props: ButtonProps | AnchorProps) => (
  <GenericButton
    as={props.renderAsDiv ? 'div' : props.href ? Link : 'button'}
    {...props}
  />
);

export default Button;
