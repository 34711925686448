export enum ColorNames {
  azure = 'azure',
  bigStone = 'bigStone',
  black = 'black',
  crimson = 'crimson',
  cognac = 'cognac',
  cyanAqua = 'cyanAqua',
  fiord = 'fiord',
  gamboge = 'gamboge',
  gondola = 'gondola',
  mirage = 'mirage',
  osloGray = 'osloGray',
  punch = 'punch',
  rumSwizzle = 'rumSwizzle',
  turbo = 'turbo',
  white = 'white',
  stratos = 'stratos',
  downriver = 'downriver',
  tonysPink = 'tonysPink',
  saffron = 'saffron',
  harlequin = 'harlequin',
  dodgerBlue = 'dodgerBlue',
  heliotrope = 'heliotrope',
  cornFlowerBlue = 'cornFlowerBlue',
  yellowOrange = 'yellowOrange',
  nevada = 'nevada',
  opal = 'opal',
  teal = 'teal',
}

export enum FontNames {
  gotham = 'gotham',
  knockout = 'knockout',
  montserrat = 'montserrat',
}

const validFontCategories = [
  'heading1',
  'heading1Small',
  'heading1Medium',
  'heading1Large',
  'heading1Code',
  'heading2',
  'heading2Code',
  'heading2Large',
  'heading2Menu',
  'heading3',
  'heading4',
  'heading4Wallet',
  'heading4Slider',
  'heading4Inventory',
  'heading5',
  'heading5Normal',
  'heading5Tooltip',
  'heading5Hotspot',
  'heading5Wallet',
  'heading5Filters',
  'heading6',
  'heading7',
  'heading8',
  'body1',
  'body2',
  'body2Large',
  'body3',
  'body4',
  'body5',
  'body6',
  'caption',
  'caption2',
  'caption3',
  'input',
  'label',
  'labelSmall',
  'badge',
  'badgeLarge',
  'popupBody',
] as const;

export interface ResponsiveValues {
  mobile?: number;
  tablet?: number;
  desktop?: number;
}

export interface FontCategory {
  fontFamily?: FontNames | Record<string, FontNames>;
  fontSize: ResponsiveValues;
  lineHeight: ResponsiveValues;
  fontWeight: number;
  letterSpacing?: number;
  wordSpacing?: number;
  fontStretch?: 'expanded' | 'normal';
  textDecoration?: 'underline' | 'none';
  textTransform?:
    | 'none'
    | 'capitalize'
    | 'uppercase'
    | 'full-size-kana'
    | 'full-width'
    | 'lowercase';
  fontStyle?: 'normal' | 'italic' | 'oblique' | 'inherit' | 'initial' | 'unset';
}

export interface Theme {
  colors: Record<ColorNames, string>;
  fonts: {
    face: Record<FontNames, string>;
    scale: Record<typeof validFontCategories[number], FontCategory>;
  };
  layout: typeof layout;
}

// "Name that Color" names
export const colors: Theme['colors'] = {
  [ColorNames.azure]: '#107EFE',
  [ColorNames.bigStone]: '#1B3249',
  [ColorNames.black]: '#000000',
  [ColorNames.cognac]: '#A6471A',
  [ColorNames.crimson]: '#C8102E',
  [ColorNames.fiord]: '#3F536B',
  [ColorNames.gamboge]: '#E9A30E',
  [ColorNames.mirage]: '#1A222F',
  [ColorNames.osloGray]: '#7F8E94',
  [ColorNames.punch]: '#D44921',
  [ColorNames.rumSwizzle]: '#F9F3E2',
  [ColorNames.turbo]: '#F8E600',
  [ColorNames.white]: '#FFFFFF',
  [ColorNames.cyanAqua]: '#24f2ff',
  [ColorNames.gondola]: '#291820',
  [ColorNames.stratos]: '#00093B',
  [ColorNames.downriver]: '#0B1F4D',
  [ColorNames.saffron]: '#F6CE3F',
  [ColorNames.harlequin]: '#45D700',
  [ColorNames.tonysPink]: '#E68C91',
  [ColorNames.dodgerBlue]: '#43C1FE',
  [ColorNames.heliotrope]: '#C68DFC',
  [ColorNames.cornFlowerBlue]: '#6195ED',
  [ColorNames.yellowOrange]: '#FFAB44',
  [ColorNames.nevada]: '#627075',
  [ColorNames.opal]: '#A8C2C7',
  [ColorNames.teal]: '#39609C',
} as const;

export const fonts: Theme['fonts'] = {
  face: {
    [FontNames.gotham]:
      "Gotham, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    [FontNames.knockout]:
      'Knockout, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace',
    [FontNames.montserrat]: 'Montserrat, Helvetica, Arial, sans-serif',
  },
  scale: {
    // Based on Material design typography scale, adjust as needed
    // fontSize: rem, letterSpacing: em
    // fontFamily: { [localeCode]: localeSpecificFont, default: defaultFont }
    heading1: {
      fontFamily: { default: FontNames.gotham },
      fontSize: { tablet: 120, mobile: 68 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    heading1Small: {
      fontFamily: { default: FontNames.gotham },
      fontSize: { tablet: 96, mobile: 56 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    heading1Medium: {
      fontFamily: { default: FontNames.gotham },
      fontSize: { tablet: 130, mobile: 90 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    heading1Large: {
      fontFamily: { default: FontNames.gotham },
      fontSize: { tablet: 300, mobile: 200 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    heading1Code: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 120, mobile: 80 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 300,
      textTransform: 'uppercase',
    },
    heading2: {
      fontFamily: { default: FontNames.gotham },
      fontSize: { tablet: 56, mobile: 40 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    heading2Code: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 80, mobile: 48 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 300,
      textTransform: 'uppercase',
    },
    heading2Large: {
      fontFamily: { default: FontNames.gotham },
      fontSize: { tablet: 80, mobile: 56 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    heading2Menu: {
      fontFamily: { default: FontNames.gotham },
      fontSize: { tablet: 40, mobile: 40 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 500,
    },
    heading3: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 80, mobile: 48 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    heading4: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 56, mobile: 36 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 700,
    },
    heading4Wallet: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 60, mobile: 44 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 200,
    },
    heading4Slider: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 56, mobile: 48 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 600,
    },
    heading4Inventory: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 56, mobile: 48 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 700,
    },
    heading5: {
      fontFamily: { default: FontNames.gotham },
      fontSize: { tablet: 44, mobile: 36 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    heading5Normal: {
      fontFamily: { default: FontNames.gotham },
      fontSize: { tablet: 44, mobile: 32 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 500,
      textTransform: 'none',
      fontStyle: 'italic',
    },
    heading5Tooltip: {
      fontFamily: { default: FontNames.gotham },
      fontSize: { tablet: 44, mobile: 44 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    heading5Hotspot: {
      fontFamily: { default: FontNames.gotham },
      fontSize: { tablet: 38, mobile: 38 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    heading5Filters: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 40, mobile: 44 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    heading5Wallet: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 48, mobile: 32 },
      lineHeight: { tablet: 1.2, mobile: 1.2 },
      letterSpacing: 0.02,
      fontWeight: 300,
    },
    heading6: {
      fontFamily: { default: FontNames.gotham },
      fontSize: { tablet: 36, mobile: 32 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    heading7: {
      fontFamily: { default: FontNames.gotham },
      fontSize: { tablet: 28, mobile: 27 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    heading8: {
      fontFamily: { default: FontNames.gotham },
      fontSize: { tablet: 32, mobile: 32 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    body1: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 40, mobile: 32 },
      lineHeight: { tablet: 1.3, mobile: 1.3 },
      letterSpacing: 0.02,
      fontWeight: 300,
      textTransform: 'uppercase',
    },
    body2: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 36, mobile: 36 },
      lineHeight: { tablet: 1.3, mobile: 1.3 },
      letterSpacing: 0.02,
      fontWeight: 300,
    },
    body2Large: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 40, mobile: 40 },
      lineHeight: { tablet: 1.3, mobile: 1.3 },
      letterSpacing: 0.02,
      fontWeight: 300,
    },
    body3: {
      fontFamily: { default: FontNames.montserrat },
      fontSize: { tablet: 32, mobile: 28 },
      lineHeight: { tablet: 1.3, mobile: 1.3 },
      fontWeight: 800,
    },
    body4: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 32, mobile: 32 },
      lineHeight: { tablet: 1.2, mobile: 1.2 },
      fontWeight: 300,
      letterSpacing: 0.02,
    },
    body5: {
      fontFamily: { default: FontNames.gotham },
      fontSize: { tablet: 15, mobile: 16.5 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    body6: {
      fontFamily: { default: FontNames.montserrat },
      fontSize: { tablet: 15, mobile: 16.5 },
      lineHeight: { tablet: 1.2, mobile: 1.2 },
      letterSpacing: 0.02,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 32, mobile: 28 },
      lineHeight: { tablet: 1.3, mobile: 1.3 },
      letterSpacing: 0.02,
      fontWeight: 300,
    },
    caption2: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 28, mobile: 24 },
      lineHeight: { tablet: 1.1, mobile: 1.1 },
      letterSpacing: 0.02,
      fontWeight: 300,
    },
    caption3: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 26, mobile: 24 },
      lineHeight: { tablet: 1.1, mobile: 1.1 },
      letterSpacing: 0.02,
      fontWeight: 300,
    },
    input: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 44, mobile: 32 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 300,
    },
    label: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 16, mobile: 14 },
      lineHeight: { tablet: 1, mobile: 1 },
      fontWeight: 500,
    },
    labelSmall: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 13, mobile: 11 },
      lineHeight: { tablet: 1, mobile: 1 },
      fontWeight: 500,
    },
    badge: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 32, mobile: 24 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 550,
    },
    badgeLarge: {
      fontFamily: { default: FontNames.knockout },
      fontSize: { tablet: 40, mobile: 40 },
      lineHeight: { tablet: 1, mobile: 1 },
      letterSpacing: 0.02,
      fontWeight: 300,
    },
    popupBody: {
      fontFamily: { default: FontNames.gotham },
      fontSize: { tablet: 26, mobile: 26 },
      lineHeight: { tablet: 1.3, mobile: 1.3 },
      letterSpacing: 0.02,
      fontWeight: 500,
      textTransform: 'none',
      fontStyle: 'normal',
    },
  },
} as const;

// Comment unit where applicable
export const layout = {
  borderRadius: 40, // rem
  lineOpacity: 0.1,
  zIndex: {
    languageSelector: 2,
    nonFunctionals: 125,
    popup: 6,
    header: 20,
    headerMenu: 19,
    footer: 20,
  },
  header: {
    height: 100, // rem
  },
  footer: {
    height: {
      mobile: 180, // rem
      desktop: 130, // rem
    },
  },
} as const;

const theme: Theme = {
  colors,
  fonts,
  layout,
} as const;

export default theme;
