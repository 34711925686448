import { colors } from 'utils/styles/theme';

export enum ValidBaseSauces {
  'Cheese',
  'BBQ',
  'Hot',
  'Nice',
  'Secret',
}

export const validRarities = ['Common', 'Rare', 'Epic', 'Legendary'] as const;

export type BaseSauceName = typeof ValidBaseSauces[number];
export type BaseSauceRarity = typeof validRarities[number];
export type BaseSauceType = {
  name: BaseSauceName;
  rarity?: BaseSauceRarity;
  color?: string;
  colorByName?: string;
  count?: number;
};

export type SauceFormula = `${BaseSauceName}-${BaseSauceName}-${BaseSauceName}`;

export const UNDO_SAUCE = 'Clapback Sauce';

export const BaseSauce: BaseSauceType[] = [
  {
    name: 'Cheese',
    rarity: 'Common',
    color: 'Orange',
    colorByName: colors.saffron,
  },
  {
    name: 'BBQ',
    rarity: 'Common',
    color: 'Green',
    colorByName: colors.harlequin,
  },
  {
    name: 'Hot',
    rarity: 'Common',
    color: 'Red',
    colorByName: colors.tonysPink,
  },
  {
    name: 'Nice',
    rarity: 'Rare',
    color: 'Blue',
    colorByName: colors.dodgerBlue,
  },
  {
    name: 'Secret',
    rarity: 'Epic',
    color: 'Purple',
    colorByName: colors.heliotrope,
  },
];

export type SauceSchema = {
  baseSauce1?: BaseSauceName;
  baseSauce2?: BaseSauceName;
  baseSauce3?: BaseSauceName;
  rarity?: BaseSauceRarity;
  name?: string;
  effectDescription?: string;
  formula?: SauceFormula;
  count?: number;
};

export const Sauce: SauceSchema[] = [
  {
    baseSauce1: 'Cheese',
    baseSauce2: 'BBQ',
    baseSauce3: 'Hot',
    rarity: 'Common',
    name: 'Meathead Sauce',
    effectDescription: 'Randomize common Head Accessory',
    formula: 'BBQ-Cheese-Hot',
    count: 1,
  },
  {
    baseSauce1: 'Cheese',
    baseSauce2: 'Cheese',
    baseSauce3: 'BBQ',
    rarity: 'Common',
    name: 'Southpaw Sauce',
    effectDescription: 'Randomize common Left Hand Accessory',
    formula: 'BBQ-Cheese-Cheese',
    count: 5,
  },
  {
    baseSauce1: 'Cheese',
    baseSauce2: 'Cheese',
    baseSauce3: 'Hot',
    rarity: 'Common',
    name: 'Tighty Sauce',
    effectDescription: 'Randomize common Right Hand Accessory',
    formula: 'Cheese-Cheese-Hot',
    count: 8,
  },
  {
    baseSauce1: 'Cheese',
    baseSauce2: 'Cheese',
    baseSauce3: 'Nice',
    rarity: 'Common',
    name: 'Body Kit Sauce',
    effectDescription: 'Randomize common Torso',
    formula: 'Cheese-Cheese-Nice',
    count: 12,
  },
  {
    baseSauce1: 'Cheese',
    baseSauce2: 'Cheese',
    baseSauce3: 'Cheese',
    rarity: 'Common',
    name: 'Flex Sauce',
    effectDescription: 'Randomize common Arm',
    formula: 'Cheese-Cheese-Cheese',
    count: 23,
  },
  {
    baseSauce1: 'BBQ',
    baseSauce2: 'BBQ',
    baseSauce3: 'Cheese',
    rarity: 'Common',
    name: 'Leg Day Sauce',
    effectDescription: 'Randomize common Legs',
    formula: 'BBQ-BBQ-Cheese',
    count: 9,
  },
  {
    baseSauce1: 'BBQ',
    baseSauce2: 'BBQ',
    baseSauce3: 'Hot',
    rarity: 'Common',
    name: 'Catch These Hands Sauce',
    effectDescription: 'Randomize common Hands',
    formula: 'BBQ-BBQ-Hot',
    count: 1,
  },
  {
    baseSauce1: 'BBQ',
    baseSauce2: 'BBQ',
    baseSauce3: 'Nice',
    rarity: 'Common',
    name: 'Face Off Sauce',
    effectDescription: 'Randomize common Head',
    formula: 'BBQ-BBQ-Nice',
    count: 4,
  },
  {
    baseSauce1: 'BBQ',
    baseSauce2: 'BBQ',
    baseSauce3: 'BBQ',
    rarity: 'Common',
    name: 'Stomp Sauce',
    effectDescription: 'Randomize common Feet',
    formula: 'BBQ-BBQ-BBQ',
    count: 7,
  },
  {
    baseSauce1: 'Hot',
    baseSauce2: 'Hot',
    baseSauce3: 'Cheese',
    rarity: 'Common',
    name: 'Snow Globe Sauce',
    effectDescription: 'Randomize common capsule',
    formula: 'Cheese-Hot-Hot',
    count: 17,
  },
  {
    baseSauce1: 'Hot',
    baseSauce2: 'Hot',
    baseSauce3: 'BBQ',
    rarity: 'Common',
    name: 'YOLO Sauce',
    effectDescription: 'Randomize a trait from a set to a random spot',
    formula: 'BBQ-Hot-Hot',
    count: 1,
  },
  {
    baseSauce1: 'Hot',
    baseSauce2: 'Hot',
    baseSauce3: 'Nice',
    rarity: 'Common',
    name: 'Juicy Sauce',
    effectDescription: 'Randomize common Back Accessory',
    formula: 'Hot-Hot-Nice',
    count: 12,
  },
  {
    baseSauce1: 'Hot',
    baseSauce2: 'Hot',
    baseSauce3: 'Hot',
    rarity: 'Common',
    name: 'Florida Man Sauce',
    effectDescription: 'Randomize any trait to common',
    formula: 'Hot-Hot-Hot',
    count: 32,
  },
  {
    baseSauce1: 'Nice',
    baseSauce2: 'Cheese',
    baseSauce3: 'BBQ',
    rarity: 'Common',
    name: 'Double Up Sauce',
    effectDescription: 'Randomize 2 random common traits to common',
    formula: 'BBQ-Cheese-Nice',
    count: 5,
  },
  {
    baseSauce1: 'Nice',
    baseSauce2: 'Cheese',
    baseSauce3: 'Hot',
    rarity: 'Common',
    name: 'Clapback Sauce',
    effectDescription: 'Undo',
    formula: 'Cheese-Hot-Nice',
    count: 14,
  },
  {
    baseSauce1: 'Nice',
    baseSauce2: 'BBQ',
    baseSauce3: 'Hot',
    rarity: 'Common',
    name: 'Clean Plate Sauce',
    effectDescription: 'Randomize all of the objects to a common trait',
    formula: 'BBQ-Hot-Nice',
    count: 16,
  },
  {
    baseSauce1: 'Cheese',
    baseSauce2: 'Nice',
    baseSauce3: 'Secret',
    rarity: 'Rare',
    name: 'Arm Party Sauce',
    effectDescription: 'Randomize rare Arm',
    formula: 'Cheese-Nice-Secret',
    count: 8,
  },
  {
    baseSauce1: 'Cheese',
    baseSauce2: 'BBQ',
    baseSauce3: 'Secret',
    rarity: 'Rare',
    name: 'Swole Sauce',
    effectDescription: 'Evolve a random common to rare',
    formula: 'BBQ-Cheese-Secret',
    count: 6,
  },
  {
    baseSauce1: 'Cheese',
    baseSauce2: 'Hot',
    baseSauce3: 'Secret',
    rarity: 'Rare',
    name: 'Back It Up Sauce',
    effectDescription: 'Randomize rare Back Accessory',
    formula: 'Cheese-Hot-Secret',
    count: 5,
  },
  {
    baseSauce1: 'Cheese',
    baseSauce2: 'Cheese',
    baseSauce3: 'Secret',
    rarity: 'Rare',
    name: 'Top-Off Sauce',
    effectDescription: 'Randomize rare Head Accessory',
    formula: 'Cheese-Cheese-Secret',
    count: 3,
  },
  {
    baseSauce1: 'BBQ',
    baseSauce2: 'Nice',
    baseSauce3: 'Secret',
    rarity: 'Rare',
    name: 'Right Hand Man Sauce',
    effectDescription: 'Randomize rare Right Hand',
    formula: 'BBQ-Nice-Secret',
    count: 1,
  },
  {
    baseSauce1: 'BBQ',
    baseSauce2: 'BBQ',
    baseSauce3: 'Secret',
    rarity: 'Rare',
    name: 'Hold It Down Sauce',
    effectDescription: 'Randomize rare Left Hand',
    formula: 'BBQ-BBQ-Secret',
    count: 2,
  },
  {
    baseSauce1: 'Hot',
    baseSauce2: 'BBQ',
    baseSauce3: 'Secret',
    rarity: 'Rare',
    name: 'Feed Me Sauce',
    effectDescription: 'Randomize rare capsule',
    formula: 'BBQ-Hot-Secret',
    count: 12,
  },
  {
    baseSauce1: 'Hot',
    baseSauce2: 'Nice',
    baseSauce3: 'Secret',
    rarity: 'Rare',
    name: 'Paint Job Sauce',
    effectDescription: 'Apply rare Colour to a single random accessory',
    formula: 'Hot-Nice-Secret',
    count: 23,
  },
  {
    baseSauce1: 'Hot',
    baseSauce2: 'Hot',
    baseSauce3: 'Secret',
    rarity: 'Rare',
    name: 'Hardcore Sauce',
    effectDescription: 'Randomize rare Torso',
    formula: 'Hot-Hot-Secret',
    count: 11,
  },
  {
    baseSauce1: 'Nice',
    baseSauce2: 'Nice',
    baseSauce3: 'Cheese',
    rarity: 'Rare',
    name: 'Twice as Nice Sauce',
    effectDescription: 'Randomize rare Feet',
    formula: 'Cheese-Nice-Nice',
    count: 10,
  },
  {
    baseSauce1: 'Nice',
    baseSauce2: 'Nice',
    baseSauce3: 'BBQ',
    rarity: 'Rare',
    name: 'Stem Sauce',
    effectDescription: 'Randomize rare Legs',
    formula: 'BBQ-Nice-Nice',
    count: 9,
  },
  {
    baseSauce1: 'Nice',
    baseSauce2: 'Nice',
    baseSauce3: 'Hot',
    rarity: 'Rare',
    name: 'Diamond Hands Sauce',
    effectDescription: 'Randomize rare Hands',
    formula: 'Hot-Nice-Nice',
    count: 6,
  },
  {
    baseSauce1: 'Nice',
    baseSauce2: 'Nice',
    baseSauce3: 'Nice',
    rarity: 'Epic',
    name: 'Get Big Sauce',
    effectDescription: 'Randomize any trait to epic',
    formula: 'Nice-Nice-Nice',
    count: 5,
  },
  {
    baseSauce1: 'Secret',
    baseSauce2: 'Secret',
    baseSauce3: 'Cheese',
    rarity: 'Epic',
    name: 'Level Up Sauce',
    effectDescription: 'Evolve a random rare or common to epic',
    formula: 'Cheese-Secret-Secret',
    count: 4,
  },
  {
    baseSauce1: 'Secret',
    baseSauce2: 'Secret',
    baseSauce3: 'BBQ',
    rarity: 'Epic',
    name: 'Glow Up Sauce',
    effectDescription: 'Apply Epic Colour to a single random accessory',
    formula: 'BBQ-Secret-Secret',
    count: 8,
  },
  {
    baseSauce1: 'Secret',
    baseSauce2: 'Secret',
    baseSauce3: 'Hot',
    rarity: 'Epic',
    name: 'Meat Me Sauce',
    effectDescription: 'Upgrade capsule to Epic',
    formula: 'Hot-Secret-Secret',
    count: 10,
  },
  {
    baseSauce1: 'Nice',
    baseSauce2: 'Nice',
    baseSauce3: 'Secret',
    rarity: 'Legendary',
    name: 'Sick Drip Sauce',
    effectDescription: 'Apply Legendary Colour to a single random accessory',
    formula: 'Nice-Nice-Secret',
    count: 7,
  },
  {
    baseSauce1: 'Secret',
    baseSauce2: 'Secret',
    baseSauce3: 'Nice',
    rarity: 'Legendary',
    name: 'Moon Sauce',
    effectDescription: 'Randomize any legendary trait',
    formula: 'Nice-Secret-Secret',
    count: 1,
  },
  {
    baseSauce1: 'Secret',
    baseSauce2: 'Secret',
    baseSauce3: 'Secret',
    rarity: 'Legendary',
    name: 'GANG GANG Sauce',
    effectDescription: 'Evolve a random epic to legendary',
    formula: 'Secret-Secret-Secret',
    count: 3,
  },
];

export const rarityColorOptions = {
  Common: 'rumSwizzle',
  Epic: 'heliotrope',
  Rare: 'cornFlowerBlue',
  Legendary: 'yellowOrange',
};
