/* eslint-disable no-console */

declare global {
  interface Console {
    re: any;
  }
}

import { loadDynamicScript } from './remote-lib';

import {
  CONSOLE_RE_CODE,
  IS_CONSOLE_RE,
  IS_LOG_DEBUG,
} from 'config/debug-links';

export const loadConsoleRe = () => {
  if (IS_CONSOLE_RE) {
    loadDynamicScript({
      src: '//console.re/connector.js',
      channel: CONSOLE_RE_CODE,
      id: 'consolerescript',
    });
  }
};

// for local tests on mobile use https://console.re/
// http://localhost:3000/?consoleRe=

export const logMe = (...args) => {
  if (IS_LOG_DEBUG) {
    if (IS_CONSOLE_RE && console.re) console.re.log(...args);
    else console.log(...args);
  }
};

export const errorMe = (...args) => {
  if (IS_LOG_DEBUG) {
    if (IS_CONSOLE_RE && console.re) console.re.error(...args);
    else console.error(...args);
  }
};
