import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';

export const Input = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12% 1em;
  width: 100%;
  height: 100%;
`;

export const InputField = styled.input<{ $hasError: boolean }>`
  padding: 0.75em;
  background: ${({ theme: { colors } }) => colors.mirage};
  border: 4px solid
    ${({ theme: { colors }, $hasError }) =>
      $hasError ? colors.crimson : colors.black};
  border-radius: 10px;
`;

export const Label = styled.label`
  ${({ theme: { locale } }) => setTypography('body2', locale)};
`;
