import styled, { css } from 'styled-components';

import Link from 'components/Link/Link';
import { hover } from 'utils/styles/mixins';

const wrapperStyles = css`
  display: block;
  position: relative;
  color: ${({ theme }) => theme.colors.osloGray};
  width: 720rem;
  height: 197.5rem;

  svg {
    height: 100%;
    width: 100%;
  }

  ${hover(css`
    color: ${({ theme }) => theme.colors.fiord};
  `)}
`;

export const WrapperLink = styled(Link)`
  ${wrapperStyles}
`;

export const Wrapper = styled.button`
  ${wrapperStyles}
`;
