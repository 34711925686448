import { mountStoreDevtool } from 'simple-zustand-devtools';
import { StoreApi, UseBoundStore } from 'zustand';

import { isBrowser } from 'u9/utils/platform';
import { getUrlParams } from 'u9/utils/url';

import useCopyStore from './copy';
import useGalleryStore from './gallery';
import useGlobalStore from './global';
import useUserStore from './user';

const addStoreDevtool = (
  name: string,
  store: UseBoundStore<Record<string, any>, StoreApi<Record<string, any>>>
) => {
  const separateRoot = document.createElement('div');
  separateRoot.id = `devtools-${name}`;
  document.body.appendChild(separateRoot);
  mountStoreDevtool(name, store, separateRoot);
};

const setupStoreDevTools = () => {
  if (
    isBrowser() &&
    (process.env.IS_DEBUG || getUrlParams().get('debugStore'))
  ) {
    addStoreDevtool('CopyStore', useCopyStore);
    addStoreDevtool('GlobalStore', useGlobalStore);
    addStoreDevtool('UserStore', useUserStore);
    addStoreDevtool('GalleryStore', useGalleryStore);
  }
};

export {
  setupStoreDevTools,
  useCopyStore,
  useGalleryStore,
  useGlobalStore,
  useUserStore,
};
