import { FC } from 'react';

import Popup from 'components/Popup/Popup';
import { useCopyStore } from 'store';
import { NewCircuitVariant } from 'utils/assets';

import * as Styled from './PermissionsPopup.styles';

export interface PermissionsPopupProps {}

const PermissionsPopup: FC<PermissionsPopupProps> = () => {
  const copy = useCopyStore(({ copy }) => copy.lab.scanner.permissionsPopup);
  return (
    <Popup
      variant={NewCircuitVariant.LAB_POPUP}
      hasCloseButton={false}
      onClose={() => false}
      minHeight={[1100, 1600]}
      key="permissions"
      animationDirection="top"
    >
      <Styled.Wrapper>
        <Styled.Title>{copy.title}</Styled.Title>
        <Styled.Subtitle>{copy.subtitle}</Styled.Subtitle>
        <Styled.Info />
        <Styled.Body>{copy.body}</Styled.Body>
      </Styled.Wrapper>
    </Popup>
  );
};

export default PermissionsPopup;
