import styled, { css } from 'styled-components';

import Button from 'components/Button/Button';
import {
  ColorNamesType,
  setTextStyles,
  setTypography,
} from 'utils/styles/mixins';
import { mediaMobile, mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const Title = styled.h1`
  ${({ theme: { locale } }) => setTypography('heading1', locale)};
  ${setTextStyles('turbo', 'xl', 0.8)};
  text-align: center;
  white-space: pre-wrap;
  max-width: 82.5%;
  margin-bottom: 15rem;
`;

export const Cta = styled(Button)`
  width: 100%;

  ${mediaTablet(css`
    width: auto;
  `)}
`;

export const Actions = styled.div`
  display: flex;
  gap: 30rem;
  flex-direction: column;
  margin-top: 52rem;

  ${mediaMobile(css`
    width: 95%;

    a,
    button {
      width: 100%;
    }
  `)}

  ${mediaTablet(css`
    gap: 60rem;
    flex-direction: row;
  `)}
`;

export const validSizes = ['sm', 'lg', 'xl'] as const;

export const Label = styled.label<{
  $size: typeof validSizes[number];
  $marginBottom?: number;
  $color?: ColorNamesType;
}>`
  ${({ theme: { locale } }) => setTypography('body4', locale)};
  ${({ $color }) => setTextStyles($color ?? 'rumSwizzle', 'md')};
  text-align: center;

  ${({ $size }) =>
    ($size === 'lg' || $size === 'xl') &&
    css`
      ${({ theme: { locale } }) => setTypography('body1', locale)};
    `};

  ${({ $size }) =>
    $size === 'xl' &&
    css`
      font-style: italic;
      font-family: 'Gotham';
    `};

  ${({ $marginBottom }) =>
    $marginBottom &&
    css`
      margin-bottom: ${$marginBottom}rem;
    `};
`;

export const Sauces = styled.div`
  display: flex;
  margin-top: 40rem;
`;

export const Sauce = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20rem;

  ${mediaTablet(css`
    margin: 0 35rem;
  `)}
`;
