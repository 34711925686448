import styled, { css } from 'styled-components';

import { InventoryItemProps } from 'components/InventoryItem/InventoryItem';
import { glowAnimation } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

import { BaseSauce, Sauce, SauceFormula } from 'constants/sauces';

export const Wrapper = styled.div<{
  $size?: InventoryItemProps['size'];
  $hasGlowEffect?: boolean;
}>`
  position: relative;
  aspect-ratio: 1 / 1;
  background: ${colors.black};
  width: 140rem;
  height: 140rem;
  user-select: none;
  pointer-events: none;
  border-radius: 50%;

  ${({ $size }) =>
    $size === 'lg' &&
    css`
      ${mediaTablet(css`
        width: 292rem;
        height: 292rem;
      `)};
    `};

  ${({ $size }) =>
    $size === 'responsive' &&
    css`
      width: 100%;
      height: 100%;

      ${mediaTablet(css`
        width: 100%;
        height: 100%;
      `)};
    `};

  ${({ $hasGlowEffect }) => $hasGlowEffect && glowAnimation(true)};
`;

export const Img = styled.img<{
  $mixBlendMode?: string;
}>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  mix-blend-mode: ${({ $mixBlendMode = 'normal' }) => `${$mixBlendMode}`};
  user-select: none;
`;

export const Pie = styled.div<{
  formula?: SauceFormula;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  ${({ formula = Sauce[0].formula }) => {
    const [baseSauce1, baseSauce2, baseSauce3] = formula?.split('-');
    const color1 = BaseSauce.find(
      sauce => sauce.name === baseSauce1
    ).colorByName;
    const color2 = BaseSauce.find(
      sauce => sauce.name === baseSauce2
    ).colorByName;
    const color3 = BaseSauce.find(
      sauce => sauce.name === baseSauce3
    ).colorByName;
    return css`
      background: radial-gradient(
          circle closest-side,
          transparent 100%,
          white 0
        ),
        conic-gradient(
          from 60deg,
          ${color2} 0,
          ${color2} 33.3%,
          ${color3} 0,
          ${color3} 66.6%,
          ${color1} 0,
          ${color1} 99.9%
        );
    `;
  }}
  mix-blend-mode: hard-light;
  clip-path: circle(48% at 50% 50%);
`;
