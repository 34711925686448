import { createGlobalStyle, css } from 'styled-components';

import { setTypography } from './mixins';
import resetCSS from './reset';
import { mediaTablet, setScalableFontSize } from './responsive';
import { colors } from './theme';

const style = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    user-select: text;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ::selection {
    background: ${colors.black};
    color: ${colors.white};
    text-shadow: none;
  }

  ::-moz-selection {
    background: ${colors.black};
    color: ${colors.white};
    text-shadow: none;
  }

  html,
  body {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
  }

  ._dK {
    > div:nth-child(2) {
      display: none !important;
    }
    > div:nth-child(3) {
      display: none !important;
    }
    > div:nth-child(4) {
      display: none !important;
    }
    > div:nth-child(5) {
      display: none !important;
    }
  }

  #_evh-link {
    display: none !important;
  }

  #evidon-gdpr-overlay {
    color: black !important;
  }

  #_evidon_banner {
    padding: 20rem 60rem 25rem !important;
    background-color: #060821 !important;
    width: 300px !important;
    height: auto !important;
    right: 30px !important;
    bottom: 30px !important;
    border-radius: 30px !important;
    border: none !important;
    display: flex !important;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 800px) {
      left: 0 !important;
      right: 0 !important;
      margin: 0 auto !important;
    }

    ::after {
    }

    ::before {
      content: 'COOKIE POLICY';
      width: 100%;
      margin: 35rem 0 30rem;
      pointer-events: none;
      font-family: 'Gotham';
      font-style: italic;
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #f8e600;
      text-shadow: 0px 2px 0px #000000;
    }

    #_evidon-title,
    #_evidon-message {
      font-family: 'Knockout' !important;
      font-style: normal !important;
      font-weight: 300 !important;
      font-size: 14px !important;
      line-height: 110% !important;
      color: #f9f3e2 !important;
      text-shadow: 0px 1px 0px #000000 !important;
      letter-spacing: 0.02em !important;
      text-align: center !important;
      margin: 0 0 35rem !important;
    }

    #_evidon-message {
      margin: 0 0 55rem !important;
    }

    br {
      display: none;
    }

    a:first-of-type {
      cursor: default !important;
    }

    a:last-of-type {
      cursor: pointer !important;
      text-decoration: underline;
      text-underline-offset: 2px;
      color: #f8e600 !important;
      margin-left: 3px;
    }

    #_evidon-accept-button,
    #_evidon-decline-button,
    #_evidon-option-button {
      background: linear-gradient(
        179.45deg,
        #f8e600 0.48%,
        #c8102e 134.57%
      ) !important;
      box-shadow: 0px 4px 0px #000000, 0px -2px 0px #ffffff !important;
      border-radius: 100px !important;
      font-family: 'Knockout' !important;
      font-weight: 300 !important;
      font-size: 18px !important;
      line-height: 100% !important;
      text-align: center !important;
      letter-spacing: 0.02em !important;
      text-transform: uppercase !important;
      color: #000000 !important;
      text-shadow: -1px 0px 0px #f8e600 !important;
      padding: 10px 27px !important;
      margin: 0 auto 35rem auto !important;
    }

    #_evh-ric-c {
      position: aboslute;
      left: 262px !important;
      top: 22px !important;

      svg {
        height: 17px;
        width: 17px;
      }
      svg path {
        stroke: #ffffff !important;
      }
    }
  }

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  ${mediaTablet(css`
    * {
      scrollbar-width: thin;
      scrollbar-color: #ff0000 #eeff00;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
      width: 9px;
    }

    *::-webkit-scrollbar-track {
      background: #eeff00;
    }

    *::-webkit-scrollbar-thumb {
      background-color: #ff0000;
      border-radius: 5px;
      border: 3px groove #ffffff;
    }
  `)}

  .grecaptcha-badge {
    visibility: hidden;
  }

  #__next {
    overflow-x: hidden;
  }

  html {
    ${setScalableFontSize()}
    position: absolute;
    height: -webkit-fill-available;
  }

  body {
    background: ${colors.bigStone};
    color: ${colors.rumSwizzle};
    ${({ theme: { locale } }) => setTypography('body1', locale)};
    min-height: -webkit-fill-available;
  }

  button,
  input,
  textarea,
  select {
    -webkit-appearance: none;
    background: none;
    font-family: inherit;
    border: none;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-transform: inherit;
    outline: none;
    border-radius: 0;
  }

  svg {
    outline: none;
    display: block;
  }

  button,
  div,
  a,
  span {
    -webkit-tap-highlight-color: transparent;
  }

  a {
    color: inherit;
  }

  button {
    cursor: pointer;

    :disabled {
      cursor: unset;
    }
  }

  #onetrust-consent-sdk {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #ot-sdk-btn-floating.ot-floating-button button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #onetrust-banner-sdk,
  #onetrust-pc-sdk {
    font-size: initial !important;
  }
`;

export default createGlobalStyle`
  ${resetCSS}
  ${style}
`;
