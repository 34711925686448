import { MutableRefObject, useCallback, useEffect } from 'react';

import { PopupProps } from './Popup';

export const usePopupBehavior = (
  ref: MutableRefObject<any>,
  onClose: () => void
) => {
  const handleKeyboardEvents = useCallback(
    (event: KeyboardEvent) => {
      const pressingEscape = event.key === 'Escape' || event.keyCode === 27;
      const pressingShift = event.shiftKey;
      const pressingTab = event.key === 'Tab' || event.keyCode === 9;

      if (pressingEscape) {
        onClose && onClose();
      } else if (pressingTab || pressingShift) {
        const elements =
          ref?.current?.querySelectorAll(
            [
              'a',
              'button:not([disabled])',
              'details',
              'input:not([readonly])',
              'select',
              'textarea',
              '[tabindex]:not([tabindex^="-"])',
            ].join(',')
          ) ?? [];

        if (elements.length === 0) {
          return;
        }

        const firstElement = elements[0];
        const lastElement = elements[elements?.length - 1];
        // Rotate to the top of focusable list
        if (pressingTab && !pressingShift && event.target === lastElement) {
          event.preventDefault();
          (firstElement as HTMLElement)?.focus();
        }
        // Rotate to the bottom of focusable list
        if (pressingShift && pressingTab && event.target === firstElement) {
          event.preventDefault();
          (lastElement as HTMLElement)?.focus();
        }
      }
    },
    [onClose, ref]
  );

  useEffect(() => {
    ref?.current?.focus();
    document.addEventListener('keydown', handleKeyboardEvents);

    return () => {
      document.removeEventListener('keydown', handleKeyboardEvents);
    };
  }, [handleKeyboardEvents, ref]);
};

export const getPopupAnimation = (
  direction: PopupProps['animationDirection']
) => {
  const property = direction === 'top' || direction === 'bottom' ? 'y' : 'x';
  const valueMark = direction === 'bottom' || direction === 'right' ? '-' : '';

  return {
    initial: { [property]: `${valueMark}100%` },
    animate: {
      [property]: '0%',
      transition: {
        [property]: { duration: 0.6, ease: [0.33, 1, 0.68, 1] },
      },
    },
    exit: {
      [property]: `${valueMark}100%`,
      transition: {
        delay: 0.075,
        duration: 0.4,
      },
    },
  };
};
