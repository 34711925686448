import { SAUCE_ME } from 'types/user';

import { errorMe } from './logger';

import { BaseSauce, Sauce } from 'constants/sauces';

export const countBaseSauces = (sauces: Array<SAUCE_ME>): number =>
  sauces.reduce((total, sauce) => total + sauce.count, 0);

export const enoughSaucesToMake = (
  userSauces: Array<SAUCE_ME>,
  formula: string
): boolean => {
  const names = formula.split('-');

  // Create a map to hold the count of each type of sauce in the formula
  const nameCounts: { [key: string]: number } = {};
  for (const name of names) {
    if (nameCounts[name]) {
      nameCounts[name]++;
    } else {
      nameCounts[name] = 1;
    }
  }

  // Check if there are enough sauces of each type
  for (const sauce of userSauces) {
    if (nameCounts[sauce.name] && nameCounts[sauce.name] > sauce.count) {
      return false;
    }
  }

  return true;
};

// Client request to change BBQ to AWESOME
// in DB will still remain as BBQ
export const getSauceNewName = data =>
  data.name === 'BBQ' ? 'AWESOME' : data.name;

export const getSauceRarity = data =>
  ({ NICE: 'RARE', SECRET: 'EPIC' }[data.name.toUpperCase()] || 'COMMON');

export const getSuperSauceNameWhereName = sauceName =>
  Sauce.find(data => data.name === sauceName).name;

export const getSauceDataWhereName = sauceName =>
  Sauce.find(data => data.name === sauceName);

export const getBaseSauceDataWhereName = sauceName =>
  BaseSauce.find(data => data.name === sauceName);

export const getSuperSauceWhereFormula = (formula: string) =>
  formula
    ? Sauce.find(data =>
        areArraysEqual(formula.split('-'), data.formula.split('-'))
      )
    : () => {
        errorMe('Error getSuperSauceWhereFormula', formula);
        return null;
      };

export const getSuperSauceWhereSauces = (sauces: any[]) =>
  Sauce.find(data => areArraysEqual(sauces, data.formula.split('-')));

const areArraysEqual = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  arr1.sort();
  arr2.sort();

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};
