import { useRouter } from 'next/router';
import React from 'react';

import Error from 'components/Error/Error';
import { useCopyStore } from 'store';
import { ROUTES } from 'utils/routes';

const NotFound = () => {
  const router = useRouter();
  const copy = useCopyStore(({ copy }) => copy.global.errors.notFound);

  const onClick = () => {
    router.push(ROUTES.HOME);
  };

  return (
    <Error
      displayVariant="container"
      title={copy.title}
      titleSize="lg"
      body={copy.body}
      cta={{
        label: copy.cta,
        onClick: onClick,
      }}
    />
  );
};

export default NotFound;
