export const loadDynamicScript = data => {
  const script = document.createElement('script');
  script.src = data.src;
  if (data.id) script.id = data.id;
  if (data.channel) script.setAttribute('data-channel', data.channel);
  if (data.crossOrigin) script.setAttribute('crossOrigin', data.crossOrigin);
  if (data.async) script.setAttribute('async', data.async);
  else script.async = true;
  script.src = data.src;
  document.head.appendChild(script);
  if (data.callback) data.callback();
};
