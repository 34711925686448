import styled, { css } from 'styled-components';

import { focus, setTextStyles, setTypography } from 'utils/styles/mixins';
import {
  MediaContent,
  mediaMobile,
  mediaTablet,
} from 'utils/styles/responsive';

import { ButtonProps, ButtonVariant, GenericButtonType } from './Button';

const onSmall = (small: ButtonProps['small'], content: MediaContent) => css`
  ${small === 'on-mobile'
    ? mediaMobile(
        css`
          ${content}
        `
      )
    : small === 'on-desktop'
    ? mediaTablet(
        css`
          ${content}
        `
      )
    : small === true &&
      css`
        ${content}
      `}
`;

export const IconWrapper = styled.div`
  position: absolute;
  height: 55rem;
  width: 55rem;
  left: 60rem;
  top: 0;
  bottom: 0;
  margin: auto 0;

  svg {
    height: 100%;
    width: 100%;
  }
`;

export const Label = styled.span``;

interface WrapperProps {
  $variant: GenericButtonType['variant'];
  $small: ButtonProps['small'];
  $iconPosition: ButtonProps['iconPosition'];
  $hasLabel: boolean;
  $textAlign: ButtonProps['textAlign'];
  $uppercase: ButtonProps['uppercase'];
  $paddingSize: ButtonProps['paddingSize'];
  $wide: ButtonProps['wide'];
}

const sharedTooltipStyles = css`
  position: relative;
  height: 40rem;
  width: 40rem;
  left: unset;
  right: unset;
  margin-top: -4rem;
  margin-left: 10rem;

  ${mediaMobile(css`
    height: 40rem;
    width: 40rem;
    left: unset;
    right: unset;
  `)}
`;

export const Wrapper = styled.button<WrapperProps>`
  ${({ theme: { locale } }) => setTypography('input', locale)};
  color: ${({ theme }) => theme.colors.black};
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;

  &:disabled {
    cursor: unset;
    pointer-events: none;
  }

  ${({
    $variant,
    theme,
    $small,
    $hasLabel,
    $textAlign,
    $iconPosition,
    $paddingSize,
    $wide,
  }) =>
    $variant === ButtonVariant.PRIMARY &&
    css`
      padding: 36rem 164rem;
      border-radius: 200rem;
      background: linear-gradient(
        180deg,
        ${theme.colors.turbo} 0%,
        ${theme.colors.crimson} 135%
      );
      text-shadow: -2rem 0px 0px ${theme.colors.turbo};
      box-shadow: 0rem 12rem 0rem ${theme.colors.black},
        0rem -4rem 0rem ${theme.colors.white};

      &:not([disabled]) {
        &:hover {
          background: ${theme.colors.turbo};
        }

        &:active {
          background: linear-gradient(
            0deg,
            ${theme.colors.turbo} 0%,
            ${theme.colors.crimson} 135%
          );
        }

        ${focus(css`
          outline: 4rem solid ${theme.colors.white};
        `)}
      }

      ${!$hasLabel &&
      css`
        padding: 0;
        width: 120rem;
        height: 120rem;
      `};

      ${$wide &&
      css`
        width: 160rem;
        height: 60rem;
      `}

      ${$paddingSize === 'sm' &&
      css`
        padding: 36rem 104rem 36rem 104rem;
      `};

      ${$paddingSize === 'xs' &&
      css`
        padding: 16rem 38rem 16rem 38rem;
      `};

      ${onSmall(
        $small,
        css`
          ${({ theme: { locale } }) => setTypography('body2', locale)};
          padding: 24rem 94rem;
          box-shadow: 0rem 8rem 0rem ${theme.colors.black},
            0rem -2rem 0rem ${theme.colors.white};

          ${$paddingSize === 'sm' &&
          css`
            padding: 20rem 64rem 20rem 64rem;
          `};

          ${$paddingSize === 'xs' &&
          css`
            padding: 12rem 24rem 12rem 24rem;
          `};

          ${!$hasLabel &&
          css`
            padding: 0;
            width: 96rem;
            height: 96rem;
          `}

          ${$wide &&
          css`
            width: 70rem;
            height: 40rem;
          `}
        `
      )}

      ${$textAlign === 'space-between' &&
      ($iconPosition === 'left'
        ? css`
            ${Label} {
              margin: 0 -100rem 0 100rem;

              ${onSmall(
                $small,
                css`
                  margin: 0 -50rem 0 50rem;
                `
              )};
            }
          `
        : $iconPosition === 'right' &&
          css`
            ${Label} {
              margin: 0 100rem 0 -100rem;

              ${onSmall(
                $small,
                css`
                  margin: 0 50rem 0 -50rem;
                `
              )};
            }
          `)};

      &:disabled {
        opacity: 0.3;
      }
    `};

  ${({
    $variant,
    theme,
    $small,
    $hasLabel,
    $textAlign,
    $iconPosition,
    $paddingSize,
  }) =>
    $variant === ButtonVariant.SECONDARY &&
    css`
      padding: 36rem 164rem;
      border-radius: 200rem;
      background: ${theme.colors.rumSwizzle};
      text-shadow: -2rem 0px 0px ${theme.colors.white};
      box-shadow: 0rem 12rem 0rem ${theme.colors.black},
        0rem -4rem 0rem ${theme.colors.white};

      &:not([disabled]) {
        &:hover {
          background: ${theme.colors.turbo};
        }

        ${focus(css`
          outline: 4rem solid ${theme.colors.punch};
        `)}
      }

      ${!$hasLabel &&
      css`
        padding: 0;
        width: 120rem;
        height: 120rem;
      `};

      ${$paddingSize === 'sm' &&
      css`
        padding: 36rem 104rem 36rem 104rem;
      `};

      ${$paddingSize === 'xs' &&
      css`
        padding: 16rem 38rem 16rem 38rem;
      `};

      ${onSmall(
        $small,
        css`
          ${({ theme: { locale } }) => setTypography('body2', locale)};
          padding: 24rem 94rem;
          box-shadow: 0rem 8rem 0rem ${theme.colors.black},
            0rem -2rem 0rem ${theme.colors.white};

          ${$paddingSize === 'sm' &&
          css`
            padding: 20rem 64rem 20rem 64rem;
          `};

          ${$paddingSize === 'xs' &&
          css`
            padding: 12rem 24rem 12rem 24rem;
          `};

          ${!$hasLabel &&
          css`
            padding: 0;
            width: 96rem;
            height: 96rem;
          `}
        `
      )};

      ${$textAlign === 'space-between' &&
      ($iconPosition === 'left'
        ? css`
            ${Label} {
              margin: 0 -100rem 0 100rem;

              ${onSmall(
                $small,
                css`
                  margin: 0 -50rem 0 50rem;
                `
              )};
            }
          `
        : $iconPosition === 'right' &&
          css`
            ${Label} {
              margin: 0 100rem 0 -100rem;

              ${onSmall(
                $small,
                css`
                  margin: 0 50rem 0 -50rem;
                `
              )};
            }
          `)};

      &:disabled {
        opacity: 0.3;
      }
    `};

  ${({ $variant, theme, $paddingSize, $small }) =>
    ($variant === ButtonVariant.LINK ||
      $variant === ButtonVariant.LINK_SECONDARY) &&
    css`
      ${({ theme: { locale } }) => setTypography('caption', locale)};
      padding: 18rem 20rem; // when changed adjust underline (in ::after)
      color: ${theme.colors.turbo};
      text-shadow: 0rem 4rem 0rem ${theme.colors.black};

      &::after {
        content: '';
        position: absolute;
        left: 20rem;
        width: calc(100% - 40rem);
        box-shadow: 0rem 4rem 0rem ${theme.colors.black};
        bottom: 29%;
        height: 3rem;
        background: ${theme.colors.turbo};
      }

      &:not([disabled]) {
        &:hover {
          color: ${theme.colors.gamboge};

          &::after {
            background: ${theme.colors.gamboge};
          }
        }

        ${focus(css`
          color: ${theme.colors.gamboge};

          &::after {
            background: ${theme.colors.gamboge};
          }
        `)}
      }

      &:disabled {
        opacity: 0.3;
      }

      ${onSmall(
        $small,
        css`
          ${({ theme: { locale } }) => setTypography('caption2', locale)};
        `
      )};

      ${$paddingSize === 'sm' &&
      css`
        padding: 12rem 20rem;

        &::after {
          display: inline-block;
          left: 20rem;
          width: calc(100% - 40rem);
          bottom: 25%;
        }
      `};

      ${$paddingSize === 'xs' &&
      css`
        padding: 0;

        &::after {
          display: inline-block;
          left: 0;
          width: 100%;
          bottom: 6%;
        }
      `};

      ${$variant === ButtonVariant.LINK_SECONDARY &&
      css`
        color: ${({ theme }) => theme.colors.rumSwizzle};

        &::after {
          background: ${({ theme }) => theme.colors.rumSwizzle};
        }

        &:not([disabled]) {
          &:hover {
            color: ${({ theme }) => theme.colors.turbo};

            &::after {
              background: ${({ theme }) => theme.colors.turbo};
            }
          }

          ${focus(css`
            color: ${({ theme }) => theme.colors.turbo};

            &::after {
              background: ${({ theme }) => theme.colors.turbo};
            }
          `)}
        }
      `}
    `};

  ${({ $textAlign, $iconPosition }) =>
    $textAlign === 'space-between' &&
    ($iconPosition === 'left'
      ? css`
          justify-content: flex-end;
        `
      : $iconPosition === 'right' &&
        css`
          justify-content: flex-start;
        `)};

  ${({ $iconPosition, $hasLabel, $textAlign, $small }) =>
    $iconPosition === 'left' &&
    $hasLabel &&
    $textAlign === 'center' &&
    css`
      ${Label} {
        margin: 0 -42rem 0 42rem;

        ${onSmall(
          $small,
          css`
            margin: 0 -28rem 0 32rem;
          `
        )}
      }
    `};

  ${({ $iconPosition, $hasLabel, $textAlign, $small }) =>
    $iconPosition === 'right' &&
    $hasLabel &&
    $textAlign === 'center' &&
    css`
      ${Label} {
        margin: 0 42rem 0 -42rem;

        ${onSmall(
          $small,
          css`
            margin: 0 32rem 0 -28rem;
          `
        )}
      }
    `};

  ${IconWrapper} {
    ${({ $iconPosition, $hasLabel }) =>
      $iconPosition === 'right' &&
      $hasLabel &&
      css`
        left: auto;
        right: 60rem;
      `};

    ${({ $small }) =>
      onSmall(
        $small,
        css<{ $iconPosition: ButtonProps['iconPosition']; $hasLabel: boolean }>`
          height: 44rem;
          width: 44rem;
          left: 32rem;

          ${({ $iconPosition, $hasLabel }) =>
            $iconPosition === 'right' &&
            $hasLabel &&
            css`
              left: auto;
              right: 32rem;
            `};
        `
      )};

    ${({ $hasLabel }) =>
      !$hasLabel &&
      css`
        left: 0 !important;
        right: 0 !important;
        margin: auto;
      `};
  }

  ${({ $uppercase }) =>
    $uppercase &&
    css`
      text-transform: uppercase;
    `};

  ${({ $variant }) =>
    $variant === ButtonVariant.TOOLTIP &&
    css`
      ${({ theme: { locale } }) => setTypography('caption3', locale)};
      ${setTextStyles('rumSwizzle', 'md')};
      padding: 18rem 20rem;

      ${IconWrapper} {
        color: ${({ theme }) => theme.colors.rumSwizzle};
        ${sharedTooltipStyles}
      }

      &:not([disabled]) {
        &:hover {
          ${IconWrapper} {
            color: ${({ theme }) => theme.colors.turbo};
          }
        }
      }
    `};

  ${({ $variant }) =>
    $variant === ButtonVariant.TOOLTIP_SECONDARY &&
    css`
      ${({ theme: { locale } }) => setTypography('caption3', locale)};
      ${setTextStyles('rumSwizzle', 'md')};
      padding: 18rem 20rem;

      ${IconWrapper} {
        color: ${({ theme }) => theme.colors.turbo};
        ${sharedTooltipStyles}
      }

      &:not([disabled]) {
        &:hover {
          ${IconWrapper} {
            color: ${({ theme }) => theme.colors.rumSwizzle};
          }
        }
      }
    `};
`;
