import styled from 'styled-components';

import imgLayoutBgBody from 'assets/images/layout-bg-body.png';
import imgLayoutBgBottom from 'assets/images/layout-bg-bottom.png';
import imgLayoutBgPattern from 'assets/images/layout-bg-pattern.png';
import imgLayoutBgTop from 'assets/images/layout-bg-top.png';
import svgBgPipe from 'svgs/layout/bg-pipe.svg';
import { aspectRatio } from 'utils/styles/mixins';

export const Wrapper = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: max(1932rem, 100%);
  align-items: flex-end;
  background-color: ${({ theme }) => theme.colors.gondola};
  background-size: 1932rem auto;
  background-position: right 0;
  background-repeat: repeat;
  background-image: url(${imgLayoutBgPattern});

  &::after {
    content: '';
    height: 100%;
    width: 40rem;
    position: absolute;
    right: -20rem;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.black};
    background-image: url(${svgBgPipe});
    background-size: 38rem 100%;
    background-repeat: no-repeat;
  }
`;

export const BackgroundElement = styled.div`
  background-size: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
`;

export const ElementTop = styled(BackgroundElement)`
  ${aspectRatio(2898, 886)};
  background-image: url(${imgLayoutBgTop});
  width: 100%;
`;

export const ElementBody = styled(BackgroundElement)`
  background-image: url(${imgLayoutBgBody});
  flex: 1;
  width: 100%;
  margin: -1rem 0;
  background-repeat: repeat-y;
`;

export const ElementBottom = styled(BackgroundElement)`
  background-image: url(${imgLayoutBgBottom});
  width: 100%;
  ${aspectRatio(2898, 886)};
`;
