import React, { useEffect } from 'react';

import Error from 'components/Error/Error';
import { API } from 'services/api/api';
import { logoutUserSession } from 'services/api/calls/userData';
import { useCopyStore } from 'store';

interface UnexpectedErrorProps {
  onClick?: () => void;
}

const UnexpectedErrorProps = ({ onClick }) => {
  const copies = useCopyStore(state => state.copy);

  useEffect(() => {
    logoutUserSession();
    try {
      process.env.ENV !== 'local' && API.magic.logout();
    } catch (error) {}
  }, []);

  if (!copies.global) return null;
  const {
    global: {
      errors: { unexpectedError: copy },
    },
  } = copies;

  return (
    <Error
      displayVariant="container"
      title={copy.title}
      body={copy.body}
      cta={{ label: copy.cta, onClick: onClick }}
    />
  );
};

export default UnexpectedErrorProps;
