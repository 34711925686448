import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import logo from 'assets/images/logo.png';
import logoMask from 'assets/images/logo-mask.png';
import useViewportWidth from 'hooks/useViewPortWidth';
import { useCopyStore, useGlobalStore, useUserStore } from 'store';
import { GlobalCopy } from 'store/copy.types';
import { Season } from 'store/global';
import GTM from 'u9/utils/gtm';
import { convertArrayToObject } from 'utils/objects';
import { ROUTES } from 'utils/routes';
import { breakpointTablet } from 'utils/styles/vars';

import NavbarLink from './NavbarLink/NavbarLink';
import NavbarMobileMenu from './NavbarMobileMenu/NavbarMobileMenu';

import * as Styled from './Navbar.styles';

const linksOrder: Record<
  Season,
  {
    logged: Array<keyof GlobalCopy['navbar']['links']>;
    notLogged: Array<keyof GlobalCopy['navbar']['links']>;
  }
> = {
  [Season.ZERO]: {
    logged: ['about', 'roadmap', 'discord'],
    notLogged: ['about', 'roadmap', 'discord'],
  },
  [Season.ONE]: {
    logged: ['barcode', 'lab', 'gallery', 'about'],
    notLogged: ['gallery', 'about'],
  },
};

export const hrefs: Partial<
  Record<keyof GlobalCopy['navbar']['links'], string>
> = {
  lab: ROUTES.LAB,
  about: ROUTES.ABOUT,
  roadmap: ROUTES.ROADMAP,
  // signUp: ROUTES.LOGIN,
  // account: ROUTES.MY_ACCOUNT,
  metaverse: ROUTES.LAB_MEATERIALIZER,
  gallery: ROUTES.GALLERY,
  barcode: ROUTES.LAB_BARCODE_SCANNER,
  labMeaterializer: ROUTES.LAB_MEATERIALIZER,
  labSauceMixer: ROUTES.LAB_SAUCE_MIXER,
  labInventory: ROUTES.LAB_INVENTORY,
  faq: ROUTES.FAQ,
};

export interface NavbarProps {
  onlyBar?: boolean;
  glowingElement?: {
    id: string;
    tooltip: string;
  };
}

const Navbar: React.FC<NavbarProps> = ({ onlyBar, glowingElement }) => {
  const { isNavbarCentered, season, onSkipCallback } = useGlobalStore();
  const { isLoggedIn, tutorialStatus, userData } = useUserStore();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const copy = useCopyStore(({ copy }) => copy.global.navbar);
  const isDesktopView = useViewportWidth(breakpointTablet);
  const router = useRouter();

  const shouldShowBarcodeItem =
    tutorialStatus.isAllFinished ||
    (tutorialStatus.isFinished && userData?.userStats?.evolutionsMade > 0);

  const linksCopy = convertArrayToObject(
    Object.entries(copy.links).map(([key, value]) => ({
      ...value,
      href: hrefs[key] || value.href || '#',
      objKey: key,
    })),
    'objKey'
  ) as GlobalCopy['navbar']['links']; // Links with the correct hrefs

  const currentLinksSet = (
    isLoggedIn ? linksOrder[season].logged : linksOrder[season].notLogged
  ).filter(link => shouldShowBarcodeItem || link !== 'barcode');

  useEffect(() => {
    if (isDesktopView) {
      setIsMenuOpened(false);
    }
  }, [isDesktopView]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (!isDesktopView) {
        setIsMenuOpened(false);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [isDesktopView, router]);

  return (
    <>
      <Styled.Wrapper $isNavbarCentered={isNavbarCentered}>
        <Styled.LogoWrapper
          href={ROUTES.HOME}
          onClick={() => setIsMenuOpened(false)}
        >
          <Styled.LogoMask src={logoMask} alt="Fire behind the logo" />
          <Styled.Logo src={logo} alt="SlimJim logo" />
        </Styled.LogoWrapper>
        {!onlyBar && (
          <nav>
            <Styled.ListDesktop>
              {currentLinksSet.map(key => {
                const { label, icon, href, children } = linksCopy[key];

                return (
                  <NavbarLink
                    key={key}
                    id={key}
                    label={label}
                    icon={icon}
                    onClick={() => {
                      if (children === undefined)
                        GTM.trackEvent('Header', 'Click', label);
                    }}
                    href={href}
                    nestedLinks={children}
                    hasGlowEffect={glowingElement?.id === key}
                    tooltip={glowingElement?.tooltip}
                  />
                );
              })}
            </Styled.ListDesktop>
            <Styled.ListMobile>
              {onSkipCallback && (
                <NavbarLink
                  label="Skip"
                  onClick={onSkipCallback}
                  icon="logout"
                  iconAsSvg
                  iconOnRightSide
                />
              )}
              <NavbarLink
                icon={isMenuOpened ? '_close' : '_menu'}
                iconAsSvg
                onClick={() => setIsMenuOpened(prev => !prev)}
                hasGlowEffect={glowingElement?.id === '_menu' && !isMenuOpened}
                tooltip={glowingElement?.tooltip}
              />
            </Styled.ListMobile>
          </nav>
        )}
      </Styled.Wrapper>
      <AnimatePresence>
        {isMenuOpened && !onlyBar && (
          <Styled.MobileMenuWrapper>
            <NavbarMobileMenu
              links={currentLinksSet.map(key => linksCopy[key])}
              onClickLink={() => setIsMenuOpened(false)}
            />
          </Styled.MobileMenuWrapper>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navbar;
