import { FC } from 'react';

import Popup from 'components/Popup/Popup';
import { NewCircuitVariant } from 'utils/assets';

export enum ErrorPopupVariant {
  ACCOUNT = 'ACCOUNT',
  DATABASE = 'DATABASE',
  TRANSACTION = 'TRANSACTION',
}

const errorPopupVariants = {
  [ErrorPopupVariant.ACCOUNT]: NewCircuitVariant.STATIC_ERROR_ACCOUNT,
  [ErrorPopupVariant.DATABASE]: NewCircuitVariant.STATIC_ERROR_DATABASE,
  [ErrorPopupVariant.TRANSACTION]: NewCircuitVariant.STATIC_ERROR_TRANSACTION,
};

export interface ErrorPopupProps {
  variant: ErrorPopupVariant;
  onClose: () => void;
}

const ErrorPopup: FC<ErrorPopupProps> = ({ children, variant, onClose }) => {
  return (
    <Popup
      variant={errorPopupVariants[variant]}
      hasCloseButton
      onClose={onClose}
      minHeight={[1400, 1500]}
    >
      {children}
    </Popup>
  );
};

export default ErrorPopup;
