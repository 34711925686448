import parseAPNG from 'apng-js';
import Player from 'apng-js/types/library/player';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { BackgroundResponsiveType } from 'components/NewCircuit/NewCircuit.data';
import { useGlobalStore } from 'store';

import {
  getArrayBufferImage,
  getImageSource,
  getRandom,
} from './ApngAnimation.utils';

import * as Styled from './ApngAnimation.styles';

export interface ApngAnimationProps {
  src: string | BackgroundResponsiveType;
  className?: string;
  infinite?: boolean;
  delayBetweenIteration?: number | 'random';
}

const MIN_DELAY = 4000;
const MAX_DELAY = 14000;

const ApngAnimation: FC<ApngAnimationProps> = ({
  src,
  className,
  infinite = false,
  delayBetweenIteration = 'random',
}) => {
  const apngPlayerRef = useRef<Player>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const timeoutRef = useRef<NodeJS.Timer>(null);
  const { isDesktopView } = useGlobalStore();
  const [size, setSize] = useState({ width: 100, height: 100 });

  const handleAnimationEnd = useCallback(() => {
    if (!infinite && delayBetweenIteration) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      const nextDelay =
        delayBetweenIteration === 'random'
          ? getRandom(MIN_DELAY, MAX_DELAY)
          : delayBetweenIteration;

      timeoutRef.current = setTimeout(() => {
        apngPlayerRef.current.play();
      }, nextDelay);
    }
  }, [delayBetweenIteration, infinite]);

  useEffect(() => {
    if (!apngPlayerRef.current && src) {
      getArrayBufferImage(getImageSource(src, isDesktopView)).then(img => {
        const apng = parseAPNG(img);

        if (!(apng instanceof Error)) {
          apng.numPlays = infinite ? 0 : 1;
          const canvas = canvasRef?.current;
          const ctx = canvas?.getContext('2d');

          if (ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            canvas.width = apng.width;
            canvas.height = apng.height;
            setSize({ width: canvas.width, height: canvas.height });

            apng.getPlayer(ctx, true).then(player => {
              apngPlayerRef.current = player;
              player.addListener('end', handleAnimationEnd);
            });
          }
        }
      });
    }

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [handleAnimationEnd, infinite, isDesktopView, src]);

  return <Styled.Canvas ref={canvasRef} className={className} $size={size} />;
};

export default ApngAnimation;
