import { RefObject } from 'react';

import { LabRoomStage } from 'components/LaboratoryS1/LabEnum';
import { ScannerState } from 'components/Scanner/Scanner';
import { PFP_ITEM } from 'types/pfp';
import { USER_ME } from 'types/user';
import GTM, { GTM_CATEGORY } from 'u9/utils/gtm';
import { errorMe, logMe } from 'utils/logger';
import { pushLoginRoute, ROUTES } from 'utils/routes';
import {
  getSauceDataWhereName,
  getSuperSauceWhereFormula,
  getSuperSauceWhereSauces,
} from 'utils/sauces';

import { API } from '../api';

import { LoginStep } from 'constants/enum';
import { after10Tutorial, initialTutorial } from 'constants/global';
import { testTutorial } from 'constants/testItem';

export const saveUserSession = async (
  didToken = '123',
  email = '',
  tutorialStatus = testTutorial
) => {
  API.getI.saveUserSession(didToken, email, tutorialStatus);
};

export const logoutUserSession = async () => {
  await fetch('/api/logout', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
};

export const addUnwrapAchivment = async setUnexpectedError => {
  const req = await API.getI.achievementsUnwrap();

  if (process.env.ENV !== 'local') {
    if (req.status >= 300) {
      setUnexpectedError(true);
      return;
    }
  }
};

interface fetchUserDataProps {
  setIsLoggedIn?: (status) => void;
  setUserData?: (status) => void;
  setUserSauces?: (status) => void;
  setTutorialStatus?: (status) => void;
  setUnexpectedError?: (status) => void;
  setMounted?: (status) => void;
  user?: any;
  router?: any;
}

export const fetchUserData = async ({
  setIsLoggedIn,
  setUserData,
  setUserSauces,
  setTutorialStatus,
  setUnexpectedError,
  setMounted,
  user,
  router,
}: fetchUserDataProps) => {
  // user from local session
  if (user) API.user.update(user);

  const req = await API.getI.getUser();
  logMe('fetchUserData');
  logMe(req);
  if (req.status >= 300) {
    setUnexpectedError(true);
    return;
  }

  const newData: USER_ME = req.data;

  // totally fresh user - generate pfp for him
  if (
    !req.data.hasAlreadyGeneratedInitialPfp &&
    req.data.createdAfterLimitReached === false
  ) {
    logMe('new guy mint NFT');
    const reqPfp = await API.getI.generatePFP();
    if (reqPfp.status >= 300) {
      setUnexpectedError(true);
      return;
    }

    // Save the object string in localStorage
    localStorage.setItem('SlimJimTmpPFP', JSON.stringify(reqPfp.data));
    logMe('saveNFT to storage', reqPfp.data);

    newData.pfps.push(reqPfp.data);
  }

  let tutorialData =
    newData.tutorialProgress !== null
      ? newData.tutorialProgress
      : initialTutorial;

  if (req.data.createdAfterLimitReached) {
    tutorialData = after10Tutorial;
  }

  // edge cases with NFT not available yet because it's still minting
  if (
    user &&
    tutorialData.meatalizer !== LabRoomStage.FINISHED &&
    newData.pfps.length === 0
  ) {
    logMe('getTmpPFP', user);
    const getTmpPFP = JSON.parse(localStorage.getItem('SlimJimTmpPFP'));
    if (getTmpPFP) newData.pfps.push(getTmpPFP);
  }
  // new active pfp in case of new mint
  if (req.data.activePfpId === null && req.data.pfps.length > 0) {
    await API.getI.changeActivePfp(req.data.pfps[0].id);
  }

  setTutorialStatus(tutorialData);

  setUserData(newData);
  setUserSauces(newData.sauces);

  // store is logged in
  setIsLoggedIn(true);

  // saving session data
  saveUserSession(API.user.didToken, API.user.email, tutorialData);

  if (router) {
    if (newData.pfps.length === 0) {
      pushLoginRoute(router, ROUTES.HOME, LoginStep.TRANSFER_NFT);
    } else {
      if (setMounted) setMounted(true);
    }
  } else {
    if (setMounted) setMounted(true);
  }
};

interface scanCodeProps {
  code?: any;
  scannerStateRef?: RefObject<string>;
  codeFromInput?: boolean;
  image?: any;
  setCode?: (status) => void;
  setValid?: any;
  setBarcode?: (status) => void;
  setSent?: (status) => void;
  setItems?: (status) => void;
  setNewItemPopup?: (status) => void;
  setUnexpectedError?: (status) => void;
  setUserSauces?: any;
  setRewardType?: (rewardType) => void;
  setNumberOfScans?: (numberOfScans) => void;
  setAllowedDailyScans?: (allowedDailyScans) => void;
}

// DailyScansLimitReachedError
export const scanCode = async ({
  code,
  scannerStateRef,
  codeFromInput,
  image,
  setValid,
  setSent,
  setBarcode,
  setItems,
  setNewItemPopup,
  setUnexpectedError,
  setUserSauces,
  setRewardType,
  setNumberOfScans,
  setAllowedDailyScans,
}: scanCodeProps) => {
  logMe('scanCode', code, image);
  setBarcode('');
  setValid(true);

  const form = new FormData();
  code && form.append('barcode_upc', code);
  image && form.append('barcode_img', image);
  const req = await API.getI.scanBarcode(form);
  logMe(req);

  if (
    codeFromInput ||
    (!codeFromInput && scannerStateRef.current === ScannerState.SCANNER)
  ) {
    if (req.status > 300) {
      if (req.status === 400) {
        if (
          req.data?.code === 'DailyScansLimitReachedError' ||
          req.data?.code === 'SocialCodeExpiredError'
        )
          setBarcode(req.data.code);

        setValid(false);
        GTM.trackEvent(GTM_CATEGORY.Scanner, 'Scan', 'Daily Limit Reached');
      } else if (req.status === 429) setValid(false);
      else setUnexpectedError(true);
      setSent?.(false);
      return true;
    }

    if (req.data.code === 'BarcodeNotExistsError') {
      if (code) {
        setBarcode(req.data.code);
        GTM.trackEvent(GTM_CATEGORY.Scanner, 'Scan', 'Invalid Barcode');
      }

      setValid(false);
      setSent?.(false);
      return true;
    } else {
      GTM.trackEvent(GTM_CATEGORY.Scanner, 'Scan', 'Scan Succeded');
    }

    setBarcode(code || '0026200117003');
    setValid(true);
    setUserSauces(req.data.userSauces);
    setNumberOfScans(req.data.numberOfScans);
    setAllowedDailyScans(req.data.allowedDailyScans);
    setItems(
      ['BASE_SAUCE', 'TWO_BASE_SAUCES'].includes(req.data.rewardType)
        ? req.data.drawnSauces
        : [getSuperSauceWhereFormula(req.data.drawnSauces[0]).name]
    );

    setRewardType(req.data.rewardType);

    setTimeout(() => {
      setNewItemPopup(true);
    }, 2000);
  }

  return true;
};

export interface scanCodeQaProps {
  code?: any;
  image?: any;
  setValid?: any;
  setBarcode?: (status) => void;
  setSent?: (status) => void;
  setItems?: (status) => void;
  setNewItemPopup?: (status) => void;
  setUnexpectedError?: (status) => void;

  setRewardType?: (rewardType, rewardItems) => void;
}

export const mixSauces = async (
  sauces,
  setNewSauce,
  setUserSauces,
  setUnexpectedError
) => {
  const newSauce = getSuperSauceWhereSauces([...sauces]);
  errorMe('mixSauces newSauce ', newSauce);

  GTM.trackEvent(GTM_CATEGORY.Lab_Meaterializer, 'Mix', newSauce.name);

  const req = await API.getI.mixSauce(newSauce.formula.split('-'));
  logMe(req);

  if (req.status <= 300) {
    logMe('mixSauces ok');
    setUserSauces(req.data.userSauces);
    setNewSauce(getSuperSauceWhereFormula(req.data.newSauceType).name);
  } else {
    logMe('mixSauces error');
    setUnexpectedError(true);
  }
};

export const tutorialUpdate = async tutorialStatus => {
  const req = await API.getI.updateTutorialProgress(tutorialStatus);

  if (req.status <= 300) {
    logMe('tutorialUpdate ok');
  } else {
    logMe('tutorialUpdate error');
  }
};

export const monitorFirstNftInWallet = (
  setUnexpectedError,
  onFinish?: () => void
) => {
  logMe('monitorForFirst');
  const intervalId = setInterval(async () => {
    try {
      const req = await API.getI.getUser();
      logMe(req);
      if (req.status >= 300) {
        setUnexpectedError(true);
        clearInterval(intervalId);
        return;
      } else {
        if (req.data.pfps.length > 0) {
          logMe('NFT in wallet');
          clearInterval(intervalId);
          onFinish();
        }
      }
    } catch (e) {}
  }, 3000);
};

export const evolveWithSauce = async (
  activePfp: PFP_ITEM,
  userData: USER_ME,
  sauceName: string,
  setUserData: any,
  setUserSauces: any,
  onFinish?: () => void,
  onError?: () => void
) => {
  logMe('evolveWithSauce', userData, sauceName);
  logMe('evolveWithSauce', activePfp);

  const sauce = getSauceDataWhereName(sauceName)?.formula;

  const newSauceFormula = sauce?.split('-').sort().join('-') ?? null;
  // Given PFP is not ready! in case of error with SUS

  const activePfpId = activePfp?.id || userData.activePfpId;

  try {
    const req = await API.getI.evolvePfp(activePfpId, newSauceFormula);
    if (req.status === 200) {
      setUserSauces(req.data.sauces);
      setUserData({
        ...userData,
        sauces: req.data.sauces,
        userStats: {
          ...userData.userStats,
          evolutionsMade: userData.userStats.evolutionsMade + 1,
        },
        pfps: [req.data.pfp, ...userData.pfps.slice(1)],
      });
      onFinish && onFinish();
    } else {
      logMe('evolveWithSauce error');
      onError && onError();
    }
  } catch (error) {
    logMe('evolveWithSauce error', error);
    onFinish && onFinish();
  }
};
