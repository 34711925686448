import { AnimatePresence } from 'framer-motion';
import { GetStaticProps } from 'next';
import { Router } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';

import ErrorComponent from 'components/Error/Error';
import { useCopyStore, useGlobalStore } from 'store';
import { getCopy } from 'store/copy.data';
import { CopyStoreType } from 'store/copy.types';
import useWindowSize from 'u9/hooks/useWindowSize';
import { isDesktop, isStorybook, isSupportedBrowser } from 'u9/utils/platform';
import { Pages, ROUTES } from 'utils/routes';
import { breakpointDesktop, desktopMinHeight } from 'utils/styles/vars';

import JavaScriptDisabled from './JavaScriptDisabled/JavaScriptDisabled';
import NotFound from './NotFound/NotFound';
// import TransactionError from './popups/TransactionError/TransactionError';
import UnexpectedError from './UnexpectedError/UnexpectedError';
import UnsupportedBrowser from './UnsupportedBrowser/UnsupportedBrowser';
import WindowTooSmall from './WindowTooSmall/WindowTooSmall';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const { head } = getCopy(Pages.notFound, locale);

  return {
    props: {
      initialCopy: {
        head,
      },
    },
  };
};

interface NonFunctionalsProps {
  initialCopy: {
    head: CopyStoreType['copy']['head'];
    global: CopyStoreType['copy']['global'];
  };
  children: React.ReactNode | React.ReactNode[];
  isBypassed?: boolean;
  router: Router;
}

const NonFunctionals: React.FunctionComponent<NonFunctionalsProps> = ({
  initialCopy,
  children,
  isBypassed = false,
  router,
}) => {
  const [isMounted, setMounted] = useState(false);
  const [isRendered, setRendered] = useState(false);
  const [showUnsupportedBrowser, setShowUnsupportedBrowser] = useState(false);
  const [showUnsupportedSocial, setUnsupportedSocial] = useState(false);

  const hasMatchingRoute = useMemo(
    () =>
      Object.values(ROUTES).includes(
        router.route as typeof ROUTES[keyof typeof ROUTES]
      ) && router.route !== ROUTES.NOT_FOUND,
    [router.route]
  );

  const windowSize = useWindowSize(true);
  const { setCopy } = useCopyStore();
  const {
    isWindowTooSmall,
    setWindowTooSmall,
    isUnexpectedError,
    errorPopup,
    showErrorPopup,
  } = useGlobalStore();

  const errorComponent = useMemo(() => {
    if (showUnsupportedBrowser || showUnsupportedSocial) {
      return <UnsupportedBrowser />;
    } else if (!hasMatchingRoute) {
      return <NotFound />;
    }

    return null;
  }, [hasMatchingRoute, showUnsupportedBrowser, showUnsupportedSocial]);

  useEffect(() => {
    setMounted(true);

    if (process.env.IS_DEBUG && router.asPath.match(/^\/make-frontend-error/)) {
      throw new Error('Testing Sentry');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMounted) {
      setRendered(true);

      const { isSupported, fromSocial } = isSupportedBrowser();

      if (!isSupported && !fromSocial) {
        setShowUnsupportedBrowser(true);
      } else if (fromSocial) {
        setUnsupportedSocial(true);
      }
    }
  }, [isMounted]);

  useEffect(() => {
    const zoomLevel = process.env.ALLOW_USER_ZOOM
      ? windowSize.devicePixelRatio
      : 1;

    setWindowTooSmall(
      isDesktop() &&
        (windowSize.width < breakpointDesktop / zoomLevel ||
          windowSize.height < desktopMinHeight / zoomLevel)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  useEffect(() => {
    if (isStorybook() || !initialCopy) return;
    const { head, global } = initialCopy;

    // If this is an error page, set the shared copy
    if (errorComponent) {
      setCopy({ global });
    }

    // If this is the 404 page, update the head copy
    if (!hasMatchingRoute) {
      setCopy({ head });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorComponent, hasMatchingRoute]);

  if (isBypassed) return <>{children}</>;

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {errorComponent || children}
      </AnimatePresence>
      {!isRendered && (
        <noscript>
          <JavaScriptDisabled sharedCopy={{ global: initialCopy?.global }} />
        </noscript>
      )}
      <AnimatePresence>
        {isRendered && isWindowTooSmall && <WindowTooSmall />}
        {isRendered && isUnexpectedError && (
          <UnexpectedError
            onClick={() => (window.location.href = window.location.origin)}
          />
        )}
        {isRendered && errorPopup && (
          <ErrorComponent
            displayVariant="popup"
            variant={errorPopup.variant}
            onClose={() => {
              showErrorPopup(null);
            }}
            title={errorPopup?.title}
            body={errorPopup?.body}
            cta={errorPopup.cta}
            autoJustifyContent
          />
        )}

        {/* {<TransactionError />} */}
      </AnimatePresence>
    </>
  );
};

export default React.memo(NonFunctionals);
