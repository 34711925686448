import { FC } from 'react';

import { ButtonVariant } from 'components/Button/Button';
import InventoryItem, {
  InventoryItemKey,
} from 'components/InventoryItem/InventoryItem';
import Popup from 'components/Popup/Popup';
import { useCopyStore } from 'store';
import { NewCircuitVariant } from 'utils/assets';
import {
  getBaseSauceDataWhereName,
  getSauceDataWhereName,
  getSauceNewName,
} from 'utils/sauces';

import * as Styled from './NewItemPopup.styles';

import { ScanningBonus } from 'constants/enum';
import { BaseSauce } from 'constants/sauces';

export interface NewItemPopupProps {
  variant: ScanningBonus;
  sauces: Array<string>;
  onClose?: () => void;
  onContinue?: () => void;
}

const NewItemPopup: FC<NewItemPopupProps> = ({
  variant,
  sauces,
  onClose,
  onContinue,
}) => {
  const copy = useCopyStore(({ copy }) => copy.lab.scanner.newItemPopup);
  const titles = {
    [ScanningBonus.BASE_SAUCE]: copy.titles.base,
    [ScanningBonus.TWO_BASE_SAUCES]: copy.titles.twoBase,
    [ScanningBonus.COMMON_SUPER_SAUCE]: copy.titles.common,
    [ScanningBonus.EPIC_SUPER_SAUCE]: copy.titles.epic,
    [ScanningBonus.LEGENDARY_SUPER_SAUCE]: copy.titles.legendary,
    [ScanningBonus.RARE_SUPER_SAUCE]: copy.titles.rare,
  };

  return (
    <Popup
      variant={NewCircuitVariant.LAB_POPUP}
      hasCloseButton={false}
      onClose={onClose}
      minHeight={[1100, 1600]}
      key="mixed-sauce"
      animationDirection="top"
    >
      <Styled.Wrapper>
        <Styled.Title>{titles[variant]}</Styled.Title>
        <Styled.Label $size={'lg'} $marginBottom={28}>
          {copy.subtitle}
        </Styled.Label>
        <Styled.Sauces>
          {sauces.map(sauce => {
            const isBaseSauce = BaseSauce.map(({ name }) => name).includes(
              sauce
            );
            const actualSauce = isBaseSauce
              ? getBaseSauceDataWhereName(sauce)
              : getSauceDataWhereName(sauce);

            return (
              <Styled.Sauce key={sauce}>
                <InventoryItem
                  itemKey={'SAUCE' as InventoryItemKey}
                  size="lg"
                  sauceName={sauce}
                />
                <Styled.Label $size="xl">{`${getSauceNewName(actualSauce)}${
                  isBaseSauce ? ' SAUCE' : ''
                }`}</Styled.Label>
                <Styled.Label
                  $size={'lg'}
                  $color={'turbo'}
                  $marginBottom={43.59}
                >
                  {copy.rarity}: {actualSauce.rarity}
                </Styled.Label>
              </Styled.Sauce>
            );
          })}
        </Styled.Sauces>
        <Styled.Actions>
          <Styled.Cta
            label={copy.ctaContinue}
            onClick={onContinue}
            paddingSize="sm"
          />
          <Styled.Cta
            label={copy.ctaMeaterializer}
            onClick={onClose}
            variant={ButtonVariant.SECONDARY}
            paddingSize="sm"
          />
        </Styled.Actions>
      </Styled.Wrapper>
    </Popup>
  );
};

export default NewItemPopup;
