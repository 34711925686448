import NextHead from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';

import useWindowSize from 'u9/hooks/useWindowSize';
import { isAndroid, isInputFocused } from 'u9/utils/platform';
import { generateLinkToShare } from 'utils/share.utils';
import { colors } from 'utils/styles/theme';

export interface HeadProps {
  title: string;
  description: string;
  ogType: string;
  shareId: string;
  ogImage: {
    url: string;
  };
}

// eslint-disable-next-line prettier/prettier
const defaultViewport = `width=device-width, initial-scale=1.0, shrink-to-fit=no${
  process.env.ALLOW_USER_ZOOM
    ? ''
    : ',minimum-scale=1.0,maximum-scale=1.0,user-scalable=0'
}, viewport-fit=cover`;

const Head: React.FunctionComponent<HeadProps> = ({ shareId }) => {
  const [isMounted, setMounted] = useState<boolean>(false);
  const [viewport, setViewport] = useState<string>(defaultViewport);
  const router = useRouter();
  const windowSize = useWindowSize();

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    // Prevent Android keyboard shrinking the viewport
    if (isAndroid() && !isInputFocused()) {
      setViewport(
        // eslint-disable-next-line prettier/prettier
        `height=${windowSize.height},width=${
          windowSize.width
        },initial-scale=1.0${
          process.env.ALLOW_USER_ZOOM
            ? ''
            : ',minimum-scale=1.0,maximum-scale=1.0,user-scalable=0'
        }`
      );
    }
  }, [windowSize]);

  // const fullPath = useMemo(
  //   () =>
  //     (isMounted && window.location.href) || 'https://meataverse.slimjim.com/',
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [isMounted, router?.asPath]
  // );

  const domain = useMemo(
    () =>
      (isMounted && window.location.host) || 'https://meataverse.slimjim.com/',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMounted, router?.asPath]
  );

  return (
    <NextHead>
      <title>{'Slim Jim Meataverse | A web3 and NFT project'}</title>
      <meta
        name="description"
        content="Slim Jim&#39;s Meataverse is a web3 and NFT project on the polygon blockchain. Users can claim 1 of 10,000 available GigaJims."
      />
      <meta name="author" content="Slim Jim" />
      <meta
        name="keywords"
        content="NFT, web3, Slim Jim, Meataverse, metaverse, polygon, blockchain"
      />

      <meta name="theme-color" content={colors.white} />
      <meta name="msapplication-TileColor" content={colors.white} />
      <link rel="icon" href={'/favicon.ico'} />
      <link
        rel="manifest"
        href={'/manifest.json'}
        crossOrigin="use-credentials"
      />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={'/favicon-32x32.png'}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={'/favicon-16x16.png'}
      />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={'/apple-icon-120x120.png'}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={'/apple-icon-152x152.png'}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={'/apple-icon-180x180.png'}
      />

      <meta property="og:url" content={'https://meataverse.slimjim.com/'} />
      <meta property="og:type" content={'website'} />
      <meta
        property="og:title"
        content="Slim Jim Meataverse | A web3 and NFT project"
      />
      <meta
        property="og:description"
        content="Slim Jim&#39;s Meataverse is a web3 and NFT project on the polygon blockchain. Users can claim 1 of 10,000 available GigaJims."
      />
      <meta
        property="og:image"
        content={generateLinkToShare(
          'https://cdn-meataverse.slimjim.com/social.png',
          shareId
        )}
      />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content={domain} />
      <meta
        property="twitter:url"
        content={'https://meataverse.slimjim.com/'}
      />
      <meta
        property="twitter:title"
        content="Slim Jim Meataverse | A web3 and NFT project"
      />
      <meta
        property="twitter:description"
        content="Slim Jim&#39;s Meataverse is a web3 and NFT project on the polygon blockchain. Users can claim 1 of 10,000 available GigaJims."
      />
      <meta
        name="twitter:image"
        content={generateLinkToShare(
          'https://cdn-meataverse.slimjim.com/social.png',
          shareId
        )}
      />

      <meta name="viewport" content={viewport} />
    </NextHead>
  );
};

export default Head;
