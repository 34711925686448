import { FC } from 'react';

import { NavbarLinkProps } from 'components/Navbar/NavbarLink/NavbarLink';
import { NavbarLink } from 'store/copy.types';
import { ReactComponent as SvgLinkBackground } from 'svgs/_navbar-mobile-menu-link.svg';
import { ReactComponent as SvgLinkBackgroundOpen } from 'svgs/_navbar-mobile-menu-link-open.svg';
import { ReactComponent as SvgArrow } from 'svgs/navbar/arrow.svg';
import { isRemotePath } from 'u9/utils/path';
import assets from 'utils/assets';

import MobileLinkWrapper from '../NavbarMobileMenuLinkWrapper/NavbarMobileMenuLinkWrapper';

import * as Styled from './NavbarMobileMenuLink.styles';

export interface NavbarMobileMenuLinkProps extends NavbarLinkProps {
  labelMobile?: string | Array<string>;
  onClick?: () => void;
  nested?: NavbarLink['children'];
  open?: boolean;
}

const NavbarMobileMenuLink: FC<NavbarMobileMenuLinkProps> = ({
  href,
  label,
  labelMobile,
  icon,
  nested,
  open,
  onClick,
}) => {
  return (
    <MobileLinkWrapper isLink={!nested} href={href} onClick={handleClick}>
      {open ? <SvgLinkBackgroundOpen /> : <SvgLinkBackground />}
      <Styled.Content>
        <Styled.ContentLeft>
          <Styled.Icon
            src={assets.svgs.navbar.icons[icon]}
            alt={`menu icon ${icon}`}
          />
          <Styled.Label>
            {labelMobile
              ? typeof labelMobile === 'string'
                ? labelMobile
                : labelMobile.map((textLine, i) => (
                    <span key={i}>{textLine}</span>
                  ))
              : label}
          </Styled.Label>
        </Styled.ContentLeft>
        {nested && (
          <Styled.Chevron $open={open}>
            <SvgArrow />
          </Styled.Chevron>
        )}
      </Styled.Content>
    </MobileLinkWrapper>
  );

  function handleClick() {
    if (!nested) {
      !isRemotePath(href) && onClick();
    }

    if (nested) {
      onClick();
    }
  }
};

export default NavbarMobileMenuLink;
