import { Html5Qrcode, Html5QrcodeScannerState } from 'html5-qrcode';
import { FC, RefObject, useEffect, useRef } from 'react';

import { scanCode } from 'services/api/calls/userData';
import { useGlobalStore, useUserStore } from 'store';
import { errorMe, logMe } from 'utils/logger';

import { captureImage } from './Scanner.utils';

import * as Styled from './Scanner.styles';

import { ScanningBonus } from 'constants/enum';

enum ScannerState {
  WELCOME = 'WELCOME',
  PERMISSIONS = 'PERMISSIONS',
  SCANNER = 'SCANNER',
  INPUT = 'INPUT',
}

export interface ScannerProps {
  setPermissions: (state: boolean) => void;
  setCheckedPermissions: (state: boolean) => void;
  setNewItemPopup: (state: boolean) => void;
  setValid: (state: boolean) => void;
  setSent: (state: boolean) => void;
  setBarcode: (state: string) => void;
  setItems: (state: Array<string>) => void;
  setRewardType: (state: ScanningBonus) => void;
  setNumberOfScans: (state: number) => void;
  setAllowedDailyScans: (state: number) => void;
  scannerStateRef: RefObject<string>;
  sent: boolean;
  setCameraIsRunning: (state: boolean) => void;
}

const ScannerCamera: FC<ScannerProps> = ({
  setPermissions,
  setCheckedPermissions,
  setNewItemPopup,
  setItems,
  setBarcode,
  setValid,
  setSent,
  setRewardType,
  setNumberOfScans,
  setAllowedDailyScans,
  sent,
  scannerStateRef,
  setCameraIsRunning,
}) => {
  const { setUserSauces } = useUserStore();

  const { setUnexpectedError } = useGlobalStore();

  const scannerRef = useRef(null);
  const qrCodeScanner = useRef(null);
  const cameraCodeRef = useRef(null);
  const timeoutRef = useRef(null);
  // const sentTmp = useRef(false);

  useEffect(() => {
    const interval = setInterval(async () => {
      // console.log('readerStart', reader.current);
      if (
        scannerStateRef.current === ScannerState.SCANNER &&
        scannerRef.current &&
        !sent
      ) {
        const image = await captureImage(scannerRef);
        setSent(true);

        // console.error('cameraCodeRef', cameraCodeRef.current);
        scanCode({
          // code: cameraCodeRef.current,
          image,
          scannerStateRef,
          setSent,
          setValid,
          setBarcode,
          setItems,
          setNewItemPopup,
          setUserSauces,
          setUnexpectedError,
          setRewardType,
          setNumberOfScans,
          setAllowedDailyScans,
        });
        cameraCodeRef.current = null;
      }
    }, 500);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sent, scannerStateRef.current]);

  useEffect(() => {
    if (
      scannerStateRef.current === ScannerState.PERMISSIONS ||
      (scannerStateRef.current === ScannerState.SCANNER &&
        qrCodeScanner.current === null)
    ) {
      readerPermission();
    }
    return () => readerStopClear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannerStateRef.current]);

  const readerPermission = () => {
    Html5Qrcode.getCameras()
      .then(devices => {
        if (devices && devices.length) {
          setPermissions(true);
          // if (scannerStateRef.current === ScannerState.SCANNER) {
          readerStart();
          // }
        } else {
          setPermissions(false);
        }
      })
      .catch(() => {
        setPermissions(false);
      })
      .finally(() => setCheckedPermissions(true));
  };

  const readerStart = async () => {
    if (qrCodeScanner.current === null) {
      qrCodeScanner.current = new Html5Qrcode('reader');
      qrCodeScanner.current
        .start(
          { facingMode: 'environment' },
          {
            fps: 10,
          },
          readerSuccess,
          readerError
        )
        .catch(err => logMe(`Unable to start scanning: ${err}`))
        .finally(() => {
          if (timeoutRef.current) clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(() => {
            setCameraIsRunning(true);
          }, 300);
        });
      setSent(false);
    }
  };

  const readerSuccess = () => {
    // scannerRef?.current?.pause();
    // setSent(true);
    // if (sentTmp.current === false) {
    //   sentTmp.current = true;
    //   cameraCodeRef.current = code;
    // } else cameraCodeRef.current = null;
    // setTimeout(() => {
    //   sentTmp.current = false;
    // }, 4000);
    // scanCode({
    //   code: code,
    //   scannerStateRef,
    //   setValid,
    //   setBarcode,
    //   setItems,
    //   setNewItemPopup,
    //   setUserSauces,
    //   setUnexpectedError,
    //   setRewardType,
    // });
  };

  const readerError = () => {
    // Failure .
  };

  const readerStopClear = () => {
    const runningState: Html5QrcodeScannerState =
      qrCodeScanner.current?.getState();

    if (runningState === Html5QrcodeScannerState.SCANNING) {
      try {
        qrCodeScanner.current?.stop().then(() => {
          qrCodeScanner.current?.clear();
          qrCodeScanner.current = null;
          setCameraIsRunning(false);
        });
      } catch (error) {
        errorMe({ error });
      }
    }
  };

  return (
    <Styled.ReaderWrapper
      $shown={scannerStateRef.current === ScannerState.SCANNER}
    >
      <Styled.ReaderCamera id="reader" ref={scannerRef} />
    </Styled.ReaderWrapper>
  );
};

export default ScannerCamera;
