import { produce } from 'immer';
import create, { UseBoundStore } from 'zustand';

import {
  InventoryTutorialStage,
  LabRoomStage,
  MeaterializerTutorialStage,
  Tutorial,
} from 'components/LaboratoryS1/LabEnum';
import { PFP_ITEM } from 'types/pfp';
import { SAUCES_ME, TUTORIAL_ME, USER_ME } from 'types/user';

export interface UserStoreType {
  isWhitelisted: boolean;
  setIsWhitelisted: (state: boolean) => void;
  isLoggedIn: boolean;
  setIsLoggedIn: (state: boolean) => void;
  walletAddress: string;
  setWalletAddress: (state: string) => void;
  userData: USER_ME;
  setUserData: (state: USER_ME) => void;
  activePfp: PFP_ITEM;
  setActivePfp: (state: PFP_ITEM) => void;
  userSauces: SAUCES_ME;
  setUserSauces: (state: SAUCES_ME) => void;
  tutorialStatus: TUTORIAL_ME;
  setTutorialStatus: (state: Partial<TUTORIAL_ME>) => void;
  newSauce: string;
  setNewSauce: (state: string) => void;
}

const useUserStore: UseBoundStore<UserStoreType> = create(
  (
    set: (partial: (store: UserStoreType) => Partial<UserStoreType>) => void
  ) => {
    return {
      isWhitelisted: false,
      setIsWhitelisted: state => {
        set(() => ({
          isWhitelisted: state,
        }));
      },
      isLoggedIn: false,
      setIsLoggedIn: state => {
        set(() => ({
          isLoggedIn: state,
        }));
      },
      userData: null, // testUser
      setUserData: state => {
        set(() => ({
          userData: {
            ...state,
            pfps: state.pfps.sort((a, b) => a?.nft - b?.nft),
          },
          activePfp:
            state?.pfps.find(pfp => pfp.id === state.activePfpId) ||
            state?.pfps[0],
        }));
      },
      activePfp: null, // testUser
      setActivePfp: state => {
        set(() => ({
          activePfp: state,
        }));
      },
      userSauces: null, // testSauces
      setUserSauces: state => {
        set(() => ({
          userSauces: state,
        }));
      },
      // is tutorial finished
      tutorialStatus: {
        [Tutorial.Meatalizer]: MeaterializerTutorialStage.DISABLED,
        [Tutorial.Mixer]: LabRoomStage.DISABLED,
        [Tutorial.Inventory]: InventoryTutorialStage.DISABLED,
        [Tutorial.Scanner]: LabRoomStage.DISABLED,
        isFinished: false,
        isAllFinished: false,
      },
      setTutorialStatus: state => {
        set(
          produce(draft => {
            return {
              tutorialStatus: {
                ...(draft?.tutorialStatus as TUTORIAL_ME),
                ...state,
                isFinished: isFinished(draft, state),
                isScannerTime: isAllFinished(draft, state),
                isAllFinished: isAllFinished(draft, state),
              },
            };
          })
        );
      },
      newSauce: null,
      setNewSauce: state => {
        set(() => ({
          newSauce: state,
        }));
      },
      walletAddress: '',
      setWalletAddress: state => {
        set(() => ({
          walletAddress: state,
        }));
      },
    };
  }
);

export default useUserStore;

const isFinished = (draft, state) => {
  const newData = { ...(draft?.tutorialStatus as TUTORIAL_ME), ...state };

  return (
    newData.meatalizer === MeaterializerTutorialStage.FINISHED &&
    newData.inventory === InventoryTutorialStage.FINISHED &&
    newData.mixer === LabRoomStage.FINISHED
  );
};

const isAllFinished = (draft, state) => {
  const newData = { ...(draft?.tutorialStatus as TUTORIAL_ME), ...state };

  return (
    newData.meatalizer === MeaterializerTutorialStage.FINISHED &&
    newData.inventory === InventoryTutorialStage.FINISHED &&
    newData.mixer === LabRoomStage.FINISHED &&
    newData.scanner === LabRoomStage.FINISHED
  );
};
