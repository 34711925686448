export enum LoginStep {
  CRYPTO_ACCOUNT_CREATED,
  CRYPTO_ACCOUNT_WALLET_CREATED,
  CRYPTO_QUESTION,
  CRYPTO_WALLET,
  ENTER_CODE,
  ENTER_EMAIL,
  VERIFY_EMAIL,
  VERIFY_MOBILE,
  WELCOME_BACK,
  NO_GIGAJIM_NFT,
  TRANSFER_NFT,
}

// Blockchain
export enum Networks {
  Ethereum = 'Ethereum (Goerli)',
  Polygon = 'Polygon (Mumbai)',
}
// Gallery
export enum Gallery_Order {
  None = '',
  DateOfBirth = 'dateOfBirth',
  Score = 'score',
}

export type Gallery_Item = {
  dateOfBirth: string;
  nftName: string;
  rarity: string;
  score: number;
  url: string;
};

export type StandardFilter = {
  name: string;
  amount?: number;
  checked?: boolean;
};

export enum Gallery_Filters {
  RarityScore = 'rarityScore',
  Ranking = 'ranking',
  // ItemRarity = 'itemRarity',
  ColourRarity = 'colourRarity',
  CapsuleType = 'capsuleType',
  Set = 'set',
}

export enum Gallery_Filter_Rarity {
  COMMON = 'Common',
  RARE = 'Rare',
  EPIC = 'Epic',
  LEGENDARY = 'Legendary',
}

export enum Gallery_Filter_Capsule_Type {
  TYPE_1 = 'Type 1',
  TYPE_2 = 'Type 2',
  TYPE_3 = 'Type 3',
}

export enum Gallery_Filter_Set {
  SET_1 = 'Set 1',
  SET_2 = 'Set 2',
  SET_3 = 'Set 3',
}

export enum Gallery_Theme {
  DESKTOP_3X3 = 'DESKTOP_3X3',
  DESKTOP_4X4 = 'DESKTOP_4X4',
  DESKTOP_3X2 = 'DESKTOP_3X2 ',
  MOBILE_1X5 = 'MOBILE_1X5',
  MOBILE_2X5 = 'MOBILE_2X5',
}

//
export type Gallery_Screens = {
  desktop: [number, number];
  mobile: [number, number];
};

export const GalleryThemeData: Record<Gallery_Theme, Gallery_Screens> = {
  [Gallery_Theme.DESKTOP_3X3]: { desktop: [3, 3], mobile: [2, 5] },
  [Gallery_Theme.DESKTOP_4X4]: { desktop: [4, 4], mobile: [1, 5] },
  [Gallery_Theme.DESKTOP_3X2]: { desktop: [3, 2], mobile: [2, 5] },
  [Gallery_Theme.MOBILE_1X5]: { desktop: [3, 3], mobile: [1, 5] },
  [Gallery_Theme.MOBILE_2X5]: { desktop: [3, 3], mobile: [2, 5] },
};

export enum Rarity {
  None = '', // No value specified
  Common = 'Common',
  Rare = 'Rare',
  Epic = 'Epic',
  Legendary = 'Legendary',
}

export enum ScanningBonus {
  BASE_SAUCE = 'BASE_SAUCE',
  TWO_BASE_SAUCES = 'TWO_BASE_SAUCES',
  COMMON_SUPER_SAUCE = 'COMMON_SUPER_SAUCE',
  RARE_SUPER_SAUCE = 'RARE_SUPER_SAUCE',
  EPIC_SUPER_SAUCE = 'EPIC_SUPER_SAUCE',
  LEGENDARY_SUPER_SAUCE = 'LEGENDARY_SUPER_SAUCE',
}

// export enum BaseSauceE = {}

// loginSteps
/** you can go to every step directly by using /login?step={loginStep}  */
export const loginSteps: Record<LoginStep, string> = {
  [LoginStep.CRYPTO_ACCOUNT_CREATED]: 'crypto-account-created',
  [LoginStep.CRYPTO_ACCOUNT_WALLET_CREATED]: 'crypto-account-wallet-created',
  [LoginStep.CRYPTO_QUESTION]: 'crypto-question',
  [LoginStep.CRYPTO_WALLET]: 'crypto-wallet',
  [LoginStep.ENTER_CODE]: 'enter-code',
  [LoginStep.ENTER_EMAIL]: 'enter-email',
  [LoginStep.VERIFY_EMAIL]: 'verify-email',
  [LoginStep.VERIFY_MOBILE]: 'verify-mobile',
  [LoginStep.WELCOME_BACK]: 'welcome-back',
  [LoginStep.NO_GIGAJIM_NFT]: 'no-gigajim-nft',
  [LoginStep.TRANSFER_NFT]: 'transfer-nft',
};

export enum NFT_STATUS {
  'NO_INFO',
  'IN_WALLET',
  'NO_NFT',
}

// NFT
export const PFP_ATRIBIUTES = {
  feet: { name: 'feet', descName: 'Feet', layerNo: 7 },
  capsule: { name: 'capsule', descName: 'Capsule', layerNo: 13 },
  headAccessory: {
    name: 'headAccessory',
    descName: 'Head Accessory',
    layerNo: 5,
  },
  leftHandAccessory: {
    name: 'leftHandAccessory',
    descName: 'Left Hand Accessory',
    layerNo: 1,
  },
  hands: { name: 'hands', descName: 'Hands', layerNo: 2 },
  armAccessory: { name: 'armAccessory', descName: 'Arm Accessory', layerNo: 4 },
  rightHandAccessory: {
    name: 'rightHandAccessory',
    descName: 'Right Hand Accessory',
    layerNo: 3,
  },
  torso: { name: 'torso', descName: 'Torso', layerNo: 6 },
  legs: { name: 'legs', descName: 'Legs', layerNo: 8 },
  head: { name: 'head', descName: 'Head', layerNo: 10 },
  backAccessory: {
    name: 'backAccessory',
    descName: 'Back Accessory',
    layerNo: 11,
  },
};

export const CAPSULE_TYPE = {
  Standard: 'Standard Capsule',
  Black: 'Black Plastic Capsule',
  White: 'White Plastic Capsule',
  Shiny: 'Shiny Black Capsule',
  Steel: 'Steel Capsule',
  Rusty: 'Rusty Capsule',
  Premium: 'Premium Standard Capsule',
  Yellow: 'Slim Jim Yellow Capsule',
  Red: 'Slim Jim Red Capsule',
  Gold: 'Gold Capsule',
  Iridescent: 'Iridescent Capsule',
};

export enum LAB0_STATUS {
  'empty',
  'letter',
  'bigLetter',
  'stick',
  'sauce',
  'mixbutton',
  'sauceCapsule',
  'silverNFT',
  'goldNFT',
}

export enum USER_TYPE {
  'new',
  'returning',
}

export enum API_T {
  // user
  /** first step of registration. Use this endpoint to get access to the app in season 0.
   It needs to be used before sign up!  */
  unlockCode = 'unlockCode',
  /** Use this endpoint to register a new account.
  "code": "string",
  "phoneNo": "string"
  with Bearer prefix
     */
  register = 'register',
  registerWithWallet = 'registerWithWallet',
  me = 'me',
  exists = 'exists',
  delete = 'delete',
  resend = 'resend',
  /** Use this endpoint to activate user account by providing SMS code validation */
  activate = 'activate',
  wallet = 'wallet',
  nonce = 'nonce',
  pfp = 'pfp',
  scanBarcode = 'scanBarcode',
  mixSauce = 'mixSauce',
  evolve = 'evolve',
  setActivePfp = 'setActivePfp',
  evolveHistory = 'evolveHistory',
  tutorial = 'tutorial',
  achievementUnwrap = 'achievementUnwrap',
  // debug
  getNfts = 'getNfts',
  modify = 'modify',
  mint = 'mint',
  freeze = 'freeze',

  // gallery
  gallery = 'gallery',
  uploadShare = 'uploadShare',
  gallerySimilar = 'gallerySimilar',
  galleryItem = 'galleryItem',
  galleryRank = 'galleryRank',
}

export const API_URL: Record<API_T, Array<string>> = {
  // user
  [API_T.unlockCode]: ['api/users/register/unlock-access'],
  [API_T.register]: ['api/users/register'],
  [API_T.registerWithWallet]: ['api/users/register/', '/wallet/', '/check'], //  /api/users/register/{email}/wallet/{wallet_address}/check
  [API_T.achievementUnwrap]: ['/api/users/me/achievements/unwrap'],
  [API_T.me]: ['api/users/me'],
  [API_T.nonce]: ['api/users/me/wallet/nonce'],
  [API_T.exists]: ['api/users/', '/exists'],
  [API_T.resend]: ['api/users/me/activate/code/resend/'],
  [API_T.wallet]: ['api/users/me/wallet'],
  [API_T.activate]: ['api/users/me/activate'],
  [API_T.delete]: ['api/users/me'],
  [API_T.pfp]: ['api/users/me/pfps/init'],
  [API_T.scanBarcode]: ['api/users/me/barcodes'],
  [API_T.mixSauce]: ['api/users/me/sauces/mix'],
  [API_T.evolve]: ['api/users/me/pfps/', '/evolve'],
  [API_T.setActivePfp]: ['api/users/me/pfps/', '/active'],
  [API_T.evolveHistory]: ['api/users/me/pfps/', '/history'],
  [API_T.tutorial]: ['api/users/me/tutorial-progress'],
  // debug
  [API_T.getNfts]: ['api/debug/nft/get-user-nfts/'],
  [API_T.modify]: ['api/debug/nft/modify-nft/'],
  [API_T.mint]: ['api/debug/nft/mint-nft'],
  [API_T.freeze]: ['api/debug/nft/freeze-nft'],
  // gallery
  [API_T.gallery]: ['api/gallery'],
  [API_T.gallerySimilar]: ['api/gallery/similar/'],
  [API_T.galleryItem]: ['api/gallery/'],
  [API_T.uploadShare]: ['api/gallery/upload-shareable'],
  [API_T.galleryRank]: ['api/gallery/', '/rank'],
};
