import styled, { css } from 'styled-components';

import { setTextStyles, setTypography } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

import { TooltipContentProps } from './TooltipContent';

const borderSize = 10; //rem
const borderRadius = 35; //rem
const indention = {
  height: 25,
  width: 140,
  offset: 25,
}; //rem

export const Container = styled.div<{
  $arrowPosition: TooltipContentProps['arrowPosition'];
}>`
  position: relative;
  filter: drop-shadow(0px -2rem 10rem ${({ theme }) => theme.colors.cyanAqua});
  --arrow-position: 50%;
  --indention-offset: var(--arrow-position);

  ${({ $arrowPosition }) =>
    $arrowPosition === 'very-start' &&
    css`
      --arrow-position: 17%;
      --indention-offset: calc(var(--arrow-position) - 0.5%);
    `};

  ${({ $arrowPosition }) =>
    $arrowPosition === 'start' &&
    css`
      --arrow-position: 22.5%;
      --indention-offset: calc(var(--arrow-position) - 0.7%);
    `};

  ${({ $arrowPosition }) =>
    $arrowPosition === 'end' &&
    css`
      --arrow-position: 77.5%;
      --indention-offset: calc(var(--arrow-position) + 0.7%);
    `};

  ${({ $arrowPosition }) =>
    $arrowPosition === 'very-end' &&
    css`
      --arrow-position: 83%;
      --indention-offset: calc(var(--arrow-position) + 0.9%);
    `};
`;

export const Wrapper = styled.div<{
  $position: TooltipContentProps['position'];
  $color: TooltipContentProps['color'];
  $smallWidth: TooltipContentProps['smallWidth'];
  $arrowVariant: TooltipContentProps['arrowVariant'];
}>`
  z-index: 10;
  position: relative;
  background: ${({ theme }) => theme.colors.rumSwizzle};
  color: ${({ theme }) => theme.colors.black};
  min-height: 200rem;
  clip-path: polygon(
    calc(var(--arrow-position) - ${indention.width / 2}rem)
      ${indention.height}rem,
    calc(var(--arrow-position) + ${indention.width / 2}rem)
      ${indention.height}rem,
    calc(var(--arrow-position) + ${indention.width / 2 + indention.offset}rem) 0,
    100% 0,
    100% 100%,
    0 100%,
    0 0,
    calc(var(--arrow-position) - ${indention.width / 2 + indention.offset}rem) 0
  );
  border-radius: ${borderRadius}rem;
  border: ${borderSize}rem solid ${({ theme }) => theme.colors.black};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: -1rem;
    background: ${({ theme }) => theme.colors.black};
    width: calc(
      ${indention.width + 2 * indention.offset}rem + ${borderSize}rem
    );
    left: calc(
      var(--indention-offset) -
        ((${indention.width + 2 * indention.offset}rem + ${borderSize}rem) / 2)
    );
    height: calc(${indention.height + 1}rem);
    clip-path: polygon(
      0 0,
      100% 0,
      calc(100% - ${indention.offset}rem) 100%,
      ${indention.offset}rem 100%
    );
  }
  padding: ${indention.height + 20}rem 40rem 30rem;
  width: 640rem;

  ${mediaTablet(css`
    width: 720rem;
  `)}

  ${({ $smallWidth }) =>
    $smallWidth &&
    css`
      width: 480rem;

      ${mediaTablet(css`
        width: 565rem;
      `)}
    `};

  ${({ $color }) =>
    $color &&
    css`
      ${$color === 'turbo' &&
      css`
        background: ${({ theme }) => theme.colors.turbo};
      `}

      ${$color === 'crimson' &&
      css`
        background: ${({ theme }) => theme.colors.crimson};
        color: ${({ theme }) => theme.colors.rumSwizzle};
      `}
    `};

  ${({ $position }) =>
    $position === 'top' &&
    css`
      padding: 30rem 40rem ${indention.height + 20}rem;

      clip-path: polygon(
        0 0,
        100% 0,
        100% 100%,
        calc(
            var(--arrow-position) + ${indention.width / 2 + indention.offset}rem
          )
          100%,
        calc(var(--arrow-position) + ${indention.width / 2}rem)
          calc(100% - ${indention.height}rem),
        calc(var(--arrow-position) - ${indention.width / 2}rem)
          calc(100% - ${indention.height}rem),
        calc(
            var(--arrow-position) - ${indention.width / 2 + indention.offset}rem
          )
          100%,
        0 100%
      );

      &::after {
        top: unset;
        bottom: -1rem;
        clip-path: polygon(
          ${indention.offset}rem 0,
          calc(100% - ${indention.offset}rem) 0,
          100% 100%,
          0 100%
        );
      }
    `};

  ${({ $position }) =>
    $position === 'right' &&
    css`
      min-height: 230rem;
      padding: 40rem 30rem 40rem ${indention.height + 20}rem;

      clip-path: polygon(
        0 0,
        100% 0,
        100% 100%,
        0 100%,
        0
          calc(
            var(--arrow-position) + ${indention.width / 2 + indention.offset}rem
          ),
        ${indention.height}rem
          calc(var(--arrow-position) + ${indention.width / 2}rem),
        ${indention.height}rem
          calc(var(--arrow-position) - ${indention.width / 2}rem),
        0
          calc(
            var(--arrow-position) - ${indention.width / 2 + indention.offset}rem
          )
      );

      &::after {
        top: calc(
          var(--indention-offset) -
            (
              (${indention.width + 2 * indention.offset}rem + ${borderSize}rem) /
                2
            )
        );
        left: -1rem;
        height: calc(
          ${indention.width + 2 * indention.offset}rem + ${borderSize}rem
        );
        width: calc(${indention.height + 1}rem);
        clip-path: polygon(
          0 0,
          ${indention.offset}rem ${indention.offset}rem,
          ${indention.offset}rem calc(100% - ${indention.offset}rem),
          0 100%
        );
      }
    `};

  ${({ $position }) =>
    $position === 'left' &&
    css`
      min-height: 230rem;
      padding: 40rem ${indention.height + 20}rem 40rem 30rem;

      clip-path: polygon(
        0 0,
        100% 0,
        100%
          calc(
            var(--arrow-position) - ${indention.width / 2 + indention.offset}rem
          ),
        calc(100% - ${indention.height}rem)
          calc(var(--arrow-position) - ${indention.width / 2}rem),
        calc(100% - ${indention.height}rem)
          calc(var(--arrow-position) + ${indention.width / 2}rem),
        100%
          calc(
            var(--arrow-position) + ${indention.width / 2 + indention.offset}rem
          ),
        100% 100%,
        0 100%
      );

      &::after {
        top: calc(
          var(--indention-offset) -
            (
              (${indention.width + 2 * indention.offset}rem + ${borderSize}rem) /
                2
            )
        );
        left: unset;
        right: -1rem;
        height: calc(
          ${indention.width + 2 * indention.offset}rem + ${borderSize}rem
        );
        width: calc(${indention.height + 1}rem);
        clip-path: polygon(
          0 ${indention.offset}rem,
          100% 0,
          100% 100%,
          0 calc(100% - ${indention.offset}rem)
        );
      }
    `};

  ${({ $arrowVariant }) =>
    $arrowVariant === 'none' &&
    css`
      clip-path: none;
      padding: 30rem;
      min-height: unset;
      min-width: unset;

      &::after {
        content: unset;
      }

      ${mediaTablet(css`
        clip-path: none;
      `)}
    `};
`;

export const Content = styled.div<{
  $fontVariant: TooltipContentProps['fontVariant'];
}>`
  ${({ theme: { locale } }) => setTypography('caption', locale)};

  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;

  ${({ $fontVariant }) =>
    $fontVariant === 'md' &&
    css`
      ${({ theme: { locale } }) => setTypography('heading6', locale)};
    `};

  ${({ $fontVariant }) =>
    $fontVariant === 'lg' &&
    css`
      ${({ theme: { locale } }) => setTypography('heading5Tooltip', locale)};
    `};
`;

export const ArrowWrapper = styled.div<{
  $position: TooltipContentProps['position'];
  $arrowVariant: TooltipContentProps['arrowVariant'];
  $color: TooltipContentProps['color'];
}>`
  position: absolute;
  height: 48rem;
  width: 92rem;
  color: ${({ theme }) => theme.colors.rumSwizzle};
  bottom: -48rem;
  left: calc(var(--arrow-position) - 46rem);
  transform: rotate(180deg);
  z-index: 11;

  ${({ $color }) =>
    $color === 'turbo' &&
    css`
      color: ${({ theme }) => theme.colors.turbo};
    `};

  ${({ $color }) =>
    $color === 'crimson' &&
    css`
      color: ${({ theme }) => theme.colors.crimson};
    `};

  ${({ $arrowVariant }) =>
    $arrowVariant !== 'default' &&
    css`
      height: 56rem;
      width: 112rem;
      left: calc(var(--arrow-position) - 56rem);
    `};

  ${({ $position }) =>
    $position === 'bottom' &&
    css<{ $arrowVariant: TooltipContentProps['arrowVariant'] }>`
      transform: none;
      bottom: unset;
      top: -48rem;

      ${({ $arrowVariant }) =>
        $arrowVariant === 'check' &&
        css`
          top: -82rem;
        `};

      ${({ $arrowVariant }) =>
        $arrowVariant === 'invalid' &&
        css`
          top: -92rem;
        `};
    `};

  ${({ $position }) =>
    $position === 'right' &&
    css`
      transform: rotate(-90deg);
      left: -68rem;
      top: calc(var(--arrow-position) - 24rem);
    `};

  ${({ $position }) =>
    $position === 'left' &&
    css`
      transform: rotate(90deg);
      left: unset;
      right: -68rem;
      top: calc(var(--arrow-position) - 24rem);
    `};
`;

export const ArrowAnimationWrapper = styled.div``;

export const Text = styled.span`
  display: block;
  ${({ theme: { locale } }) => setTypography('heading5Tooltip', locale)};
  ${setTextStyles('rumSwizzle', 'md')};
  text-align: center;
  transform: scale(1.1);
  white-space: nowrap;
`;
