import { useEffect, useState } from 'react';

function useViewportWidth(width: number) {
  const [isViewportLarger, setIsViewportLarger] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsViewportLarger(window.innerWidth > width);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  return isViewportLarger;
}

export default useViewportWidth;
