import { ApngAnimationProps } from './ApngAnimation';

export const getRandom = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

export const getArrayBufferImage = async (src: string) => {
  return src ? fetch(src).then(r => r.arrayBuffer()) : new ArrayBuffer(0);
};

export const getImageSource = (
  src: ApngAnimationProps['src'],
  isDesktop: boolean
) => {
  if (typeof src === 'string') return src;

  if (isDesktop && src?.desktop) {
    return src.desktop.src;
  }

  return src?.mobile?.src;
};
