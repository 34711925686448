/* eslint-disable prettier/prettier */
import { kebabCase } from 'lodash';
import { rgba } from 'polished';
import { css } from 'styled-components';

import svgBgTile from 'svgs/bg-tile.svg';

import { glow, pulse, spin } from './animations';
import {
  layoutMaxWidthDesktop,
  MediaContent,
  mediaDesktop,
  mediaDesktopWide,
  mediaTablet,
} from './responsive';
import { ColorNames, FontCategory, fonts, ResponsiveValues } from './theme';

// Fix Safari mobile fix, where vh values are wrongly calculated
// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
export const setVh = (value: number) => `calc(var(--vh, 1vh) * ${value})`;

export const hover = (content: MediaContent, mustHoldOnTablet = false) => css`
  &:active {
    ${content}
  }

  ${(mustHoldOnTablet ? mediaDesktop : mediaTablet)(css`
    &:hover {
      ${content}
    }
  `)}
`;

export const focus = (content: MediaContent) => css`
  &:focus-visible {
    ${content}
  }

  @supports not selector(:focus-visible) {
    :focus {
      ${content}
    }
  }
`;

export const setVisibility = (isVisible: boolean) => css`
  transition: opacity 0.2s ease-out;

  ${!isVisible
    ? `
    opacity: 0;
    pointer-events: none;
  `
    : ''}
`;

const getStyleString = (
  key: keyof FontCategory,
  value: FontCategory[keyof FontCategory],
  deviceType: keyof ResponsiveValues,
  isImportant?: boolean,
  locale?: string
) => {
  const isRemUnit = ['fontSize', 'wordSpacing'].includes(key);
  const isEmUnit = ['letterSpacing'].includes(key);
  const isObject = ['fontSize', 'lineHeight'].includes(key);
  const isFontFamily = key === 'fontFamily';

  const parsedKey = kebabCase(key);
  const parsedValue = isObject
    ? (value as any)[deviceType]
    : isFontFamily
    ? fonts.face[
        typeof value === 'object'
          ? value[locale] || (value as any).default
          : value
      ]
    : value;
  const parsedUnit =
    (isRemUnit || isEmUnit) && parsedValue ? (isRemUnit ? 'rem' : 'em') : '';
  const parsedValueWithUnit = `${parsedValue}${parsedUnit}`;

  return `${parsedKey}: ${parsedValueWithUnit}${
    isImportant ? ' !important' : ''
  };`;
};

export const setTypography = (
  category: keyof typeof fonts.scale,
  locale = process.env.DEFAULT_LOCALE,
  isImportant = false
) => css`
  ${Object.entries(fonts.scale[category] as FontCategory)
    .map(
      ([key, value]: [keyof FontCategory, FontCategory[keyof FontCategory]]) =>
        getStyleString(key, value, 'mobile', isImportant, locale)
    )
    .join('\n')}

  ${mediaTablet(css`
    ${Object.entries(fonts.scale[category] as FontCategory)
      .filter(([, value]) => value?.tablet !== undefined)
      .map(
        ([key, value]: [
          keyof FontCategory,
          FontCategory[keyof FontCategory]
        ]) => getStyleString(key, value, 'tablet', isImportant, locale)
      )
      .join('\n')}
  `)}

  ${mediaDesktop(css`
    ${Object.entries(fonts.scale[category] as FontCategory)
      .filter(([, value]) => value?.desktop !== undefined)
      .map(
        ([key, value]: [
          keyof FontCategory,
          FontCategory[keyof FontCategory]
        ]) => getStyleString(key, value, 'desktop', isImportant, locale)
      )
      .join('\n')}
  `)}
`;

// Bezier curves
export const easeInSine = 'cubic-bezier(0.470, 0.000, 0.745, 0.715)';
export const easeOutSine = 'cubic-bezier(0.390, 0.575, 0.565, 1.000)';
export const easeInOutSine = 'cubic-bezier(0.445, 0.050, 0.550, 0.950)';
export const easeInQuad = 'cubic-bezier(0.550, 0.085, 0.680, 0.530)';
export const easeOutQuad = 'cubic-bezier(0.250, 0.460, 0.450, 0.940)';
export const easeInOutQuad = 'cubic-bezier(0.455, 0.030, 0.515, 0.955)';
export const easeInCubic = 'cubic-bezier(0.550, 0.055, 0.675, 0.190)';
export const easeOutCubic = 'cubic-bezier(0.215, 0.610, 0.355, 1.000)';
export const easeInOutCubic = 'cubic-bezier(0.645, 0.045, 0.355, 1.000)';
export const easeInQuart = 'cubic-bezier(0.895, 0.030, 0.685, 0.220)';
export const easeOutQuart = 'cubic-bezier(0.165, 0.840, 0.440, 1.000)';
export const easeInOutQuart = 'cubic-bezier(0.770, 0.000, 0.175, 1.000)';
export const easeInQuint = 'cubic-bezier(0.755, 0.050, 0.855, 0.060)';
export const easeOutQuint = 'cubic-bezier(0.230, 1.000, 0.320, 1.000)';
export const easeInOutQuint = 'cubic-bezier(0.860, 0.000, 0.070, 1.000)';
export const easeInExpo = 'cubic-bezier(0.950, 0.050, 0.795, 0.035)';
export const easeOutExpo = 'cubic-bezier(0.190, 1.000, 0.220, 1.000)';
export const easeInOutExpo = 'cubic-bezier(1.000, 0.000, 0.000, 1.000)';
export const easeInCirc = 'cubic-bezier(0.600, 0.040, 0.980, 0.335)';
export const easeOutCirc = 'cubic-bezier(0.075, 0.820, 0.165, 1.000)';
export const easeInOutCirc = 'cubic-bezier(0.785, 0.135, 0.150, 0.860)';
export const easeInBack = 'cubic-bezier(0.600, -0.280, 0.735, 0.045)';
export const easeOutBack = 'cubic-bezier(0.175, 0.885, 0.320, 1.275)';
export const easeInOutBack = 'cubic-bezier(0.680, -0.550, 0.265, 1.550)';

export const eases = {
  easeInSine,
  easeOutSine,
  easeInOutSine,
  easeInQuad,
  easeOutQuad,
  easeInOutQuad,
  easeInCubic,
  easeOutCubic,
  easeInOutCubic,
  easeInQuart,
  easeOutQuart,
  easeInOutQuart,
  easeInQuint,
  easeOutQuint,
  easeInOutQuint,
  easeInExpo,
  easeOutExpo,
  easeInOutExpo,
  easeInCirc,
  easeOutCirc,
  easeInOutCirc,
  easeInBack,
  easeOutBack,
  easeInOutBack,
} as const;

export const getEasingValues = (easing: keyof typeof eases) =>
  eases[easing].match(/(\d+\.?\d*)/g).map(parseFloat);

// Animations
export const spinAnimation = () => css`
  animation: ${spin()} 1s infinite linear;
`;

export const pulseAnimation = (transform?: string, duration = 3.5) => css`
  animation: ${pulse(transform)} ${duration}s ${easeOutCubic} forwards;
`;

export const aspectRatio = (width: number, height: number) => css`
  aspect-ratio: ${width} / ${height};

  @supports not (aspect-ratio: ${width} / ${height}) {
    &::before {
      float: left;
      padding-top: calc((${height} / ${width}) * 100%);
      content: '';
    }

    &::after {
      display: block;
      content: '';
      clear: both;
    }
  }
`;

export type ColorNamesType = `${ColorNames}`;

export const setTextStyles = (
  color: ColorNamesType,
  textShadow: 'sm' | 'md' | 'lg' | 'xl',
  shadowOpacity?: number
) => css`
  color: ${({ theme }) => theme.colors[color]};

  ${textShadow === 'sm' &&
  css`
    text-shadow: 0 2rem 0
      ${({ theme }) => rgba(theme.colors.black, shadowOpacity || 1)};

    ${mediaTablet(css`
      text-shadow: 0 4rem 0
        ${({ theme }) => rgba(theme.colors.black, shadowOpacity || 1)};
    `)}
  `}

  ${textShadow === 'md' &&
  css`
    text-shadow: 0 4rem 0
      ${({ theme }) => rgba(theme.colors.black, shadowOpacity || 1)};
  `}

  ${textShadow === 'lg' &&
  css`
    text-shadow: 0 4rem 0
      ${({ theme }) => rgba(theme.colors.black, shadowOpacity || 1)};

    ${mediaTablet(css`
      text-shadow: 0 8rem 0
        ${({ theme }) => rgba(theme.colors.black, shadowOpacity || 1)};
    `)}
  `}

  ${textShadow === 'xl' &&
  css`
    text-shadow: 0 8rem 0
      ${({ theme }) => rgba(theme.colors.black, shadowOpacity || 1)};

    ${mediaTablet(css`
      text-shadow: 0 12rem 0
        ${({ theme }) => rgba(theme.colors.black, shadowOpacity || 1)};
    `)}
  `}
`;

type AspectRatioType = [number, number];

export const backgroundImage = (
  image: string,
  ratio?: [number, number],
  imageTablet?: string,
  ratioTablet?: AspectRatioType
) => css`
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(${image});

  ${ratio &&
  css`
    ${aspectRatio(ratio[0], ratio[1])}
  `};

  ${imageTablet &&
  css`
    ${mediaTablet(css`
      background-image: url(${imageTablet});

      ${ratioTablet &&
      css`
        ${aspectRatio(ratioTablet[0], ratioTablet[1])}
      `}
    `)};
  `}
`;

export const mask = (mask?: string, maskTablet?: string) => css`
  ${mask &&
  css`
    mask: url(${mask});
    mask-repeat: no-repeat;
  `};

  ${maskTablet &&
  css`
    ${mediaTablet(css`
      mask: url(${maskTablet});
      mask-repeat: no-repeat;
    `)};
  `}
`;

export const setDefaultBackground = (
  topOffset?: number,
  topOffsetTablet?: number
) => css`
  background-color: ${({ theme }) => theme.colors.black};
  background-image: url(${svgBgTile});
  background-size: calc(100% / 3) auto;

  ${topOffset &&
  css`
    background-position-y: ${topOffset}rem;
  `};

  ${mediaTablet(css`
    background-size: 20% auto;

    ${topOffsetTablet !== undefined &&
    css`
      background-position-y: ${topOffsetTablet}rem;
    `};
  `)}

  ${mediaDesktopWide(css`
    background-size: calc(${layoutMaxWidthDesktop}rem / 5) auto;
    background-position: center top;
  `)};
`;

export const backgroundDecoration = (
  image: string,
  ratio?: [number, number],
  imageTablet?: string,
  ratioTablet?: AspectRatioType
) => css`
  display: block;
  ${backgroundImage(image, ratio, imageTablet, ratioTablet)};
  ${ratio &&
  css`
    width: ${ratio[0] * 2}rem;
  `}

  ${mediaTablet(css`
    ${ratioTablet &&
    css`
      width: ${ratioTablet[0] * 2}rem;
    `}
  `)}
`;

export const widthLimit = () => css`
  max-width: ${layoutMaxWidthDesktop};
  overflow: hidden;
`;

export const glowAnimation = (glowOnHover?: boolean) => css`
  animation: ${glow} 1s linear infinite;

  ${glowOnHover &&
  hover(css`
    filter: drop-shadow(0 0 2rem #ffffff) drop-shadow(0 0 4rem #fff2a8)
      drop-shadow(0 0 4rem #f8e600) drop-shadow(0 0 10rem #ef8533)
      drop-shadow(0 0 20rem #ef0000);
  `)}
`;

export const customScrollbar = () =>
  mediaTablet(css`
    ::-webkit-scrollbar {
      width: 11px; /* vertical scrollbar width */
      height: 11px; /* horizontal scrollbar height */
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.rumSwizzle};
      border-radius: 5px;
      border: 3px solid ${({ theme }) => theme.colors.black};
    }
  `);
