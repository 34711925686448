import { Dialog } from 'ariakit';
import { motion } from 'framer-motion';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import Button from 'components/Button/Button';
import svgClose1 from 'svgs/my-account/close.svg';
import svgClose1Mobile from 'svgs/my-account/close-mobile.svg';
import svgCloseWrapper from 'svgs/my-account/close-wrapper.svg';
import assets, { NewCircuitVariant } from 'utils/assets';
import { backgroundDecoration, setVh } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

import { PopupProps } from './Popup';

export const WrapperComponent = styled.div`
  position: relative;
  display: flex;
`;

export const ContentWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  width: 100%;

  ${mediaTablet(css`
    width: auto;
  `)};
`;

export const Wrapper = styled(motion.div)<{
  $minHeight?: PopupProps['minHeight'];
  $hasCloseButton?: PopupProps['hasCloseButton'];
  $alignment?: PopupProps['alignment'];
  $hasTransparentBackground?: boolean;
}>`
  position: relative;
  z-index: 1;
  pointer-events: all;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => rgba(theme.colors.mirage, 0.8)};
  padding: ${({ theme }) => theme.layout.header.height + 36}rem 0;

  ${mediaTablet(css`
    padding-bottom: ${({ theme }) => theme.layout.footer.height.desktop}rem;
  `)};

  ${({ $minHeight }) =>
    $minHeight &&
    $minHeight.length > 1 &&
    css`
      min-height: ${$minHeight[0]}rem;

      ${mediaTablet(css`
        min-height: ${$minHeight[1]}rem;
      `)};
    `};

  ${({ $hasCloseButton }) =>
    $hasCloseButton &&
    css`
      padding-top: ${({ theme }) => theme.layout.header.height + 84}rem;
    `};

  ${({ $alignment }) =>
    $alignment === 'top-right' &&
    css`
      ${mediaTablet(css`
        align-items: flex-end;
        justify-content: flex-start;
        padding-top: ${({ theme }) => theme.layout.header.height + 64}rem;
        padding-right: 72rem;
      `)}
    `};

  ${({ $alignment }) =>
    $alignment === 'right' &&
    css`
      ${mediaTablet(css`
        align-items: flex-end;
        justify-content: center;
        padding-right: 72rem;
      `)}
    `};

  ${({ $alignment }) =>
    $alignment === 'left' &&
    css`
      ${mediaTablet(css`
        align-items: flex-start;
        justify-content: center;
        padding-left: 72rem;
      `)}
    `};

  ${({ $hasTransparentBackground }) =>
    $hasTransparentBackground &&
    css`
      background-color: unset;
      pointer-events: none;
    `};
`;

Wrapper.defaultProps = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      opacity: { duration: 0.3 },
    },
  },
  exit: {
    opacity: 0,
    transition: {
      delay: 0.075,
      duration: 0.15,
    },
  },
};

export const PopupDialog = styled(Dialog)`
  z-index: ${({ theme }) => theme.layout.zIndex.popup};
  min-height: ${setVh(100)};
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const Backdrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => rgba(theme.colors.mirage, 0.8)};
  overflow-y: auto;
  overflow-x: hidden;

  ${mediaTablet(css`
    overflow: unset;
  `)}
`;

export const CloseButton = styled(Button)`
  position: absolute;
`;

export const CloseButtonWrapper = styled.div<{
  $variant: PopupProps['variant'];
}>`
  ${backgroundDecoration(svgClose1Mobile, [227, 132], svgClose1, [295, 179])};
  position: absolute;
  top: 0;
  right: 0;

  ${({ $variant }) =>
    $variant === NewCircuitVariant.MY_ACCOUNT_POPUP &&
    css`
      z-index: 1;
      right: -330rem;
      top: -68rem;

      ${mediaTablet(css`
        z-index: -1;
        right: -260rem;
        top: -70rem;
      `)}

      ${CloseButton} {
        top: 28rem;
        left: 30rem;

        ${mediaTablet(css`
          right: 39rem;
          top: 38rem;
          left: unset;
        `)}
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.LAB_INVENTORY_POPUP &&
    css`
      z-index: 5;
      ${backgroundDecoration(svgCloseWrapper, [1, 1])};
      height: 4.5em;
      width: 4.5em;
      right: 0.5%;
      top: -2.5%;

      ${mediaTablet(css`
        ${backgroundDecoration(svgCloseWrapper, [1, 1])};
        height: 4.5em;
        width: 4.5em;
        right: 9%;
        top: -3%;
      `)} ${CloseButton} {
        top: 16%;
        right: 16.2%;
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.LAB_INVENTORY_MEATA_POPUP &&
    css`
      z-index: 5;
      ${backgroundDecoration(svgCloseWrapper, [1, 1])};
      height: 4.5em;
      width: 4.5em;
      right: 0.5%;
      top: -2.5%;

      ${mediaTablet(css`
        ${backgroundDecoration(svgCloseWrapper, [1, 1])};
        height: 4.5em;
        width: 4.5em;
        right: -8%;
        top: -3%;
      `)} ${CloseButton} {
        top: 16%;
        right: 16.2%;
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.LAB_INVENTORY_SAUCES &&
    css`
      ${backgroundDecoration(
        assets.svgs.newCircuit[NewCircuitVariant.LAB_INVENTORY_SAUCES].close,
        [74, 74]
      )};
      z-index: 5;
      right: 0;
      top: -50rem;

      ${CloseButton} {
        top: 16.5%;
        right: 17%;
      }

      ${mediaTablet(css`
        left: -11.5em;
        top: -1.5em;

        ${CloseButton} {
          top: 10%;
          right: 7%;
        }
      `)}
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.LAB_WIDE_POPUP &&
    css`
      z-index: 1;
      right: -290rem;
      top: -68rem;

      ${mediaTablet(css`
        z-index: -1;
        right: -260rem;
        top: -70rem;
      `)}

      ${CloseButton} {
        top: 28rem;
        left: 30rem;

        ${mediaTablet(css`
          right: 39rem;
          top: 38rem;
          left: unset;
        `)}
      }
    `};

  ${({ $variant }) =>
    [
      NewCircuitVariant.STATIC_ERROR_ACCOUNT,
      NewCircuitVariant.STATIC_ERROR_DATABASE,
      NewCircuitVariant.STATIC_ERROR_TRANSACTION,
    ].includes($variant) &&
    css`
      z-index: 1;
      right: -290rem;
      top: -68rem;

      ${mediaTablet(css`
        z-index: -1;
        right: -230rem;
        top: -130rem;
      `)}

      ${CloseButton} {
        top: 28rem;
        left: 30rem;

        ${mediaTablet(css`
          right: 39rem;
          top: 38rem;
          left: unset;
        `)}
      }
    `};

  ${({ $variant }) =>
    $variant === NewCircuitVariant.STATIC_ERROR_TRANSACTION &&
    css`
      top: -18rem;
    `};
`;

export const Spacer = styled.div<{ $top?: boolean }>`
  height: 0;
  flex-shrink: 0;
`;

export const Container = styled.div<{
  zIndex?: number;
  halfWidth?: boolean;
}>`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;

  ${({ zIndex }) =>
    typeof zIndex === 'number' &&
    css`
      z-index: ${zIndex};
    `};
`;

export const Content = styled.div`
  flex: 1;
`;

export const Scroll = styled.div<{ $hasTransparentBackground?: boolean }>`
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  pointer-events: all;

  ${({ $hasTransparentBackground }) =>
    $hasTransparentBackground &&
    css`
      pointer-events: none;
    `};
`;
