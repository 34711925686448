import { TooltipStateProps, useTooltipState } from 'ariakit';
import { FC, MouseEventHandler, ReactNode } from 'react';

import TooltipButton from './TooltipButton/TooltipButton';
import TooltipButtonSecondary from './TooltipButton/TooltipButtonSecondary';
import TooltipContent, {
  TooltipContentProps,
} from './TooltipContent/TooltipContent';

import * as Styled from './Tooltip.styles';

export interface TooltipProps {
  text: string | ReactNode;
  label?: string;
  position?: TooltipContentProps['position'];
  arrowPosition?: TooltipContentProps['arrowPosition'];
  arrowVariant?: TooltipContentProps['arrowVariant'];
  arrowText?: TooltipContentProps['arrowText'];
  color?: TooltipContentProps['color'];
  fontVariant?: TooltipContentProps['fontVariant'];
  invertedStyle?: boolean;
  inheritedStyle?: boolean;
  open?: boolean;
  anchor?: ReactNode;
  zIndex?: number;
  offset?: { bottom?: number; top?: number; left?: number; right?: number };
  animatedArrow?: boolean;
  smallWidth?: boolean;
  slide?: boolean;
  nonePointerEvents?: boolean;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
}

const Tooltip: FC<TooltipProps> = ({
  label,
  text,
  position = 'bottom',
  arrowPosition = 'start',
  arrowVariant,
  arrowText,
  offset,
  color = 'rumSwizzle',
  fontVariant = 'sm',
  invertedStyle,
  inheritedStyle,
  open,
  anchor,
  zIndex = 10,
  animatedArrow = true,
  smallWidth = false,
  slide = false,
  nonePointerEvents = true,
}) => {
  const placement = arrowPosition.startsWith('very-')
    ? arrowPosition.substring(5)
    : arrowPosition;

  const tooltip = useTooltipState({
    placement: `${position}-${placement}` as TooltipStateProps['placement'],
    open,
    slide,
    animated: 500,
    fixed: true,
  });

  const [currentPosition, currentArrowPosition] =
    tooltip.currentPlacement.split('-') as [
      TooltipContentProps['position'],
      TooltipContentProps['arrowPosition']
    ];
  const fixedArrowPosition =
    currentArrowPosition === placement ? arrowPosition : currentArrowPosition;

  return (
    <>
      <Styled.Anchor
        state={tooltip}
        $inheritedStyle={inheritedStyle}
        className="tooltip-anchor"
      >
        {anchor ? (
          anchor
        ) : invertedStyle ? (
          <TooltipButtonSecondary label={label} />
        ) : (
          <TooltipButton label={label} />
        )}
      </Styled.Anchor>
      <Styled.TooltipContainer
        key={tooltip.currentPlacement}
        state={tooltip}
        $zIndex={zIndex}
        $position={currentPosition}
        $arrowPosition={fixedArrowPosition}
        $animatedArrow={animatedArrow}
        wrapperProps={{
          style: nonePointerEvents ? { pointerEvents: 'none' } : {},
        }}
        $nonePointerEvents={nonePointerEvents}
      >
        <Styled.OffsetWrapper $offset={offset}>
          <TooltipContent
            text={text}
            position={currentPosition}
            arrowPosition={fixedArrowPosition}
            arrowVariant={arrowVariant}
            color={color}
            fontVariant={fontVariant}
            smallWidth={smallWidth}
            arrowText={arrowText}
          />
        </Styled.OffsetWrapper>
      </Styled.TooltipContainer>
    </>
  );
};

export default Tooltip;
