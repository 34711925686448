export default `
  @font-face {
    font-family: 'Knockout';
    src: url('/fonts/Knockout-HTF50-Welterweight.woff2') format('woff2'),
        url('/fonts/Knockout-HTF50-Welterweight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Knockout';
    src: url('/fonts/Knockout-HTF70-FullWelterwt.woff2') format('woff2'),
        url('/fonts/Knockout-HTF70-FullWelterwt.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Knockout';
    src: url('/fonts/Knockout-HTF72-FullCruiserwt.woff2') format('woff2'),
        url('/fonts/Knockout-HTF72-FullCruiserwt.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Knockout';
    src: url('/fonts/Knockout-HTF91-UltmtMiddlewt.woff2') format('woff2'),
        url('/fonts/Knockout-HTF91-UltmtMiddlewt.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: block;
  }

  @font-face {
      font-family: 'Gotham';
      src: url('/fonts/Gotham-UltraItalic.woff2') format('woff2'),
          url('/fonts/Gotham-UltraItalic.woff') format('woff');
      font-weight: 500;
      font-style: normal;
      font-display: block;
  }

  @font-face {
      font-family: 'Montserrat';
      src: url('/fonts/Montserrat-ExtraBold.woff2') format('woff2'),
          url('/fonts/Montserrat-ExtraBold.woff') format('woff');
      font-weight: 800;
      font-style: normal;
      font-display: block;
  }

  @font-face {
      font-family: 'Montserrat';
      src: url('/fonts/Montserrat-SemiBold.woff2') format('woff2'),
          url('/fonts/Montserrat-SemiBold.woff') format('woff');
      font-weight: 600;
      font-style: normal;
      font-display: block;
  }
`;
