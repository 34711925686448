import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';

export const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 40rem;
`;

export const ContentLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.p`
  ${({ theme: { locale } }) => setTypography('heading2Menu', locale)};
  color: ${({ theme }) => theme.colors.rumSwizzle};
  text-shadow: 0 4rem 0 ${({ theme }) => theme.colors.black};
  margin-left: 20rem;
  text-align: left;

  span {
    display: block;
  }
`;

export const Icon = styled.img`
  width: 95rem;
  height: 95rem;
  margin: 0 40rem 17rem;
  flex-shrink: 0;
`;

export const Chevron = styled.div<{ $open?: boolean }>`
  width: 60rem;
  height: 60rem;
  background: ${({ theme }) => theme.colors.rumSwizzle};
  box-shadow: 0 6rem 0 ${({ theme }) => theme.colors.black},
    0 -2rem 0 ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.black};

  svg {
    transition: all 250ms ease-in-out;
    width: 34rem;

    ${({ $open }) =>
      $open &&
      css`
        transform: scaleY(-1);
      `};
  }
`;
